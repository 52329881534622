import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Close } from 'UI/Icon';
import Button from 'UI/Button';
import { withRouter } from "react-router";

const styles = theme => ({
	root: {
		position: 'absolute',
		width: '100%',
		top: '60px',
		backgroundColor: 'rgba(102,102,102,0.8)',
		zIndex: 1000
	},
	container:{
		...theme.layout.container,
		padding: '16px',
	},
	text:{
		color: "#fff",
		textAlign: 'center',
		fontSize: theme.text.small
	},
	close: {
		fill: '#fff',
		position: 'absolute',
		cursor: 'pointer'
	},
	action: {
		marginTop: '8px',
		
		...theme.flex.container,
		...theme.flex.centerCenter
	}
})

class ActionBar extends PureComponent {
	doAction = () => {
		const { history } = this.props;
		history.push('/extra/contact');
		this.close();
	}
	close = () => {
		const { onClose } = this.props;
		onClose();
	}
	render(){
		const { classes } = this.props;
		return(
			<div className={classes.root}>
				<div className={classes.container}>
					<div className={classes.text}>Voui diventare un nostro inserzionista?</div>
					<div className={classes.close} onClick={this.close}>
						<Close isSmall={true}  />
					</div>
					<div className={classes.action}>
						<Button text="contattaci" isSmall={true} onClick={this.doAction} />
					</div>
				</div>
			</div>
		)
	}
}

ActionBar.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired
}

export default withRouter(injectSheet(styles)(ActionBar));