import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GMarker extends Component {
	componentDidMount(){
		const { onPlace, onClick, onDragEnd, lat, lng, icon, title, isDropping, IsBouncing, isDraggable } = this.props;
		let latlng = new window.google.maps.LatLng(lat || 0, lng ||0);
		let animation = null;
		if(isDropping) animation = window.google.maps.Animation.DROP;
		if(IsBouncing) animation = window.google.maps.Animation.BOUNCE;
		this.marker = new window.google.maps.Marker({
    	position: latlng,
    	title: title,
    	animation: animation,
    	icon: icon,
    	draggable: isDraggable
  	});
  	this.marker.addListener('click', (e) => {
  		if(onClick) onClick(e)
  	});
  	this.marker.addListener('dragend', (e) => {
  		if(onDragEnd) onDragEnd(this.marker.getPosition());
  	});
		if(onPlace) onPlace(this.marker);
	}
	componentWillUnmount(){
		this.marker.setMap(null);
	}
	render(){
		return (
			<div></div>
		);	
	}
}
GMarker.propTypes ={
	lat: PropTypes.number,
	lng: PropTypes.number,
	icon: PropTypes.string,
	title: PropTypes.string,
	isDraggable: PropTypes.bool,
	isDropping: PropTypes.bool,
	IsBouncing: PropTypes.bool,
	onPlace: PropTypes.func,
	onClick: PropTypes.func,
	onDragEnd: PropTypes.func
};

export default GMarker;