import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Spinner } from 'UI/Icon';

const styles = theme => ({
	root:{
		display: 'none',
		position: 'fixed',
		height: '100%',
		width: '100%',
		background: '#d6d6d6bf',
		'&.is-active':{
			display: 'block'
		},
		zIndex: 30000
	},
	container:{
		...theme.flex.container,
		...theme.flex.centerCenter,
		fill:theme.colors.text.default.regular,
		height: '100%',
		width: '100%',
		'& span':{
			height: '50px',
			width: '50px'
		}
	}
});

class Waiting extends Component {
	render(){
		const { classes, isWaiting } = this.props;
		let waitingClass= classes.root;
		if(isWaiting) waitingClass += ' is-active';
		return (
			<div className={waitingClass}>
				<div className={classes.container}>
					<Spinner spin={true}/>
				</div>
			</div>
		);	
	}
}
Waiting.propTypes ={
	classes: PropTypes.object.isRequired,
	isWaiting: PropTypes.bool,
};
export default injectSheet(styles)(Waiting);