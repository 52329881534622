import { 
	AUTH_AUTH
} from "state/actions/auth";
import { Map } from 'immutable';

const initState = Map({
	isLogged: undefined,
	role: 0
});

const auth = ( state = initState, action ) => {
	switch(action.type){
		case AUTH_AUTH: {
			return Map({
				isLogged: action.isLogged,
				role: action.role
			})
		}
		default: {
			return state;
		}
	}
};

export default auth;