import { connect } from 'react-redux';
import { logout, emailVerify } from 'state/actions/auth'
import ConfirmEmail from './components';

const mapStateToProps = (state) => {
	return {
		lastAction: state.getIn(['ui','lastAction'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(logout())
		},
		emailVerify: (token) => {
			dispatch(emailVerify(token))
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfirmEmail)