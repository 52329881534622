import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

const PATH = "/user/";

export const get = async (id) => {
	const url = `${apiUrl}${PATH}get?`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	params.append('id', id);
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const list = async (query) => {
	const url = `${apiUrl}${PATH}list?`;
	const token = await getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	for(const [key, value] of Object.entries(query)) {
		params.append(key, value.toString());
	}
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const getBestDealers = async (perPage, page) => {
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let params = new URLSearchParams();
	if(perPage) params.append('perPage', perPage);
	if(page) params.append('page', page);
	const response = await fetch(apiUrl + "/user/listBestDealers?" + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
};