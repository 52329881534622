import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { LogoDark } from 'Elements/Logo'
import ContactForm from './ContactForm';

const styles = theme => ({
	root: {
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		height: '100%',
		"& form":{
			width: '100%'
		}
	},
	section:{
		...theme.layout.section
	},
	logo: {
		marginBottom: '16px'
	},
	title:{
		...theme.text.title4,
		marginBottom: '16px'
	},
	text: {
		padding: "8px"
	}
});

class Contact extends Component {
	send = (values) => {
		const { send } = this.props;
		const subject = "Richiesta informazioni";
		const name = values.get('name') || '';
		const lastname = values.get('lastname') || '';
		const company = values.get('company') || '';
		const text = values.get('message') || '';
		const email = values.get('email');
		const message = `
			Da: ${name} ${lastname} per conto di ${company}
			Richiesta informazioni:
			${text}
		`;
		send(email, subject, message);
	}
	render(){
		const { classes, t } = this.props;
		return (
			<div className={classes.root}>
				
				<div className={classes.section}>
					<div className={classes.logo}><LogoDark isBig={true} /></div>
					<h2 className={classes.title}>Compila il form</h2>
					<p className={classes.text}>oppure invia una mail a <a href="mailto:info@stonesbusiness.com">info@stonesbusiness.com</a></p>
					<ContactForm t={t} onSubmit={ this.send }/>
				</div>
			</div>
		);
	}
}

Contact.propTypes = {
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	lastAction: PropTypes.string, 
	send: PropTypes.func.isRequired
};

export default injectSheet(styles)(Contact);