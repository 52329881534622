import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Figure from 'UI/Figure';
import { Envelope, EnvelopeOpen } from 'UI/Icon';
import { staticUrl } from 'config/urls';
import injectSheet from 'react-jss';

const styles = theme => ({
	item: {
		...theme.flex.elemNone,
		...theme.flex.container,
		...theme.flex.startCenter,
		border: "1px solid " + theme.colors.border, 
		cursor: 'pointer',
		height: '60px',
		"& .avatar":{
			padding: "0.5rem",
			width: "50px"
		},
		"& .title": {
			...theme.flex.elemAuto,
			padding: "0.5rem"
		},
		"&:hover":{
			backgroundColor: "#f7f7f7"
		},
		"&.active":{
			backgroundColor: "#e0e0e0"
		},
		"& .newMsgs":{
			position: "relative",
			padding: "0.5rem",
			fill: theme.text.default.regular,
			"& .number":{
				display: 'block',
				position: "absolute",
				top: "20px",
				left: "-4px",
				fontSize: "9px",
				textAlign: "center",
				lineHeight: "20px",
				backgroundColor: theme.colors.text.default.regular,
				color: theme.colors.text.inverse.regular,
				borderRadius: "20px",
				height: "20px",
				width: "20px"
			}
		}
	}
})

class Item extends PureComponent {
	select = () => {
		const { id, onSelect } = this.props;
		if(onSelect && id) onSelect(id);
	}
	render(){
		const { classes, conversation, selected } = this.props;
		const user = conversation.get('recipient');
		const messageToRead = conversation.get('messageToRead')
		let name, url;
		if(user){
			let kind = user.get('kind');
			name = (kind === "company")? user.getIn(['name','company']): user.getIn(['name','first']) + ' ' +  user.getIn(['name','last']);
			url = staticUrl + '/' + user.getIn(['images',0,'urls','ld']) || "/assets/png/user.png";
		}
		return (
			<div 
				className={classNames(classes.item, {
					"active": selected
				})}
				onClick={this.select}
			>
				<div className="avatar">
					{ url && <Figure url={url} shape="hexagon"/> }
					{ !url && <Figure url="/assets/png/user.png" shape="hexagon"/> }
				</div>
				<div className="title"> {name} </div>
				{ messageToRead > 0 && <div className="newMsgs"><Envelope /><span className="number">{ messageToRead }</span></div>}
				{ messageToRead <= 0 && <div className="newMsgs"><EnvelopeOpen /></div>}
			</div>
		);
	}
}
Item.propTypes ={
	classes: PropTypes.object.isRequired,
	id: PropTypes.string,
	conversation: PropTypes.instanceOf(Map),
	selected: PropTypes.bool,
	onSelect: PropTypes.func.isRequired
};
export default injectSheet(styles)(Item);