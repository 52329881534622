import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	details:{
		...theme.flex.elemSetFlex(50,'row'),
		paddingLeft: "3rem",
		paddingTop: "3rem",
		position: 'relative',
		"& .info":{
			marginBottom: ".5rem"
		},
		"& .action":{
			position: "absolute",
			top: "0px"
		},
		"& .messageCall":{
			color: theme.colors.error
		},
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		}	
	},
	maps:{
		height: "200px"
	},
	title:{
		...theme.text.title3
	},
	label:{
		fontSize: theme.text.small
	}
});

class Details extends Component{
	sendMessage = (values) => {
		const  { user, sendMessage } = this.props;
		if(user && sendMessage){
			const id = user.get('_id');
			const message = values.get('message');
			sendMessage(message, id);
		}
	}
	render(){
		const { classes, category } = this.props;
		return (
			<div className={classes.details}>
				<h1 className={classes.title}> { category.get('name') } </h1>
				{ category.get('description') && <p className="info"><span className={classes.label}>descrizione:</span><span> { category.get('description') } </span></p> }
			</div>
		);
	}
}
Details.propTypes = {
	classes: PropTypes.object.isRequired,
	category: PropTypes.instanceOf(Map).isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(Details);