import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import Login from 'Routes/Auth/Login';
import Logout from 'Routes/Auth/Logout';
import Signup from 'Routes/Auth/Signup';
import RecoverPassword from 'Routes/Auth/RecoverPassword';
import ResetPassword from 'Routes/Auth/ResetPassword';
import AppResetPassword from 'Routes/Auth/AppResetPassword';
import ConfirmEmail from 'Routes/Auth/ConfirmEmail';
import AppConfirmEmail from 'Routes/Auth/AppConfirmEmail';
import ErrorPage from 'Routes/Errors/404';


class Router extends Component {
	isLogged = () => {
		const { isLogged } = this.props;
		if(isLogged === true) return true;
		else if(isLogged === false) return false;
		else return null;
	}
	render() {
		const { t } = this.props;
		return (
			<Switch>
				<Route exact path="/auth/login" render={(props)=>{
					if(this.isLogged() === true) return (<Redirect to='/'/>);
					return (<Login t={t} {...props} />)
				}} />
				<Route exact path="/auth/signup" render={(props)=>{
					if(this.isLogged() === true) return (<Redirect to='/'/>);
					return (<Signup t={t} {...props} />)
				}} />
				<Route exact path="/auth/logout" render={(props)=>{
					if(this.isLogged() === true) return (<Logout/>);
					return (<Redirect to='/'/>)
				}} />
				<Route exact path="/auth/recoverPassword" render={(props) => (<RecoverPassword t={t} {...props} />)} />
				<Route exact path="/auth/resetPassword/:token" render={(props) => (<ResetPassword t={t} {...props} />)} />
				<Route exact path="/auth/appResetPassword/:token" render={(props) => (<AppResetPassword t={t} {...props} />)} />
				<Route exact path="/auth/confirmEmail/:token" render={(props)=>{ return <ConfirmEmail t={t} {...props}/>}}/>
				<Route exact path="/auth/appConfirmEmail/:token" render={(props)=>{ return <AppConfirmEmail t={t} {...props}/>}}/>
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}

Router.propTypes ={
	t: PropTypes.func.isRequired,
	isLogged: PropTypes.bool,
	role: PropTypes.number
};

export default Router;