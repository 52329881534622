export default theme => ({
	field:{
		...theme.flex.container,
		...theme.flex.rows,
		minWidth: "100px",
		margin: ".5em 0",
		"&.inline":{
			...theme.flex.columns,
		},
		"&.is-full":{
			...theme.flex.elemSetFlex(100, 'row')
		},
		"&.is-half":{
			...theme.flex.elemSetFlex(50, 'row')
		}
	},
	menuContainer:{
		position: "relative"
	},
	fieldGroup:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.startCenter,
		"& > div":{
			...theme.flex.elemGrow,
			marginLeft:"1em",
			marginRight:"1em",
		},
		"& > div:first-child":{
			marginLeft:"0",
		},
		"& > div:last-child":{
			marginRight:"0",
		},
		"&.is-marginless":{
			"& > div":{
				margin: "0"
			}
		}
	},
	label:{
		fontSize: theme.text.small,
		lineHeight: "24px",
		color:theme.colors.text.default.regular
	},
	control:{
		...theme.flex.container,
		...theme.flex.rows,
		position: "relative",
	},
	input:{
		fontSize: theme.text.default,
		lineHeight: "24px",
		borderRadius: "4px",
		padding: "2px 8px",
		border: "1px solid " + theme.colors.border,
		color:theme.colors.text.default.regular,
		"&.has-icon":{
			paddingLeft: "2.25em"
		},
		"&:focus":{
			outline: 'none',
			borderColor: theme.colors.primary,
			"&~$icon":{
				fill: '#7a7a7a'
			}
		},
		"&.is-error":{
			outline: 'none',
			color: theme.colors.error,
			borderColor: theme.colors.error
		},
		"&.is-success":{
			outline: 'none',
			borderColor: theme.colors.success
		},
	},
	icon:{
		fill: "#dbdbdb",
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		pointerEvents: 'none',
		position: 'absolute',
		left: '5px',
		zIndex: 4
	},
	error:{
		margin: "0px",
		fontSize: "10px",
		color: theme.colors.error
	},
	submit:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.centerStart,
		display:'inline-flex',
		height: "2.25em",
		lineHeight: "1.5",
		padding: "calc(.375em - 1px) calc(.625em - 1px)",
		border: "1px solid " + theme.colors.border,
		fontSize: theme.text.default,
		textDecoration: "none",
		cursor: "pointer",
		borderRadius: "4px",
		userSelect: "none",
		...theme.colors.button.default,
		"& span:last-child:not(:first-child)":{
			marginLeft: "0.5em"
		},
		"&:active, &.active, &.is-active":{
			...theme.colors.button.active.default
		},
		"&:hover":{
			...theme.colors.button.hover.default
		},
		"&.is-primary":{
			...theme.colors.button.primary,
			"&:hover":{
				...theme.colors.button.hover.primary
			},
			"&:active, &.active, &.is-active":{
				...theme.colors.button.active.primary
			}
		},
		"&.is-secondary":{
			...theme.colors.button.secondary,
			"&:hover":{
				...theme.colors.button.hover.secondary
			},
			"&:active, &.active, &.is-active":{
				...theme.colors.button.active.secondary
			}
		},
		"&.is-disabled":{
			...theme.colors.button.disabled,
			 pointerEvents: "none"
		}
	},
	iconSelect:{
		...theme.colors.button.default,
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		margin: ".5em",
		padding: ".5em",
		cursor: "pointer",
		"& .label":{
			marginTop: ".5em",
			fontSize:  theme.text.small,
		},
		"&:hover":{
			...theme.colors.button.hover.default
		},
		"&.is-selected":{
			...theme.colors.button.active.default
		}
	},
	imageSelect:{
		maxHeight: "150px",
		maxWidth: "150px",
		height: 'auto',
		border: "1px solid " + theme.colors.border,
		...theme.flex.container,
		...theme.flex.centerCenter,
		padding: ".2em",
		cursor: "pointer",
		"&:hover":{
			border: "1px solid " + theme.colors.primary
		},
		"&.is-selected":{
			...theme.colors.button.primary
		}
	},
	textSelect:{
		height: "50px",
		minWidth: "140px",
		border: "1px solid " + theme.colors.border,
		...theme.flex.container,
		...theme.flex.centerCenter,
		padding: ".2em",
		cursor: "pointer",
		"&:hover":{
			border: "1px solid " + theme.colors.primary
		},
		"&.is-selected":{
			...theme.colors.button.primary
		},
		"&.is-disabled":{
			...theme.colors.button.disabled,
			 pointerEvents: "none"
		}
	},
	options:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.wrap,
		"& div":{
			...theme.flex.elemAuto
		}
	},
	mapContainer:{
		width: "100%",
		height: "400px"
	},
	switch:{
		cursor: 'pointer',
		position: 'relative',
		height: '24px',
		width: '48px',
		borderRadius: '4px',
		background: "#d2d2d2",
		"&.active":{
			background: theme.colors.primary
		}
	},
	switchBtn: {
		height: '16px',
		width: '16px',
		top: '4px',
		left: '4px',
		borderRadius: '4px',
		background: "#ffffff",
		position: 'absolute',
		transition: "all .25s ease-out",
		"&.active":{
			left: '26px'
		}
	},
	switchIcon: {
		fill: "#7a7a7a",
		height: '100%',
		marginRight: '1rem',
		display: 'flex',
		alignItems: 'center',
		pointerEvents: 'none',
		zIndex: 4
	},
	separator: {
		...theme.flex.elemAuto
	}
});