import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles =  theme => ({
	root:{
		display: 'none',
		position: 'fixed',
		height: '100%',
		width: '100%',
		'&.is-active':{
			display: 'block'
		}
	},
	container:{
    position: "absolute",
    bottom: 0,
		...theme.flex.container,
		...theme.flex.centerCenter,
		fill:theme.colors.text.default.regular,
		width: '100%',
	},
	body:{
		width: '400px',
		padding: "1.25rem 2.5rem 1.25rem 1.5rem",
		background: '#ffffff',
		textAlign: 'center',
		'&.is-success':{
			backgroundColor: theme.colors.success
		},
		'&.is-error':{
			backgroundColor: theme.colors.error
		},
		'&.is-warning':{
			backgroundColor: theme.colors.warning
		},
		'&.is-primary':{
			backgroundColor: theme.colors.primary
		}
	}
});

class Notification extends Component {
	constructor(props){
		super(props);
		this.state = {
			isOpened: false
		}
	}
	open = () => {
		this.setState({isOpened:true});
	}
	close = () => {
		this.setState({isOpened:false});
	}
	componentDidUpdate(prevProps){
		const { children } = this.props;
		if(children && prevProps.children !== children){
			this.open();
			setTimeout(() => {
  			this.close();
			}, 3000);
		}
	}
	render(){
		const { classes, children, isError, isWarning, isPrimary, isSuccess } = this.props;
		const { isOpened } = this.state;
		let notificationClass = classes.root;
		let bodyClass = classes.body;
		if(isOpened) notificationClass += ' is-active';
		if(isError) bodyClass += ' is-error';
		if(isWarning) bodyClass += ' is-warning';
		if(isPrimary) bodyClass += ' is-primary';
		if(isSuccess) bodyClass += ' is-success';
		return (
			<div className={notificationClass}>
				<div className={classes.container}>
					<div className={bodyClass}>
						{ children }
					</div>
				</div>
			</div>
		);	
	}
}
Notification.propTypes ={
	classes: PropTypes.object.isRequired,
	isPrimary: PropTypes.bool,
	isSuccess: PropTypes.bool,
	isError: PropTypes.bool,
	isWarning: PropTypes.bool,
	children: PropTypes.node
};
export default injectSheet(styles)(Notification);