import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

const styles = theme => ({
	root: {
		height: '32px',
		backgroundColor: theme.colors.primary,
	},
	container:{
		height: '32px',
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.startCenter
	},
	link:{
		color: theme.colors.text.inverse.regular,
		textDecoration: "none",
		fontSize: theme.text.small,
		padding: "0 .5rem",
		"&:hover":{
			color: theme.colors.text.inverse.hover
		},
	}
})

class Footer extends PureComponent{
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root }>
				<div className={classes.container}>
					<Link className={classes.link} to="/legal">Stones Business S.R.L.</Link>
					<Link className={classes.link} to="/legal/policy">Privacy Policy</Link>
					<Link className={classes.link} to="/extra/contact">Contattaci</Link>
					{/*<Link to="/">Terms & Condictions</Link>
					<Link to="/">Privacy</Link>
					<Link to="/">Cookies Policy</Link>*/}
				</div>
			</div>
		);	
	}
}

Footer.propTypes ={
	classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(Footer);