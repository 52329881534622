import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import Button from 'UI/Button';
import MessageForm from './MessageForm';
import injectSheet from 'react-jss';
import { GMap, GMarker } from 'lib/google-maps';

const styles = theme => ({
	details:{
		...theme.flex.elemSetFlex(50,'row'),
		paddingLeft: "3rem",
		paddingTop: "3rem",
		position: 'relative',
		"& .info":{
			marginBottom: ".5rem"
		},
		"& .action":{
			position: "absolute",
			top: "0px"
		},
		"& .messageCall":{
			color: theme.colors.error
		},
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		}	
	},
	maps:{
		height: "200px"
	},
	title:{
		...theme.text.title3
	},
	label:{
		fontSize: theme.text.small
	}
});

class Details extends Component{
	sendMessage = (values) => {
		const  { user, sendMessage } = this.props;
		if(user && sendMessage){
			const id = user.get('_id');
			const message = values.get('message');
			sendMessage(id, message);
		}
	}
	render(){
		const { classes, user, isLogged, t } = this.props;
		let kind, displayName, country, state, city, lat, lng;
		if(user){
			country = user.getIn(['address', 'country']);
			state = user.getIn(['address', 'state']);
			city = user.getIn(['address', 'city']);
			kind = user.get('kind');
			lng = user.getIn(['geoloc', 'coordinates', 0]);
			lat = user.getIn(['geoloc', 'coordinates', 1]);
			if(kind === "company"){
				displayName = user.getIn(['name','company'])
			}else{
				displayName = user.getIn(['name','first'])
				displayName += ' ' + user.getIn(['name','last'])
			}
		}
		return (
			<div className={classes.details}>
				<h1 className={classes.title}> { displayName } </h1>
				{ country && <p className="info"><span className={classes.label}>{t('address.country')}:</span><span> { country } </span></p> }
				{ state && <p className="info"><span className={classes.label}>{t('address.state')}:</span><span> { state } </span></p> }
				{ city && <p className="info"><span className={classes.label}>{t('address.city')}:</span><span> { city } </span></p> }
				{ (lat && lng) &&
					<div className={classes.maps}>
						<GMap lat={lat} lng={lng} zoom={12}>
							{ lat && lng && <GMarker lat={lat} lng={lng} /> }
						</GMap>
					</div>
				}
				{ isLogged && <MessageForm t={t} onSubmit={this.sendMessage} /> }
				{ !isLogged && <div className="messageCall"> {t('message.call')} <Button text={t('login.enter')} href="/login" /></div> }
			</div>
		);
	}
}
Details.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.instanceOf(Map).isRequired,
	isLogged: PropTypes.bool,
	sendMessage: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(Details);