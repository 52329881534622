import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';
const PATH = "/conversation/";

export const start = async (recipient) => {
	const url = `${apiUrl}${PATH}start`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { recipient }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const remove = async (id) => {
	const url = `${apiUrl}${PATH}remove`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const block = async (id) => {
	const url = `${apiUrl}${PATH}block`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const unblock = async (id) => {
	const url = `${apiUrl}${PATH}unblock`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const emptyMessagesToRead = async (id) => {
	const url = `${apiUrl}${PATH}emptyMessageToRead`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const get = async (id) => {
	const url = `${apiUrl}${PATH}get?`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let params = new URLSearchParams();
	params.append('id', id);
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const getMessagesToRead = async () => {
	const url = `${apiUrl}${PATH}messageToRead?`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const list = async (query) => {
	const url = `${apiUrl}${PATH}listMine?`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	for(const [key, value] of Object.entries(query)) {
		params.append(key, value.toString());
	}
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}