import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Immutable from 'immutable';
import { staticUrl } from 'config/urls';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import mockImage from 'images/mock.jpg';

const styles = (theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		height: '65vh'
	},
	action:{
		position: 'absolute',
		zIndex: 5,
		display: 'flex',
		flexDirection: 'column',
		bottom: '16px',
		right: '16px'
	},
	actionButton:{
		margin: '8px'
	},
	item1: {
		flex: 'none',
		width: '100%',
		overflow: 'hidden',
		border: '1px solid #dfe1e5',
		backgroundColor: '#000000',
		['@media ' + theme.media.mobile]: {
			width: '67%'
		},
		['@media ' + theme.media.laptop]:{
			width: '50%',
			'&.double':{
				width: '67% !important',
			}
		},
		'&.single':{
			width: '100% !important',
		}
	},
	row1: {
		flexDirection: 'column',
		flex: 'none',
		display: 'none',
		['@media ' + theme.media.mobile]: {
			display: 'flex',
			width: '33%'
		},
		['@media ' + theme.media.laptop]:{
			width: '25%',
			'&.double':{
				width: '33% !important',
			}
		}
	},
	row2: {
		flexDirection: 'column',
		flex: 'none',
		display: 'none',
		['@media ' + theme.media.mobile]:{
			display: 'flex',
			width: '25% !important'
		}
	},
	item: {
		flex: 1,
		overflow: 'hidden',
		border: '1px solid #dfe1e5',
		backgroundColor: '#000000'
	},
	image: {
		cursor: 'pointer',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		height: '100%',
		transition: "all .25s",
		'&:hover':{
			transform: 'scale(1.02) !important'
		},
		'&.deactive':{
			opacity: .7
		}
	}
});

class ImagesHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			hover:null
		}
	}
	setHover = (hover) => {
		this.setState({
			hover:hover
		});
	}
	openPhotos = (index) => {
		const { onOpenPhotos } = this.props;
		onOpenPhotos(index);
	}
	openPhotosManage = () => {
		const { onOpenPhotosManage } = this.props;
		onOpenPhotosManage();
	}
	render(){
		const { classes, images, list, t } = this.props;
		const { hover } = this.state;
		let image1, image2, image3, image4, image5;
		if(!list || list.size === 0){
			image1 = mockImage;
		}else if(list && images){	
			if(images.getIn([list.get(0), 'url'])) image1 = staticUrl + '/' + images.getIn([list.get(0), 'url']);
			if(images.getIn([list.get(1), 'url'])) image2 = staticUrl + '/' + images.getIn([list.get(1), 'url']);
			if(images.getIn([list.get(2), 'url'])) image3 = staticUrl + '/' + images.getIn([list.get(2), 'url']);
			if(images.getIn([list.get(3), 'url'])) image4 = staticUrl + '/' + images.getIn([list.get(3), 'url']);
			if(images.getIn([list.get(4), 'url'])) image5 = staticUrl + '/' + images.getIn([list.get(4), 'url']);
		}
		return(
			<div className={classes.root}>
				<div className={classes.action}>
					<div className={classes.actionButton}>
						<Button variant="contained" size="small" color="primary" text={t('warehouse.watchPhotos')} onClick={()=>{this.openPhotos(0)}} />
					</div>
					<div className={classes.actionButton}>
						<Button className={classes.actionButton} variant="contained" size="small" color="primary" text={t('warehouse.managePhotos')} onClick={()=>{this.openPhotosManage()}} />
					</div>
				</div>
				<div className={classNames(classes.item1, {
					'single': !image2,
					'double': !image4
				})}>
					<div 
						className={classNames(classes.image, {
									'deactive': (hover !==null && hover !== 'image1')
								})}
						style={{backgroundImage: 'url(' + image1 + ')'}} 
						onMouseEnter ={()=>{this.setHover('image1')}}
						onMouseLeave ={()=>{this.setHover(null)}}
						onClick={()=>{this.openPhotos(0)}}
					/>
				</div>
				{ (image2 && image3 ) &&
					<div className={classNames(classes.row1, {
						'double': !image4
					})}>
						<div className={classes.item}>
							<div
								className={classNames(classes.image, {
									'deactive': (hover !==null && hover !== 'image2')
								})}
								style={{backgroundImage: 'url(' + image2 + ')'}}
								onMouseEnter ={()=>{this.setHover('image2')}}
								onMouseLeave ={()=>{this.setHover(null)}}
								onClick={()=>{this.openPhotos(1)}}
							/>
						</div>
						<div className={classes.item}>
							<div 
								className={classNames(classes.image, {
									'deactive': (hover !==null && hover !== 'image3')
								})}
								style={{backgroundImage: 'url(' + image3 + ')'}}
								onMouseEnter ={()=>{this.setHover('image3')}}
								onMouseLeave ={()=>{this.setHover(null)}}
								onClick={()=>{this.openPhotos(2)}}
							/>
						</div>
					</div>
				}
				{ (image4 && image5 ) &&
					<div className={classes.row2}>
						<div className={classes.item}>
							<div
								className={classNames(classes.image, {
									'deactive': (hover !==null && hover !== 'image4')
								})}
								style={{backgroundImage: 'url(' + image4 + ')'}}
								onMouseEnter ={()=>{this.setHover('image4')}}
								onMouseLeave ={()=>{this.setHover(null)}}
								onClick={()=>{this.openPhotos(3)}}
							/>
						</div>
						<div className={classes.item}>
							<div
								className={classNames(classes.image, {
									'deactive': (hover !==null && hover !== 'image5')
								})}
								style={{backgroundImage: 'url(' + image5 + ')'}}
								onMouseEnter ={()=>{this.setHover('image5')}}
								onMouseLeave ={()=>{this.setHover(null)}}
								onClick={()=>{this.openPhotos(4)}}
							/>
						</div>
					</div>
				}
			</div>
		)
	}
}

ImagesHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	list: PropTypes.instanceOf(Immutable.List),
	images: PropTypes.instanceOf(Immutable.Map),
	onOpenPhotos: PropTypes.func.isRequired,
	onOpenPhotosManage: PropTypes.func.isRequired
};

export default injectSheet(styles)(ImagesHeader);