import { connect } from 'react-redux';
import { get, uploadImage, removeImage } from 'state/actions/stone';
import Images from './components';

const mapStateToProps = (state) => {
	return {
		stones: state.getIn(['stone','stones']),
		error: state.getIn(['ui','error']),
		lastAction: state.getIn(['ui','lastAction']),
		isUploading: state.getIn(['ui','isUploading']),
		uploadingProgress: state.getIn(['ui','uploadingProgress'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		uploadImage: (id, image, index) => {
			dispatch(uploadImage(id, image, index));
		},
		removeImage: (id, imageId) => {
			dispatch(removeImage(id, imageId));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Images)