import { 
	WAREHOUSE_GET,
	WAREHOUSE_LIST,
	WAREHOUSE_UPDATE,
	WAREHOUSE_EMPTY
} from "state/actions/warehouse";
import { fromJS, Map, OrderedMap } from 'immutable';

const initState = Map({
	selected: [],
	page: 0,
	perPage: 0,
	results: 0,
	warehouses: OrderedMap({})
});

const warehouse = ( state = initState, action ) => {
	switch(action.type){
		case WAREHOUSE_GET: {
			return state.setIn(['warehouses', action.warehouse._id], fromJS(action.warehouse));
		}
		case WAREHOUSE_LIST: {
			let _warehouses = [];
			const { warehouses, page, perPage, results } = action;
			for(let i = 0; i < warehouses.length; i++){
				let _warehouse = [ warehouses[i]._id, fromJS(warehouses[i])];
				_warehouses.push(_warehouse);
			}
			return state.mergeDeep({
				page,
				perPage,
				results,
				warehouses: OrderedMap(_warehouses),
			});
		}
		case WAREHOUSE_UPDATE: {
			const warehouse = state.getIn(['warehouses', action.id]);
			return state.setIn(['warehouses', action.id], warehouse.mergeDeep(fromJS(action.warehouse)));
		}
		case WAREHOUSE_EMPTY:{
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default warehouse;