import { connect } from 'react-redux';
import { setReady } from 'lib/google-maps/actions';
import Loader from '../components';

const mapStateToProps = (state) => {
	return {
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		setReady:() => {
			dispatch(setReady());
		}
	}
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Loader)