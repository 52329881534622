import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	results:{
		...theme.flex.container,
		...theme.flex.centerCenter
	},
	message:{
		padding: '32px',
		textAlign: 'center',
		...theme.text.title2
	}
});

class EmptyResults extends Component{
	select = (permalink) => {
		const { onSelect } = this.props;
		if(permalink) onSelect(permalink);
	}
	addImpression = (id) => {
		const { onAddImpression } = this.props;
		if(id) onAddImpression(id);
	}
	render(){
		const { classes, t } = this.props;
		return (
			<div className={classes.root}>
				<h3 className={classes.message} >{t('warehouse.search.noResults')}</h3>
			</div>
		);
	}
}
EmptyResults.propTypes = {
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(EmptyResults);