import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types';

import Tracker from './Tracker';

class GAnalytics extends Component {
	render(){
		const { id } = this.props;
		return (
			<Route render={(props)=>{ 
				return <Tracker id={id} {...props} />}}/>
		)
	}
}

GAnalytics.propTypes = {
	id: PropTypes.string
};

export default GAnalytics;