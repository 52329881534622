import { connect } from 'react-redux';
import { getBestDealers, empty } from 'state/actions/user';
import RankingBox from './components';

const mapStateToProps = (state) => {
	return {
		users: state.getIn(['user', 'users'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		getBestDealers: (perPage, page) => {
			dispatch(getBestDealers(perPage, page));
		},
		empty: () => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RankingBox)