import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	root: {
		...theme.flex.container,
		"& .link":{
			padding: ".75em",
			textDecoration: "none",
			color: theme.colors.text.default.regular,
			border: "1px solid " + theme.colors.border,
			backgroundColor: theme.colors.background,
			textAlign: "center",
			...theme.flex.elem,
			"&:hover":{
				backgroundColor: theme.colors.colorLuminance(theme.colors.background, -0.1),
				color: theme.colors.colorLuminance(theme.colors.text.default.regular, 0.1),
				fill: theme.colors.colorLuminance(theme.colors.text.default.regular, 0.1),
			},
			"&.active":{
				backgroundColor: theme.colors.colorLuminance(theme.colors.background, -0.2),
				color: theme.colors.colorLuminance(theme.colors.text.default.regular, 0.2),
				fill: theme.colors.colorLuminance(theme.colors.text.default.regular, 0.2),
			},
		}
	}
});

class Header extends PureComponent{
	render(){
		const { classes, links } = this.props;
		return (
			<div className={classes.root} >
				{ links }
			</div>
		);
	}
}
Header.propTypes = {
	classes: PropTypes.object,
	links: PropTypes.array
};

export default injectSheet(styles)(Header);