import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	root: {
		padding: "1.5rem"
	},
});

class Body extends Component{
	render(){
		const { classes, children } = this.props;
		return (
			<div className={classes.root} >
				{ children }
			</div>
		);
	}
}
Body.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node
};

export default injectSheet(styles)(Body);