import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class UserIcon extends PureComponent{
	render(){
		const { classes } = this.props;
		return (
			<span className={classes.icon}>
				<svg viewBox="0 0 512 512">
					<path xmlns="http://www.w3.org/2000/svg" d="M256 0c88.366 0 160 71.634 160 160s-71.634 160-160 160S96 248.366 96 160 167.634 0 256 0zm183.283 333.821l-71.313-17.828c-74.923 53.89-165.738 41.864-223.94 0l-71.313 17.828C29.981 344.505 0 382.903 0 426.955V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-37.045c0-44.052-29.981-82.45-72.717-93.134z"/>
				</svg>
			</span>
		)
	}
}
UserIcon.propTypes ={
	classes: PropTypes.object
};

export default injectSheet(style)(UserIcon);