import { connect } from 'react-redux';
import { getMine, removeImage, uploadImage, update, uploadLogo, empty } from 'state/actions/warehouse';
import Search from './components';

const mapStateToProps = (state) => {
	return {
		warehouses: state.getIn(['warehouse','warehouses']),
		lastAction: state.getIn(['ui','lastAction']),
		isUploading: state.getIn(['ui','isUploading']),
		uploadingProgress: state.getIn(['ui','uploadingProgress']),
		error: state.getIn(['ui', 'error'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: () => {
			dispatch(getMine());
		},
		update: (id, warehouse) => {
			dispatch(update(id, warehouse))
		},
		removeImage:(id, imageId) => {
			dispatch(removeImage(id, imageId));
		},
		uploadImage:(id, image, index) => {
			dispatch(uploadImage(id, image, index));
		},
		uploadLogo:(id, image) => {
			dispatch(uploadLogo(id, image))
		},
		empty: () => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Search)