export default {
	"category":{
		"type":{
			"Granite": "جرانيت",
			"Limestone": "حجر الكلس",
			"Marble": "رخام",
			"Onyx": "عقيق يماني",
			"Quartzite": "الكوارتز",
			"Stone": "حجر",
			"Travertine": "الترافرتين حجر جيري",
			"Ceramic": "سيراميك"
		}
	},
	"errors":{
		"required": "مطلوب",
		"userNotFound": "المستخدم غير موجود",
		"wrongPassword": "كلمة مرور خاطئة",
		"invalidEmail": "بريد إلكتروني خاطئ",
		"noMatchPassword": "كلمة المرور غير مطابقة",
		"UserAlreadyExists": "هذا البريد الإلكتروني مأخوذ من قبل مستخدم مسجل"
	},
	"login":{
		"title": "دخول",
		"login": "ادخل",
		"signup": "تسجيل",
		"email": "ايميل",
		"password": "كلمة السر",
		"enter": "ادخل",
		"forgotPassword": "هل نسيت كلمة السر"
	},
	"material":{
	},
	"navbar":{
		"enter": "ادخل"
		},
	"signup":{
		"title": "تسجيل",
		"login": "سجل",
		"signup": "تسجيل",
		"email": "ايميل",
		"password":  "كلمة السر",
		"passwordB": "تاكيد كلمة السر",
		"signin": "سجل"
	},
	"stone":{
		"name": "اسم",
		"shape": "جدول توضيحي",
		"category": "نوع الحجر",
		"material": "انواع الحجر",
		"height": "ارتفاع",
		"width": "العرض",
		"depth": "العمق",
		"thickness": "السماكة",
		"weight": "وزن",
		"coverArea": "المساحة العامة",
		"quantity": "الكمية",
		"shapeType":{
			"shapeless": "حجر غير مربع",
			"block": "صخرة ",
			"slab": "لوح",
			"tile": "قطع رخام صغيرة"
		},
		"search":{
			"title": "البحث عن انواع الحجر",
			"minHeight": "اقل ارتفاع",
			"maxHeight": "اكبر ارتفاع",
			"minWidth": "اقل عرض",
			"maxWidth": "اكتر عرض",
			"minDepth": "اقل عمق",
			"maxDepth": "اكتر عمق",
			"minThickness": "اقل سماكة",
			"maxThickness": "اكتر سماكة",
			"minWeight": "اقل وزن",
			"maxWeight": "اكتر وزن",
			"minCoverArea": "المساحة الضغيرة",
			"maxCoverArea": "المساحةالكبيرة",
			"search": "بحث"
		},
		"list":{
			"title": "التحميل الخاص",
			"add": "اضافة",
			"images": "الصور",
			"modify": "تعديل",
			"view": "مشاهدة",
			"delete": "ازالة",
			"search": "بحث"
		},
		"add":{
			"addImages": "اضيف صورة",
			"where": "اين   يوجد",
			"save": "احفظ",
			"cancel": "الغي"
		},
		"update":{
			"where": "اين   يوجد",
			"save": "احفظ",
			"cancel": "الغي"
		},
		"images":{
			"addImages": "اضيف صورة",
			"add": "اضافة",
			"cancel": "الغي"
		}
	},	
	"user":{
		"kind": "نوع المستخدم",
		"company": "شركة",
		"pro": "تاجر",
		"private": "الشخصية",
		"firstName": "اسم",
		"lastName": "الشهرة",
		"companyName": "اسم الشركة",
		"telephone": "نمرة التليفون",
		"email": "ايميل",
		"taxCode": "ظريبه الشراء",
		"update":{
			"save": "احفظ",
			"cancel": "الغي"
		},
		"images":{
			"addImages": "اضيف صورة",
			"add": "اضافة",
			"cancel": "الغي"
		}		
	},
	"address":{
		"country":"بلد",
		"state": "مقاطعة / مقاطعة / ولاية",
		"city": "مدينة",
		"address": "عنوان",
		"postalCode": "الرمز البريدي"
	},
	"message":{
		"call": "تسجيل الدخول أو التسجيل لإرسال رسالة إلى البائع",
		"label": "اكتب رسالتك",
		"send": "إرسال",
		"empty": "لا رسالة"
	}	
}