export default theme => ({
	field:{
		...theme.flex.container,
		...theme.flex.rows,
		margin: ".5em 0",
		"&.inline":{
			...theme.flex.columns,
		}
	},
	menuContainer:{
		position: "relative"
	},
	control:{
		...theme.flex.container,
		...theme.flex.rows,
		position: "relative"
	},
	input:{
		fontSize: theme.text.default,
		lineHeight: "24px",
		borderRadius: "4px",
		padding: "2px 8px",
		border: "1px solid " + theme.colors.border,
		color:theme.colors.text.default.regular,
		"&:focus":{
			outline: 'none',
			borderColor: theme.colors.primary,
			"&~$icon":{
				fill: '#7a7a7a'
			}
		},
		"&.is-error":{
			outline: 'none',
			color: theme.colors.error,
			borderColor: theme.colors.error
		},
		"&.is-success":{
			outline: 'none',
			borderColor: theme.colors.success
		},
		"&.has-icon":{
			paddingLeft: "2.25em"
		},
		"&.has-geolocalize":{
			paddingRight: "2.25em"
		},
	},
	icon:{
		fill: "#dbdbdb",
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		pointerEvents: 'none',
		position: 'absolute',
		left: '5px',
		zIndex: 4
	},
	geolocIcon:{
		cursor: 'pointer',
		fill: "#dbdbdb",
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		right: '5px',
		zIndex: 4,
		'&:hover':{
			fill: '#7a7a7a'
		},
		'&.active':{
			fill: theme.colors.primary
		}
	},
	label:{
		fontSize: theme.text.small,
		lineHeight: "24px",
		color:theme.colors.text.default.regular
	},
});