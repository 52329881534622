import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import StonePreview from 'Elements/Stone/Preview';

const styles = theme => ({
	stones:{
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.columns,
		...theme.flex.wrap
	}
});

class Stones extends Component{
	select = (id) => {
		const { onSelect } = this.props;
		if(onSelect) onSelect(id)
	}
	addImpression = (id) => {
		const { onAddImpression } = this.props;
		if(onAddImpression) onAddImpression(id)
	}
	render(){
		const { classes, stones, t } = this.props;
		let stoneItems = [];
		stones.forEach( stone => {
			stoneItems.push(
				<StonePreview key={stone.get('_id')} stone={stone} onSelect={this.select} onAddImpression={this.addImpression} t={t} />
			)
		});
		return (
			<div className={classes.stones}>
				{ stoneItems }
			</div>
		);
	}
}
Stones.propTypes = {
	classes: PropTypes.object.isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	onSelect: PropTypes.func.isRequired,
	onAddImpression: PropTypes.func.isRequired,
	t: PropTypes.func
};

export default injectSheet(styles)(Stones);