import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { LogoDark } from 'Elements/Logo'

const styles = theme => ({
	root: {
		...theme.layout.container,
		height: '100%',
		"& p":{
			marginBottom: '8px'
		},
		"& h2, h3": {
			marginBottom: '8px',
			fontWeight: 'bold'
		},
		"& h2": {
			...theme.text.title4,
		},
		"& h3": {
			...theme.text.title5,
		},
		"& ol": {
  		listStyleType: "none",
  		counterReset: "item",
 		 	margin: 0,
  		padding: 0,
		},
		"& ol > li": {
 			display: "table",
  		counterIncrement: "item",
  		marginBottom: "0.6em"
		},
		"& ol > li:before": {
  		content: 'counters(item, ".") ". "',
  		display: 'table-cell',
  		paddingRight: '0.6em',    
		},
		"& li ol > li": {
  		margin: 0
		},
		"& li ol > li:before": {
  		content: 'counters(item, ".") " "'
		},
		"& ul":{
			listStyle: "square"
		},
		"& ul > li": {
			marginLeft: "0.8em",
  		marginBottom: "0.6em"
		},
	},
	logo: {
		...theme.flex.container,
		...theme.flex.centerCenter,
		marginBottom: '16px'
	},
	title:{
		...theme.text.title4,
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '16px'
	},
	text: {
		padding: "8px"
	}
});

class UserPolicy extends Component {
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.logo}><LogoDark isBig={true} /></div>
				<h1 className={classes.title}>Informativa sul trattamento dei dati personali GDPR 2016/679</h1>
				<p>
					Informativa sul trattamento dei dati personali GDPR 2016/679
					Gentile Utente, ai sensi del GDPR 2016/679, <b>La informiamo che Stone Business S.r.l.</b>, mediante il proprio Sito “stonesbusiness.com” (<b>“Sito”</b>) ospita una piattaforma tecnologica (<b>“Piattaforma”</b>) 
					che consente di mettere in contatto venditori, (<b>“Venditori”</b>), e potenziali acquirenti (<b>“Acquirenti”</b>), ai fini di compravendita di beni e/o servizi. I Venditori sono individuati all’interno della scheda 
					di presentazione del prodotto/servizio pubblicizzato; gli Acquirenti possono visionare i prodotti/servizi pubblicizzati dai Venditori e/o contattarli, previa registrazione al Sito. I Navigatori non registrati (<b>“Utenti”</b>) 
					possono visionare soltanto la schermata iniziale delle pagine dei Venditori e non possono interagire con essi mediante la Piattaforma.
				</p>
				<p>
					Stone Business S.r.l. in qualità di erogatore del servizio, tratta i dati personali degli Utenti/Venditori/Acquirenti in qualità di <b>“Titolare del trattamento”</b>, sia in caso di semplice accesso, che in caso di 
					registrazione al Sito.
				</p>
				<p>
					Occorre sottolineare che Stones Business S.r.l. ricoprendo il solo ruolo di creatore e gestore della Piattaforma, rimane sempre e comunque estranea al Contratto di Acquisto eventualmente concluso tra Venditore ed 
					Acquirente, che sarà stipulato al di fuori della Piattaforma; pertanto, in caso di conferimento di dati personali da parte dell’Acquirente al Venditore, in occasione dell’acquisto di un prodotto/servizio dallo stesso 
					offerto, il <b>Venditore</b> assumerà il ruolo di <b>“Titolare autonomo”</b> del trattamento dei <b>dati personali comunicati dall’Acquirente</b>. Il Venditore sarà Titolare autonomo dei dati personali eventualmente 
					conferiti dagli Acquirenti anche in occasione della corrispondenza effettuata attraverso il sistema di messaggistica interna del Sito.
				</p>
				<h3>Definizioni</h3>
				<p>
					Per <b>dati personali</b> si intende: <i>“qualsiasi informazione riguardante una persona fisica identificata o identificabile (interessato)”</i> (art. 4 Regolamento UE 2016/679);
				</p>
				<p>
					Per <b>trattamento</b> dei dati personali si intende: <i>“qualsiasi operazione o insieme di operazioni, compiute con o senza l'ausilio di processi automatizzati e applicati a dati personali o insiemi di dati personali, 
					come la raccolta, la registrazione, l'organizzazione, la strutturazione, la conservazione, l'adattamento o la modifica, l'estrazione, la consultazione, l'uso, la comunicazione mediante trasmissione, diffusione o qualsiasi 
					altra forma di messa a disposizione, il raffronto o l'interconnessione, la limitazione, la cancellazione o la distruzione”</i> (art. 4 Regolamento UE 2016/679);
				</p>
				<p>
					Per <b>Titolare</b> del trattamento si intende: <i>“la persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del 
					trattamento di dati personali”</i> (art. 4 Regolamento UE 2016/679);
				</p>
				<p>
					Il <b>Responsabile</b> del trattamento è: <i>“la persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che tratta dati personali per conto del titolare del trattamento”</i> 
					(art. 4 Regolamento UE 2016/679).
				</p>
				<p>
					Per <b>Interessato</b> si intende: la persona fisica cui si riferiscono i dati.
				</p>
				<p>
					Tutto ciò premesso, La INFORMIAMO di quanto segue:
				</p>
				<ol>
					<li>
						<h3>Titolare del trattamento.</h3>
						<p>
							 I dati personali saranno trattati da Stone Business S.r.l., in persona del legale rappresentante pro tempore, corrente in Carrara (MS), alla Via Fivizzano, 15 CAP 54033 (CF: 01398350452), 
							 iscritta al n. 137871 Registro delle Imprese, Indirizzo Pec: <a href="mailto:stonesbusinnesssrl@pec.it" rel="noopener noreferrer" target="_blank">stonesbusinnesssrl@pec.it</a>,	
							 Email: <a href="mailto:info@stonesbusiness.com" rel="noopener noreferrer" target="_blank">info@stonesbusiness.com</a>
						</p>
					</li>
					{/*<li>
						<h3>Responsabile della protezione dei dati</h3>
						<p>
							Il Titolare ha provveduto a nominare Responsabile della protezione dei dati (Data Protection Officer), ………………………………………………….
						</p>
					</li>*/}
					<li>
						<h3>Finalità del trattamento e base giuridica del trattamento</h3>	
						<ol>
							<li>
								<p>
									Il trattamento ha il fine di consentire il corretto funzionamento del Sito. Base giuridica del trattamento è il perseguimento del legittimo interesse del Titolare al trattamento di informazioni statistiche 
									anonime per controllare il corretto funzionamento del Sito.
								</p>
							</li>
							<li>
								<p>
									Il trattamento ha il fine di consentire la navigazione sul Sito. Base giuridica del trattamento è il perseguimento del legittimo interesse del Titolare al trattamento per lo sviluppo della piattaforma e 
									l’utilizzo di cookies tecnici e il consenso dell'Interessato per l’utilizzo dei cookies di analisi.
								</p>
							</li>
							<li>
								<p>
									Il trattamento è finalizzato a consentire la Registrazione al Sito, mediante acquisizione di informazioni utili ad esaminare ed evadere specifiche richieste formulate dall’Interessato in riferimento ai 
									servizi offerti dal Titolare. Base giuridica del trattamento è la necessità di eseguire misure precontrattuali/contrattuali adottate su richiesta dell’Utente e/o per dare esecuzione ad un contratto di 
									cui l’Utente è parte.
								</p>
							</li>
							<li>
								<p>
									I dati saranno trattati per finalità amministrativo-contabili. Base giuridica è l’esistenza di un contratto tra le parti e/o di un obbligo di legge.
								</p>
							</li>
							<li>
								<p>
									I dati saranno trattati e conservati per eventuali ulteriori obblighi previsti da leggi, regolamenti e normative comunitarie, o per eseguire un ordine dell’Autorità o di organi di vigilanza e di controllo. 
									La base giuridica di tale trattamento è data da un obbligo di legge.
								</p>
							</li>
							<li>
								<p>
									I dati saranno trattati per sviluppare la propria piattaforma e migliorarne le caratteristiche. Base giuridica del trattamento è il legittimo interesse del Titolare per lo sviluppo della piattaforma.
								</p>
							</li>
							<li>
								<p>
									I dati saranno trattati per la tutela giudiziale e stragiudiziale dei diritti derivanti dal contratto stipulato con l’Utente. Base giuridica del trattamento sarà l’interesse legittimo del Titolare di tutelare 
									un suo diritto o la necessità di accertare, esercitare o difendere un diritto in sede giudiziaria.
								</p>
							</li>
							<li>
								<p>
									I dati saranno trattati per finalità di marketing, con invio di comunicazioni (e-mail, SMS, fax) promozionali e commerciali relative a prodotti/servizi offerti dal Titolare o da soggetti Terzi. Base giuridica del 
									trattamento sarà il consenso esplicito dell’interessato.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Categorie di dati personali e Fonte da cui hanno origine.</h3>
						<ol>
							<li>
								<p>
									I dati personali trattati saranno comuni (nome e cognome/Denominazione sociale, Codice Fiscale, PIVA, indirizzo, sede email, immagine, numero di telefono, password e username, ID, cookie). Nel rispetto del principio di 
									minimizzazione verranno richiesti dati soltanto quando strettamente necessari al tipo di finalità perseguita nel trattamento.
								</p>
							</li>
							<li>
								<p>
									I dati saranno raccolti dal Titolare direttamente dall’Interessato.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Necessità del conferimento dei dati.</h3>
						<ol>
							<li>
								<p>
									Durante la navigazione i sistemi informatici e le procedure software preposte al funzionamento della piattaforma web acquisiscono, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione 
									di internet. Sono informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di 
									identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI delle risorse richieste, l'orario 
									della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore ..) ed altri 
									parametri relativi al sistema operativo e all'ambiente informatico dell'utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto funzionamento 
									e vengono cancellati immediatamente dopo l'elaborazione. { /*In particolare: LINK pagina COOKIES*/}
								</p>
							</li>
							<li>
								<p>
									Il conferimento dei dati comuni richiesti durante la registrazione o l’accettazione dei termini contrattuali è necessario per l’esecuzione del contratto conseguente; pertanto detto conferimento è obbligatorio ai fini dell'instaurazione 
									del rapporto negoziale. L'eventuale rifiuto a fornirli in tutto o in parte darà luogo all'impossibilità per il Titolare di dare esecuzione al contratto o di svolgere correttamente tutti gli adempimenti connessi. Nel rispetto del principio 
									di minimizzazione verranno domandati soltanto i dati strettamente necessari al perseguimento delle finalità indicate.
								</p>
							</li>
							<li>
								<p>
									Il conferimento dei dati è poi obbligatorio ogni qualvolta sia richiesto dalla legge, da un regolamento o una normativa comunitaria, limitatamente ai dati richiesti da tali fonti normative. Il rifiuto di fornire i dati comporterà 
									l’impossibilità di concludere ed eseguire i rapporti contrattuali.
								</p>
							</li>
							<li>
								<p>
									 Il conferimento dei dati per finalità di marketing è facoltativo e subordinato al consenso esplicito a tale trattamento. L’eventuale rifiuto a fornire il dato non comporterà alcuna conseguenza sui rapporti contrattuali in essere ovvero 
									 in corso di costituzione, ma impedirà la possibilità di svolgere il trattamento per tali finalità.
								</p>
							</li>
							<li>
								<p>
									In caso di comunicazione da parte dell’Utente/Acquirente di dati personali relativi a terzi, lo stesso sarà responsabile della comunicazione in qualità di Titolare autonomo del trattamento e sarà tenuto ad informare i terzi e ad acquisire, 
									se del caso, il loro consenso espresso.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Modalità del trattamento</h3>
						<ol>
							<li>
								<p>
									I dati verranno trattati dal personale e dai collaboratori del Titolare opportunamente nominati quali incaricati e/o responsabili del trattamento con il supporto dei seguenti mezzi:
								</p>
								<p>
									a) Tramite calcolatori elettronici e piattaforme telematiche;
								</p>
								<p>
									b) Manualmente a mezzo archivi cartacei;
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Destinatari e categorie di destinatari dei dati personali</h3>
						<ol>
							<li>
								<p>
									Ferme restando le comunicazioni eseguite in adempimento di obblighi di legge e contrattuali, tutti i dati raccolti ed elaborati potranno essere comunicati esclusivamente per le finalità sopra specificate alle seguenti categorie di interessati:
								</p>
								<ul>
									<li>
										Organi costituzionali o di rilevanza costituzionale in caso di obblighi di legge
									</li>
									<li>
										Enti pubblici ed Uffici finanziari per obblighi di legge
									</li>
									<li>
										Pubbliche Autorità che hanno accesso ai dati in forza di provvedimenti giudiziari o amministrativi
									</li>
									<li>
										Amministratori di Sistema, fornitori di servizi WEB per siti e posta elettronica, Società che fornisce la piattaforma telematica del sistema o ne studia l’implementazione, che a tal fine saranno nominati responsabili esterni
									</li>
									<li>
										Consulenti anche in forma societaria e/o liberi professionisti anche in forma associata, all'uopo nominati responsabili del trattamento
									</li>
									<li>
										Dipendenti e/o collaboratori del Titolare nella qualità di Incaricati/responsabili
									</li>
									<li>
										Operatori commerciali per invio di materiale informativo/pubblicitario là dove autorizzato dall’interessato il tipo di trattamento
									</li>
								</ul>
							</li>
							<li>
								<p>
									L'elenco dei soggetti nominati responsabili è consultabile facendo richiesta al Titolare.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Collegamenti esterni</h3>
						<ol>
							<li>
								<p>
									Il Sito può includere dei collegamenti o permettere di accedere a siti web, social network, come i pulsanti "Condividi" o "Mi piace", di soggetti terzi. L'accesso a tali siti o l'utilizzo di tali collegamenti può permettere al soggetto 
									terzo di acquisire e utilizzare dati personali dell'Interessato: in tal caso l’Interessato dovrà consultare policy privacy di questi soggetti terzi, non avendo il Sito potere di controllo alcuno sul trattamento dagli stessi effettuato
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Diffusione dei dati</h3>
						<ol>
							<li>
								<p>
									I dati personali saranno resi pubblici nella Piattaforma solo nella misura in cui gli Utenti lo abbiano consentito nell'ambito delle impostazioni del profilo, al fine di consentire la visualizzazione tra Utenti Registrati o da parte di terzi 
									Navigatori (con le limitazioni di accesso a loro riservate). 
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Trasferimento dei dati a paese terzo o a un'organizzazione internazionale</h3>
						<ol>
							<li>
								<p>
									Il Titolare non trasferisce i dati personali verso paesi terzi o organizzazioni internazionali, ma si riserva la possibilità di far uso di servizi in cloud, selezionando i fornitori di tali servizi tra coloro che daranno garanzie adeguate come 
									richiesto dall'art. 46 del GDPR 2016/679. 
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Periodo di conservazione</h3>
						<ol>
							<li>
								<p>
									I dati personali acquisiti mediante i sistemi informatici e le procedure software preposte al funzionamento della piattaforma web, vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto 
									funzionamento, pertanto vengono cancellati immediatamente dopo l'elaborazione.
								</p>
							</li>
							<li>
								<p>
									I dati personali comuni richiesti per il contratto di servizi saranno conservati per dieci anni dalla cessazione del rapporto, nel rispetto della normativa sulla responsabilità contrattuale.
								</p>
							</li>
							<li>
								<p>
									Nel caso di contenzioso giudiziale i dati saranno trattati anche per tutta la durata dello stesso, fino all’esaurimento dei termini di esperibilità delle azioni di impugnazione.
								</p>
							</li>
							<li>
								<p>
									Nell’ipotesi di trattamento ai fini marketing i dati saranno conservati per due anni decorrenti dalla cessazione del rapporto. Trascorsi detti termini non saranno più utilizzati per le suddette finalità
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3>Diritti interessati</h3>
						<p>
							Nei confronti del Titolare Lei potrà esercitare i seguenti diritti:
						</p>
						<ol>
							<li>
								<p>
									Ottenere la conferma che sia o meno in corso un trattamento di dati personali che la riguardano e, in tal caso, ottenere l'accesso ai dati personali e a tutte le informazioni previste dell’art. 15 del GDPR.
								</p>
							</li>
							<li>
								<p>
									Ottenere la rettifica dei dati personali inesatti che la riguardano senza ingiustificato ritardo. Tenuto conto delle finalità del trattamento lei ha il diritto di ottenere l’integrazione dei dati personali incompleti, 
									anche fornendo una dichiarazione integrativa.
								</p>
							</li>
							<li>
								<p>
									Ottenere la cancellazione dei dati personali che la riguardano senza ingiustificato ritardo nelle ipotesi previste dall'art. 17 del GDPR.
								</p>
							</li>
							<li>
								<p>
									Ottenere la limitazione del trattamento quando ricorra una delle ipotesi previste dall’art. 18 del GDPR.
								</p>
							</li>
							<li>
								<p>
									Ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che la riguardano forniti al Titolare nelle ipotesi previste dall'art. 20 del GDPR.
								</p>
							</li>
							<li>
								<p>
									Opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che la riguardano ai sensi dell’articolo 6, paragrafo 1, lettere e) o f) del GDPR, come indicato dall'art. 21 del GDPR.
								</p>
								<p>	
									In questo caso la società si asterrà dal trattare ulteriormente i dati personali salvo l'esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell’interessato 
									oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.
								</p>
							</li>
							<li>
								<p>
									Non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, che produca effetti giuridici che la riguardano o che incida in modo analogo significativamente sulla sua persona.
								</p>
								<p>
									Questo diritto non si applica nei casi disciplinati dall’art. 22, paragrafo 2 del GDPR.
								</p>
							</li>
							<li>
								<p>
									Rivolgersi all’Autorità di controllo (Garante per la protezione dei dati personali-Piazza Venezia 11 00187 Roma email: garante@gpdp.it) per reclamare nel caso ritenga che i Suoi dati siano stati trattati in modo illegittimo.
								</p>
							</li>
						</ol>
						<p>
							L’esercizio dei suoi diritti potrà avvenire attraverso l’invio di una richiesta mediante email all’indirizzo stonesbusinesssrl@pec.it oppure inviando una raccomandata alla sede del Titolare in Carrara (MS), alla Via Fivizzano n. 15 CAP 54033.
						</p>
					</li>
					<li>
						<h3>Revoca consenso</h3>
						<p>
							Ogni qualvolta il trattamento abbia quale base giuridica il consenso, l'interessato ha diritto di revocare il consenso espresso in qualsiasi momento senza pregiudicare la liceità del trattamento basato sul consenso prestato prima della revoca. 
							Potrà esercitare tale diritto scrivendo all’indirizzo stonesbusinesssrl@pec.it oppure inviando una raccomandata alla sede del Titolare in Carrara (MS), alla Via Fivizzano n. 15 CAP 54033.
						</p>
					</li>
					<li>
						<h3>Processo decisionale automatizzato.</h3>
						<p>
							Il Titolare dichiara che non è in corso alcun un processo decisionale automatizzato.
						</p>
					</li>
				</ol>
			</div>
		);
	}
}

UserPolicy.propTypes = {
	classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(UserPolicy);