import { connect } from 'react-redux';

import UserPolicy from './components';

const mapStateToProps = (state) => {
	return {
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserPolicy)