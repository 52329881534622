import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class GMap extends Component {
	constructor(props){
		super(props);
		this.elem = React.createRef();
		this.state = {
			childs:undefined
		}
	}
	storeChilds = (children) => {
		if(children){
			const cloned = React.Children.map(children, child => {
				if(child)	return React.cloneElement(child, { onPlace: this.placeMarker })
			});
			this.setState({childs:cloned});
		}else{
			this.setState({childs:undefined});
		}
	}
	setCenter = (latlng) => {
		if(this.map) this.map.setCenter(latlng);
	}
	setZoom = (zoom) => {
		if(this.map) this.map.setZoom(zoom);
	}
	placeMarker = (marker) => {
		if(marker) marker.setMap(this.map);
	}
	panToBounds = (latLngBounds) => {
		if(this.map){
			this.map.panToBounds(latLngBounds);
			this.map.fitBounds(latLngBounds); 
		}
	}
	componentDidMount(){
		const { ready, lat, lng, zoom, children } = this.props;
		if(ready){
			let latlng = new window.google.maps.LatLng(lat || 0, lng ||0);
    	var options = {
        zoom: zoom || 8,
        center: latlng
    	};
			this.map = new window.google.maps.Map(this.elem.current, options);
			this.storeChilds(children);
		}	
	}
	componentWillUnmount(){
		this.map = null;
	}
	componentDidUpdate(prevProps){
		const { ready, lat, lng, zoom, children, latLngBounds } = this.props;
		if(ready && ready !== prevProps.ready){
			let latlng = new window.google.maps.LatLng(lat || 0, lng ||0);
    	var options = {
    		gestureHandling: 'cooperative',
        zoom: zoom || 8,
        center: latlng,
        disableDefaultUI: true
    	};
			this.map = new window.google.maps.Map(this.elem.current, options);
			this.storeChilds(children);
		}
		if(ready && lat !== prevProps.lat){
			let latlng = new window.google.maps.LatLng(lat || 0, lng ||0);
			this.setCenter(latlng);
		}
		if(ready && lng !== prevProps.lng){
			let latlng = new window.google.maps.LatLng(lat || 0, lng ||0);
			this.setCenter(latlng);
		}
		if(ready && zoom !== prevProps.zoom){
			this.setZoom(zoom);
		}
		if(ready && children !== prevProps.children){
			this.storeChilds(children);
		}
		if(ready &&  latLngBounds!== prevProps.latLngBounds){
			this.panToBounds(JSON.parse(latLngBounds));
		}
	}
	render(){
		const { classes } = this.props;
		const { childs } = this.state;
		return (
			<div className={classes.map} ref={this.elem}>{childs}</div>
		);	
	}
}
GMap.propTypes ={
	classes: PropTypes.object
};

export default injectSheet(style)(GMap);