import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { ArrowLeft, ArrowRight } from 'UI/Icon';

const styles = theme => ({
	root:{
		...theme.flex.container,
		...theme.flex.columns
	},
	pages:{
		...theme.flex.elemNone
	},
	buttons:{
		...theme.flex.elemNone,
		...theme.flex.container,
	},
	divider:{
		...theme.flex.elemAuto
	},
});

class Pagination extends Component {
	changePage = (newPage) =>{
		const { page, onChange } = this.props;
		if(page !== newPage && onChange) onChange(newPage);
	}
	nextPage = () => {
		const { page, perPage, total, onChange } = this.props;
		const extraPage = (total%perPage>0)? 1: 0;
		const totalPages = parseInt(total/perPage, 10) + extraPage;
		if(page < totalPages && onChange) onChange(page+1);
	}
	prevPage = () => {
		const { page, onChange } = this.props;
		if(page > 1 && onChange) onChange(page-1);
	}
	render(){
		const { classes, page, perPage, total } = this.props;
		const extraPage = (total%perPage>0)? 1: 0;
		const totalPages = parseInt(total/perPage, 10) + extraPage;
		let pages = [];
		pages.push(
			<Button key={1} text={'1'} isActive={1===page} onClick={() => {this.changePage(1)}} />
		);
		if(page > 3){
			pages.push(
				<span key={'span-1'}>&hellip;</span>
			);
		}
		if(page > 2){
			pages.push(
				<Button key={page-1} text={''+ (page-1)} onClick={() => {this.changePage(page-1)}} />
			);
		}
		if(page > 1 && page < totalPages){
			pages.push(
				<Button key={page} text={'' + page} isActive={true} />
			);
		}
		if(page < totalPages-1){
			pages.push(
				<Button key={page+1} text={'' + (page+1)} onClick={() => {this.changePage(page+1)}} />
			);
		}
		if(page < totalPages-2){
			pages.push(
				<span key={'span-2'}>&hellip;</span>
			);
		}
		if(totalPages>1){
			pages.push(
				<Button key={totalPages} isActive={totalPages===page} text={ '' + totalPages} onClick={() => {this.changePage(totalPages)}} />
			);
		}
		return (
			<div className={classes.root}>
				<div className={classes.pages}>
					{pages}
				</div>
				<div className={classes.divider}></div>
				<div className={classes.buttons}>
					<Button Icon={ArrowLeft} isDisabled={page===1} onClick={this.prevPage} />
					<Button Icon={ArrowRight} isDisabled={page===totalPages} onClick={this.nextPage} />
				</div>
			</div>
		);	
	}
}
Pagination.propTypes ={
	classes: PropTypes.object,
	page: PropTypes.number,
	perPage: PropTypes.number,
	total: PropTypes.number
};
export default injectSheet(styles)(Pagination);