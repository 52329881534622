import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, Submit } from 'UI/Form';

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('passwordA')) {
		errors.passwordA = t('errors.required');
	}
	if (!values.get('passwordB')) {
		errors.passwordB = t('errors.required');
	}else if(values.get('passwordA') !== values.get('passwordB')){
		errors.passwordB = t('errors.noMatchPassword');
	}
	return errors
}


class RecoverPasswordForm extends Component{
	render(){
		const { handleSubmit, authError, t } = this.props;
		let passwordError;
		if(authError && authError.message === 'passwords do not match')
			passwordError = t('errors.noMatchPassword');
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="newPasswordA"
					type="password"
					label={t('signup.password')}
					component={Input}
				/>
				<Field
					name="newPasswordB"
					type="password"
					label={t('signup.passwordB')}
					component={Input}
					asyncerror={passwordError}
				/>
				<Submit text={t('login.changePassword')}/>
			</form>
		);
	}
}
RecoverPasswordForm.propTypes = {
	...propTypes,
	authError: PropTypes.object,
	t: PropTypes.func
};

export default reduxForm({
	form:'recoverPasswordForm',
	validate
})(RecoverPasswordForm);