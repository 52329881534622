import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { GSearchBox, GMap, GMarker } from 'lib/google-maps';
import style from '../jss';

class InputMap extends Component{
	constructor(props){
		super(props);
		this.state = {
			lat:undefined,
			lng:undefined
		}
	}
	dragEnd = (value) => {
		let location = {
			lat: value.lat(),
			lng: value.lng()
		} 
		this.setLocation(location);
	}
	setLocation = (location) => {
		const { input:{onChange} } = this.props;
		this.setState({
			lat:location.lat,
			lng:location.lng
		});
		let geoloc = {
			type: 'Point',
			coordinates:[location.lng, location.lat]
		}
		if(onChange) onChange(geoloc);
	}
	cancel = () => {
		const { input:{onChange} } = this.props;
		this.setState({
			lat:undefined,
			lng:undefined
		});
		if(onChange) onChange(undefined);
	}
	componentDidMount() {
		const { meta: {initial, pristine}} = this.props;
		if(pristine && initial){
			const lng = initial.getIn(['coordinates', 0]);
			const lat = initial.getIn(['coordinates', 1]);
			this.setState({
				lat:lat,
				lng:lng
			})
		}
	}
	componentWillUnmount() {
	}
	componentDidUpdate(prevProps){
		const { input:{value}, meta: {initial, pristine}} = this.props;
		if(pristine && value !== prevProps.input.value){
			const lng = initial.getIn(['coordinates', 0]);
			const lat = initial.getIn(['coordinates', 1]);
			this.setState({
				lat:lat,
				lng:lng
			})
		}
	}
	render(){
		const { classes, asyncerror, label, meta, placeholder, icon } = this.props;
		const { touched, error } = meta;
		const { lat, lng } = this.state;
		return (
			<div>
				<GSearchBox label={label} placeholder={placeholder} onSetLocation={this.setLocation} onFocus={this.cancel}/>
				<div className={classes.mapContainer}>
					<GMap lat={lat} lng={lng} zoom={14}>
						{ lat && lng && <GMarker lat={lat} lng={lng} icon={icon} isDraggable={true} onDragEnd={this.dragEnd}/> }
					</GMap>
				</div>
				{touched && error && <p className={classes.error}> {error} </p>}
				{asyncerror && <p className={classes.error}> {asyncerror} </p>}
			</div>
		);
	}
}
InputMap.propTypes = {
	classes: PropTypes.object,
	input: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	icon: PropTypes.string
};

export default injectSheet(style)(InputMap);