import * as material from 'fetch/material';
import {
	startFetching,
	endFetching,
	storeError,
	removeError
} from 'state/actions/ui';

export const MATERIAL_GET = "MATERIAL_GET";
export const MATERIAL_LIST = "MATERIAL_LIST";
export const MATERIAL_EMPTY = "MATERIAL_EMPTY";

const storeGet = (answer) => {
	return {
		type: MATERIAL_GET,
		material : answer
	}
};

const storeList = (answer) => {
	return {
		type: MATERIAL_LIST,
		materials: answer.materials,
		page: answer.page,
		perPage: answer.perPage,
		total: answer.results
	}
};

const storeEmpty = () => {
	return {
		type: MATERIAL_EMPTY
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('material_get'));
		try {
			const answer = await material.get(id);
			dispatch(storeGet(answer));
			dispatch(endFetching('material_get','material_get_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('material_get','material_get_error'));
		}
	}
};

export const getAll = (orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('material_get_all'));
		try {
			const answer = await material.list({orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('material_get_all','material_get_all_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('material_get_all','material_get_all_error'));
		}
	}
};

export const searchName = (name, category, perPage) => {
	return async dispatch => {
		dispatch(removeError());
		//dispatch(startFetching('material_search_name'));
		try {
			const answer = await material.list({name, category, perPage});
			dispatch(storeList(answer));
			dispatch(endFetching('material_search_name', 'material_search_namel_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('material_search_name', 'material_search_namel_error'));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};