import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

export const send = async (email, subject, message) => {
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = {
		email,
		subject,
		message
	};
	const response = await fetch(apiUrl + "/mailer/send", {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}