import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import MessageForm from './MessageForm';
import injectSheet from 'react-jss';
import { View } from 'UI/Icon';
import Button from 'UI/Button';
import { GMap, GMarker } from 'lib/google-maps';

const styles = theme => ({
	details:{
		...theme.flex.elemSetFlex(50,'row'),
		paddingLeft: "3rem",
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		}	
	},
	separator:{
		...theme.flex.elemAuto
	},
	extra:{
		...theme.flex.container,
		...theme.flex.startCenter
	},
	stats:{
		...theme.flex.container,
		...theme.flex.startCenter
	},
	statsItem:{
		padding: "0.5rem",
		fill: theme.colors.primary,
		...theme.flex.container,
		...theme.flex.startCenter
	},
	statsValue:{
		position: 'relative',
		top: '1px',
		marginLeft: "0.1rem",
		fontSize: theme.text.small
	},
	title: {
		...theme.text.title3,
		marginTop: '16px',
	},
	description: {
	},
	infos:{
		marginTop: "1rem",
		"& .info":{
			marginBottom: ".5rem"
		}
	},
	label:{
		fontSize: theme.text.small
	},
	maps:{
		height: "200px"
	},
	link: {
		textDecoration: "none",
		"&:hover":{
			color: theme.colors.primary,
			textDecoration: 'underline'
		}
	},
	user:{
		marginTop: "1rem",
		"& .title": {
			display: "block",
			...theme.text.title5,
			marginBottom:"0.5rem",
			textDecoration: "none",
			"&:hover":{
				color: theme.colors.primary,
				textDecoration: 'underline'
			}
		},
		"& .info":{
			marginBottom: ".5rem"
		},
		"& .messageCall":{
			color: theme.colors.error
		},
	},
});

class Details extends Component{
	sendMessage = (values) => {
		const  { user, sendMessage } = this.props;
		if(user && sendMessage){
			const id = user.get('_id');
			const message = values.get('message');
			sendMessage(id, message);
		}
	}
	render(){
		const { classes, stats, stone, user, isLogged, t } = this.props;
		let name, description, shape, categoryName, materialName, height, width, depth, weight, quantity, lng, lat;
		let impressions;
		let userName, country, state, city, userId;
		let permalink, categoryPermalink, materialPermalink;
		if(stone){
			name = stone.get('name');
			description = stone.get('description');
			categoryName = stone.getIn(['category','name']);
			materialName = stone.getIn(['material','name']);
			categoryPermalink = t('category.type.' + categoryName).replace(/ /g, "-") + '-' + stone.getIn(['category','_id']);
			materialPermalink = materialName.replace(/ /g, "-") + '-' + stone.getIn(['material','_id']);
			shape = stone.get('shape');
			height = stone.get('height');
			width = stone.get('width');
			depth = stone.get('depth');
			weight = stone.get('weight');
			quantity = stone.get('quantity');
			lng = stone.getIn(['geoloc', 'coordinates', 0]);
			lat = stone.getIn(['geoloc', 'coordinates', 1]);
		}
		if(stats){
			impressions = stats.get('impressions');
		}
		if(user){

			if(user.get('kind') === 'company'){
				userName = user.getIn(['name', 'company']);
			}else{
				userName = user.getIn(['name', 'first']) + " " + user.getIn(['name', 'last']);
			}
			userId = user.get('_id');			
			permalink = userName.replace(/ /g, "-") + '-' + userId;
			country = user.getIn(['address', 'country']);
			state = user.getIn(['address', 'state']);
			city = user.getIn(['address', 'city']);
		}
		let mapIcon = "/assets/shapes/block_map.png";
		if(shape === "shapeless" ) mapIcon =  "/assets/shapes/shapeless_map.png";
		if(shape === "slab" ) mapIcon =  "/assets/shapes/slabs_map.png";
		if(shape === "tile" ) mapIcon =  "/assets/shapes/tiles_map.png";
		return (
			<div className={classes.details}>
				<div className={classes.extra}>
					{ !!impressions &&
						<div className={classes.stats} >
							<div className={classes.statsItem} ><View/><span className={classes.statsValue} > {impressions}</span></div>
						</div>
					}
					<div className={classes.separator} />
					<div className={classes.social}>
					</div>
				</div>
				<h1 className={classes.title}> { name } </h1>
				{!! description && <h5 className={classes.description}> { description } </h5> }
				<div className={classes.infos}>
					{ shape && <p className="info"><span className={classes.label}>{t('stone.shape')}:</span><span> { t('stone.shapeType.' + shape) } </span></p> }
					
					{ categoryName && <p className="info"><span className={classes.label}>{t('stone.category')}:</span><Link className={classes.link} to={'/material_kind/' + categoryPermalink}><span> { t('category.type.' + categoryName) } </span></Link></p> }
					
					{ materialName && <p className="info"><span className={classes.label}>{t('stone.material')}:</span><Link className={classes.link} to={'/material/' + materialPermalink}><span> { materialName } </span></Link></p> }
					
					{ ( (shape === 'block' || shape === 'shapeless') && weight ) && <p className="info"><span className={classes.label}>{t('stone.weight') + " (t)"}:</span><span> { weight } </span></p> }
					
					{ ( (shape === 'slab' || shape === 'tile') && quantity ) && <p className="info"><span className={classes.label}>{t('stone.quantity')}:</span><span> { quantity } </span></p> }

					{ height && <p className="info"><span className={classes.label}>{t('stone.height') + " (m)"}:</span><span> { height } </span></p> }

					{ width && <p className="info"><span className={classes.label}>{t('stone.width') + " (m)"}:</span><span> { width } </span></p> }
					
					{ ( (shape === 'block' || shape === 'shapeless') && depth ) && <p className="info"><span className={classes.label}>{t('stone.depth') + " (m)"}:</span><span> { depth } </span></p> }

					{ ( (shape === 'slab' || shape === 'tile') && depth ) && <p className="info"><span className={classes.label}>{t('stone.depth') + " (mm)"}:</span><span> { depth } </span></p> }
				</div>
				<div className={classes.maps}>
					<GMap lat={lat} lng={lng} zoom={12}>
						{ lat && lng && <GMarker lat={lat} lng={lng} icon={mapIcon} /> }
					</GMap>
				</div>
				<div className={classes.user}>
					<Link to={'/user/'+ permalink} className="title"> { userName } </Link>
					{ country && <p className="info"><span className={classes.label}>{t('address.country')}:</span><span> { country } </span></p> }
					{ state && <p className="info"><span className={classes.label}>{t('address.state')}:</span><span> { state } </span></p> }
					{ city && <p className="info"><span className={classes.label}>{t('address.city')}:</span><span> { city } </span></p> }
					{ isLogged && <MessageForm t={t} onSubmit={this.sendMessage} /> }
					{ !isLogged && <div className="messageCall"> {t('message.call')} <Button text={t('login.enter')} href="/auth/login" /></div> }
				</div>
			</div>
		);
	}
}
Details.propTypes = {
	classes: PropTypes.object.isRequired,
	stone: PropTypes.instanceOf(Map).isRequired,
	stats: PropTypes.instanceOf(Map),
	material: PropTypes.object,
	category: PropTypes.object,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(Details);