import reset from "./reset";
import flex from "./flex";
import colors from "./colors";
import logo from "./logo";
import text from "./text";
import layout from "./layout";
import media from "./media"

const shadows = {
	s1:"-1px 1px 3px -1px rgba(0,0,0,.2), 2px 3px 3px 0 rgba(0,0,0,.14), 1px 1px 3px 0 rgba(0,0,0,.12)",
	insetS1: "inset 0 0 0 1px  rgba(0,0,0,.2)"
	//insetS1:"inset 1px -1px 3px 1px rgba(0,0,0,.2), inset 2px 3px 3px 0 rgba(0,0,0,.14), inset -1px -1px 3px 0 rgba(0,0,0,.12)"
}
const theme = {
	reset: reset,
	logo: logo,
	colors:colors,
	shadows: shadows,
	flex:flex,
	text: text,
	layout: layout,
	transition: "all cubic-bezier(0.35, 0, 0.25, 1) 0.5s",
	media: media
}
export default theme;