import { connect } from 'react-redux';
import { login } from 'state/actions/auth';
import Login from './components';

const mapStateToProps = (state) => {
	return {
		error: state.getIn(['ui','error'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		login:(email, password) =>{
			dispatch(login(email, password));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login)