import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Add from './Add';
import Preview from './Preview';

const styles = theme => ({
	root:{
		...theme.flex.container,
		...theme.flex.rows,
		margin: ".5em 0"
	},
	label:{
		fontSize: theme.text.small,
		lineHeight: "24px",
		color:theme.colors.text.default.regular
	},
	container:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.startCenter,
		...theme.flex.wrap
	}
});

class ImagesUpload extends Component{
	constructor(props){
		super(props);
		this.state = {
			files:[]
		}
	}
	addFiles = (fileList) => {
		const {input: { onChange }} = this.props;
		const { files } = this.state;
		let fileArray = [];
		for(let i = 0; i < fileList.length; i++){
			fileArray.push(fileList[i]);
		}
		let newFiles = [...files, ...fileArray]
		this.setState({
			files: newFiles
		});
		if(onChange) onChange(newFiles);
	}
	deleteFile = (file) => {
		const { files } = this.state;
		let newFiles = files.slice();
		let index = newFiles.indexOf(file);
		newFiles.splice(index, 1);
		if(index !== -1){
			this.setState({
				files: newFiles
			});
		}
	}
	componentDidUpdate(prevProps){
		const { meta:{pristine} } = this.props;
		if(pristine !== prevProps.meta.pristine && pristine === true)
			this.setState({files:[]});
	}
	render(){
		const { classes, label, isUploading, progress } = this.props;
		const { files } = this.state;
		let previews = [];
		files.forEach( (file, index) => {
			let _progress = progress.get(index);
			let _isUploading = isUploading.get(index);
			previews.push(
				<Preview key={index} file={file} onDelete={this.deleteFile} progress={_progress} isUploading={_isUploading} />
			);
		})
		return (
			<div className={classes.root} >
				{ label && <label className={classes.label} >{ label }</label> }
				<div className={classes.container}>
					{ previews }
					<Add onAdd={this.addFiles} />
				</div>
			</div>
		);
	}
}
ImagesUpload.propTypes = {
	classes: PropTypes.object.isRequired,
	isUploading: PropTypes.instanceOf(Map).isRequired,
	progress: PropTypes.instanceOf(Map).isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func
};

export default injectSheet(styles)(ImagesUpload);