import { connect } from 'react-redux';
import { getAllMine, remove, empty } from 'state/actions/stone';
import List from './components';

const mapStateToProps = (state) => {
	return {
		page: state.getIn(['stone','page']),
		perPage: state.getIn(['stone','perPage']),
		total: state.getIn(['stone', 'total']),
		stones: state.getIn(['stone','stones']),
		screen: state.getIn(['ui' ,'screen']),
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		getAll: (orderBy, order, perPage, page) => {
			dispatch(getAllMine(orderBy, order, perPage, page));
		},
		remove: (id) => {
			dispatch(remove(id));
		},
		empty: () => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List)