import React, { Component } from 'react';
import { Map } from 'immutable'
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Edit } from 'UI/Icon';

const styles = theme => ({
	details:{
		...theme.flex.elemSetFlex(50,'row'),
		paddingLeft: "3rem",
		paddingTop: "3rem",
		position: 'relative',
		"& .info":{
			marginBottom: ".5rem"
		},
		"& .action":{
			position: "absolute",
			top: "0px"
		},
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		}	
	},
	label:{
		fontSize: theme.text.small
	},
	userActions: {
		marginTop: '32px'
	},
	title: {
		...theme.text.title4
	}
})
class Details extends Component{
	render(){
		const { classes, me, t } = this.props;
		let kind, email, telephone, taxCode, country, state, city, address, postalCode
		if(me){
			kind = me.get('kind');
			email = me.get('email');
			telephone = me.get('telephone');
			taxCode = me.get('taxCode');
			country = me.getIn(['address', 'country']);
			state = me.getIn(['address', 'state']);
			city = me.getIn(['address', 'city']);
			address = me.getIn(['address', 'address']);
			postalCode = me.getIn(['address', 'postCode']);
		}
		return (
			<div className={classes.details}>
				<div className="action"><Button Icon={Edit} href="/me/update"/></div>
				<p className="info"><span className={classes.label}>{t('user.kind')}:</span><span> { t('user.'+ kind) } </span></p>
				<p className="info"><span className={classes.label}>{t('user.email')}:</span><span> { email } </span></p>
				<p className="info"><span className={classes.label}>{t('user.telephone')}:</span><span> { telephone } </span></p>
				<p className="info"><span className={classes.label}>{t('address.country')}:</span><span> { country } </span></p>
				<p className="info"><span className={classes.label}>{t('address.state')}:</span><span> { state } </span></p>
				<p className="info"><span className={classes.label}>{t('address.city')}:</span><span> { city } </span></p>
				<p className="info"><span className={classes.label}>{t('address.address')}:</span><span> { address } </span></p>
				<p className="info"><span className={classes.label}>{t('address.postalCode')}:</span><span> { postalCode } </span></p>
				{ (kind === "company" || kind === "dealer") && <p className="info"><span className={classes.label}>{t('user.taxCode')}:</span><span> { taxCode } </span></p> }
				<div className={classes.userActions} >
					<h3 className={classes.title}>Voui diventare un nostro inserzionista? </h3>
					<Button isPrimary={true} text="Contattaci" href="/extra/contact"/>
				</div>
			</div>
		);
	}
}
Details.propTypes = {
	classes: PropTypes.object.isRequired,
	me: PropTypes.instanceOf(Map).isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(Details);