import { connect } from 'react-redux';
import Home from './components';

const mapStateToProps = (state) => {
	return {
		screen: state.getIn(['ui' ,'screen'])
	};
};
const mapDispatchToProps = (dispatch) => {
	return {};
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Home)