import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	root:{
		width: '100%',
		"& .outer":{
			position: 'relative',
			width: '100%',
			paddingTop: '100%',
			"& .content":{
				position: 'absolute',
				top: '0px',
				left: '0px',
				bottom: '0px',
				right: '0px',
				"& .body":{
					height: '100%',
					width: '100%',
					backgroundColor: theme.colors.primary,
					backgroundSize: 'cover',
					backgroundPosition: 'center center'
				}
			}
		},
		"&.hexagon":{
			clipPath: "polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)"
		},
		"&.circle":{
			"& .body":{
				borderRadius: '100%'
			}
		}
	}
});

class Figure extends PureComponent{
	render(){
		const { classes, url, shape } = this.props;
		let figureClass = classes.root;
		if(shape) figureClass += " " + shape;
		return (
			<div className={figureClass}>
				<div className="outer">
					<div className="content">
						<div className="body" style={{backgroundImage:'url('+ url +')'}}/>
					</div>
				</div>
			</div>
		);
	}
}
Figure.propTypes = {
	classes: PropTypes.object.isRequired,
	url: PropTypes.string,
	shape: PropTypes.string
};

export default injectSheet(styles)(Figure);