import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class FieldGroup extends Component{
	render(){
		const { classes, children, label } = this.props;
		return (
			<div className={classes.rangeGroup} >
				{ label && <label className={classes.label}>{ label } </label> }
				{ children }
			</div>
		);
	}
}
FieldGroup.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node
};

export default injectSheet(style)(FieldGroup);