import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import Figure from 'UI/Figure';

const styles = theme => ({
	root:{
		...theme.flex.elemSetFlex(100,'row'),
		padding: "1rem"
	},
	card:{
		cursor: 'pointer',
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.wrap,
		boxShadow: theme.shadows.s1,
		"& .left":{
			position: "relative",
			...theme.flex.elemSetFlex(100,'row'),
			'@media screen and (min-width: 1088px)':{
				...theme.flex.elemSetFlex(50,'row')
			}
		},
		"& .right":{
			position: "relative",
			...theme.flex.elemSetFlex(100,'row'),
			padding: "1rem",
			'@media screen and (min-width: 1088px)':{
				...theme.flex.elemSetFlex(50,'row')
			}
		}
	},
	infos:{
		marginTop: "1rem",
		"& .info":{
			marginBottom: ".5rem"
		}
	},
	label:{
		fontSize: theme.text.small
	},
	icon:{
		width: "36px",
		position: "absolute",
		top: ".3rem",
		left: ".3rem"
	},
	title:{
		...theme.text.title2
	}
});

class WarehousePreview extends Component {
	select = () => {
		const { onSelect, warehouse } = this.props;
		const id = warehouse.get('_id');
		const name = warehouse.get('name');
		let permalink = name.replace(/ /g, "-") + '-' + id;
		onSelect(permalink);
	}
	addImpression = () => {
		const { onAddImpression, warehouse } = this.props;
		const id = warehouse.get('_id');
		onAddImpression(id)
	}
	componentDidMount(){
		//this.addImpression();
	}
	render(){
		const { classes, warehouse } = this.props;
		const name = warehouse.get('name');
		const image = warehouse.getIn(['images',0, 'urls', 'ld']);
		const url = (image)? `${staticUrl}/${image}`: "/assets/png/block.png";
		return (
			<div className={classes.root}>
				<div className={classes.card} onClick={this.select}>
					<div className='left'>
						<Figure url={url} />
					</div> 
					<div className="right">
						<h1 className={classes.title}> { name } </h1>
						<div className={classes.infos}>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

WarehousePreview.propTypes = {
	classes: PropTypes.object.isRequired,
	warehouse: PropTypes.instanceOf(Map).isRequired,
	onSelect: PropTypes.func.isRequired,
	//onAddImpression:  PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(WarehousePreview);