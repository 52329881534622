import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionBar from 'UI/ActionBar';

class ListActionBar extends Component {
	search = (values) => {
		const { onSearch } = this.props;
		if(onSearch) onSearch(values);
	} 
	render(){
		const { t } = this.props;
		const leftBtns = [
			{isPrimary:true, name:t('stone.list.add'), title:"stone-add", href:"/stone/add" }
		];
		return (
			<ActionBar leftBtns={leftBtns} />
		);
	}
}
ListActionBar.propTypes ={
	t: PropTypes.func.isRequired
};
export default ListActionBar;