import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import injectSheet from 'react-jss';
import StonePreview from 'Elements/Stone/Preview';

const styles = theme => ({
	results:{
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.columns,
		...theme.flex.wrap
	},
});

class SearchResults extends Component{
	select = (permalink) => {
		const { onSelect } = this.props;
		if(permalink) onSelect(permalink);
	}
	addImpression = (id) => {
		const { onAddImpression } = this.props;
		if(id) onAddImpression(id);
	}
	render(){
		const { classes, stones, t } = this.props;
		let stoneItems = [];
		stones.forEach( stone => {
			stoneItems.push(
				<StonePreview key={stone.get('_id')} stone={stone} onSelect={this.select} onAddImpression={this.addImpression} t={t} />
			);
		});
		return (
			<div className={classes.results}>
				{ stoneItems }
			</div>
		);
	}
}
SearchResults.propTypes = {
	classes: PropTypes.object.isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	onSelect: PropTypes.func.isRequired,
	onAddImpression: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(SearchResults);