import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	root:{
		position:'absolute',
		left: '0px',
		top: '0px',
		width: "100%",
		boxShadow: theme.shadows.s1,
		transition: theme.transition,
		overflow: 'hidden',
		backgroundColor:theme.colors.background,
		opacity:1,
		zIndex: 10,
		"&.hide":{
			opacity:"0",
			height: "0px",
		}
	},
	menuContainer:{
		...theme.flex.container,
		...theme.flex.rows
	},
	menuItem: {
		cursor: 'pointer',
		...theme.flex.elemNone,
		textDecoration: 'none',
		padding: '0 16px',
		lineHeight: '40px',
		...theme.colors.button.default,
		fontSize: theme.text.default,
		textAlign: 'left',
		'&:hover':{
			...theme.colors.button.hover.default
		},
		"&:active, &.active, &.is-active":{
			...theme.colors.button.active.default
		},
	}
});

class Menu extends Component{
	constructor(props){
		super(props);
		this.state = {};
		this.elem = React.createRef();
		this.animationStart = 0;
		this.currentScroll = 0;
	}
	componentDidMount(){
		let elem = this.elem.current;
		this.setState({height:elem.clientHeight});
	}
	componentDidUpdate(prevProps){
		const { show } = this.props;
		if(show !== prevProps.show && show){
			this.startScroll()
		}
	}
	startScroll = () => {
		const padding = 10;
		let node = this.elem.current;
		let dim = node.getBoundingClientRect();
		let height = (dim.height>200)? dim.height: 200;
		this.currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
		this.offset = this.currentScroll + padding + dim.top + height - window.innerHeight;
		if(this.offset > 0){
			window.requestAnimationFrame(this.scroll);
		}
	}
	scroll = (timestamp) => {
		if(!this.animationStart) this.animationStart = timestamp;
		let t = timestamp - this.animationStart;
		let scroll = t*(this.offset - this.currentScroll)/500 + this.currentScroll;
		if(t <= 500){
			window.scrollTo (0,scroll);
			window.requestAnimationFrame(this.scroll);
		}else{
			window.scrollTo (0,this.offset);
		}
	}
	select = (name) => {
		const { onSelect } = this.props;
		if(onSelect) onSelect(name);
	}
	render(){
		const { classes, show, items, width } = this.props;
		const { height } = this.state;
		let menuPanelClass = (show) ? classes.root: classes.root + " hide";
		let inlineStyle = {};
		if(height){
			inlineStyle.height = (show) ? height:0;
		}
		if(width){
			inlineStyle.width = width
		}
		let menuItems = [];
		if(items)
			for(let i = 0; i < items.length; i++){
				let item = items[i];
				if(item.url){
					let name = item.name;
					menuItems.push(
						<Link to={item.url} className={classes.menuItem} title={item.title} key={i} onClick={()=>{this.select(name)}}> {item.name} </Link>
					)
				}else{
					menuItems.push(
						<div className={classes.menuItem} title={item.name} value={item.value} key={i} onClick={item.onClick}> {item.name} </div>
					)
				}
			}
		return (
			<div className={menuPanelClass} ref={this.elem} style={inlineStyle}>
				<div className={classes.menuContainer}>
					{ menuItems }
				</div>
			</div>
		);	
	}
}
Menu.propTypes ={
	classes: PropTypes.object,
	show: PropTypes.bool,
	items: PropTypes.array,
	onSelect: PropTypes.func,
	width: PropTypes.string
};

export default injectSheet(styles)(Menu);