import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { InputGeo, Submit } from 'UI/Form';
import { MapMarker } from 'UI/Icon';

const styles = theme => ({
	root:{
	}
});

const validate = (values, props) => {
	const errors = {}
	return errors
}

class SearchForm extends Component{
	search = (text) => {
		const { onSearch } = this.props;
		if(onSearch) onSearch(text);
	}
	render(){
		const { handleSubmit, t } = this.props;
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="geoloc"
					label={t('warehouse.where')}
					component={InputGeo}
					geolocalize={<MapMarker />}
					nearMeLabel={t('warehouse.search.nearMe')}
				/>
				<Submit isPrimary={true} text={t('warehouse.search.search')}/>
			</form>
		);
	}
}
SearchForm.propTypes = {
	...propTypes,
	onSearch: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
};

SearchForm = injectSheet(styles)(SearchForm);
export default  reduxForm({
	form:'warehouseSearchForm',
	validate
})(SearchForm);
