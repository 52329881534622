import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles =  theme => ({
	root:{
		position: 'fixed',
		bottom: '2.5rem',
		right:'1.5rem',
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.centerCenter,
		display:'inline-flex',
		height: "2.25em",
		width: "2.25em",
		lineHeight: "1.5",
		padding: "calc(.375em - 1px) calc(.625em - 1px)",
		border: "1px solid " + theme.colors.border,
		fontSize: theme.text.default,
		textDecoration: "none",
		cursor: "pointer",
		borderRadius: "100px",
		userSelect: "none",
		...theme.colors.button.default,
		"& span:last-child:not(:first-child)":{
			marginLeft: "0.5em"
		},
		"&:active, &.active, &.is-active":{
			...theme.colors.button.active.default
		},
		"&:hover":{
			...theme.colors.button.hover.default
		},
		"&.is-primary":{
			...theme.colors.button.primary,
			"&:hover":{
				...theme.colors.button.hover.primary
			},
			"&:active, &.active, &.is-active":{
				...theme.colors.button.active.primary
			}
		},
		"&.is-secondary":{
			...theme.colors.button.secondary,
			"&:hover":{
				...theme.colors.button.hover.secondary
			},
			"&:active, &.active, &.is-active":{
				...theme.colors.button.active.secondary
			}
		},
		"&.is-disabled":{
			...theme.colors.button.disabled,
			 pointerEvents: "none"
		}
	}
});

class Button extends PureComponent {
	render(){
		const { classes, text, href, isPrimary, isSecondary, isDisabled, isActive, Icon, isSmallIcon, onClick } = this.props;
		let buttonClass = classes.root;
		if(isPrimary === true)	buttonClass += " is-primary";
		if(isSecondary === true) buttonClass += " is-secondary";
		if(isDisabled === true) buttonClass += " is-disabled";
		if(isActive === true) buttonClass += " is-active";
		if (href)
			return (
				<Link to={href} className={buttonClass}>
					{ text && <span className="text">{ text }</span> }
					{ Icon && <Icon isSmall={isSmallIcon}/> }
				</Link>
			);
		return (
				<button className={buttonClass} onClick={onClick} >
					{ text && <span className="text">{ text }</span> }
					{ Icon && <Icon isSmall={isSmallIcon}/> }
				</button>
			);
	}
}
Button.propTypes ={
	classes: PropTypes.object,
	text: PropTypes.string,
	href: PropTypes.string,
	isPrimary: PropTypes.bool,
	isSecondary: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isActive: PropTypes.bool,
	isFloat: PropTypes.bool,
	isSmallIcon: PropTypes.bool,
	Icon: PropTypes.elementType
};
export default injectSheet(styles)(Button);