import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Menu from 'UI/Menu';
import style from '../jss';

class Select extends Component{
	constructor(props){
		super(props);
		this.state = {
			isOpen: false
		}
		this.field = React.createRef();
		this.input = React.createRef();
	}
	clickOutside = (event) => {
		const { meta:{active}} = this.props;
		if (active && this.field && this.field.current && !this.field.current.contains(event.target))
			this.blur();
	}
	select = (event) => {
		const { input : { onChange, onBlur } } = this.props;
		const target = event.target;
		const value = target.getAttribute('value');
		const name = target.getAttribute('title');
		onChange(value);
		this.input.current.value = name;
		onBlur();
		this.close();
	}
	focus = (event) => {
		event.preventDefault();
		const {  input: {onFocus} } = this.props;
		if(onFocus) onFocus();
	}
	blur = () => {
		const { input : { onBlur } } = this.props;
		onBlur();
		this.close();
	}
	open = (event) => {
		event.preventDefault();
		this.focus(event);
		this.setState({isOpen:true});
	}
	close = () => {
		this.setState({isOpen:false});
	}
	componentDidMount() {
		const { meta: {initial}, items } = this.props;
		document.addEventListener('mousedown', this.clickOutside);
		if(initial && items){
			let name = null;
			for(let i = 0; i < items.length; i++){
				if(items[i].value.toString() === initial){
					name = items[i].name;
					break
				}
			}
			this.input.current.value = name;
		}
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.clickOutside);
	}
	componentDidUpdate(prevProps){
		const { meta: {initial}, items } = this.props;
		if(prevProps.meta.initial !== initial || JSON.stringify(prevProps.items) !== JSON.stringify(items)){
			let name = null;
			for(let i = 0; i < items.length; i++){
				if(items[i].value.toString() === initial){
					name = items[i].name;
					break
				}
			}
			this.input.current.value = name;
		}
	}
	render(){
		const { classes, input, asyncerror, label, meta, placeholder, items } = this.props;
		const { isOpen } = this.state;
		const { touched, error } = meta;
		if(items)
			for(let i = 0; i < items.length; i++){
				let item = items[i];
				let onClick = this.select;
				item.onClick = onClick;
			}
		let inputClass = classes.input;
		if(touched && (error || asyncerror) ) inputClass += " is-error";
		if(touched && (!error && !asyncerror) ) inputClass += " is-success";
		return (
			<div className={classes.field} ref={this.field}>
				{ label && <label className={classes.label} htmlFor={input.name}>{ label }</label> }
				<div className={classes.control}>
					<input readOnly={true} className={inputClass} placeholder={placeholder} ref={this.input} onClick={this.open} onFocus={this.focus}/>
					<input {...input} type="hidden" />
					<Menu show={isOpen} items={items} />
					{touched && error && <p className={classes.error}> {error} </p>}
					{asyncerror && <p className={classes.error}> {asyncerror} </p>}
				</div>
			</div>
		);
	}
}
Select.propTypes = {
	classes: PropTypes.object,
	input: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	items: PropTypes.array
};

export default injectSheet(style)(Select);