import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, InputGeo, InputSearch, Submit, ImageSelect, TextSelect, RadioGroup, FieldGroup } from 'UI/Form';
import { Minus, Plus, MapMarker } from 'UI/Icon';
import { Height, Show } from 'lib/motion';

const styles = theme => ({
	buttons:{
		...theme.flex.container,
		...theme.flex.endEnd
	},
	button:{
		cursor: "pointer",
		fill: theme.colors.text.default.regular,
		"&:hover":{
			fill: theme.colors.text.default.hover
		}
	}
});

const validate = (values, props) => {
	const errors = {}
	return errors
};

class SearchForm extends Component{
	constructor(props){
		super(props);
		this.state = {
			shape:undefined,
			extra: false,
		}
	}
	search = (text) => {
		const { onSearch } = this.props;
		if(onSearch) onSearch(text);
	}
	setCategory = (event, newValue, prevValue, name) =>{
		const { onSetCategory, change, untouch } = this.props;
		if(newValue !== prevValue && onSetCategory){
			if(change) change('materialId','');
			if(untouch) untouch('materialId');
			onSetCategory(newValue);
		}
	}
	setShape = (event, newValue, prevValue, name) =>{
		const { clearFields } = this.props;
		if(newValue !== prevValue){
			this.setState({shape:newValue});
			clearFields('height', 'width', 'depth', 'thickness', 'weight', 'quantity');
		}
	}
	setText = (value) => {
		const { change } = this.props;
		change('text', value);
	}
	showExtra = () => {
		this.setState({
			extra: true
		});
	}
	hideExtra = () => {
		this.setState({
			extra: false
		});
	}
	cancel = () => {
		const { onCancel } = this.props;
		if(onCancel) onCancel();
	}
	componentDidMount(){
		const { initialValues } = this.props;
		if(initialValues){
			const shape = initialValues.get('shape');
			this.setState({
				shape: shape
			});
		}
	}
	componentDidUpdate(prevProps){
		const { initialValues } = this.props;
		if(initialValues && !initialValues.equals(prevProps.initialValues)){
			const shape = initialValues.get('shape');
			this.setState({
				shape: shape
			});
		}
	}
	render(){
		const { handleSubmit, initialValues, initialMaterial, categoryItems, materialItems, t, classes } = this.props;
		const { shape, extra } = this.state;
		const items = [
			{ component: ImageSelect, image:"/assets/shapes/shapeless.png", value:"shapeless", label:t('stone.shapeType.shapeless')},
			{ component: ImageSelect, image:"/assets/shapes/block.png", value:"block", label:t('stone.shapeType.block')},
			{ component: ImageSelect, image:"/assets/shapes/slabs.png", value:"slab", label:t('stone.shapeType.slab')},
			{ component: ImageSelect, image:"/assets/shapes/tiles.png", value:"tile", label:t('stone.shapeType.tile')}
		];
		let items2 = [];
		let initialCategory;
		if(categoryItems)
			for(let i = 0; i < categoryItems.length; i ++){
				if(categoryItems[i].value === initialValues.get('categoryId')) initialCategory = categoryItems[i].name;
				items2.push(
					{ component: TextSelect, value:categoryItems[i].value, label:categoryItems[i].name, isDisabled:categoryItems[i].value ==='5b338212ecb90907caba2e48' && shape!=='tile'}
				);
			}
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="geoloc"
					label={t('stone.where')}
					component={InputGeo}
					geolocalize={<MapMarker />}
					nearMeLabel={t('stone.search.nearMe')}
				/>
				<Field
					name="shape"
					label={t('stone.shape')}
					initialLabel={t('stone.shapeType.' + initialValues.get('shape'))}
					component={RadioGroup}
					items={items}
					onChange={this.setShape}
				/>
				<Field
					name="categoryId"
					label={t('stone.category')}
					initialLabel={initialCategory}
					component={RadioGroup}
					items={items2}
					onChange={this.setCategory}
				/>
				<Field
					name="materialId"
					label={t('stone.material')}
					component={InputSearch}
					initialName={initialMaterial || initialValues.get('text')}
					onSearch={this.search}
					onCallback={this.setText}
					items={materialItems}
				/>
				<Show animate={shape==='block' || shape==='shapeless'}>
					<FieldGroup>
						<Field
							name="minWeight"
							type="number"
							label={t('stone.search.minWeight')+" (t)"}
							component={Input}
						/>
						<Field
							name="maxWeight"
							type="number"
							label={t('stone.search.maxWeight')+" (t)"}
							component={Input}
						/>
					</FieldGroup>
					<div className={classes.buttons}>
						{ extra === false && <div className={classes.button} onClick={this.showExtra}><Plus isSmall={true} /></div> }
						{ extra === true && <div className={classes.button} onClick={this.hideExtra}><Minus isSmall={true} /></div> }
					</div>
				</Show>
				<Show animate={shape==='tile' || shape==='slab'}>
					<FieldGroup>
						<Field
							name="minCoverArea"
							type="number"
							label={t('stone.search.minCoverArea') + " (m²)"}
							component={Input}
						/>
						<Field
							name="maxCoverArea"
							type="number"
							label={t('stone.search.maxCoverArea') + " (m²)"}
							component={Input}
						/>
					</FieldGroup>
					<div className={classes.buttons}>
						{ extra === false && <div className={classes.button} onClick={this.showExtra}><Plus isSmall={true} /></div> }
						{ extra === true && <div className={classes.button} onClick={this.hideExtra}><Minus isSmall={true} /></div> }
					</div>
				</Show>
				<Height animate={extra}>
					<FieldGroup>
						<Field
							name="minHeight"
							type="number"
							label={t('stone.search.minHeight') + " (m)"}
							component={Input}
						/>
						<Field
							name="maxHheight"
							type="number"
							label={t('stone.search.maxHeight') + " (m)"}
							component={Input}
						/>
					</FieldGroup>
					<FieldGroup>
						<Field
							name="minWidth"
							type="number"
							label={t('stone.search.minWidth') + " (m)"}
							component={Input}
						/>
						<Field
							name="maxWidth"
							type="number"
							label={t('stone.search.maxWidth') + " (m)"}
							component={Input}
						/>
					</FieldGroup>
					<Show animate={shape==='block' || shape==='shapeless'}>
						<FieldGroup>
							<Field
								name="minDepth"
								type="number"
								label={t('stone.search.minDepth') + " (m)"}
								component={Input}
							/>
						<Field
							name="maxDepth"
							type="number"
							label={t('stone.search.maxDepth') + " (m)"}
							component={Input}
						/>
						</FieldGroup>
					</Show>
					<Show animate={shape==='tile' || shape==='slab'}>
						<FieldGroup>
							<Field
								name="minDepth"
								type="number"
								label={t('stone.search.minThickness') + " (mm)"}
								component={Input}
							/>
							<Field
								name="maxDepth"
								type="number"
								label={t('stone.search.maxThickness') + " (mm)"}
								component={Input}
								/>
						</FieldGroup>
					</Show>
				</Height>
				<Submit isPrimary={true} text={t('stone.search.search')}/>
			</form>
		);
	}
}
SearchForm.propTypes = {
	...propTypes,
	onCancel: PropTypes.func,
	onSearch: PropTypes.func,
	onSetCategory: PropTypes.func,
	t: PropTypes.func
};

SearchForm = injectSheet(styles)(SearchForm);
export default  reduxForm({
	form:'stoneSearchForm',
	validate
})(SearchForm);
