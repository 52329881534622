import React, { PureComponent } from 'react';
import { Transition } from 'react-transition-group';

const defaultStyle = {
	overflow: 'hidden',
	opacity: 0,
	transition: `all 300ms ease-in-out`,
	height: 'auto'
}

class HeightAnimator extends PureComponent {
	constructor(props){
		super(props);
		this.elem = React.createRef();
		this.state = {
			transitionStyles: {
			}
		}
	}
	setTransition = () => {
		var height = this.elem.current.getBoundingClientRect().height;
		if(height)
			this.setState({
				transitionStyles: {
					entering: {	height: 0, opacity:0},
			  	entered:  { height: height, opacity:1},
			  	exiting:  { height: height, opacity:1},
			  	exited:   { height: 0, opacity:0}
				}
			})
	}
	componentDidMount(){
		setTimeout(this.setTransition, 100)
	}
	render(){
		const { animate, children } = this.props;
		const { transitionStyles } = this.state;
		return(
			<Transition in={ animate } timeout={0}>
				{ state => (
					<div style={{
							...defaultStyle,
							...transitionStyles[state]
						}}
						ref={this.elem}
					>
						{ children }
					</div>
				)}
				
			</Transition>
		)
	}
}

export default HeightAnimator;