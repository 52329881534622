import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Edit, Trash, Camera, BadgeChecked, ExclamationTriangle, ExclamationCircle } from 'UI/Icon';

const styles = theme => ({
	root:{
		...theme.flex.container,
		...theme.flex.startCenter,
		backgroundColor: theme.colors.secondary,
		width: '100%',
		padding: '8px'
	},
	separator: {
		...theme.flex.elem
	},
	content:{
		backgroundColor: theme.colors.primary,
		width: '100px',
		height: '40px',
		borderRadius: '20px'
	},
	checked: {
		fill: theme.colors.success
	},
	warning: {
		fill: theme.colors.warning
	},
	error: {
		fill: theme.colors.error
	}
})
class ActionBar extends PureComponent {
	remove = (event) => {
		const { onRemove } = this.props;
		event.preventDefault();
		event.stopPropagation();
		onRemove();
	}
	edit = (event) => {
		const { onEdit } = this.props;
		event.preventDefault();
		event.stopPropagation();
		onEdit();
	}
	managePhotos = (event) => {
		const { onManagePhotos } = this.props;
		event.preventDefault();
		event.stopPropagation();
		onManagePhotos();
	}
	showApprovedInfo = (event) =>{
		const { onShowInfo } = this.props;	
		event.preventDefault();
		event.stopPropagation();
		const text = "la tua inserzione è approvata e visibile";
		onShowInfo(text);
	}
	showNotApprovedInfo = (event) =>{
		const { onShowInfo } = this.props;	
		event.preventDefault();
		event.stopPropagation();
		const text = "la tua inserzione è in attesa di approvazione";
		onShowInfo(text);
	}
	showBlockedInfo = (event) =>{
		const { onShowInfo } = this.props;	
		event.preventDefault();
		event.stopPropagation();
		const text = "la tua inserzione è stata bloccata per violazione dei termini e condizioni";
		onShowInfo(text);
	}
	render(){
		const { classes, isBlocked, isApproved } = this.props;
		return (
			<div className={classes.root}>
				<Button Icon={Edit} isPrimary={false} isSmallIcon={true} onClick={this.edit} />
				<Button Icon={Camera} isPrimary={false} isSmallIcon={true} onClick={this.managePhotos}/>
				<Button Icon={Trash} isPrimary={false} isSmallIcon={true} onClick={this.remove}/>
				<div className={classes.separator} />
				{ isApproved && <div className={classes.checked} onClick={this.showApprovedInfo} ><BadgeChecked /></div> }
				{ !isApproved && <div className={classes.warning} onClick={this.showNotApprovedInfo} ><ExclamationTriangle /></div> }
				{ isBlocked && <div className={classes.error} onClick={this.showBlockedInfo} ><ExclamationCircle /></div> }
			</div>
		);
	}
}

ActionBar.propTypes = {
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	isBlocked: PropTypes.bool.isRequired,
	isApproved: PropTypes.bool.isRequired,
	onRemove: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onManagePhotos: PropTypes.func.isRequired
};

export default injectSheet(styles)(ActionBar);
