export const CHANGE_SCREEN = "UI_CHANGE_SCREEN";
export const SHOW_NOTIFICATION = "UI_SHOW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "UI_CLEAR_NOTIFICATION";
export const START_FETCHING = "UI_START_FETCHING";
export const END_FETCHING = "UI_END_FETCHING";
export const STORE_ERROR = "UI_STORE_ERROR";
export const REMOVE_ERROR = "UI_REMOVE_ERROR";
export const INIT_UPLOADING = "UI_INIT_UPLOADING";
export const START_UPLOADING = "UI_START_UPLOADING";
export const PROGRESS_UPLOADING = "UI_PROGRESS_UPLOADING";
export const END_UPLOADING = "UI_END_UPLOADING";
export const CHANGE_COLOR = "UI_CHANGE_COLOR";
export const STORE_MESSAGES = "STORE_MESSAGES";

export const storeError = (error) => {
	return {
		type:STORE_ERROR,
		error
	};
};

export const removeError = () => {
	return {
		type:REMOVE_ERROR
	};
};

export const startFetching = (toFetch) => {
	return {
		type:START_FETCHING,
		toFetch
	}
};

export const endFetching = (toFetch, done) => {
	return {
		type: END_FETCHING,
		toFetch,
		done
	}
};

export const initUploading = () => {
	return {
		type: INIT_UPLOADING
	}
};

export const startUploading = (index) => {
	return {
		type: START_UPLOADING,
		index
	}
};

export const progressUploading = (progress, index) => {
	return {
		type: PROGRESS_UPLOADING,
		progress,
		index
	}
};

export const endUploading = (index) => {
	return {
		type: END_UPLOADING,
		index
	}
};

export const changeScreen = (screen) => {
	return {
		type: CHANGE_SCREEN,
		screen
	}
};

export const showNotification = (info) => {
	return {
		type:SHOW_NOTIFICATION,
		info
	}
};

export const clearNotification = () => {
	return {
		type:CLEAR_NOTIFICATION
	}
};

export const changeColor = (color) =>{
	return {
		type: CHANGE_COLOR,
		color
	}
};

export const storeMessages = (messages) => {
	return {
		type: STORE_MESSAGES,
		messages
	}
};