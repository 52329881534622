import * as user from 'fetch/user';

import {
	startFetching,
	endFetching,
	storeError,
	removeError
} from 'state/actions/ui';

export const USER_GET = "USER_GET";
export const USER_GET_IMAGE = "USER_GET_IMAGE";
export const USER_LIST = "USER_LIST";
export const USER_SELECT = "USER_SELECT";
export const USER_SELECT_ALL = "USER_SELECT_ALL";
export const USER_UNSELECT = "USER_UNSELECT";
export const USER_UNSELECT_ALL = "USER_UNSELECT_ALL";
export const USER_UPDATE = "USER_UPDATE";
export const USER_ADD_IMAGE = "USER_ADD_IMAGE";
export const USER_REMOVE_IMAGE = "USER_REMOVE_IMAGE";
export const USER_REMOVE = "USER_REMOVE";
export const USER_EMPTY = "USER_EMPTY";

const storeGet = (answer) => {
	return {
		type: USER_GET,
		user : answer
	}
};

const storeList = (answer) => {
	return {
		type: USER_LIST,
		users: answer.users,
		page: answer.page,
		perPage: answer.perPage,
		total: answer.results
	}
};

const storeSelect = (id) => {
	return {
		type: USER_SELECT,
		id
	}
};

const storeSelectAll = () =>{
	return {
		type: USER_SELECT_ALL
	}
};

const storeUnselect = (id) => {
	return {
		type: USER_UNSELECT,
		id
	}
};

const storeUnselectAll = () =>{
	return {
		type: USER_UNSELECT_ALL
	}
};

const storeEmpty = () => {
	return {
		type: USER_EMPTY
	}
};

export const select = (id) => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeSelect(id));
	}
};

export const unselect = (id) => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeUnselect(id));
	}
};

export const selectAll = () => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeSelectAll());
	}
};

export const unselectAll = () => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeUnselectAll());
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('user_get'));
		try {
			const answer = await user.get(id);
			dispatch(storeGet(answer));
			dispatch(endFetching('user_get','user_get_done'));
		} catch(error){
			console.log(error)
			dispatch(storeError(error));
			dispatch(endFetching('user_get','user_get_error'));
		}
	}
};

export const search = (name, lang, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('user_search'));
		try {
			const answer = await user.list({name, lang, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('user_search','user_search_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('user_search','user_search_error'));
		}
	}
};

export const getBestDealers = (perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('user_get_best_dealers'));
		try {
			const answer = await user.getBestDealers(perPage, page);
			dispatch(storeList(answer));
			dispatch(endFetching('user_get_best_dealers','user_get_best_dealers_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('user_get_best_dealers','user_get_best_dealers_error'));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
}