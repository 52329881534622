import { connect } from 'react-redux';
import { logout } from 'state/actions/auth'
import Logout from './components';

const mapStateToProps = (state) => {
	return {
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(logout())
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Logout)