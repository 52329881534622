import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
//import List from 'Routes/Stone/List';
import StonesBusiness from 'Routes/Legal/StonesBusiness';
import UserPolicy from 'Routes/Legal/UserPolicy';
import Terms from 'Routes/Legal/TermsConditions';
import ErrorPage from 'Routes/Errors/404';


class Router extends Component {

	render() {
		const { t } = this.props;
		return (
			<Switch>
				<Route exact path="/legal" render={(props)=>{ return <StonesBusiness t={t} {...props} />}}/>
				<Route exact path="/legal/policy" render={(props)=>{ return <UserPolicy t={t} {...props} />}}/>
				<Route exact path="/legal/terms" render={(props)=>{ return <Terms t={t} {...props} />}}/>
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}

Router.propTypes = {
	t: PropTypes.func
};

export default Router;