import { connect } from 'react-redux';

import GMap from '../components';

const mapStateToProps = (state) => {
	return {
		ready: state.getIn(['gmaps','ready']),
		latLngBounds: state.getIn(['gmaps','latLngBounds'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {

	}
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GMap)