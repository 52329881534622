import { connect } from 'react-redux';
import { getAll } from 'state/actions/category';
import { searchName } from 'state/actions/material';
import { add } from 'state/actions/stone';
import Add from './components';

const mapStateToProps = (state) => {
	return {
		error: state.getIn(['ui','error']),
		lastAction: state.getIn(['ui','lastAction']),
		isUploading: state.getIn(['ui','isUploading']),
		uploadingProgress: state.getIn(['ui','uploadingProgress']),
		categories: state.getIn(['category','categories']),
		materials: state.getIn(['material','materials'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		add: (stone) => {
			dispatch(add(stone));
		},
		getCategories: () => {
			dispatch(getAll('createdAt', 'asc', 20, 1));
		},
		materialSearch: (text, cateogryId, perPage) => {
			dispatch(searchName(text, cateogryId, perPage));
		},
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Add)