import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

const PATH = "/me/";

export const get = async () => {
	const url = `${apiUrl}${PATH}`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const create = async (user) => {
	const url = `${apiUrl}${PATH}create`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(user)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const update = async (user) => {
	const url = `${apiUrl}${PATH}update`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		update: user
	}
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const addImage = async (imageId) => {
	const url = `${apiUrl}${PATH}addImage`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		imageId: imageId
	}
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const removeImage = async (imageId) => {
	const url = `${apiUrl}${PATH}removeImage`;
	const token = await getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		imageId: imageId
	}
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}