import { connect } from 'react-redux';
import { get, empty } from 'state/actions/stone';
import { get as getStats } from 'state/actions/analytics/stone';
import DealerView from './components';

const mapStateToProps = (state) => {
	return {
		stones: state.getIn(['stone', 'stones']),
		users: state.getIn(['user', 'users']),
		stats: state.getIn(['analytics', 'stone']),
		isLogged: state.getIn(['auth' ,'isLogged']),
		error: state.getIn(['ui', 'error'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		empty: () => {
			dispatch(empty());
		},
		getStats:(id) => {
			dispatch(getStats(id));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DealerView)