import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router'
import { Switch } from 'react-router';
import Auth from 'Routes/Auth';
import Stone from 'Routes/Stone';
import Material from 'Routes/Material';
import MaterialKind from 'Routes/MaterialKind';
import Home from 'Routes/Home';
import Warehouse from 'Routes/Warehouse';
import User from 'Routes/User';
import Me from 'Routes/Me';
import Messages from 'Routes/Messages';
import Legal from 'Routes/Legal';
import Extra from 'Routes/Extra';
import ErrorPage from 'Routes/Errors/404';


class Router extends Component {
	constructor(props){
		super(props);
		this.state = {
			returnTo:null
		};
	}
	isLogged = () => {
		const { isLogged } = this.props;
		if(isLogged === true) return true;
		else if(isLogged === false) return false;
		else return null;
	}
	isUserDisabled = () => {
		const { role } = this.props;
		if (role <  0) return true;
		else if(role > 0 ) return false;
		else return null;
	}
	componentDidMount(){
		const { isLogged, location:{pathname} } = this.props;
		if(isLogged === true && pathname !=="/me/messageBox"){
			this.getNotifications();
		} 
	}
	componentDidUpdate(prevProps){
		const { isLogged, lastAction } = this.props;
		if(isLogged !== prevProps.isLogged && this.isLogged()) this.getNotifications();
		if(lastAction !== prevProps.lastAction && lastAction === "conversation_get_messages_to_read_done"){
			this.listenToNotifications();
		}
	}
	storeReturnTo = (pathname) => {
		if(pathname)
			this.setState({
				returnTo: pathname
			})
	}
	getNotifications = () => {
		const { getNotifications } = this.props;
		if(getNotifications) getNotifications();
	}

	listenToNotifications = () => {
		const { listenToNotifications } = this.props;
		if(listenToNotifications) listenToNotifications();
	}
	render() {
		const { isLogged, role, t, body, i18n } = this.props;
		return (
			<Switch>
				<Route exact path="/" render={(props)=>{ 
					if(this.isUserDisabled() === true) return <Redirect to="/me" />
					return <Home t={t} body={body} {...props} />
				}}/>
				<Route exact path="/warehouse" render={(props)=>{ 
					if(this.isUserDisabled() === true) return <Redirect to="/me" />
					return <Home t={t} body={body} {...props} />
				}}/>
				<Route path="/auth" render={(props)=>{ return <Auth t={t} isLogged={isLogged} role={role} {...props} />}}/>
				<Route path="/user" render={(props)=>{ 
					if(this.isUserDisabled() === true) return <Redirect to="/me" />
					return <User t={t} body={body} {...props} />
				}}/>
				<Route path="/material" render={(props)=>{ 
					if(this.isUserDisabled() === true) return <Redirect to="/me" />
					return <Material t={t} body={body} {...props} />
				}}/>
				<Route path="/material_kind" render={(props)=>{ 
					if(this.isUserDisabled() === true) return <Redirect to="/me" />
					return <MaterialKind t={t} body={body} {...props} />
				}}/>
				<Route path="/stone" render={(props)=>{
					if(this.isUserDisabled() === true) return <Redirect to="/me" /> 
					return <Stone t={t} isLogged={isLogged} role={role} body={body} {...props} />
				}}/>
				<Route path="/warehouse" render={(props)=>{ 
					if(this.isUserDisabled() === true) return <Redirect to="/me" />
					return <Warehouse t={t} isLogged={isLogged} role={role} body={body} {...props} />
				}}/>
				<Route path="/me" render={(props) => { 
					return <Me t={t} isLogged={isLogged} role={role}  {...props} />
				}}/>
				<Route path="/messages" render={(props) => {
					if(this.isUserDisabled() === true) return <Redirect to="/me" /> 
					if(this.isLogged() === false) return <Redirect to="/me" />
					return (<Messages t={t} isLogged={isLogged} role={role} language={i18n.language} {...props} />)
				}} />
				<Route path="/legal" render={(props)=>{ return <Legal t={t} {...props} />}}/>
				<Route path="/extra" render={(props)=>{ return <Extra t={t} {...props} />}}/>
				<Route path="/not_found" render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
				<Route  render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}
Router.propTypes ={
	isLogged: PropTypes.bool,
	role: PropTypes.number.isRequired,
	t: PropTypes.func.isRequired,
	getNotifications: PropTypes.func.isRequired,
	listenToNotifications: PropTypes.func.isRequired,
	unlistenToNotifications: PropTypes.func.isRequired,
	noTimeout: PropTypes.number,
	lastAction: PropTypes.string,
	error: PropTypes.object,
	body: PropTypes.object
};

Router = withRouter(Router);

export default Router;