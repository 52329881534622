import { connect } from 'react-redux';
import App from 'App/components';
import { authenticate } from 'state/actions/auth';
import { getMessagesToRead, listenMessagesToRead, unlistenMessagesToRead } from 'state/actions/conversation';
import { changeScreen, changeColor } from 'state/actions/ui';

const mapStateToProps = (state) => {
	return {
		isLogged: state.getIn(['auth', 'isLogged']),
		role: state.getIn(['auth', 'role']),
		error: state.getIn(['ui','error']),
		info: state.getIn(['ui','info']),
		isWaiting: state.getIn(['ui','isFetching']),
		screen: state.getIn(['ui' ,'screen']),
		color: state.getIn(['ui', 'color']),
		messages: state.getIn(['conversation', 'messagesToRead']),
		lastAction: state.getIn(['ui','lastAction']),
		messageToReadTimeout: state.getIn(['conversation', 'messageToReadTimeout'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		authenticate: () => {
			dispatch(authenticate());
		},
		changeScreen:(screen) => {
			dispatch(changeScreen(screen));
		},
		changeColor: (color) => {
			dispatch(changeColor(color));
		},
		getMessagesToRead: () => {
		 	dispatch(getMessagesToRead());
		},
		listenMessagesToRead:(millisec) => {
		 	dispatch(listenMessagesToRead(millisec));
		},
		unlistenMessagesToRead:(timeout) => {
			dispatch(unlistenMessagesToRead(timeout));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App)