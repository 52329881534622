import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import Button from 'UI/Button';
import { Trash } from 'UI/Icon';

const styles = theme => ({
	root:{
		padding: '8px',
	},
	image:{
		height: 200,
		width: 200,
		backgroundColor: '#000000',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		cursor: 'pointer',
		borderRadius: '8px',
		boxShadow: theme.shadows.s1
	}
});

class Image extends Component{
	remove = () => {	
		const { onRemove, image } = this.props;
		const id = image.get('_id');
		if(id) onRemove(id);
	}
	render(){
		const { classes, image } = this.props;
		const url = `${staticUrl}/${image.getIn(['urls','ld'])}`;
		return(
			<div className={classes.root}>
				<div 
					className={classes.image}
					style={{backgroundImage: 'url(' + url + ')'}}
				>
					<Button Icon={Trash} isSmallIcon={true} onClick={this.remove} />
				</div>
			</div>
		);
	}
}

Image.propTypes = {
	classes: PropTypes.object.isRequired,
	onRemove: PropTypes.func.isRequired,
	image: PropTypes.instanceOf(Map).isRequired
};

export default injectSheet(styles)(Image);