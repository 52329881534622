import * as me from 'fetch/me';
import * as imageFetch from 'fetch/image';
import { authenticate } from 'state/actions/auth';
import { 
	startFetching,
	endFetching,
	storeError,
	removeError,
	showNotification,
	clearNotification,
	progressUploading,
	startUploading,
	endUploading
} from 'state/actions/ui';

export const ME_GET = "ME_GET";
export const ME_UPDATE = "ME_UPDATE";
export const ME_REMOVE = "ME_REMOVE";
export const ME_EMPTY = "ME_EMPTY";

const storeGet = (answer) => {
	return {
		type: ME_GET,
		me : answer
	}
};

const storeUpdate = (me) => {
	return {
		type: ME_UPDATE, 
		me
	}
};

export const storeRemove = () => {
	return {
		type: ME_REMOVE,
	}
};

const storeEmpty = () => {
	return {
		type: ME_EMPTY
	}
};

export const create = (user) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('me_update'));
		dispatch(clearNotification());
		try {
			await me.create(user);
			dispatch(authenticate());
			dispatch(endFetching('me_create','me_create_done'));
			dispatch(showNotification('User created!'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('me_create','me_create_error'));
		}
	}
};

export const uploadImage = (id, image, images, index) => {
	return dispatch => {
		const onProgress = (progress) => {
			dispatch(progressUploading(progress, index));
		}
		const onSuccess = async (err, newImage) => {
			if(err){
				dispatch(endUploading(index));
				dispatch(storeError(err));
				dispatch(endFetching('me_upload_image:' + image.name, 'me_upload_image:' + image.name));
			} else {
				try {
					for(let i = 0; i < images.length; i++){
						await me.removeImage(images[i]._id);
					}
					await me.addImage(newImage._id);
					dispatch(endUploading(index))
					dispatch(endFetching('me_upload_image:' + image.name, 'me_upload_image_done:' + image.name));
				} catch(error){
					dispatch(storeError(error));
					dispatch(endFetching('me_upload_image:' + image.name, 'me_upload_image_error:' + image.name));
				}
			} 
		}
		dispatch(removeError());
		//dispatch(startFetching('warehouse_upload_image:' + image.name));
		dispatch(clearNotification());
		dispatch(startUploading(index));
		imageFetch.upload(image, 'user', id, onProgress, onSuccess);
	}
};

export const removeImage = (imageId) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('me_remove_image'));
		dispatch(clearNotification());
		try {
			await me.removeImage(imageId);
			dispatch(endFetching('me_remove_image','me_remove_image_done'));
			dispatch(showNotification('Image removed!'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('me_remove_image','me_remove_image_error'));
		}
	}
};

export const get = () => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('me_get'));
		try {
			const answer = await me.get();
			dispatch(storeGet(answer));
			dispatch(endFetching('me_get','me_get_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('me_get','me_get_error'));
		}
	}
};

export const update = (user) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('me_update'));
		dispatch(clearNotification());
		try {
			await me.update(user);
			dispatch(storeUpdate(user));
			dispatch(endFetching('me_update','me_update_done'));
			dispatch(showNotification('Updated!'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('me_update','me_update_error'));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};