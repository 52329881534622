import { apiUrl, appUrl } from 'config/urls';
import { storeToken, removeToken, getToken } from 'lib/jwt';
const PATH = "/user_auth/";

export const login = async (email, password) => {
	removeToken();
	const url = `${apiUrl}${PATH}login`;
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		email,
		password
	};
	const response = await fetch( url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	storeToken(json.jwt);
	return json;
}

export const signup = async (email, passwordA, passwordB) => {
	removeToken();
	const url = `${apiUrl}${PATH}signup`;
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		email,
		passwordA,
		passwordB
	};
	const response = await fetch( url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	return json;
}

export const recoverPassword = async (email) => {
	removeToken();
	const url = `${apiUrl}${PATH}recoverPassword`;
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		email
	};
	const response = await fetch( url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	storeToken(json.jwt);
	return json;
}

export const changePasswordFromRecovery = async (token, newPasswordA, newPasswordB) => {
	removeToken();
	const url = `${apiUrl}${PATH}changePasswordFromRecovery`;
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		token,
		newPasswordA,
		newPasswordB
	};
	const response = await fetch( url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	storeToken(json.jwt);
	return json;
}

export const appChangePasswordFromRecovery = async (token, newPasswordA, newPasswordB) => {
	removeToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		token,
		newPasswordA,
		newPasswordB
	};
	const response = await fetch( appUrl + "/user_auth/changePasswordFromRecovery", {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	storeToken(json.jwt);
	return json;
}

export const emailVerify = async (token) => {
	removeToken();
	const url = `${apiUrl}${PATH}emailVerify`;
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		token
	};
	const response = await fetch( url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	storeToken(json.jwt);
	return json;
}

export const appEmailVerify = async (token) => {
	removeToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let data = {
		token
	};
	const response = await fetch( appUrl + "/user_auth/emailVerify", {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 && response.status !== 400) throw new Error('Server Error');
	const json = await response.json();
	if(json.error) throw new Error(json.error.message);
	storeToken(json.jwt);
	return json;
}

export const logout = () => {
	removeToken();
	return true;
}

export const authenticate = async (callback) => {
	const url = `${apiUrl}${PATH}`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const response = await fetch( url, {
		method: 'GET',
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
} 