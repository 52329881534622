import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import injectSheet from 'react-jss';
import { withTranslation } from 'react-i18next';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import EmptyResults from './EmptyResults';
import Pagination from 'UI/Pagination';

const styles = theme => ({
	root:{
		...theme.layout.container,
		...theme.layout.section,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
		paddingTop: 0
	}
});

class DepositSearch extends Component {
	constructor(props){
		super(props);
		this.state = {
			searched: false
		};
		this.elem = React.createRef();
		this.isNewSearch = false;
	}
	select = (id) => {
		const { history } = this.props;
		if(history) history.push('/warehouse/' + id);
	}
	startScroll = () => {
		const { body } = this.props;
		let node = this.elem.current;
		this._animationStart = null;
		let dim = node.getBoundingClientRect();
		this._currentScroll = body.scrollTop;
		this._offset = this._currentScroll + dim.top - 60;
		if(this._offset > 0){
			window.requestAnimationFrame(this.scroll);
		}
	}
	scroll = (timestamp) => {
		const { body } = this.props;
		if(!this._animationStart) this._animationStart = timestamp;
		let t = timestamp - this._animationStart;
		let scroll = t*(this._offset - this._currentScroll)/500 + this._currentScroll;
		if(t <= 500){
			body.scrollTop = scroll;
			window.requestAnimationFrame(this.scroll);
		}else{
			body.scrollTop =this._offset;
		}
	}
	prepareSearch = (values) => {
		const { history, location:{ search } } = this.props;
		const geoloc = values.get('geoloc');
		const params = new URLSearchParams(search);
		params.set('orderBy', 'createdAt');
		params.set('order', 'desc');
		params.set('page', 1);
		params.set('perPage', 10);
		if(geoloc){
			params.set('lat', geoloc.coordinates[1]);
			params.set('long', geoloc.coordinates[0]);
		}else{
			params.delete('lat');
			params.delete('long');
		}
		history.push({
			search: '?'+params.toString()
		});
		this.isNewSearch = true;
	}
	changePage = (page) => {
		const { history, location:{ search } } = this.props;
		const params = new URLSearchParams(search);
		params.set('page', page);
		history.push({
			search: '?'+params.toString()
		});
	}
	search = (lat, lng, orderBy, order, perPage, page) => {
		const { search } = this.props;
		search(lat, lng, orderBy, order, perPage, page);
	}
	componentDidMount(){
		const { location:{ search } } = this.props;
		const params = new URLSearchParams(search);
		const orderBy = params.get('orderBy') || 'createdAt';
		const order = params.get('order') || 'desc';
		const perPage = params.get('perPage') || 10;
		const page = params.get('page') || 1;
		const lat = params.get('lat');
		const lng = params.get('long');
		this.search(lat, lng, orderBy, order, perPage, page);
	}
	componentDidUpdate(prevProps){
		const { location:{ search }, warehouses, lastAction } = this.props;
		if(lastAction!== prevProps.lastAction && lastAction === "warehouse_search_done") this.setState({searched: true});
		if(prevProps.location.search !== search){
			const params = new URLSearchParams(search);
			const orderBy = params.get('orderBy') || 'createdAt';
			const order = params.get('order') || 'desc';
			const perPage = params.get('perPage') || 10;
			const page = params.get('page') || 1;
			const lat = params.get('lat');
			const lng = params.get('lng');
			this.search(lat, lng, orderBy, order, perPage, page);
		}
		if(warehouses && !warehouses.equals(prevProps.warehouses) && this.isNewSearch){
			this.startScroll();
		}
	}
	componentWillUnmount(){
		const { empty } = this.props;
		empty();
	}
	render(){
		const { classes, t, warehouses, page, perPage, results } = this.props;
		const { searched } = this.state;
		return (
			<div className={classes.root}>
				<SearchForm t={t} onSubmit={ this.search } onSearch={this.search}/>
				{ (searched && results === 0) && <EmptyResults t={t}/> }
				<SearchResults t={t} warehouses={warehouses} onSelect={this.select}/>
				{ results > 0 && <Pagination page={page} perPage={perPage} total={results} onChange={this.changePage} /> }
				<div ref={this.elem} />
			</div>
		);
	}
}

DepositSearch.propTypes ={
	classes: PropTypes.object.isRequired,
	body: PropTypes.object,
	page: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	results: PropTypes.number.isRequired,
	warehouses: PropTypes.instanceOf(OrderedMap).isRequired,
	screen: PropTypes.string,
	lastAction: PropTypes.string,
	t: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired	
};

export default injectSheet(styles)(withTranslation()(DepositSearch));