import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'UI/Navbar';
import { SignIn, Envelope, UserCircle, Cubes, Warehouse, SignOut } from 'UI/Icon';
import { Logo, LogoIcon }  from 'Elements/Logo';

class AppBar extends PureComponent{
	isLogged = () => {
		const { isLogged } = this.props;
		if(isLogged === true) return true;
		else if(isLogged === false) return false;
		else return null;
	}
	isCompleted = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && role !== -2) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	isVerified = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && role !== -1) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	isOwner = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && [4,5,6].includes(role) ) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	isDealer = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && [3,5,6].includes(role) ) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	render(){
		const { t, messages, screen } = this.props;
		let navItems = [];
		if(this.isLogged() === false){
			navItems = [
				{type:"link", name:t('navbar.enter'), title:"singin", url:"/auth/login", icon: SignIn}
			];
		}else if(this.isLogged() === true){
			if(this.isOwner() === true) navItems.push({type:"link", name:"", title:"il mio deposito", url:"/warehouse/update", icon: Warehouse});
			if(this.isDealer() === true) navItems.push({type:"link", name:"", title:"i miei annunci", url:"/stone/list", icon: Cubes});
			if(this.isCompleted() === true && this.isVerified() === true) navItems.push({type:"link", name:"", title:"user", url:"/me", icon: UserCircle},);
			if(this.isCompleted() === true && this.isVerified() === true) navItems.push({type:"link", name:"", title:"messages", url:"/messages", icon: Envelope, info:messages});
			if(this.isCompleted() === false || this.isVerified() === false) navItems.push({type:"link", name:"", title:"logout", url:"/auth/logout", icon: SignOut,});
		}
		const StonesLogo = (screen === 'mobile' || screen === 'tablet') ? (<LogoIcon/>) :(<Logo/>);
		return (
			<Navbar Logo={StonesLogo} items={navItems} alt="stone's business" />
		);	
	}
}

AppBar.propTypes ={
	isLogged: PropTypes.bool,
	role: PropTypes.number,
	messages: PropTypes.number,
	t: PropTypes.func,
	screen: PropTypes.string
};
export default AppBar;