import React, { Component } from 'react';
import { OrderedMap, List, Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Close, Camera } from 'UI/Icon';
import Image from './Thumbs/Image';
import Preview from './Thumbs/Preview';

const styles = theme => ({
	stonePhotos:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
	},
	container:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.startCenter,
		...theme.flex.wrap
	},
	title:{
		...theme.text.title3,
		marginBottom: '16px'
	},
	action:{
		position: 'absolute',
		top: '.5rem',
		left: '1.5rem',
		zIndex: 10
	},
	addContainer:{
		padding: '8px'
	},
	add:{
		height: 200,
		width: 200,
		...theme.flex.container,
		...theme.flex.centerCenter,
		fill: theme.colors.secondary,
		border: `1px solid ${theme.colors.secondary}`,
		borderRadius: '8px',
		cursor: 'pointer',
		'&:hover':{
			fill: theme.colors.primary,
			boxShadow: theme.shadows.s1
		}
	},
	input:{
		display: 'none'
	},
});

class StoneImages extends Component {
	constructor(props){
		super(props);
		this.input = React.createRef();
		this.state = {
			tmpImages: []
		};
	}
	get = () => {
		const { match:{params}, get } = this.props;
		const id = params.id;
		if(id) get(id)
	}
	removeImage = (imageId) => {
		const { match:{params}, removeImage } = this.props;
		const id = params.id;
		if(id && imageId) removeImage(id, imageId);
	}
	clearPreview = () => {
		this.input.current.value = '';
		this.setState({
			tmpImages: []
		});
	}
	browse = () => {
		this.clearPreview();
		this.input.current.click();
	}
	startUpload = () => {
		let fileList = this.input.current.files;
		this.setState({
			tmpImages: fileList
		});
	}
	upload = (image, index) => {
		const { uploadImage, match:{params} } = this.props;
		const id = params.id;
		if(id && image && index >= 0) uploadImage(id, image, index);
	}
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	isMine = () => {
		const { match:{params}, stones } = this.props;
		const id = params.id;
		const stone = stones.get(id);
		if(stone && stone.get('mine')) return true;
		return false;
	}
	componentDidMount(){
		const { match:{params} } = this.props; 
		this.get(params.id);
	}
	componentDidUpdate(prevProps){
		const { stones, error, lastAction } = this.props;
		if(stones && !stones.equals(prevProps.stones) && !stones.isEmpty() && !this.isMine()) this.goToNotFound();
		if(error && error.message === 'not found') this.goToNotFound();
		if(lastAction && lastAction !== prevProps.lastAction && lastAction.indexOf("stone_upload_image_done")>-1){
			this.clearPreview();
		 	this.get();
		}
		if(lastAction !== prevProps.lastAction && lastAction === "stone_remove_image_done") this.get(); 
	}
	render(){
		const { classes, stones, match:{params}, t, isUploading, uploadingProgress } = this.props;
		const { tmpImages } = this.state;
		const id = params.id;
		const imagesList = [];
		const images = stones.getIn([id, 'images']) || List();
		images.forEach((image, index) => {
			imagesList.push(
				<Image key={image.get('_id')} image={image} onRemove={this.removeImage} />
			);
		});
		for(let index = 0; index < tmpImages.length; index ++){
			const file = tmpImages.item(index);
			imagesList.push(
				<Preview key={index} index={index} file={file} progress={uploadingProgress.get(index)} isUploading={isUploading.get(index)} onUpload={this.upload} />
			);
		}
		return (
			<div className={classes.stonePhotos}>
				<div className={classes.action}><Button Icon={Close} isSmallIcon={true} onClick={this.back} /></div>
				<h1 className={classes.title}>{t('stone.list.images')}</h1>
				<div className={classes.container}>
					{ imagesList }
					<div className={classes.addContainer}>
						{ !isUploading.get(0) &&
							<div className={classes.add} onClick={this.browse}>
								<Camera isBig={true}/>
								<input name="images" type="file" accept="image/png, image/jpeg" className={classes.input} ref={this.input} multiple={false} onChange={this.startUpload}/>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

StoneImages.propTypes = {
	classes: PropTypes.object.isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	lastAction: PropTypes.string,
	error: PropTypes.string,
	isUploading: PropTypes.instanceOf(Map).isRequired,
	uploadingProgress: PropTypes.instanceOf(Map).isRequired,
	get: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	removeImage: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(StoneImages);