import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Trash } from 'UI/Icon';

const styles = theme => ({
	root:{
		height: 100,
		width: 150,
		backgroundColor: '#000000',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		cursor: 'pointer'
	}
});

class Image extends Component{
	delete = () => {	
		const { onDelete, id } = this.props;
		onDelete(id);
	}
	render(){
		const { classes, url } = this.props;
		return(
			<div 
				className={classes.root}
				style={{backgroundImage: 'url(' + url + ')'}}
			>
				<Button Icon={Trash} isSmallIcon={true} onClick={this.delete} />
			</div>
		);
	}
}

Image.propTypes = {
	classes: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	url: PropTypes.string.isRequired,
	id: PropTypes.string
};

export default injectSheet(styles)(Image);