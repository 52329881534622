import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';
import gmaps from 'lib/google-maps/reducers';
import analytics from './analytics';
import auth from './auth';
import category from './category';
import conversation from './conversation';
import material from './material';
import me from './me';
import message from './message';
import stone from './stone';
import ui from './ui';
import user from './user';
import warehouse from './warehouse';

const rootReducer = combineReducers({
	analytics,
	auth,
	category,
	conversation,
	form,
	gmaps,
	material,
	me,
	message,
	stone,
	ui,
	user,	
	warehouse
});

export default rootReducer;