export default {
	"category":{
		"type":{
			"Granite": "Гранит",
			"Limestone": "Известняк",
			"Marble": "Мрамор",
			"Onyx": "Оникс",
			"Quartzite": "Кварцит",
			"Stone": "Камень",
			"Travertine": "Травертин",
			"Ceramic": "керамический"
		}
	},
	"errors":{
		"required": "обязательный",
		"userNotFound": "Пользователь не существует",
		"wrongPassword": "неправильный пароль",
		"invalidEmail": "Неверный адрес электронной почты",
		"noMatchPassword": "Пароли не совпадают",
		"UserAlreadyExists": "Это письмо уже отправлено зарегистрированным пользователем"
	},
	"login":{
		"title": "Зайти",
		"login": "Зайти",
		"signup": "Зарегестрироваться",
		"email": "Е-майл",
		"password": "Password",
		"enter": "Входить",
		"forgotPassword": "Забыли пароль?"
	},
	"material":{
	},
	"navbar":{
		"enter": "Входить"
	},
	"signup":{
		"title": "Зарегестрироваться",
		"login": "Зайти",
		"signup": "Зарегестрироваться",
		"email": "Е-майл",
		"password": "Пароль",
		"passwordB": "Подтвердить пороль",
		"signin": "Зарегестрироваться"
	},
	"stone":{
		"name": "Имя",
		"shape": "форма",
		"category": "Вид камня",
		"material": "материал",
		"height": "Высота",
		"width": "Ширина",
		"depth": "Длина",
		"thickness": "Толщина",
		"weight": "Вес",
		"coverArea": "Зона покрытия",
		"quantity": "Количества",
		"shapeType":{
			"shapeless": "Деформированый",
			"block": "Блок",
			"slab": "Плита",
			"tile": "Плитка"
		},
		"search":{
			"title": "Найти материал",
			"minHeight": "Минимальноя высота",
			"maxHeight": "Максимальноя высота",
			"minWidth": "Минимальная ширина",
			"maxWidth": "Максимальная ширина",
			"minDepth": "Минимальная длина",
			"maxDepth": "Максимальная длина",
			"minThickness": "Минимальная толщина",
			"maxThickness": "Максимальная толщина",
			"minWeight": "Минимальный вес",
			"maxWeight": "Максимальный вес",
			"minCoverArea": "Минимальная зона покрытия ",
			"maxCoverArea": "Максимальная зона покрытия",
			"search": "Искать"
		},
		"list":{
			"title": "Мой список материалов",
			"add": "Добавить",
			"images": "Фотографии",
			"modify": "Модификация",
			"view": "Увидеть",
			"delete": "Удалить",
			"search": "Искать"
		},
		"add":{
			"addImages": "Добавить фотографии",
			"where": "Где находиться ?",
			"save": "Сохронить",
			"cancel": "Отмена"
		},
		"update":{
			"where": "Где находиться ?",
			"save": "Сохронить",
			"cancel": "Отмена"
		},
		"images":{
			"addImages": "Добавить фотографии",
			"add": "Добавить",
			"cancel": "Отмена"
		}
	},	
	"user":{
		"kind": "тип пользователя",
		"firstName": "Имя",
		"lastName": "Фамилия ",
		"pro": "дилер",
		"telephone": "Номер телефона",
		"email": "Е-майл",
		"company": "Компания",
		"private": "личный",
		"companyName": "название компании",
		"taxCode": "Номер НДС",
		"update":{
			"save": "Сохронить",
			"cancel": "Отмена"
		},
		"images":{
			"addImages": "Добавить фотографии",
			"add": "Добавить",
			"cancel": "Отмена"
		}		
	},
	"address":{
		"country":"Страна",
		"state": "Область / округ / штат",
		"city": "город",
		"address": "Адрес",
		"postalCode": "Почтовый индекс"
	},
	"message":{
		"call": "Войдите или зарегистрируйтесь, чтобы отправить сообщение продавцу",
		"label": "Написать сообщение",
		"send": "Отправить",
		"empty": "Нет сообщений"
	}	
}