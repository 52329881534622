import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class FieldGroup extends Component{
	render(){
		const { classes, children, isMarginless } = this.props;
		const fieldGroupClass = (isMarginless) ? classes.fieldGroup + " is-marginless" : classes.fieldGroup
		return (
			<div className={fieldGroupClass} >
				{ children }
			</div>
		);
	}
}
FieldGroup.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node
};

export default injectSheet(style)(FieldGroup);