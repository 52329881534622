import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class TextSelect extends Component {
	constructor(props){
		super(props);
		this.state = { selected:false };
	}
	componentDidMount(){
		const { isSelected } = this.props;
		if(isSelected)
			this.setState({selected:isSelected})
	}
	componentDidUpdate(prevProps) {
		const { isSelected } = this.props;
		if(isSelected !== prevProps.isSelected)
			this.setState({selected:isSelected});
	}
	click = (event) => {
		const { selected } = this.state;
		const { onSelect, input, isDisabled } = this.props;
		if(isDisabled) return;
		this.setState({selected:!selected});
		if(onSelect) onSelect(!selected);
		if(input && input.onChange) input.onChange(!selected);
	}
	render(){
		const { classes, label, isDisabled } = this.props;
		const { selected } = this.state;
		let textSelectClass = classes.textSelect;
		if(selected === true) textSelectClass += " is-selected";
		if(isDisabled === true) textSelectClass += " is-disabled";
		return (
			<div className={textSelectClass} onClick={this.click}>
				{ label }
			</div>
		);	
	}
}
TextSelect.propTypes ={
	classes: PropTypes.object,
	isSelected: PropTypes.bool,
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	onSelect: PropTypes.func
};
export default injectSheet(style)(TextSelect);