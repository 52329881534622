import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Height } from 'lib/motion';
import injectSheet from 'react-jss';

const styles =  theme => ({
	langSelector:{
		position: 'absolute',
		right: "1rem",
		top: "65px",
		height: '20px',
		zIndex: 100
	},
	lang:{
		height: '20px',
		width: '20px',
		borderRadius: '20px',
		margin: '.3rem',
		cursor: 'pointer',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundColor: '#666666',
		"&.it":{
			backgroundImage:'url(/assets/flags/it.png)'
		},
		"&.en":{
			backgroundImage:'url(/assets/flags/en.png)'
		},
		"&.fr":{
			backgroundImage:'url(/assets/flags/fr.png)'
		},
		"&.de":{
			backgroundImage:'url(/assets/flags/de.png)'
		},
		"&.es":{
			backgroundImage:'url(/assets/flags/es.png)'
		},
		"&.ru":{
			backgroundImage:'url(/assets/flags/ru.png)'
		},
		"&.zh":{
			backgroundImage:'url(/assets/flags/zh.png)'
		},
		"&.ar":{
			backgroundImage:'url(/assets/flags/ar.png)'
		}
	}
});

class LangSelector extends PureComponent{
	constructor(props){
		super(props);
		this.state = {
			isActive: false
		};
		this.timeout = null;
	}
	changeLang = (lang) => {
		const { onChangeLang } = this.props;
		if(onChangeLang) onChangeLang(lang)
		this.toggle();
	}
	toggle = () => {
		const { isActive } = this.state;
		this.setState({
			isActive: !isActive
		});
		if(isActive) window.clearTimeout(this.timeout);
		if(!isActive)	this.timeout = window.setTimeout(this.toggle, 5000);
	}
	render(){
		const { classes, lang } = this.props;
		const { isActive } = this.state;
		let langCode = (lang)? lang.substring(0, 2): '';
		return (
			<div className={classes.langSelector} >
				{ langCode === "it" && <div className={classes.lang + " it"} onClick={this.toggle} /> }
				{ langCode === "en" && <div className={classes.lang + " en"} onClick={this.toggle} /> }
				{ langCode === "fr" && <div className={classes.lang + " fr"} onClick={this.toggle} /> }
				{ langCode === "de" && <div className={classes.lang + " de"} onClick={this.toggle} /> }
				{ langCode === "es" && <div className={classes.lang + " es"} onClick={this.toggle} /> }
				{ langCode === "ru" && <div className={classes.lang + " ru"} onClick={this.toggle} /> }
				{ langCode === "zh" && <div className={classes.lang + " zh"} onClick={this.toggle} /> }
				{ langCode === "ar" && <div className={classes.lang + " ar"} onClick={this.toggle} /> }
				<Height animate={isActive} name='lang'>
					{ langCode !== "it" && <div className={classes.lang + " it"} onClick={()=>{this.changeLang('it')}} /> }
					{ langCode !== "en" && <div className={classes.lang + " en"} onClick={()=>{this.changeLang('en')}} /> }
					{ langCode !== "fr" && <div className={classes.lang + " fr"} onClick={()=>{this.changeLang('fr')}} /> }
					{ langCode !== "de" && <div className={classes.lang + " de"} onClick={()=>{this.changeLang('de')}} /> }
					{ langCode !== "es" && <div className={classes.lang + " es"} onClick={()=>{this.changeLang('es')}} /> }
					{ langCode !== "ru" && <div className={classes.lang + " ru"} onClick={()=>{this.changeLang('ru')}} /> }
					{ langCode !== "zh" && <div className={classes.lang + " zh"} onClick={()=>{this.changeLang('zh')}} /> }
					{ langCode !== "ar" && <div className={classes.lang + " ar"} onClick={()=>{this.changeLang('ar')}} /> }
				</Height>
			</div>
		);	
	}
}

LangSelector.propTypes ={
	classes: PropTypes.object,
	onChangeLang: PropTypes.func
};
export default injectSheet(styles)(LangSelector);