import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import injectSheet from 'react-jss';
import ImagesHeader from './ImagesHeader';
import ImagesSlider from './ImagesSlider';
import Logo from './Logo';
import UpdateForm from './UpdateForm';
import PhotosModal from './Modals/Photos'

const styles = theme => ({
	root:{
		...theme.layout.container,
		...theme.flex.container
	},
	content:{
		...theme.layout.section,
		...theme.flex.elemSetFlex(60, 'row')
	},
	sectionTitle: {
		...theme.text.title5
	},
	rightColumn:{
		...theme.layout.section,
		...theme.flex.elemSetFlex(40, 'row')
	},
	actions:{
		...theme.layout.section,
		...theme.layout.container,
		paddingTop: 0
	},
	logoContainer:{
		...theme.layout.container,
		['@media ' + theme.media.notTouch]: {
			...theme.flex.container
		}
	},
	logoContent:{
		...theme.layout.section,
		paddingBottom: 0,
		['@media ' + theme.media.notTouch]: {
			paddingBottom: '3rem',
			...theme.flex.elemSetFlex(60, 'row'),
		}
	}
});

class WarehouseUpdate extends Component {
	constructor(props){
		super(props);
		this.state = {
			isPhotosOpen: false,
			isPhotosManageOpen: false,
			index: 0
		};
	}
	goToNotFound = () =>  {
		const { history } = this.props;
		if(history) history.push('/not_found');
	}
	get = () => {
		const { get } = this.props;
		get();
	}
	getImage = (imageId) => {
		const { getImage } = this.props;
		if(imageId) getImage(imageId);
	}
	add = () => {
		const { add } = this.props;
		add();
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	update = (values) => {
		const { warehouses, update } = this.props;
		const warehouse  = warehouses.first();
		const id = warehouse.get('_id');
		update(id, values);
	}
	uploadImage = (image, index) => {
		const { uploadImage, id } = this.props;
		if(id && image && index >=0 ) uploadImage(id, image, index);
	}
	removeImage = (imageId) => {
		const { removeImage, id } = this.props;
		if(id && imageId) removeImage(id, imageId);
	}
	uploadLogo = (image) => {
		const { uploadLogo, warehouses } = this.props;
		const warehouse = warehouses.first();
		const id = warehouse.get('_id');
		if(id && image) uploadLogo(id, image);
	}
	updatePhotos = ( list ) => {
		const { warehouse } = this.state;
		this.setState({
			warehouse: warehouse.set('images', list)
		});
		this.update(warehouse.set('images', list));
	}
	updateLogo = (id) => {
		const { warehouse } = this.state;
		this.setState({
			warehouse: warehouse.set('logo', id)
		});
		this.update(warehouse.set('logo', id));
	}
	togglePhotos = (index) => {
		const { isPhotosOpen } = this.state;
		this.setState({
			isPhotosOpen: !isPhotosOpen,
			index: index
		});
	}
	togglePhotosManage = () => {
		const { isPhotosManageOpen } = this.state;
		this.setState({
			isPhotosManageOpen: !isPhotosManageOpen
		});
	}
	componentDidUpdate(prevProps){
		const { error } = this.props;
		if(error && error.message === 'not found') this.goToNotFound();
	}
	componentDidMount(){
		this.get();
	}
	render(){
		const { t, isUploading, uploadingProgress, warehouses, classes } = this.props;
		const { isPhotosOpen, isPhotosManageOpen, index } = this.state;
		const warehouse = warehouses.first();
		if(!warehouse) return(<React.Fragment/>);
		const images = warehouse.get('images');
		const logo = warehouse.get('logo');
		return (
			<React.Fragment>
				<ImagesHeader t={t} images={images} onOpenPhotos={this.togglePhotos} onOpenPhotosManage={this.togglePhotosManage}/>
				{ isPhotosOpen && <ImagesSlider t={t} images={images} initIndex={index} onClose={this.togglePhotos} /> }
				<div className={classes.logoContainer} >
					<div className={classes.logoContent}>
						<Logo 
							onUpload={this.uploadLogo}
							progress={uploadingProgress.get(0)}
							isUploading={isUploading.get(0)}
							logo={logo}
						/>
					</div>
				</div>
				{ warehouse && 
					<UpdateForm 
						t={t}
						onSubmit={ this.update }
						initialValues={warehouse}
					/>
				}
				<PhotosModal
					t={t}
					images={images}
					isOpen={isPhotosManageOpen}
					onClose={this.togglePhotosManage}
					onUpload={this.uploadImage}
					onDelete={this.removeImage}
					progress={uploadingProgress}
					isUploading={isUploading}
				/>
			</React.Fragment>
		);
	}
}

WarehouseUpdate.propTypes ={
	classes: PropTypes.object.isRequired,
	lastAction: PropTypes.string,
	warehouses: PropTypes.instanceOf(Immutable.Map),
	isUploading: PropTypes.instanceOf(Immutable.Map),
	uploadingProgress: PropTypes.instanceOf(Immutable.Map),
	id: PropTypes.string,
	t: PropTypes.func.isRequired,
	get: PropTypes.func.isRequired,
	add: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired,
	getImage: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	removeImage: PropTypes.func.isRequired,
};

export default injectSheet(styles)(WarehouseUpdate);