import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import { Switch } from 'react-router';
import injectSheet from 'react-jss';
import StoneSearch from 'Routes/Stone/Search';
import WarehouseSearch from 'Routes/Warehouse/Search';

const styles = theme => ({
	headerContainer:{
		...theme.layout.container,
		...theme.layout.section,
		...theme.flex.container,
		...theme.flex.columns
	},
	header:{
		...theme.text.title3
	},
	headerMobile:{
		...theme.text.title5
	},
	headerLink:{
		...theme.text.title3,
		textDecoration: 'none',
		color: theme.colors.text.inverse.bold,
		'&:hover':{
			color: theme.colors.text.default.regular,
		}
	},
	headerLinkMobile:{
		...theme.text.title5,
		textDecoration: 'none',
		color: theme.colors.text.inverse.bold,
		'&:hover':{
			color: theme.colors.text.default.regular,
		}
	},
	divisor:{
		margin: '0 30px',
		'&:before': {
        content: '"|"',
        fontSize: '2rem'
    }
	}
});

class Home extends Component {
	render(){
		const { classes, t, body, location:{pathname}, screen } = this.props;
		return (
			<React.Fragment>
				{ pathname === '/' &&
					<div className={classes.headerContainer}>
						<h2 className={classNames({
							[classes.header]: screen !== 'tablet' && screen !== 'mobile',
							[classes.headerMobile]: screen === 'tablet' || screen === 'mobile'
						})}> {t('stone.search.title')} </h2>
					<div className={classes.divisor} />
						<Link to="/warehouse" className={classNames({
							[classes.headerLink]: screen !== 'tablet' && screen !== 'mobile',
							[classes.headerLinkMobile]: screen === 'tablet' || screen === 'mobile'
						})}> {t('warehouse.search.title')} </Link>
					</div>
				}
				{ pathname === '/warehouse' &&
					<div className={classes.headerContainer}>
						<Link to="/" className={classNames({
							[classes.headerLink]: screen !== 'tablet' && screen !== 'mobile',
							[classes.headerLinkMobile]: screen === 'tablet' || screen === 'mobile'
						})}> {t('stone.search.title')} </Link>
						<div className={classes.divisor} />
						<h2 className={classNames({
							[classes.header]: screen !== 'tablet' && screen !== 'mobile',
							[classes.headerMobile]: screen === 'tablet' || screen === 'mobile'
						})}> {t('warehouse.search.title')} </h2>	
					</div>
				}
				<Switch>
					<Route exact path="/" render={(props)=>{ return <StoneSearch t={t} body={body} {...props} />}}/>
					<Route exact path="/warehouse" render={(props)=>{ return <WarehouseSearch t={t} body={body} {...props} />}}/>
				</Switch>
			</React.Fragment>
		);
	}
}

Home.propTypes ={
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	body: PropTypes.object,
	screen: PropTypes.string
};
export default injectSheet(styles)(Home);