import { connect } from 'react-redux';
import { search, empty } from 'state/actions/warehouse';
import Search from './components';

const mapStateToProps = (state) => {
	return {
		page: state.getIn(['warehouse','page']),
		perPage: state.getIn(['warehouse','perPage']),
		results: state.getIn(['warehouse', 'results']),
		warehouses: state.getIn(['warehouse','warehouses']),
		lastAction: state.getIn(['ui', 'lastAction']),
		screen: state.getIn(['ui' ,'screen'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		search: (lat, lng, orderBy, order, perPage, page) => {
			dispatch(search(lat, lng, orderBy, order, perPage, page));
		},
		empty: () => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Search)