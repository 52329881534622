import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, Submit } from 'UI/Form';

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('email')) {
		errors.email = t('errors.required');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('email'))) {
		errors.email = t('errors.invalidEmail');
	}
	return errors
}

class RecoverPasswordForm extends Component{
	render(){
		const { handleSubmit, authError, t } = this.props;
		let userError;
		if(authError && authError.message === 'email not found')
			userError = t('errors.userNotFound');
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="email"
					type="email"
					label={t('login.email')}
					component={Input}
					asyncerror={userError}
				/>
				<Submit text={t('login.recoverPassword')}/>
			</form>
		);
	}
}
RecoverPasswordForm.propTypes = {
	...propTypes,
	authError: PropTypes.object,
	t: PropTypes.func
};

export default reduxForm({
	form:'recoverPasswordForm',
	validate
})(RecoverPasswordForm);