import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Translate, Check } from 'UI/Icon';
import injectSheet from 'react-jss';

const styles = theme => ({
	message: {
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.elemNone,
		"&.mine": {
			...theme.flex.endStart,
			"& .from":{
				textAlign: "right"
			}
		},
		padding: "0.5rem",
		"& .container":{
			...theme.flex.elemSetFlex(50,'columns'),
			position: "relative"
		},
		"& .from":{
			fontSize: theme.text.small
		},
		"& .text":{
			border: "1px solid " + theme.colors.border,
			backgroundColor: '#fff',
			"borderRadius": "5px",
			padding: "1rem",
			"& .traslation":{
				fontStyle: "italic"
			}
		},
		"& .translate":{
			cursor: 'pointer',
			position: 'absolute',
			right: "5px",
			bottom: "-5px"
		},
		"& .status":{
			position: "absolute",
			top: "0",
			right: "5px",
			color: theme.colors.success
		}
	}
})

class Message extends Component {
	componentDidMount(){
		const { message, user, setRead } = this.props;
		if(user && message && message.get('from') === user.get('_id') && !message.get('read') && setRead){
			setRead(message.get('_id'));
		}
	}
	translate = () => {
		const { message, translate } = this.props;
		const id = message.get('_id');
		if(id) translate(id);
	}
	render(){
		const { classes, message, conversationId, translation } = this.props;
		let text, fromId, read, mine;
		let classMessage = classes.message;
		if(message){
			text = message.get('text');
			fromId = message.get('fromConversation');
			mine = conversationId === fromId;
			read = message.get('read');
			if(mine){
				classMessage += " mine";
			}
		}
		return (
			<div className={classMessage}>
				<div className="container">
					<div className="from" >  </div>
					<div className="text" > 
						{ text && <p>{ text } </p> }
						{ translation && <p className="traslation"> { translation} </p> }
					 </div>
					{ (mine && read) && <span className="status"><Check isSmall={true}/></span> }
					<div className="translate" onClick={this.translate}><Translate /></div>
				</div>
			</div>
		);
	}
}
Message.propTypes ={
	classes: PropTypes.object,
	message: PropTypes.instanceOf(Immutable.Map),
	conversationId: PropTypes.string,
	translation: PropTypes.string,
	setRead: PropTypes.func,
	translate: PropTypes.func
};
export default injectSheet(styles)(Message);