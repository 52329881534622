import { 
	MATERIAL_GET,
	MATERIAL_LIST,
	MATERIAL_EMPTY
} from "state/actions/material";
import { Map, OrderedMap, fromJS } from 'immutable';

const initState = fromJS({
	page: 0,
	perPage: 0,
	total: 0,
	materials: OrderedMap({}),
});

const material = ( state = initState, action ) => {
	switch(action.type){
		case MATERIAL_GET: {
			return state.setIn(['materials', action.material._id], fromJS(action.material));
		}
		case MATERIAL_LIST: {
			let _materials = [];
			for(let i = 0; i < action.materials.length; i++){
				let _material = [ action.materials[i]._id, fromJS(action.materials[i])];
				_materials.push(_material);
			}
			return Map({
				page: action.page,
				perPage: action.perPage,
				total: action.total,
				materials: OrderedMap(_materials),
			});
		}
		case MATERIAL_EMPTY: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default material;