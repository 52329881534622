import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Map } from 'immutable';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import mockUser from 'images/UserMock.png';
import Figure from 'UI/Figure';
import { Camera } from 'UI/Icon';
import Progress from 'UI/Progress';

const styles = (theme) => ({ 
	root: {
		position: 'relative',
		width: "50%",
		marginBottom: "1rem",
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		cursor: 'pointer',
		"& .hoverEl": {
			display: 'none'
		},
		"&:hover":{
			"& .hoverEl": {
				display: 'flex'
			},
		}
	},
	action:{
		position: 'absolute',
		...theme.flex.container,
		...theme.flex.centerCenter,
		top: '0',
		left: '0',
		height: '100%',
		width: '100%',
		background: 'rgba(0,0,0,0.2)'
	},
	button:{
		position: 'absolute',
		top: '5px',
		left: '5px'
	},
	obfuscator:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		right: 0,
		top: 0,
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	progressContainer:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		padding: '.5rem',
		right: 0,
		top: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	input:{
		display: 'none'
	},
	hexagon:{
		clipPath: "polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)"
	},
});

class UserImage extends Component{
	constructor(props){
		super(props);
		this.input = React.createRef();
		this.state = {
			tmpUrl: undefined
		}
	}
	browse = () => {
		const { isUploading } = this.props;
		if(!isUploading) this.input.current.click();
	}
	upload = (image) => {
		const { onUpload } = this.props;
		if(image) onUpload(image);
	}
	startUpload = (e) => {
		let fileList = this.input.current.files;
		let image = fileList[0];
		if(image){
			var reader  = new FileReader();
			reader.addEventListener("load", () => {
				this.setState({
					tmpUrl: reader.result
				});
				this.upload(image);
			}, false);
			reader.readAsDataURL(fileList[0]);
		}else{
			this.setState({
				tmpUrl: undefined
			})
		}

	}
	render(){
		const { classes, progress, isUploading, image } = this.props;
		const { tmpUrl } = this.state;
		let url;
		if(tmpUrl){
			url = tmpUrl;
		} else if(image){
			url = staticUrl + '/' + image.getIn(['urls','ld']);
		}else {
			url = mockUser;
		}
		return(
			<div className={classes.root} onClick={this.browse}>
				{ url && <Figure url={url} shape="hexagon"/> }
				{ !isUploading && 
					<div className={classNames(classes.action, classes.hexagon, 'hoverEl')}  >
						<Camera isBig={true} />
					</div>
				}
				{ isUploading && <div className={classNames(classes.obfuscator, classes.hexagon)}></div> }
				{ isUploading &&
					<div className={classNames(classes.progressContainer,  classes.hexagon)}>
						<Progress progress={progress} />
					</div>
				}
				<input name="images" type="file" accept="image/png, image/jpeg" className={classes.input} ref={this.input} multiple={true} onChange={this.startUpload}/>
			</div>
		);
	}
}

UserImage.propTypes = { 
	classes: PropTypes.object.isRequired,
	isUploading: PropTypes.bool,
	progress: PropTypes.number,
	logoUrl: PropTypes.string,
	onUpload: PropTypes.func.isRequired,
	image: PropTypes.instanceOf(Map)
};

export default injectSheet(styles)(UserImage);