import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { GSearchBox } from 'lib/google-maps';
import style from '../jss';

class InputGeo extends Component{
	setLocation = (location) => {
		const { input:{onChange} } = this.props;
		let geoloc = {
			type: 'Point',
			coordinates:[location.lng, location.lat]
		}
		if(onChange) onChange(geoloc);
	}
	cancel = () => {
		const { input:{onChange} } = this.props;
		if(onChange) onChange(null);
	}
	render(){
		const { classes, asyncerror, label, meta, placeholder, icon, geolocalize, nearMeLabel } = this.props;
		const { touched, error } = meta;
		return (
			<div>
				<GSearchBox label={label} placeholder={placeholder} onSetLocation={this.setLocation} onFocus={this.cancel} icon={icon} geolocalize={geolocalize} nearMeLabel={nearMeLabel} />
				{touched && error && <p className={classes.error}> {error} </p>}
				{asyncerror && <p className={classes.error}> {asyncerror} </p>}
			</div>
		);
	}
}
InputGeo.propTypes = {
	classes: PropTypes.object,
	input: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	icon: PropTypes.element,
	geolocalize: PropTypes.element,
	nearMeLabel: PropTypes.string
};

export default injectSheet(style)(InputGeo);