import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	details:{
		...theme.flex.elemSetFlex(50,'row'),
		paddingLeft: "3rem",
		paddingTop: "3rem",
		position: 'relative',
		"& .info":{
			marginBottom: ".5rem"
		},
		"& .action":{
			position: "absolute",
			top: "0px"
		},
		"& .messageCall":{
			color: theme.colors.error
		},
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		}	
	},
	maps:{
		height: "200px"
	},
	title:{
		...theme.text.title3
	},
	label:{
		fontSize: theme.text.small
	}
});

class Details extends Component{
	sendMessage = (values) => {
		const  { user, sendMessage } = this.props;
		if(user && sendMessage){
			const id = user.get('_id');
			const message = values.get('message');
			sendMessage(message, id);
		}
	}
	render(){
		const { classes, material } = this.props;
		return (
			<div className={classes.details}>
				<h1 className={classes.title}> { material.get('name') } </h1>
				{ material.get('origin') && <p className="info"><span className={classes.label}>origine:</span><span> { material.get('origin') } </span></p> }
				{ material.get('location') && <p className="info"><span className={classes.label}>cava:</span><span> { material.get('location') } </span></p> }
				{ material.get('weight') && <p className="info"><span className={classes.label}>peso per unità di volume kg/m^3:</span><span> { material.get('weight') } </span></p> }
				{ material.get('compression') && <p className="info"><span className={classes.label}>carico di rottura a compressione kg/cm^2 :</span><span> { material.get('compression') } </span></p> }
				{ material.get('bending') && <p className="info"><span className={classes.label}>resistenza alla flessione kg/m^3:</span><span> { material.get('bending') } </span></p> }
				{ material.get('abrasion') && <p className="info"><span className={classes.label}>resistenza all'abrasione:</span><span> { material.get('abrasion') } </span></p> }
				{ material.get('absorption') && <p className="info"><span className={classes.label}>coefficiente di imbibizione (in peso):</span><span> { material.get('absorption') } </span></p> }
				{ material.getIn(['colors', 'primary']) && <p className="info"><span className={classes.label}>colore primario:</span><span> { material.getIn(['colors', 'primary']) } </span></p> }
				{ material.getIn(['colors', 'secondary']) && <p className="info"><span className={classes.label}>colore secondario:</span><span> { material.getIn(['colors', 'primary']) } </span></p> }
				{ material.get('description') && <p className="info"><span className={classes.label}>descrizione:</span><span> { material.get('description') } </span></p> }
			</div>
		);
	}
}
Details.propTypes = {
	classes: PropTypes.object.isRequired,
	material: PropTypes.instanceOf(Map).isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(Details);