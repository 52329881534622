import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class RadioGroup extends Component{
	constructor(props){
		super(props);
		this.state={
			selected:'',
			value: null
		}
	}
	onSelect = (value, label) => {
		const { input: {onChange} } = this.props;
		if(onChange) onChange(value);
		this.setState({value, selected:label});
	}
	onUnselect = () => {
		const { input: {onChange} } = this.props;
		if(onChange) onChange(null);
		this.setState({value:null, selected:''});
	}
	componentDidMount(){
		const { meta: {initial}, initialLabel } = this.props;
		if(initial) this.setState({value: initial, selected:initialLabel});
	}
	componentDidUpdate(prevProps){
		const { meta: {initial}, initialLabel } = this.props;
		if(prevProps.meta.initial !== initial && initial) this.setState({value: initial});
		if(prevProps.initialLabel !== initialLabel && initialLabel) this.setState({selected: initialLabel});
	}
	render(){
		const { classes, input, input:{value, name} , asyncerror, label, meta:{touched, error}, items, width } = this.props;
		const { selected } = this.state;
		let fields = [];
		for(let i = 0; i < items.length; i++ ){
			let Component = items[i].component;
			fields.push(
				<Component Icon={items[i].Icon} image={items[i].image} 
					onSelect={ (state) => {
						if(state){
							this.onSelect(items[i].value, items[i].label, state);
						}else{
							this.onUnselect();
						}
					}}
					isSelected={value===items[i].value} 
					key={i} label={items[i].label} isDisabled={items[i].isDisabled}
				/>
			);
		}
		let fieldClass = classes.field;
		if(width) fieldClass +=  ' ' + width;
		return (
			<div className={fieldClass} >
				<div className={classes.output}>{ label && <label className={classes.label} htmlFor={name}>{ label }</label> } {selected} </div>
				<div className={classes.control}>
					<div className={classes.options}>
						{ fields }
					</div>
					<input {...input} type="hidden" />
					{touched && error && <p className={classes.error}> {error} </p>}
					{asyncerror && <p className={classes.error}> {asyncerror} </p>}
				</div>
			</div>
		);
	}
}
RadioGroup.propTypes = {
	classes: PropTypes.object,
	width: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	items: PropTypes.array
};

export default injectSheet(style)(RadioGroup);