import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import BtnGroup from './BtnGroup';

const styles = theme => ({
	root:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.startCenter,
		...theme.flex.wrap
	},
	leftButtons:{
		...theme.flex.elemNone
	},
	rightButtons:{
		...theme.flex.elemNone
	},
	searchbar:{
		...theme.flex.elemNone
	},
	divider:{
		...theme.flex.elemAuto
	}
});

class ActionBar extends PureComponent {
	search = (values) => {
		const {onSearch} = this.props;
		if(onSearch) onSearch(values);
	}
	render(){
		const { classes, leftBtns, rightBtns, Searchbar, screen} = this.props;
		return (
			<div className={classes.root} >
				{ leftBtns &&
					<div className={classes.leftButtons}>
						<BtnGroup items={leftBtns}/>
					</div>
				}
				<div className={classes.divider} />
				{ rightBtns && 
					<div className={classes.rightButtons}>
						<BtnGroup items={rightBtns} />
					</div>
				}
				{ Searchbar &&
					<div className={classes.searchbar}>
						<Searchbar onSubmit={this.search} screen={screen}/>
					</div>
				}
			</div>
		);
	}
}
ActionBar.propTypes ={
	classes: PropTypes.object.isRequired,
	leftBtns: PropTypes.array,
	rightBtns: PropTypes.array,
	screen: PropTypes.string,
	Searchbar: PropTypes.object,
	onSearch: PropTypes.func
};
export default injectSheet(styles)(ActionBar);