import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import UpdateForm from "./UpdateForm";
import Button from "UI/Button";
import { Close } from "UI/Icon";

const styles = theme => ({
	stoneUpdate:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
	},
	title:{
		...theme.text.title3,
		marginBottom: '16px'
	},
	action:{
		position: 'absolute',
		top: '.5rem',
		left: '1.5rem',
		zIndex: 10
	}
});


class StoneUpdate extends Component {
	constructor(props){
		super(props);
		this.state = {
			categoryId: undefined
		}
	}
	startScrollUp = () => {
		const { body } = this.props;
		this._animationStart = null;
		this._currentScroll = body.scrollTop;
		window.requestAnimationFrame(this.scrollUp);
	}
	scrollUp = (timestamp) => {
		const { body } = this.props;
		if(!this._animationStart) this._animationStart = timestamp;
		let t = timestamp - this._animationStart;
		let scroll = -t*this._currentScroll/500 + this._currentScroll;
		if (t <= 500) {
			window.requestAnimationFrame(this.scrollUp);
			body.scrollTop = scroll;
		}else{
			body.scrollTop = 0;
		}
	}
	get = () => {
		const {  match:{params}, get } = this.props;
		const id = params.id;
		if(id) get(id);
	}
	getCategories = () => {
		const { getCategories } = this.props;
		getCategories();
	}
	goToNotFound = () =>  {
		const { history } = this.props;
		if(history) history.push('/not_found');
	}
	update = (values) => {
		const {  match:{params}, update } = this.props;
		const id = params.id;
		let stone = {
			name: values.get('name'),
			shape: values.get('shape'),
			categoryId: values.get('categoryId'),
			materialId: values.get('materialId'),
			dimensions: {
				height: values.get('height'),
				width: values.get('width'),
				depth: values.get('depth'),
			},
			description: values.get('description'),
			quantity: values.get('quantity'),
			weight: values.get('weight'),
			geoloc: values.get('geoloc')
		}
		if(id) update(id, stone);
	}
	setCategory = (categoryId) => {
		this.setState({
			categoryId: categoryId
		});
	}
	search = (text) => {
		const { materialSearch } = this.props;
		const { categoryId } = this.state;
		if(materialSearch) materialSearch(text, categoryId, 5);
	}
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	isMine = () => {
		const { match:{params}, stones } = this.props;
		const id = params.id;
		const stone = stones.get(id);
		if(stone && stone.get('mine')) return true;
		return false;
	}
	componentDidMount(){
		this.get();
		this.getCategories();		
	}
	componentDidUpdate(prevProps){
		const { lastAction, error, stones } = this.props;
		if(lastAction !== prevProps.lastAction && lastAction === "stone_update_done")	this.back();
		if(error && error.message === 'not found') this.goToNotFound();
		if(stones && !stones.equals(prevProps.stones) && !stones.isEmpty() && !this.isMine()) this.goToNotFound();	
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, match:{params}, stones, categories, materials, t } = this.props;
		const id = params.id;

		let categoryItems = [];
		let materialItems = [];

		let stone = stones.get(id);

		if(stone && materials.size === 0) {
			materialItems.push({
				value: stone.getIn(['materialId','_id']),
				name: stone.getIn(['materialId','name'])
			})
		}

		if(stone) stone = stone.set('materialId', stone.getIn(['materialId','_id']));
		if(stone) stone = stone.set('categoryId', stone.getIn(['categoryId','_id']));
		categories.forEach(category => {
			let item = {
				value: category.get('_id'),
				name: t('category.type.' + category.get('name'))
			}
			categoryItems.push(item);
		});

		materials.forEach(material => {
			let item = {
				value: material.get('_id'),
				name: material.get('name')
			}
			materialItems.push(item);
		})

		return (
			<div className={classes.stoneUpdate}>
				<div className={classes.action}><Button Icon={Close} isSmallIcon={true} onClick={this.back} /></div>
				<h1 className={classes.title}>{t('stone.list.modify')}</h1>
				<UpdateForm onSubmit={ this.update } initialValues={stone} categoryItems={categoryItems} materialItems={materialItems} onCancel={ this.back } onSearch={ this.search } onSetCategory={this.setCategory} t={t} />
			</div>
		);
	}
}

StoneUpdate.propTypes = {
	classes: PropTypes.object,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	categories: PropTypes.instanceOf(OrderedMap).isRequired,
	materials: PropTypes.instanceOf(OrderedMap).isRequired,
	body: PropTypes.object,
	t: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,
	materialSearch: PropTypes.func.isRequired,
	error: PropTypes.object
};

export default injectSheet(styles)(StoneUpdate);