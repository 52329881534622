import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CreateForm from "./CreateForm";

const style = theme => ({
	complete:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
	},
	title: {
		...theme.text.title3
	}
});

class MeUpdate extends Component {
	authenticate = () => {
		const { authenticate } = this.props;
		authenticate(); 
	}
	create = (values) => {
		const { create } = this.props;
		let me = {
			kind: values.get('kind'),
			name: {
				first: values.getIn(['name', 'first']),
				last: values.getIn(['name', 'last']),
				company: values.getIn(['name', 'company'])
			},
			telephone: values.get('telephone'),
			taxCode: values.get('taxCode'),
			address:{
				country: values.getIn(['address', 'country']),
				state: values.getIn(['address', 'state']),
				city: values.getIn(['address', 'city']),
				address: values.getIn(['address', 'address']),
				postCode: values.getIn(['address', 'postCode']),
			}
		}
		if(create) create(me);
	}
	componentDidMount(){
	}
	componentDidUpdate(prevProps){
		const { lastAction } = this.props;
		if(lastAction !== prevProps.lastAction && lastAction === "me_create_done" ) this.authenticate();
	}
	render(){
		const { classes, t } = this.props;
		return (
			<div className={classes.complete}>
				<h1 className={classes.title}>{t('user.complete')}</h1>
				<CreateForm onSubmit={ this.create } onCancel={ this.back } t={t} />
			</div>
		);
	}
}
MeUpdate.propTypes ={
	classes: PropTypes.object.isRequired,
	create: PropTypes.func.isRequired,
	authenticate: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};
export default injectSheet(style)(MeUpdate);