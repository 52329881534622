import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
//import Search from 'Routes/Warehouse/Search';
import Update from 'Routes/Warehouse/Update';
import ErrorPage from 'Routes/Errors/404';


class Router extends Component {
	isLogged = () => {
		const { isLogged } = this.props;
		if(isLogged === true) return true;
		else if(isLogged === false) return false;
		else return null;
	}
	isOwner = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && [4,5,6].includes(role) ) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	render() {
		const { t } = this.props;
		return (
			<Switch>
				<Route exact path="/warehouse/update" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isOwner() === false) return (<Redirect to='/'/>);
					return (<Update t={t} {...props} />)
				}} />
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}

Router.propTypes ={
	t: PropTypes.func.isRequired
};

export default Router;