import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';

const styles = theme => ({
	root:{
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
		alignItems: 'center',
		display: 'none',
		flexDirection: 'column',
		justifyContent: 'center',
 		overflow: 'hidden',
  	position: 'fixed',
  	zIndex: 4000,
  	'&.is-active':{
  		display: 'flex'
  	}
	},
	background:{
		bottom: 0,
		left: 0,
		position: 'absolute',
		right: 0,
		top: 0,
		backgroundColor: 'rgba(10, 10, 10, 0.86)'
	},
	content:{
		margin: '0 20px',
		padding: '0 8px',
  	maxHeight: 'calc(100vh - 160px)',
		overflow: 'auto',
		position: 'relative',
		width: '100%',
		"@media screen and (min-width: 769px)": {
			margin: '0 auto',
			maxHeight: 'calc(100vh - 40px)',
			width: '640px'
		}
	},
	close:{
		userSelect: 'none',
		backgroundColor: 'rgba(10, 10, 10, 0.2)',
		border: 'none',
		borderRadius: '290486px',
		cursor: 'pointer',
		pointerEvents: 'auto',
		display:' inline-block',
		flexGrow: 0,
		flexShrink: 0,
		fontSize: 0,
		height: '32px',
		maxHeight: '32px',
		maxWidth: '32px',
		minHeight: '32px',
		minWidth: '32px',
		outline: 'none',
		verticalAlign: 'top',
		width: '32px',
  	position: 'fixed',
  	right: '20px',
  	top: '20px',
		'&::before':{
			backgroundColor: '#ffffff',
			content: '""',
			display: 'block',
			left: '50%',
			position: 'absolute',
			top:'50%',
			transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
			transformOrigin: 'center center',
			height: '2px',
			width: '50%'
		},
		'&::after':{
			backgroundColor: '#ffffff',
			content: '""',
			display: 'block',
			left: '50%',
			position: 'absolute',
			top:'50%',
			transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
			transformOrigin: 'center center',
			height: '50%',
			width: '2px'
		},
		'&:hover':{
			backgroundColor: 'rgba(10, 10, 10, 0.3)'
		}
	}
});

class Modal extends Component {
	close = () => {
		const { onClose } = this.props;
		onClose();
	}
	render(){
		const { classes, isOpen, children } = this.props;
		return (
			<div className={classNames(classes.root, {'is-active': isOpen})}>
				<div className={classes.background}></div>
				<div className={classes.content}>
					{children}
				</div>
				<button className={classes.close} aria-label="close" onClick={this.close}></button>
			</div>
		);
	}
}

Modal.propTypes ={
	classes: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func.isRequired
};


export default injectSheet(styles)(Modal);