import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class Submit extends Component {
	render(){
		const { classes, text, isPrimary, isSecondary, Icon, isDisabled, style } = this.props;
		let submitClass = classes.submit;
		if(isPrimary === true){
			submitClass += " is-primary";
		}else if(isSecondary === true){
			submitClass += " is-secondary";
		}
		if(isDisabled === true){
			submitClass += " is-disabled";
		}
		return (
			<button type="submit" className={submitClass} style={style} >
				{ text && <span className="text">{ text }</span> }
				{ Icon && <span className="icon"><Icon/></span> }
			</button>
		);
	}
}
Submit.propTypes ={
	classes: PropTypes.object,
	text: PropTypes.string,
	isPrimary: PropTypes.bool,
	isSecondary:PropTypes.bool,
	isDisabled: PropTypes.bool,
	Icon:PropTypes.func
};
export default injectSheet(style)(Submit);