import { ME_GET, ME_UPDATE, ME_REMOVE } from "state/actions/me";
import { Map, fromJS } from 'immutable'

const initState = Map({});

const me = ( state = initState, action ) => {
	switch(action.type){
		case ME_GET: {
			return fromJS(action.me)
		}
		case ME_UPDATE: {
			return state.mergeDeep(fromJS(action.me))
		}
		case ME_REMOVE: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default me;