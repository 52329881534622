import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

const PATH = "/analytics/stone/";

export const get =  async (id) => {
	const url = `${apiUrl}${PATH}get?`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let params = new URLSearchParams();
	params.append('id', id);
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const addImpression = async (id) => {
	const url = `${apiUrl}${PATH}addImpression`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = { id	};
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return await response.json();
}

export const addClick = async (id) => {
	const url = `${apiUrl}${PATH}addClick`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = { id };
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data)
	})
	if(response.status !== 200 ) throw new Error('Server Error');
	return await response.json();
}