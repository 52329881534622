import React, { Component } from 'react';
import { List } from 'immutable'; 
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import Figure from 'UI/Figure';

const styles = theme => ({
	main:{
		position: "relative",
		...theme.flex.elemSetFlex(50,'row'),
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.startCenter,
		"& .action": {
			position: "absolute",
			top: "0px",
			left: "0px",
		},
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row')
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row')
		}
	},
	image:{
		width: "50%",
		marginBottom: "1rem",
	}
});

class Image extends Component{
	render(){
		const { classes, images} = this.props;
		let imageUrl = images.getIn([0, 'urls', 'md']) ||  images.getIn([0, 'urls', 'ld']);;
		return (
			<div className={classes.main}>
				<div className={classes.image}>
					{ imageUrl && <Figure url={staticUrl + "/" + imageUrl} shape="hexagon"/> }
					{ !imageUrl && <Figure url="/assets/png/block.png" shape="hexagon"/> }
				</div>
			</div>
		);
	}
}
Image.propTypes = {
	classes: PropTypes.object.isRequired,
	images: PropTypes.instanceOf(List).isRequired
};

export default injectSheet(styles)(Image);