import media from "./media";

const layout = {
	container: {
		margin: "0 auto",
		position: "relative",
		width: "100%"
	},
	section: {
		padding: "3rem 1.5rem"
	}
}
layout.container['@media ' + media.laptop] = {
	maxWidth: "960px",
	width: "960px",
};
layout.container['@media ' + media.desktop] = {
	maxWidth: "1152px",
	width: "1152px"
}
layout.container['@media ' + media.fullHd] = {
	maxWidth: "1344px",
	width: "1344px"
}


export default layout;