import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { JssProvider } from 'react-jss';
import WebFont from 'webfontloader';
import configureStore from 'config/store';
import jss from 'config/jss';
import ThemeProvider from './container/ThemeProvider';


const store = configureStore();

WebFont.load({
  google: {
    families: ['Comfortaa', 'cursive']
  }
});

class Root extends Component {
	render(){
		return (
			<Provider store={store}>
				<JssProvider jss={jss}>
					<ThemeProvider />
				</JssProvider>
			</Provider>
		);
	}
};

export default Root;