import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Immutable from 'immutable';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import mockLogo from 'images/Logo.png';
import { Camera } from 'UI/Icon';
import Progress from 'UI/Progress';

const styles = (theme) => ({ 
	root: {
		height: '200px',
		width: '200px',
		position: 'relative',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		cursor: 'pointer',
		"& .hoverEl": {
			display: 'none'
		},
		"&:hover":{
			"& .hoverEl": {
				display: 'flex'
			},
		}
	},
	action:{
		position: 'absolute',
		...theme.flex.container,
		...theme.flex.centerCenter,
		top: '0',
		left: '0',
		height: '100%',
		width: '100%',
		background: 'rgba(0,0,0,0.2)'
	},
	button:{
		position: 'absolute',
		top: '5px',
		left: '5px'
	},
	obfuscator:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		right: 0,
		top: 0,
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	progressContainer:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		padding: '.5rem',
		right: 0,
		top: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	input:{
		display: 'none'
	},
});

class Logo extends Component{
	constructor(props){
		super(props);
		this.input = React.createRef();
		this.state = {
			tmpUrl: undefined
		}
	}
	browse = () => {
		const { isUploading } = this.props;
		if(!isUploading) this.input.current.click();
	}
	upload = (image) => {
		const { onUpload } = this.props;
		if(image) onUpload(image);
	}
	startUpload = (e) => {
		let fileList = this.input.current.files;
		let image = fileList[0];
		if(image){
			var reader  = new FileReader();
			reader.addEventListener("load", () => {
				this.setState({
					tmpUrl: reader.result
				});
				this.upload(image);
			}, false);
			reader.readAsDataURL(fileList[0]);
		}else{
			this.setState({
				tmpUrl: undefined
			})
		}

	}
	render(){
		const { classes, progress, isUploading, logo } = this.props;
		const { tmpUrl } = this.state;
		let url;
		if(tmpUrl){
			url = tmpUrl;
		} else if(logo){
			url = staticUrl + '/' + logo.getIn(['urls','ld']);
		}else {
			url = mockLogo;
		}
		return(
			<div className={classes.root} style={{backgroundImage: 'url(' + url + ')'}} onClick={this.browse}>
				{ !isUploading && 
					<div className={classNames(classes.action, 'hoverEl')}  >
						<Camera isBig={true} />
					</div>
				}
				{ isUploading && <div className={classes.obfuscator}></div> }
				{ isUploading &&
					<div className={classes.progressContainer}>
						<Progress progress={progress} />
					</div>
				}
				<input name="images" type="file" accept="image/png, image/jpeg" className={classes.input} ref={this.input} multiple={true} onChange={this.startUpload}/>
			</div>
		);
	}
}

Logo.propTypes = { 
	classes: PropTypes.object.isRequired,
	isUploading: PropTypes.bool,
	progress: PropTypes.number,
	logoUrl: PropTypes.string,
	onUpload: PropTypes.func.isRequired,
	logo: PropTypes.instanceOf(Immutable.Map)
};

export default injectSheet(styles)(Logo);