export default {
	"category":{
		"type":{
			"Granite": "Granit",
			"Limestone": "Kalkstein",
			"Marble": "Marmor",
			"Onyx": "Onyx",
			"Quartzite": "Quarzite",
			"Stone": "Edelstein",
			"Travertine": "Travertin",
			"Ceramic": "Keramik"
		}
	},
	"errors":{
		"required": "erforderlich",
		"userNotFound": "Benutzer existiert nicht",
		"wrongPassword": "Falsches Passwort",
		"invalidEmail": "Ungültige E-Mail",
		"noMatchPassword": "Passwörter stimmen nicht überein",
		"UserAlreadyExists": "Diese E-Mail-Adresse wird bereits von einem registrierten Benutzer verwendet"
	},
	"login":{
		"title": "Eingang",
		"login": "Eingang",
		"signup": "Registrieren",
		"email": "Email",
		"password": "Password",
		"enter": "Einloggen",
		"forgotPassword": "Password vergessen"
	},
	"material":{
	},
	"navbar":{
		"enter": "Einloggen"
	},
	"signup":{
		"title": "Registrieren",
		"login": "Eingang",
		"signup": "Registrieren",
		"email": "Email",
		"password": "Password",
		"passwordB": "Password bestätigen",
		"signin": "Registrieren"
	},
	"stone":{
		"name": "Name",
		"shape": "Gestalten",
		"category": "Stein Sorte",
		"material": "Materialien",
		"height": "Größe",
		"width": "Breite",
		"depth": "Tiefe",
		"thickness": "Dicke",
		"weight": "Gewicht",
		"coverArea": "Deckungsfläche",
		"quantity": "Quantität",
		"shapeType":{
			"shapeless": "Grober Block",
			"block": "Block",
			"slab": "Platte",
			"tile": "Fließen"
		},
		"search":{
			"title": "Materialien finden",
			"minHeight": "Minimal Größe",
			"maxHeight": "Maximal Größe",
			"minWidth": "Minimal breite",
			"maxWidth": "Maximal breite",
			"minDepth": "Minimal tiefe",
			"maxDepth": "Maximal tiefe",
			"minThickness": "Minimal Dicke",
			"maxThickness": "Maximal Dicke",
			"minWeight": "Minimal Gewicht",
			"maxWeight": "Maximal Gewicht",
			"minCoverArea": "Minimale Deckungsfläche",
			"maxCoverArea": "Maximale Deckungsfläche",
			"search": "Suchen"
		},
		"list":{
			"title": "Meine Anzeigen",
			"add": "Hinzufügen",
			"images": "Bilder",
			"modify": "Ändern",
			"view": "Visualisieren",
			"delete": "Löschen",
			"search": "Suchen"
		},
		"add":{
			"addImages": "Bild hinzufügen",
			"where": "Befindeort",
			"save": "Speichern",
			"cancel": "Löschen"
		},
		"update":{
			"where": "Befindeort",
			"save": "Speichern",
			"cancel": "Löschen"
		},
		"images":{
			"addImages": "Bild hinzufügen",
			"add": "Hinzufügen",
			"cancel": "Löschen"
		}
	},	
	"user":{
		"kind": "Benutzertyp",
		"company": "Firma",
		"pro": "Händler",
		"private": "Privat",
		"firstName": "Name",
		"lastName": "Nachname",
		"companyName": "Name der Firma",
		"telephone": "Telefonnummer",
		"email": "Email",
		"taxCode": "Umsatzsteuer-Identifikationsnummer",
		"update":{
			"save": "Speichern",
			"cancel": "Löschen"
		},
		"images":{
			"addImages": "Bild hinzufügen",
			"add": "Hinzufügen",
			"cancel": "Speichern"
		}		
	},
	"address":{
		"country":"Land",
		"state": "Provinz / Bezirk / Staat",
		"city": "Stadt",
		"address": "Adresse",
		"postalCode": "Postleitzahl"
	},
	"message":{
		"call": "Einloggen oder registrieren, um eine Nachricht an den Verkäufer zu senden",
		"label": "Schreibe deine Nachricht",
		"send": "senden",
		"empty": "keine Nachricht"
	}	
}