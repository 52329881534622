import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';
const PATH = "/stone/";

export const add = async (stone) => {
	const url = `${apiUrl}${PATH}add`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(stone)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const remove = async (id) => {
	const url = `${apiUrl}${PATH}add`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const update = async (id, stone) => {
	const url = `${apiUrl}${PATH}update`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		update: stone
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	})
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const addImage = async (id, imageId) => {
	const url = `${apiUrl}${PATH}addImage`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = {
		id,
		imageId
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const removeImage = async (id, imageId) => {
	const url = `${apiUrl}${PATH}removeImage`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = {
		id,
		imageId
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const setPublished = async (id, isPublished) => {
	const url = `${apiUrl}${PATH}setPublished`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = {
		id,
		isPublished
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const setStatus = async (id, status) => {
	const url = `${apiUrl}${PATH}setStatus`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let data = {
		id,
		status
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const get = async (id) => {
	const url = `${apiUrl}${PATH}get?`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let params = new URLSearchParams();
	params.append('id', id);
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const list = async (query) => {
	const url = `${apiUrl}${PATH}list?`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	for(const [key, value] of Object.entries(query)) {
		if(value) params.append(key, value.toString());
	}
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const listMine = async (query) => {
	const url = `${apiUrl}${PATH}listMine?`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	for(const [key, value] of Object.entries(query)) {
		if(value) params.append(key, value.toString());
	}
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}