import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from 'UI/Card';
import Figure from 'UI/Figure';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';

const styles = theme => ({
	box:{
		marginTop: "1.5rem",
	},
	card:{
		...theme.flex.container,
		...theme.flex.spaceBetweenCenter
	},
	logo:{
		width: '100px',
		height: '100px'
	},
	ranking:{
		...theme.flex.container,
		...theme.flex.wrap,
	},
	column:{
		...theme.flex.elem
	},
	user:{
		...theme.flex.container,
		...theme.flex.startCenter,
		textDecoration: 'none',
		padding: ".5rem"
	},
	image:{
		height: "50px",
		width: "50px",
		margin:'0 3rem',
	}
});


class Icon extends Component {
	render(){
		return (
			<img height="48px" width="48px" src="/assets/png/ranking.png"  alt="ranking"/>
		)
	}
}
class RankingBox extends Component {

	getBestDealers = () => {
		const { getBestDealers } = this.props;
		getBestDealers();
	}
	empty = () =>{
		const { empty } = this.props;
		empty();
	}
	componentDidMount(){
		this.empty();
		this.getBestDealers();
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, users } = this.props;
		const usersListA = [];
		const usersListB = [];
		let pos = 0;
		users.forEach( (user) => {
			let displayName;
			let index = pos + 1;
			pos++;
			let image = user.getIn(['images',0 ,'urls', 'ld']);
			if(user.get('kind') === 'company'){
				displayName = user.getIn(['name', 'company']);
			}else{
				displayName = user.getIn(['name', 'first']);
				displayName += ' ' +  user.getIn(['name', 'last']);
			}
			if(pos<6){
				usersListA.push(
					<Link to={"/user/" + user.get('_id')} className={classes.user} key={user.get('_id')} >
						<span className={classes.rank}>{index}</span>
						<div className={classes.image}>
							{ image && <Figure url={ staticUrl + "/" + image} shape="hexagon"/> }
							{ !image && <Figure url="/assets/png/user.png" shape="hexagon"/> }
						</div>
						<span className={classes.userName}>{displayName}</span>
					</Link>
				);
			} else {
				usersListB.push(
					<Link to={"/user/" + user.get('_id')} className={classes.user} key={user.get('_id')} >
						<span className={classes.rank}>{index}</span>
						<div className={classes.image}>
							{ image && <Figure url={ staticUrl + "/" + image} shape="hexagon"/> }
							{ !image && <Figure url="/assets/png/user.png" shape="hexagon"/> }
						</div>
						<span className={classes.userName}>{displayName}</span>
					</Link>
				);
			}
		})
		return (
			<div className={classes.box}>
				<Card title="top dealers" Icon={Icon}>
					<div className={classes.ranking}>
						<div className={classes.column}>
							{ usersListA }
						</div>
						<div className={classes.column}>
							{ usersListB }
						</div>
					</div> 
				</Card>
			</div>
		);
	}
}
RankingBox.propTypes ={
	classes: PropTypes.object.isRequired,
	users: PropTypes.instanceOf(OrderedMap).isRequired,
	getBestDealers: PropTypes.func.isRequired
};
export default injectSheet(styles)(RankingBox);