import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import Figure from 'UI/Figure';
import ActionBar from './ActionBar';

const styles = theme => ({
	root:{
		...theme.flex.elemSetFlex(100,'row'),
		padding: "1rem",
		'@media screen and (min-width: 1088px)':{
			...theme.flex.elemSetFlex(50,'row')
		}
	},
	card:{
		cursor: 'pointer',
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.wrap,
		boxShadow: theme.shadows.s1,
		"& .left":{
			position: "relative",
			...theme.flex.elemSetFlex(100,'row'),
			'@media screen and (min-width: 1088px)':{
				...theme.flex.elemSetFlex(50,'row')
			}
		},
		"& .right":{
			position: "relative",
			...theme.flex.elemSetFlex(100,'row'),
			padding: "1rem",
			'@media screen and (min-width: 1088px)':{
				...theme.flex.elemSetFlex(50,'row')
			}
		}
	},
	infos:{
		marginTop: "1rem",
		"& .info":{
			marginBottom: ".5rem"
		}
	},
	label:{
		fontSize: theme.text.small
	},
	icon:{
		width: "36px",
		position: "absolute",
		top: ".3rem",
		left: ".3rem"
	},
	title:{
		...theme.text.title5
	}
});

class StonePreview extends Component {
	select = () => {
		const { onSelect, stone } = this.props;
		const id = stone.get('_id');
		onSelect(id);
	}
	addImpression = () => {
		const { onAddImpression, stone } = this.props;
		const id = stone.get('_id');
		onAddImpression(id)
	}
	componentDidMount(){
		//this.addImpression();
	}
	remove = () => {
		const { stone, onRemove } = this.props;
		const id = stone.get('_id');
		if(id) onRemove(id); 
	}
	edit = () => {
		const { stone, onEdit } = this.props;
		const id = stone.get('_id');
		if(id) onEdit(id); 
	}
	managePhotos = () => {
		const { stone, onManagePhotos } = this.props;
		const id = stone.get('_id');
		if(id) onManagePhotos(id); 
	}
	showInfo = (text) => {
		const { onShowInfo } = this.props;
		if(text) onShowInfo(text)
	}
	render(){
		const { classes, stone, t } = this.props;
		const image = stone.getIn(['images',0, 'urls', 'ld']);
		const categoryName = stone.getIn(['categoryId', 'name']);
		const materialName = stone.getIn(['materialId', 'name']);
		const name = stone.get('name');
		const shape = stone.get('shape');
		const height = stone.getIn(['dimensions','height']);
		const width = stone.getIn(['dimensions','width']);
		const depth = stone.getIn(['dimensions','depth']);
		const weight = stone.get('weight');
		const quantity = stone.get('quantity');
		//const coverArea = stone.get('coverArea');
		const isBlocked = stone.get('blocked');
		const isApproved = stone.get('approved');
		const url = (image)? `${staticUrl}/${image}`: "/assets/images/no-image.jpg";
		let icon = "/assets/shapes/block.png";
		if(shape === "shapeless" ) icon =  "/assets/shapes/shapeless.png";
		if(shape === "slab" ) icon =  "/assets/shapes/slabs.png";
		if(shape === "tile" ) icon =  "/assets/shapes/tiles.png";
		return (
			<div className={classes.root}>
				<div className={classes.card} onClick={this.select}>
					<ActionBar t={t} isBlocked={isBlocked} isApproved={isApproved} onRemove={this.remove} onEdit={this.edit} onManagePhotos={this.managePhotos} onShowInfo={this.showInfo} />
					<div className='left'>
						<Figure url={url} />
						<div className={classes.icon}> 	<Figure url={icon} shape="circle"/> </div>
					</div> 
					<div className="right">
						<h1 className={classes.title}> { name } </h1>
						<div className={classes.infos}>
							{ shape && <p className="info"><span className={classes.label}>{t('stone.shape')}:</span><span> { t('stone.shapeType.' + shape) } </span></p> }
					
							{ categoryName && <p className="info"><span className={classes.label}>{t('stone.category')}:</span><span> { t('category.type.' + categoryName) } </span></p> }
							
							{ materialName && <p className="info"><span className={classes.label}>{t('stone.material')}:</span><span> { materialName } </span></p> }
							
							{ ( (shape === 'block' || shape === 'shapeless') && weight ) && <p className="info"><span className={classes.label}>{t('stone.weight') + " (t)"}:</span><span> { weight } </span></p> }
							
							{ ( (shape === 'slab' || shape === 'tile') && quantity ) && <p className="info"><span className={classes.label}>{t('stone.quantity')}:</span><span> { quantity } </span></p> }

							{ height && <p className="info"><span className={classes.label}>{t('stone.height') + " (m)"}:</span><span> { height } </span></p> }

							{ width && <p className="info"><span className={classes.label}>{t('stone.width') + " (m)"}:</span><span> { width } </span></p> }
							
							{ ( (shape === 'block' || shape === 'shapeless') && depth ) && <p className="info"><span className={classes.label}>{t('stone.depth') + " (m)"}:</span><span> { depth } </span></p> }

							{ ( (shape === 'slab' || shape === 'tile') && depth ) && <p className="info"><span className={classes.label}>{t('stone.depth') + " (mm)"}:</span><span> { depth } </span></p> }
						</div>
					</div>
				</div>
			</div>
		);
	}
}
StonePreview.propTypes = {
	classes: PropTypes.object.isRequired,
	stone: PropTypes.instanceOf(Map).isRequired,
	onSelect: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onManagePhotos: PropTypes.func.isRequired,
	onShowInfo: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(StonePreview);

