import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	root:{
		cursor: 'pointer',
		userSelect: "none",
		...theme.flex.container,
		...theme.flex.columns,
		"&.is-error":{
			color: `${theme.colors.error} !important`,
			fill: `${theme.colors.error} !important`,
		},
		"&.is-error a":{
			color: `${theme.colors.error} !important`,
			fill: `${theme.colors.error} !important`,
		}
	},
	label:{
		fontSize: "16px",
		padding: " 3px 8px 3px 3px",
		color:theme.colors.textBold,
		...theme.flex.elem
	},
	checkboxContainer:{
		width: "24px",
		height: "24px",
	},
	checkboxOutline:{
		width: "24px",
		height: "24px",
		fill: theme.colors.text,
		position: 'absolute',
		opacity: 1,
		'&.hide':{
			opacity:0
		}
	},
	checkboxOutlineSelected:{
		width: "24px",
		height: "24px",
		fill: theme.colors.primary,
		position: 'absolute',
		opacity:1,
		'&.hide':{
			opacity:0
		}
	}
});

class CheckBox extends PureComponent {
	constructor(props){
		super(props);
		this.state = {selected:false};
	}
	componentDidMount(){
		const { isSelected } = this.props;
		if(isSelected)
			this.setState({selected:isSelected})
	}
	componentDidUpdate(prevProps) {
		const { isSelected } = this.props;
		if(isSelected !== prevProps.isSelected)
			this.setState({selected:isSelected});
	}
	click = (event) => {
		event.stopPropagation();
		const { selected } = this.state;
		const { onSelect, input } = this.props;
		this.setState({selected:!selected});
		if(onSelect) onSelect(!selected);
		if(input && input.onChange) input.onChange(!selected);
	}
	render(){
		const { classes, label, input, meta } = this.props;
		//const { touched, error } = meta;
		const touched = meta ? meta.touched: null;
		const error = meta ? meta.error: null;
		const { selected } = this.state;
		let checkboxOutline = (!selected) ? classes.checkboxOutline: classes.checkboxOutline + " hide";
		let checkboxOutlineSelected = (selected) ? classes.checkboxOutlineSelected: classes.checkboxOutlineSelected + " hide";
		return (
			<div 
				className={classNames(classes.root, {
					'is-error': touched && error
				})}
				type="checkbox"
				onClick={this.click}
			>
				{ label && <label className={classes.label} htmlFor={input.name}>{ label }</label> }
				<div className={classes.checkboxContainer}>
					<svg className={checkboxOutline} viewBox="0 0 24 24">
						<path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
					</svg>
					<svg className={checkboxOutlineSelected} viewBox="0 0 24 24">
						<path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
					</svg>
				</div>
			</div>
		);	
	}
}
CheckBox.propTypes ={
	classes: PropTypes.object.isRequired,
	isSelected: PropTypes.bool,
	label: PropTypes.any,
	onSelect: PropTypes.func
};
export default injectSheet(styles)(CheckBox);