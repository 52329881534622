import { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

class Tracker extends Component {
	componentDidMount(){
		const { location: { pathname, search } } = this.props;
		this.analyticsInit();
		this.logPageChange(pathname, search);
	}
	componentDidUpdate({ location: prevLocation }){
		const { location: { pathname, search } } = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search
    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search)
    }
	}
	analyticsInit = () => {
		const { id } = this.props;
		ReactGA.initialize(id);
	}
	logPageChange (pathname, search = '') {
    const page = pathname + search
    const { location } = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })
    ReactGA.pageview(page)
  }
	render(){
		return null;
	}
}

Tracker.propTypes = {
	id: PropTypes.string
};

export default Tracker;