import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import { Megaphone, Eye } from 'UI/Icon';
import { GMap, GMarker } from 'lib/google-maps';

const styles = theme => ({
	details:{
		...theme.flex.elemSetFlex(50,'row'),
		paddingLeft: "3rem",
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row'),
			paddingLeft: '0'
		},
		...theme.flex.container,
		...theme.flex.rows,	
	},
	title:{
		...theme.text.title3,
		marginTop: '16px',
	},
	description: {
	},
	separator:{
		...theme.flex.elemAuto
	},
	extra:{
		...theme.flex.container,
		...theme.flex.startCenter
	},
	statsItem:{
		padding: "0.5rem",
		fill: theme.colors.primary,
		...theme.flex.container,
		...theme.flex.startCenter
	},
	statsValue:{
		position: 'relative',
		top: '1px',
		marginLeft: "0.1rem",
		fontSize: theme.text.small
	},
	infos:{
		marginTop: "1rem",
		"& .info":{
			marginBottom: ".5rem"
		}
	},
	label:{
		fontSize: theme.text.small
	},
	maps:{
		height: "200px"
	},
	link: {
		textDecoration: "none",
		"&:hover":{
			color: theme.colors.primary,
			textDecoration: 'underline'
		}
	},
	stats: {
		position: 'relative',
		padding: '16px',
		border: `1px solid ${theme.colors.secondary}`,
		borderRadius: '8px',
		marginTop: '16px'
	},
	statsTitle: {
		position: 'absolute',
		background: '#fff',
		top: '-17px',
		padding: '0 4px',
		...theme.text.title5
	},
	item:{
		...theme.flex.container,
		...theme.flex.startCenter,
	},
	icon: {
		fill: theme.colors.primary,
		marginRight: '24px'
	}
});

class Details extends Component{
	sendMessage = (values) => {
		const  { user, sendMessage } = this.props;
		if(user && sendMessage){
			const id = user.get('_id');
			const message = values.get('message');
			sendMessage(message, id);
		}
	}
	render(){
		const { classes, stats, stone, t } = this.props;
		let name, description, shape, categoryName, materialName, height, width, depth, weight, quantity, lng, lat;
		let categoryPermalink, materialPermalink;
		let impressions = stats? stats.get('impressions'): 0;
		let reachs = stats? stats.get('clicks'): 0;
		if(stone){
			name = stone.get('name');
			description = stone.get('description');
			categoryName = stone.getIn(['category','name']);
			materialName = stone.getIn(['material','name']);
			categoryPermalink = t('category.type.' + categoryName).replace(/ /g, "-") + '-' + stone.getIn(['category','_id']);
			materialPermalink = materialName.replace(/ /g, "-") + '-' + stone.getIn(['material','_id']);
			shape = stone.get('shape');
			height = stone.get('height');
			width = stone.get('width');
			depth = stone.get('depth');
			weight = stone.get('weight');
			quantity = stone.get('quantity');
			lng = stone.getIn(['geoloc', 'coordinates', 0]);
			lat = stone.getIn(['geoloc', 'coordinates', 1]);
		}
		let mapIcon = "/assets/shapes/block_map.png";
		if(shape === "shapeless" ) mapIcon =  "/assets/shapes/shapeless_map.png";
		if(shape === "slab" ) mapIcon =  "/assets/shapes/slabs_map.png";
		if(shape === "tile" ) mapIcon =  "/assets/shapes/tiles_map.png";
		return (
			<div className={classes.details}>
				<div className={classes.extra}>
					<div className={classes.separator} />
					<div className={classes.social}>
					</div>
				</div>
				<h1 className={classes.title}> { name } </h1>
				{!! description && <h5 className={classes.description}> { description } </h5> }
				<div className={classes.infos}>
					
					{ shape && <p className="info"><span className={classes.label}>{t('stone.shape')}:</span><span> { t('stone.shapeType.' + shape) } </span></p> }
					
					{ categoryName && <p className="info"><span className={classes.label}>{t('stone.category')}:</span><Link className={classes.link} to={'/material_kind/' + categoryPermalink}><span> { t('category.type.' + categoryName) } </span></Link></p> }
					
					{ materialName && <p className="info"><span className={classes.label}>{t('stone.material')}:</span><Link className={classes.link} to={'/material/' + materialPermalink}><span> { materialName } </span></Link></p> }
					
					{ ( (shape === 'block' || shape === 'shapeless') && weight ) && <p className="info"><span className={classes.label}>{t('stone.weight') + " (t)"}:</span><span> { weight } </span></p> }
					
					{ ( (shape === 'slab' || shape === 'tile') && quantity ) && <p className="info"><span className={classes.label}>{t('stone.quantity')}:</span><span> { quantity } </span></p> }

					{ height && <p className="info"><span className={classes.label}>{t('stone.height') + " (m)"}:</span><span> { height } </span></p> }

					{ width && <p className="info"><span className={classes.label}>{t('stone.width') + " (m)"}:</span><span> { width } </span></p> }
					
					{ ( (shape === 'block' || shape === 'shapeless') && depth ) && <p className="info"><span className={classes.label}>{t('stone.depth') + " (m)"}:</span><span> { depth } </span></p> }

					{ ( (shape === 'slab' || shape === 'tile') && depth ) && <p className="info"><span className={classes.label}>{t('stone.depth') + " (mm)"}:</span><span> { depth } </span></p> }

					
				</div>
				<div className={classes.maps}>
					<GMap lat={lat} lng={lng} zoom={12}>
						{ lat && lng && <GMarker lat={lat} lng={lng} icon={mapIcon} /> }
					</GMap>
				</div>
				<div className={classes.separator} />
				<div className={classes.stats}>
					<h3 className={classes.statsTitle}>stats</h3>
					<div className={classes.item}>
						<div className={classes.icon}><Megaphone /></div>
						<div className={classes.label}>Impressions</div>
						<div className={classes.separator} />
						<div className={classes.value}>{impressions}</div>
					</div>
					<div className={classes.item}>
						<div className={classes.icon}><Eye /></div>
						<div className={classes.label}>Reachs</div>
						<div className={classes.separator} />
						<div className={classes.value}>{reachs}</div>
					</div>
				</div>
			</div>
		);
	}
}
Details.propTypes = {
	classes: PropTypes.object.isRequired,
	stone: PropTypes.instanceOf(Map).isRequired,
	stats: PropTypes.instanceOf(Map),
	material: PropTypes.object,
	category: PropTypes.object,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(Details);