import { 
	ANALYTICS_STONE_STORE_STATS,
	ANALYTICS_STONE_STORE_IMPRESSIONS,
	ANALYTICS_STONE_STORE_CLICKS,
	ANALYTICS_STONE_ADD_IMPRESSION,
	ANALYTICS_STONE_ADD_CLICK,
	ANALYTICS_STONE_EMPTY
} from "state/actions/analytics/stone";
import { Map } from 'immutable';

const initState = Map({

});

const stone = ( state = initState, action ) => {

	switch(action.type){
		case ANALYTICS_STONE_STORE_STATS: {
			let impressions = (action.stats)? (action.stats.impressions || 0): 0;
			let clicks = (action.stats)? (action.stats.clicks || 0) : 0;
			return state.mergeDeep(Map({[action.id]: Map({
				impressions: impressions,
				clicks: clicks 
			}) } ));
		}
		case ANALYTICS_STONE_STORE_IMPRESSIONS: {
			return state.mergeDeep(Map({[action.id]: Map({impressions: action.impressions}) } ));
		}
		case ANALYTICS_STONE_STORE_CLICKS: {
			return state.mergeDeep(Map({[action.id]:{clicks: action.clicks}}));
		}
		case ANALYTICS_STONE_ADD_IMPRESSION: {
			return state.updateIn([action.id, 'impressions'], impressions => impressions + 1);
		}
		case ANALYTICS_STONE_ADD_CLICK: {
			return state.updateIn([action.id, 'clicks'], clicks => clicks + 1);
		}
		case ANALYTICS_STONE_EMPTY: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default stone;