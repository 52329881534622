import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Figure from 'UI/Figure';
import Button from 'UI/Button';
import { ArrowLeft } from 'UI/Icon';
import Immutable from 'immutable';
import { staticUrl } from 'config/urls';
import injectSheet from 'react-jss';

const styles = theme => ({
	userInfo: {
		...theme.flex.elemNone,
		...theme.flex.container,
		...theme.flex.startCenter,
		backgroundColor: theme.colors.primary,
		border: "1px solid " + theme.colors.border, 
		cursor: 'pointer',
		height: '60px',
		"& .avatar":{
			padding: "0.5rem",
			width: "50px"
		},
		"& .title": {
			...theme.flex.elemAuto,
			color: '#fff',
			padding: "0.5rem"
		},
		"& .back":{
			margin: "0 .5rem"
		},
		"&:hover":{
			backgroundColor: theme.colors.secondary
		}
	}
});

class UserInfo extends Component {
	select = () => {
		const { onSelect, user } = this.props;
		if(onSelect && user) onSelect(user.get('_id'));
	}
	cancel = (event) => {
		const { onCancel } = this.props;
		event.stopPropagation();
		if(onCancel) onCancel();
	}
	render(){
		const { classes, user, screen } = this.props;
		let name, url;
		if(user){
			let kind = user.get('kind');
			url = staticUrl + '/' + user.getIn(['images',0,'urls','ld']) || "/assets/png/user.png";
			name = (kind === "company")? user.getIn(['name','company']): user.getIn(['name','first']) + ' ' +  user.getIn(['name','last']);
		}
		return (
			<div className={classes.userInfo} onClick={this.select}>
				{ ( (screen === 'tablet' || screen === 'mobile')) && <div className="back"><Button Icon={ArrowLeft} isSmallIcon={true} onClick={this.cancel} /> </div>}
				<div className="avatar">
					{ url && <Figure url={url} shape="hexagon"/> }
					{ !url && <Figure url="/assets/png/user.png" shape="hexagon"/> }
				</div>
				<div className="title"> {name} </div>
			</div>
		);
	}
}
UserInfo.propTypes ={
	classes: PropTypes.object.isRequired,
	id: PropTypes.string,
	user: PropTypes.instanceOf(Immutable.Map),
	onSelect: PropTypes.func,
	onCancel: PropTypes.func
};
export default injectSheet(styles)(UserInfo);