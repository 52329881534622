import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Logout extends Component {
	logout = () => {
		const { logout } = this.props;
		logout();
	}
	componentDidMount(){
		this.logout();
	}
	render(){
		return (
			<React.Fragment />
		)
	}
}

Logout.propTypes = {
	logout: PropTypes.func.isRequired
};

export default Logout;