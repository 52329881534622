import * as mailer from 'fetch/mailer';
import { 
	startFetching,
	endFetching,
	storeError,
	removeError,
	showNotification,
	clearNotification,
} from 'state/actions/ui';

export const send = (email, subject, message) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('mailer_send'));
		dispatch(clearNotification());
		try {
			await mailer.send(email, subject, message);
			dispatch(endFetching('mailer_send','mailer_send_done'));
			dispatch(showNotification('Grazie di averci contattato!'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('mailer_send','mailer_send_error'));
		}
	}
};