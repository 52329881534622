import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LogoDark } from 'Elements/Logo'

const styles = theme => ({
	root: {
		...theme.layout.container,
		height: '100%',
		"& p":{
			marginBottom: '8px'
		},
		"& h2, h3": {
			marginBottom: '8px',
			fontWeight: 'bold'
		},
		"& h2": {
			...theme.text.title4,
		},
		"& h3": {
			...theme.text.title5,
		},
		"& ol, ul": {
			marginLeft: '18px'
		}
	},
	contentA: {
		"& ol": {
			listStyleType: "none",
			counterReset: "item",
			margin: 0,
			padding: 0,
		},
		"& ol > li": {
			display: "table",
			counterIncrement: "item",
			marginBottom: "0.6em"
		},
		"& ol > li:before": {
			content: '"A." counters(item, ".") ". "',
			display: 'table-cell',
			paddingRight: '0.6em',    
		},
		"& li ol > li": {
			margin: 0
		},
		"& li ol > li:before": {
			content: '"A. counters(item, ".") " "'
		},
		"& ul":{
			listStyle: "square"
		},
		"& ul > li": {
			marginLeft: "0.8em",
			marginBottom: "0.6em"
		},
	},
	contentB: {
		"& ol": {
			listStyleType: "none",
			counterReset: "item",
			margin: 0,
			padding: 0,
		},
		"& ol > li": {
			display: "table",
			counterIncrement: "item",
			marginBottom: "0.6em"
		},
		"& ol > li:before": {
			content: '"B." counters(item, ".") ". "',
			display: 'table-cell',
			paddingRight: '0.6em',    
		},
		"& li ol > li": {
			margin: 0
		},
		"& li ol > li:before": {
			content: '"B. counters(item, ".") " "'
		},
		"& ul":{
			listStyle: "square"
		},
		"& ul > li": {
			marginLeft: "0.8em",
			marginBottom: "0.6em"
		},
	},
	logo: {
		...theme.flex.container,
		...theme.flex.centerCenter,
		marginBottom: '16px'
	},
	title:{
		...theme.text.title4,
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '16px'
	},
	text: {
		padding: "8px"
	}
});

class TermsAndConditions extends Component {
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.logo}><LogoDark isBig={true} /></div>
				<p>	
					STONES BUSINESS S.R.L.<br/>
					SEDE LEGALE: VIA FIVIZZANO 15, 54033 CARRARA (MS)<br/>
					C.F. / P.IVA  01398350452<br/>
					CAP.SOC. € 12.000,00 <br />
					REA MS – 137871 <br />
					E-MAIL: STONESBUSINNESSSRL@PEC.IT <br/>
					WEB PAGE: STONESBUSINESS.COM
				</p>
				<h1 className={classes.title}>Contratto di registrazione alla Piattaforma</h1>
				<p>
					<b>Tra Stone Business S.r.l., in persona del legale rappresentante pro tempore, corrente in Carrara (MS) alla Via Fivizzano, 15, CF-PIVA: 01398350452, REA MS-137871</b><br/>
					e<br/>
					<b>l'utente</b>
				</p>
				<h3>premesso che</h3>
				<ol type="a">
					<li>
						<p> 
							Stone Business S.r.l.  ha ideato e creato una piattaforma web utile alla pubblicizzazione di servizi applicabili al settore lapideo;
						</p>
					</li>
					<li>
						<p> 
							Detta piattaforma consente di mettere in contatto Venditori e Acquirenti di beni e/o servizi del settore; in particolare permette ai Venditori di mostrare attraverso la piattaforma i beni e servizi offerti e 
							agli Acquirenti di visionarli e contattare i Venditori se interessati;
						</p>
					</li>
					<li>
						<p> 
							 Stone Business S.r.l. ricoprendo il solo ruolo di creatore e gestore della Piattaforma, rimane sempre e comunque estranea al Contratto di Acquisto eventualmente concluso tra Venditore e Acquirente, che sarà 
							 stipulato al di fuori della Piattaforma, secondo le norme di legge e le regole contrattuali che i singoli Venditori e Acquirenti decideranno di sottoscrivere;
						</p>
					</li>
					<li>
						<p> 
							Il Venditore, pertanto, è e rimane il solo ed esclusivo responsabile della conclusione e della esecuzione del contratto di acquisto e/o richiesta di fornitura di servizio, restando Stone Business S.r.l. 
							estranea ed esonerata da qualsiasi responsabilità derivante dalla conclusione, dalla risoluzione e/o dall’esecuzione o dalla mancata esecuzione del contratto di acquisto e/o richiesta di fornitura suddetti;
						</p>
					</li>
					<li>
						<p> 
							l Venditore al momento della adesione alla Piattaforma dichiara di esercitare professionalmente l’attività di vendita di beni e/o servizi nel settore lapideo; 
						</p>
					</li>
					<li>
						<p> 
							Agli Acquirenti – necessariamente maggiorenni - è consentito un Servizio di Accesso e Utilizzo della Piattaforma previa sottoscrizione del presente Contratto, contenente i termini e le condizioni d’uso del servizio.
						</p>
					</li>
				</ol>
				<p> 
					Tutto ciò premesso si conviene che:<br/>
					Il Contratto è costituito dalle seguenti sezioni e allegati:<br/>
					<b>A. Disposizioni Generali</b><br/>
					<b>B. Termini e condizioni del Servizio di Accesso e Utilizzo della Piattaforma</b><br/>
					<b>Allegato 1</b>: <Link to="/legal/policy">Informativa Privacy</Link> resa agli Utenti/Acquirenti<br/>
					<b>Allegato 2</b>: Istruzioni di Funzionamento Piattaforma
				</p>
				<div className={classes.contentA}>
					<h2 className={classes.title}>A - Disposizioni generali</h2>
					<ol>
						<li>
							<h3>Premesse e allegati</h3>
							<ol>
								<li>
									<p>
										 Le premesse e gli allegati al Contratto devono intendersi quali parti integranti e sostanziali dello stesso. 
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Definizioni </h3>
							<ol>
								<li>
									<p>
										 Per consentire una completa comprensione del presente Contratto, si specifica che le espressioni che seguono avranno i seguenti significati: 
									</p>
									<ul>
										<li>
											<b>“Piattaforma”</b> indica il luogo virtuale creato e gestito da Stones Business S.r.l. con indirizzo <a href="https://stonesbusiness.com" rel="noopener noreferrer" target="_blank">stonesbusiness.com</a> sul quale possono navigare 
											Utenti interessati all’offerta di beni e servizi del settore lapideo e sul quale possono pubblicizzare Venditori professionali i propri beni e servizi.
										</li>
										<li>
											<b>“Corrispettivo”</b> indica l’importo che il Venditore è tenuto a corrispondere a Stone Business S.r.l. per i Servizi, come indicato nell’Allegato 1 al Contratto.
										</li>
										<li>
											<b>“Utente”</b> indica il soggetto che naviga sulla Piattaforma, interessato ai beni e/o servizi offerti dal Venditore.
										</li>
										<li>
											<b>“Venditore”</b> indica il Fornitore professionale di Beni o Servizi che attraverso la Piattaforma pubblicizza i propri prodotti o servizi.
										</li>
										<li>
											<b>“Acquirente”</b> indica l’Utente registrato che ha richiesto di poter entrare in contatto con il Venditore tramite la Piattaforma. 
										</li>
										<li>
											<b>“Registrazione utente”</b> indica la registrazione dell’Utente che gli consente di entrare in contatto con i Venditori tramite la Piattaforma. 
										</li>
										<li>
											<b>“Pagina dedicata”</b> indica lo spazio occupato sulla Piattaforma dal Venditore contenente i Prodotti/Servizi pubblicizzati sulla Piattaforma – comprensivo dei titoli, delle immagini dei prodotti/servizi 
											e delle descrizioni degli stessi – di proprietà di Stone Business S.r.l., messo a disposizione del Venditore tramite il Sito. 
										</li>
										<li>
											<b>“Credenziali di Autenticazione”</b> indica lo username e la password, scelti dal Venditore e dall’Utente, al momento della registrazione alla Piattaforma, che consentono loro l’accesso a tale Piattaforma. 
										</li>
										<li>
											<b>“Dati di Navigazione”</b> indica i nomi a dominio dei computer utilizzati dai soggetti che accedono al Sito, gli indirizzi in notazione Url delle risorse richieste, l’orario della richiesta, il metodo 
											utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, ecc. 
										</li>
										<li>
											<b>“Dati di Registrazione”</b> indica i dati personali conferiti dai Venditori e dagli Utenti all’atto della registrazione al Sito. 
										</li>
									</ul>
								</li>
							</ol>
						</li>
						<li>
							<h3>Utente e adesione dell’Acquirente alla Piattaforma</h3>
							<ol>
								<li>
									<p>
										L’Utente che naviga sul Sito ha facoltà di vedere solo parzialmente le pagine dei Venditori e non ha facoltà di interagire con essi mediante la Piattaforma; per poter visionare interamente le Pagine dedicate dei 
										Venditori e potersi mettere in contatto con essi mediante la Piattaforma, ha necessità di aderire alla Piattaforma con una procedura di registrazione, nella quale gli saranno richiesti: nome e cognome/denominazione, 
										dati anagrafici/dati societari, dati di contatto. Con la registrazione l’Utente diventa “Acquirente” e dichiara di conoscere, approvare e rispettare le Disposizioni Generali e i Termini e condizioni di Servizio della 
										Piattaforma.
									</p>
								</li>
								<li>
									<p>
										La registrazione alla Piattaforma è gratuita. Per registrarsi l’Acquirente deve compilare l’apposito modulo e cliccare sul tasto “Continua”. La registrazione gli consentirà di accedere alle Pagine dedicate dei 
										Venditori presenti sulla Piattaforma e di avere un’area riservata e personale attraverso la quale poter contattare i Venditori di interesse e scambiare con loro messaggi; inoltre potrà modificare e aggiornare i 
										propri dati di registrazione.
									</p>
									<p>
										Al termine della procedura di registrazione, l'Utente riceverà una mail di conferma nella propria casella di posta elettronica con la quale gli verranno comunicate le credenziali di autenticazione e le procedure 
										di accesso alla Piattaforma.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente garantisce che le informazioni fornite durante la procedura di registrazione sono complete e veritiere e si obbliga a comunicare tempestivamente a Stones Business S.r.l. le variazioni che si verificheranno 
										nel tempo. In difetto, le eventuali variazioni non saranno opponibili a Stones Business S.r.l.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente ha l’onere di custodire le proprie credenziali di accesso alla Piattaforma e ha l’onere di uso esclusivo delle stesse, non potendo cederle a terzi. L’Acquirente si impegna a tenerle segrete e ad 
										assicurarsi che nessun terzo vi abbia accesso. Si obbliga, inoltre, a informare immediatamente Stones Business S.r.l. nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una indebita divulgazione 
										delle credenziali ricevute.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Adesione del Venditore alla Piattaforma</h3>
							<ol>	
								<li>
									<p>
										Il Venditore per aderire alla Piattaforma dovrà registrarsi fornendo in maniera veritiera e completa tutti i dati richiesti durante la procedura di registrazione. Inoltre, il Venditore dovrà approvare il Contratto 
										dopo averlo letto ed esaminato con attenzione ed inviare la richiesta di adesione alla Piattaforma. Il Contratto si riterrà concluso ed efficace nel momento in cui il Venditore riceverà da parte di 
										Stones Business S.r.l. la conferma scritta.
									</p>
								</li>
								<li>
									<p>
										Il Venditore garantisce che le informazioni fornite durante la procedura di registrazione sono complete e veritiere e si obbliga a comunicare tempestivamente a Stones Business S.r.l. le variazioni che si 
										verificheranno nel tempo. In difetto, le eventuali variazioni non saranno opponibili a Stones Business S.r.l..
									</p>
								</li>
								<li>
									<p>
										Il Venditore ha l’onere di custodire le proprie credenziali di accesso e ha l’onere di uso esclusivo delle stesse, non potendo essere cedute a terzi. Il Venditore si impegna a tenerle segrete e ad assicurarsi 
										che nessun terzo vi abbia accesso e a informare immediatamente Stones Business S.r.l. nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una indebita divulgazione delle stesse.
									</p>
								</li>
								<li>
									<p>
										Il Venditore è tenuto ad attenersi scrupolosamente nell’utilizzo della Piattaforma alle prescrizioni di Stones Business S.r.l.. In caso contrario sarà considerato inadempiente.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Contratto di fornitura beni o servizi da parte del Venditore</h3>
							<ol>
								<li>
									<p>
										Stones Business S.r.l. mediante la propria Piattaforma mette in contatto Venditore e Acquirente. Il contratto di acquisto e/o richiesta di fornitura di un Prodotto/Servizio del Venditore a richiesta dell’Acquirente 
										è concluso, direttamente tra il Venditore e l’Acquirente, al di fuori della Piattaforma. Il Venditore, pertanto, è e rimane il solo ed esclusivo responsabile della conclusione e della esecuzione del contratto 
										di acquisto e/o richiesta di fornitura. A tal fine il Venditore predisporrà autonome “Condizioni generali di contratto” che regoleranno il conseguente rapporto negoziale. 
									</p>
								</li>
								<li>
									<p>
										L’Acquirente dichiara pertanto che nessuna richiesta inerente al rapporto contrattuale tra Venditore e Acquirente potrà essere rivolta a Stones Business S.r.l. che si limiterà a permettere alle parti di mettersi in 
										contatto, rimanendo però del tutto estranea al prosieguo delle trattative e al successivo eventuale accordo.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Contenuti pubblicati sulla Piattaforma</h3>
							<ol>
								<li>
									<p>
										L'Acquirente, con l'accettazione delle presenti Condizioni Generali, si impegna a non utilizzare in modo improprio il Servizio offerto dalla Piattaforma e a non scambiare materiale illecito, osceno, calunnioso, 
										diffamatorio, offensivo della morale corrente, o, comunque, lesivo dei diritti altrui. Dichiara di riconoscere che sono altresì proibiti messaggi che incitano all'odio ed alla discriminazione razziale o religiosa. 
									</p>
								</li>
								<li>
									<p>
										In caso di inadempimento dell'Acquirente a uno degli obblighi sopra descritti o qualora la condotta dell'Acquirente non fosse conforme a quanto qui previsto, Stones Business S.r.l. si riserva il diritto di sospendere 
										e/o interrompere il Servizio, nonché di rifiutare ogni e qualsiasi futura richiesta da parte dell'utente di utilizzo dello stesso. 
									</p>
								</li>
								<li>
									<p>
										In caso di Sospensione del Servizio e/o eliminazione dell’account all’Acquirente sarà inibito l’accesso alla Piattaforma senza che il Venditore possa far valere nei confronti di Stones Business S.r.l. diritti a 
										qualsiasi titolo, anche risarcitorio. 
									</p>
								</li>
								<li>
									<p>
										In caso di contestazione da parte di terzi riguardo a qualsiasi condotta tenuta dall’Acquirente all’interno della Piattaforma, l’Acquirente dichiara di assumersene la piena ed esclusiva responsabilità e si impegna a 
										tenere manlevata e indenne Stones Business S.r.l. da qualsiasi danno, perdita o spesa conseguenti. Stones Business S.r.l., poi, si riserva di assumere le opportune iniziative giudiziarie sia in sede civile che penale 
										tutela dei propri diritti e dei diritti dei terzi.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Sospensione dell’account del Venditore</h3>
							<ol>
								<li>
									<p>
										Stones Business S.r.l. potrà sospendere l’account del Venditore, in ogni momento e con 5 giorni di  preavviso, per un periodo massimo di 25 giorni, in caso di violazione da parte del Venditore delle disposizioni di 
										legge applicabili, degli obblighi contrattuali, delle istruzioni sul funzionamento della Piattaforma, della normativa sulla privacy. La comunicazione della sospensione avverrà tramite messaggio e-mail nel quale 
										saranno specificate le ragioni della decisione. Contro tale decisione potrà essere posto reclamo come indicato al punto A.10.
									</p>
								</li>
								<li>
									<p>
										Il Venditore durante il periodo di Sospensione del Servizio avrà Accesso Limitato alla sola visualizzazione della propria Pagina dedicata e non potrà, né usare le funzioni di Contatto e Messaggistica con gli 
										Acquirenti, né modificare la propria Pagina dedicata. 
									</p>
								</li>
								<li>
									<p>
										L’Acquirente accetta che possa verificarsi tale condizione e pertanto dichiara e garantisce espressamente che non riterrà, in alcun modo, Stones Business S.r.l. responsabile di eventuali danni, di qualsivoglia natura 
										e specie, che possano derivare all’Acquirente dalla sospensione dell’account del Venditore, rinunciando sin da ora ad ogni azione relativa.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Chiusura dell’account del Venditore</h3>
							<ol>
								<li>
									<p>
										L’Acquirente dichiara di essere consapevole - e nulla oppone - che in caso di scioglimento del Contratto per l’utilizzo della Piattaforma tra Venditore e Stones Business S.r.l., tutti i diritti conferiti al Venditore 
										di utilizzo della Piattaforma cesseranno di avere effetto; pertanto dal momento in cui sarà efficace la cessazione del contratto tra Venditore e Stones Business S.r.l., non sarà più visibile e/o accessibile dalla 
										Piattaforma la Pagina dedicata del Venditore.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Durata e Diritto di recesso</h3>
							<ol>
								<li>
									<p>
										L'Acquirente può rimare registrato alla Piattaforma per il tempo che ritiene opportuno. In caso di inutilizzo dell’account per oltre un anno lo stesso verrà disabilitato – previo avvertimento via email almeno 
										30 giorni prima della disabilitazione - salvo la possibilità di riattivazione mediante richiesta dell’Acquirente da formularsi via mail all’indirizzo 
										<a href="mailto:info@stonesbusiness.com" rel="noopener noreferrer" target="_blank">info@stonesbusiness.com</a>. 
									</p>
								</li>
								<li>
									<p>
										L’Acquirente può interrompere l'uso del servizio e recedere dal contratto in qualsiasi momento, mediante l'attivazione della relativa procedura on line. In tal caso non potrà più accedere alla Piattaforma con le 
										precedenti credenziali ricevute.
									</p>
								</li>
								<li>
									<p>
										A Stones Business S.r.l. è riconosciuto il diritto di recedere dal Contratto di servizi con l’Acquirente (a) in ogni momento, fornendo motivazione, mediante invio di lettera raccomandata A/R o email pec, con un 
										preavviso di almeno 30 giorni, (b) immediatamente, mediante invio di lettera raccomandata A/R o email pec quando: l’Acquirente abbia rilasciato dati identificativi falsi, incompleti o comunque inesatti; oppure abbia 
										utilizzato la Piattaforma senza rispettare le Disposizioni Generali o i Termini del Servizio. In ogni caso resta fermo il diritto di Stones Business S.r.l. di agire a tutela dei suoi diritti in tutte le sedi più 
										opportune, comprese quelle penali, e salvo, in ogni caso, il diritto al risarcimento del danno che essa abbia eventualmente subito. 
									</p>
								</li>
								<li>
									<p>
										Il recesso esercitato da Stones Business S.r.l. in ogni caso non comporterà il pagamento di alcuna penale e/o rimborso e/o indennizzo e/o corrispettivo a favore dell’Acquirente. 
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Reclami</h3>
							<p>
								Stones Business S.r.l. ha organizzato un sistema interno di gestione dei reclami degli Acquirenti. 
								Il sistema interno di gestione dei reclami deve essere è facilmente accessibile scrivendo all’email <a href="mailto:info@stonesbusiness.com" rel="noopener noreferrer" target="_blank">info@stonesbusiness.com</a> e gratuito e garantisce che i 
								reclami siano gestiti in un lasso di tempo di massimo 5 giorni dal ricevimento della segnalazione, con conseguente risposta. Il sistema si basa sui principi della trasparenza e della parità di trattamento a parità di 
								situazione, e tratta i reclami in modo proporzionato alla loro importanza e complessità. I reclami potranno essere presentati in merito alle seguenti questioni: 
							</p>
							<ul>
								<li>
									presunta inadempienza di Stones Business S.r.l. degli obblighi stabiliti dal presente regolamento con conseguenze per l’Acquirente che presenta un reclamo ("reclamante"); 
								</li>
								<li>
									problemi tecnologici che si ricollegano direttamente alla Piattaforma con conseguenze per il reclamante; 
								</li>
								<li>
									misure o comportamenti adottati da Stones Business S.r.l. direttamente connessi alla Piattaforma con conseguenze per il reclamante. 
								</li>
							</ul>					
						</li>
						<li>
							<h3>Diritti di Privativa</h3>
							<ol>
								<li>
									<p>
										Stones Business S.r.l. è l’ideatore ed autore, ai sensi della Legge 22 aprile 1941, n. 633 e successive modifiche ed integrazioni, del Sito e ad essa sono riservati tutti i diritti. 
									</p>
								</li>
								<li>
									<p>
										L’Utente, il Venditore e l’Acquirente riconoscono che sono di proprietà di Stones Business S.r.l., la Piattaforma, il Sito, il Software, l’architettura informatica del Sito, i codici, metodi, gli algoritmi, le 
										istruzioni rappresentative di perfezionamenti di natura tecnica, creativa o organizzativa, gli aggiornamenti, le release ed ogni e qualsiasi applicazione alla Piattaforma, risultato o ritrovato, siano utilizzati o 
										sviluppati da Stones Business S.r.l. – o da terzi dalla stessa incaricati – per il coordinamento e l’interazione delle piattaforme o per la realizzazione, attivazione, gestione online del Sito e degli elementi 
										informativi pubblicati nel Sito e/o per la fornitura dei Servizi.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente, riconoscendo Stones Business S.r.l. quale titolare esclusivo di tutti i diritti di proprietà intellettuale connessi alla Piattaforma si impegna a non:
									</p>
									<ul>
										<li>
											copiare, distribuire o modificare la Piattaforma o tentare di accedere al relativo codice sorgente al fine di creare prodotti derivati da questo; 
										</li>
										<li>
											vendere, cedere, concedere in sub-licenza, o in qualunque modo permettere l’utilizzo a terzi, i diritti di proprietà intellettuale connessi all’utilizzo della Piattaforma.
										</li>
									</ul>
								</li>
								<li>
									<p>
										L’Acquirente si obbliga a non usare in alcun modo (neppure su materiali pubblicitari, commerciali, promozionali o similari) i Marchi di Stones Business S.r.l., senza il preventivo consenso scritto di quest’ultima. 
										L’Acquirente si obbliga inoltre non esprimersi in alcun modo (sia esso positivo o negativo) relativamente al rapporto contrattuale esistente con Stone Business S.r.l., astenendosi dal divulgare informazioni inerenti 
										al contratto di servizi sottoscritto con Stones Business S.r.l. a soggetti che non siano suoi amministratori/dipendenti/consulenti. 
									</p>
								</li>
								<li>
									<p>
										Le obbligazioni di cui al presente articolo sono assunte in relazione a tutto il mondo e resteranno in vigore anche dopo la cessazione per qualsiasi causa del Contratto. 
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Trattamento dei Dati Personali</h3>
							<ol>
								<li>
									<p>
										In relazione al trattamento dei “Dati Personali” (come definiti all’art. 4, Regolamento Europeo 2016/679) conferiti dagli Utenti, dagli Acquirenti e dai Venditori, le Parti si danno atto che: 
									</p>
									<ul>
										<li>
											Stones Business S.r.l. agirà in veste di: Titolare esclusivo dei Dati di Registrazione e dei Dati di Navigazione; sarà Titolare autonomo di ogni eventuale ulteriore Dato Personale eventualmente conferito dagli 
											Acquirenti nelle comunicazioni con il Venditore, attraverso il sistema di messaggistica interna della Piattaforma Transazionale; tratterà i dati solo per adempiere alle obbligazioni che sulla stessa incombono in 
											qualità di erogatore del Sito e del servizio di registrazione al Sito, oltre che di fornitore e gestore della Piattaforma, nonché, previo consenso espresso dell’Interessato, per finalità di marketing o per le altre 
											inalità meglio definite nell’Informativa di cui all’Allegato 2.
										</li>
										<li>
											Il Venditore agirà in veste di: Titolare autonomo del trattamento dei Dati a cui potrà accedere attraverso la Piattaforma forniti dagli Acquirenti per la Vendita, nonché di ogni ulteriore Dato Personale eventualmente 
											onferito dagli Acquirenti nelle comunicazioni con il Venditore, attraverso il sistema di messaggistica interna della Piattaforma; sarà Titolare esclusivo dei Dati Personali che l’Acquirente avrà eventualmente conferito, 
											direttamente al Venditore, non per il tramite della Piattaforma Transazionale; tratterà i dati che precedono per finalità strettamente connesse alla esecuzione del Contratto di Acquisto e per finalità 
											amministrativo-contabili connesse alla Vendita, fornendo a tal fine idonea Informativa come previsto dal Regolamento Europeo 2016/679. 
										</li>
									</ul>
								</li>
								<li>
									<p>
										Il Venditore si obbliga a tenere Stones Business S.r.l. indenne e manlevata da qualsiasi danno possa alla stessa derivare in caso di violazione degli obblighi previsti dalla normativa europea e nazionale in materia di 
										trattamento dei dati personali.
									</p>
								</li>
								<li>
									<p>
										Il Venditore è consapevole e accetta che nel caso di scioglimento, per qualsiasi causa, del Contratto, i suoi Dati Personali continueranno a risiedere nello storico della corrispondenza intercorsa con l’Acquirente 
										attraverso la Piattaforma Transazionale, per tutto il tempo in cui l’area riservata dell’Acquirente sarà attiva.
									</p>
								</li>
							</ol> 
						</li>
						<li>
							<h3>Dichiarazione e Garanzia dell’Acquirente</h3>
							<ol>
								<li>
									<p>
										L’Acquirente dichiara e garantisce che durante l’utilizzo della Piattaforma fornirà informazioni veritiere, aggiornate e complete, e non porrà in essere condotte fraudolente finalizzate a trarre un ingiusto profitto 
										dall’utilizzo della Piattaforma. A tal fine l’Acquirente si impegna a garantire e manlevare Stones Business S.r.l. in relazione alle richieste di risarcimento danni che Stones Business S.r.l. dovesse ricevere da 
										terzi a qualsiasi titolo, quale conseguenza di comportamenti compiuti e/o informazioni rese dall’Acquirente attraverso l’utilizzo della Piattaforma. 
									</p>
								</li>
								<li>
									<p>
										L’Acquirente si impegna a utilizzare la Piattaforma nel rispetto delle presenti Disposizioni Generali, delle norme applicabili, dell’ordine pubblico e del buon costume. 
									</p>
								</li>
								<li>
									<p>
										l’Acquirente espressamente prende atto e riconosce a qualunque titolo ed effetto che il completamento delle procedure di accreditamento sulla Piattaforma comporta la piena accettazione del presente Contratto, dei 
										Termini e condizioni del Servizio di Accesso e Utilizzo della Piattaforma.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Responsabilità di Stones Business S.r.l.</h3>
							<ol>
								<li>
									<p>
										L’Acquirente prende atto ed accetta che Stones Business S.r.l. è e rimane sempre e comunque estranea al contratto concluso tra Venditore e Acquirente. Inoltre Stones Business S.r.l. non potrà essere ritenuta 
										responsabile per eventuali interruzioni, guasti, malfunzionamenti, errori, ritardi o difetti della Piattaforma, ivi compresi eventuali effetti di virus o di altri elementi capaci di inficiare l’utilizzo della 
										Piattaforma, che non siano imputabili a dolo o colpa grave di Stones Business S.r.l. e/o che siano imputabili a fatto di terzo, compresi eventuali fornitori di Stones Business S.r.l.
									</p>
								</li>
								<li>
									<p>
										Stones Business S.r.l. si riserva, a proprio insindacabile giudizio, il diritto di sospendere, interrompere, rimuovere o apportare modifiche alla Piattaforma per esigenze di gestione, manutenzione e/o aggiornamento, 
										senza obbligo di informare preventivamente l’Acquirente e senza che da ciò possa derivare alcuna responsabilità. 
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Regole Generali</h3>
							<ol>
								<li>
									<p>
										La lingua ufficiale del Contratto e di tutta la documentazione relativa è l’italiano.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente non può cedere il presente Contratto. 
									</p>
								</li>
								<li>
									<p>
										Stones Business S.r.l. si riserva la facoltà di modificare i termini e le condizioni contenuti nel presente Contratto: in tal caso si impegna a comunicare all’Acquirente ogni modifica relativa al Contratto con un 
										preavviso non inferiore a 20 (venti) giorni dalla data di efficacia delle modifiche stesse. L’Acquirente che non intenda accettare le modifiche proposte deve esercitare il recesso inviando mail all’indirizzo 
										<a href="mailto:info@stonesbusiness.com" rel="noopener noreferrer" target="_blank">info@stonesbusiness.com</a>, entro e non oltre la predetta data. Tutte le modifiche saranno effettive al trascorrere dei 20 giorni dall’intervenuta 
										comunicazione al Venditore.
									</p>
								</li>
								<li>
									<p>
										Le comunicazioni inviate a mezzo posta elettronica certificata avranno effetto immediato. Le comunicazioni trasmesse via e-mail avranno effetto soltanto se seguite da e-mail che ne confermano l’avvenuta ricezione, 
										e ciascuna parte si obbliga ad inviare immediatamente la conferma di avvenuta ricezione nel momento in cui il messaggio è pervenuto. Le Parti si obbligano a comunicare, nel corso della vigenza del presente contratto, 
										ogni variazione dei rispettivi indirizzi. In caso di mancata comunicazione della variazione, tutte le comunicazioni che saranno effettuate in base ai vecchi recapiti avranno piena efficacia e validità.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Pubblicità sulla Piattaforma</h3>
							<ol>
								<li>
									<p>
										Stones Business S.r.l., a suo insindacabile giudizio, il diritto di inserire sul Sito e sulla Piattaforma avvisi pubblicitari compatibili con l'attività svolta, nonché collegamenti ad altri siti che siano 
										rispettosi degli obblighi previsti a suo carico nel presente contratto.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Legge Applicabile e Foro competente</h3>
							<ol>
								<li>
									<p>
										Il presente Contratto è soggetto alla legge italiana. Per ogni eventuale controversia da esso derivante e/o comunque ad esso connessa, il Venditore e l’Acquirente riconoscono ed accettano la competenza esclusiva 
										del Foro di Massa. 
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Risoluzione delle controversie online per Utenti Consumatori</h3>
							<ol>
								<li>
									<p>
										L’Acquirente che si registri alla Piattaforma nelle vesti di Consumatore residente in Europa, deve essere a conoscenza del fatto che la Commissione Europea ha istituito una piattaforma online che fornisce uno 
										strumento di risoluzione alternativa delle controversie. Tale strumento può essere utilizzato dall’Acquirente Consumatore per risolvere in via non giudiziale ogni controversia relativa a e/o derivante da contratti 
										di vendita di beni e fornitura di servizi stipulati in rete. Di conseguenza, l’Acquirente - Consumatore può usare tale piattaforma per la risoluzione di ogni disputa nascente dal contratto stipulato online con 
										Stones Business S.r.l. di fornitura di servizi. La piattaforma europea è disponibile al seguente indirizzo: <br/>
										<a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" rel="noopener noreferrer" target="_blank">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage </a>
									</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
				<div className={classes.contentB}>
					<h2 className={classes.title}>B - Termini e condizioni del Servizio di Accesso e Utilizzo della Piattaforma</h2>
					<ol>
						<li>
							<h3>Termini e condizioni del servizio di accesso e utilizzo della Piattaforma</h3>
							<ol>
								<li>
									<p>
										Per registrarsi alla Piattaforma, l’Acquirente deve:
									</p>
									<ul>
										<li>
											compilare in ogni sua parte il Modulo di Iscrizione; 
										</li>
										<li>
											sottoscrivere telematicamente, dove indicato, il Contratto e i relativi allegati, dopo averli attentamente letti esaminati e compresi;
										</li>
										<li>
											attendere la mail di conferma avvenuta registrazione.
										</li>
									</ul>
								</li>
								<li>
									<p>
										La registrazione alla Piattaforma comporta l’accesso alla Piattaforma.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente è responsabile della veridicità delle informazioni fornite tramite il Modulo di Iscrizione e si obbliga a comunicare prontamente e per iscritto a Stones Business S.r.l. qualunque variazione di tali 
										informazioni. In difetto di tale comunicazione, eventuali variazioni non gli saranno opponibili. In ogni caso, l’Acquirente si impegna a tenere Stones Business S.r.l. indenne e manlevata da qualsiasi danno, obbligo 
										risarcitorio e/o sanzione derivante da e/o in qualsiasi modo collegata alla violazione da parte dell’Acquirente delle regole sulla registrazione alla Piattaforma, delle regole di on-boarding, delle regole sulla 
										conservazione delle Credenziali di Autenticazione e/o sull’aggiornamento dei propri dati. 
									</p>
								</li>
								<li>
									<p>
										Le Credenziali di Autenticazione devono essere conservate dall’Acquirente con estrema cura e attenzione. Esse possono essere utilizzare solo dall’Acquirente e non possono essere cedute a terzi. L’Acquirente è l’unico 
										ed esclusivo responsabile della custodia delle Credenziali di Autenticazione e si impegna a mantenerle segrete e ad assicurarsi che nessun terzo vi abbia accesso. Egli si impegna inoltre a informare immediatamente 
										Stones Business S.r.l. nel caso in cui sospetti o venga a conoscenza di un uso indebito o di una indebita divulgazione delle stesse. In ogni caso in cui, per qualsiasi motivo, le predette credenziali vengano utilizzate 
										da terzi, diversi dall’Acquirente, autorizzati o meno da quest’ultimo, l’Acquirente risponderà di tale utilizzo nei confronti di ogni terzo e nei confronti di Stones Business S.r.l.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<h3>Utilizzo e funzionalità della Piattaforma</h3>
							<ol>
								<li>
									<p>
										L’Acquirente utilizza la Piattaforma a proprio rischio e sotto la sua diretta ed esclusiva responsabilità. 
									</p>
								</li>
								<li>
									<p>
										Le Istruzioni di Funzionamento della Piattaforma sono disponibili al Contratto come Allegato 2 all’interno dell’area riservata della Piattaforma. Eventuali e successive versioni aggiornate delle Istruzioni di 
										Funzionamento saranno messe a disposizione dell’Acquirente all’interno di tale area, che ciascun Acquirente è pertanto invitato a consultare con regolarità.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente è tenuto ad attenersi scrupolosamente, nell’utilizzo della Piattaforma, a quanto prescritto nelle Istruzioni di Funzionamento, tempo per tempo vigenti, da considerarsi, a tutti gli effetti, parte 
										integrante del Contratto. L’Acquirente si obbliga, pertanto, a osservare le Istruzioni di Funzionamento.
									</p>
								</li>
								<li>
									<p>
										La Piattaforma consente all’Acquirente di effettuare, conformemente alle Istruzioni di Funzionamento, tra le altre, le seguenti attività:
									</p>
									<ul>
										<li>
											gestire i propri dati identificativi, modificarli e/o aggiornarli in ogni momento;
										</li>
										<li>
											compilare nelle Parti Editabili il Modulo di Recesso Tipo; 
										</li>
										<li>
											dialogare con il Venditore tramite l’apposito sistema di messaggistica;
										</li>
										<li>
											ricevere comunicazioni da parte di Stones Business S.r.l..
										</li>
									</ul>
								</li>
							</ol>
						</li>
						<li>
							<h3>Dichiarazioni e garanzie dell’Acquirente in relazione all’utilizzo della Piattaforma Transazionale</h3>
							<ol>
								<li>
									<p>
										L’Acquirente è consapevole e riconosce di essere l’unico e il solo responsabile delle attività effettuate tramite la Piattaforma. 
									</p>
								</li>
								<li>
									<p>
										L’Acquirente si impegna a tenere Stones Business S.r.l. indenne e manlevato da ogni e qualsiasi danno, comprese eventuali sanzioni delle autorità competenti, possa alle stesse derivare da Errori di Caricamento e/o 
										dalle attività effettuate dall’Acquirente tramite la Piattaforma.
									</p>
								</li>
								<li>
									<p>
										L’Acquirente dichiara di essere consapevole e di accettare che:
									</p>
									<ul>
										<li>
											l’accesso al Sito, alla Piattaforma e/o a tutti o parte dei Servizi della Piattaforma potrebbe essere momentaneamente sospeso, non potendo Stones Business S.r.l. assicurare il funzionamento permanente e/o l’accesso 
											permanente al Sito, alla Piattaforma Transazionale e/o a tutti o parte dei Servizi della Piattaforma, secondo quanto indicato all’art. A.13 che precede;
										</li>
										<li>
											Stones Business S.r.l. non è tenuta a verificare la veridicità e/o correttezza e/o esaustività delle informazioni fornite e/o inserite dal Venditore tramite la Piattaforma né è sottoposta all’obbligo di condurre 
											controlli circa l’eventuale illiceità, non conformità alla Normativa Applicabile, contrarietà alla decenza, al buon costume, all’onore, di tali informazioni e/o, più in generale, della condotta del Venditore. 
										</li>
									</ul>
								</li>
							</ol>
						</li>
						<li>
							<h3>Dichiarazioni di Stones Business S.r.l. in relazione alla Piattaforma</h3>
							<ol>
								<li>
									<p>
										Nella sua qualità di gestore tecnico-operativo della Piattaforma e del Sito, Stones Business S.r.l.  si riserva il diritto di modificare, sospendere o interrompere, in qualsiasi momento, l’operatività della 
										Piattaforma e/o di tutti o parte dei Servizi della Piattaforma, dandone, ove possibile, congruo preavviso all’Acquirente. Stone Business S.r.l. si riserva inoltre la facoltà di modificare e/o ridisegnare, a sua 
										esclusiva discrezione, tutta o parte della Piattaforma e/o del Sito, nonché il contenuto, il formato, il layout, i caratteri e qualsiasi altro elemento (tecnico, grafico, etc.) della Piattaforma e/o del Sito. 
									</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
				<p>
					<b>
						L’Acquirente dichiara di aver letto attentamente il Contratto, di averne compreso il contenuto e di accettarlo integralmente. 
					</b>
				</p>
				<p/>
				<p>
					Ai fini e per gli effetti degli articoli 1341 e 1342 c.c., il Venditore dichiara di approvare specificamente le seguenti clausole del Contratto: A.3.3 (variazioni informazioni Acquirente non comunicate a Stones Business S.r.l. 
					non opponibili); A.5.2 (estraneità Stones Business S.r.l. rapporto contrattuale tra Venditore Acquirente); A.6.2 (sospensione dell’account dell’Acquirente); A.6.3 (esclusione di responsabilità di Stones Business S.r.l. per 
					danni derivanti all’Acquirente durante il periodo di Sospensione del Servizio e rinuncia dell’Acquirente a ogni relativa azione); A.6.4 (esclusiva responsabilità dell’Acquirente delle sue condotte verso terzi all’interno della 
					Piattaforma); A.7.3 (esclusione di responsabilità Stones Business S.r.l. in caso di sospensione account Venditore); A.8.1 (esclusione responsabilità Stone Business S.r.l. in caso di chiusura account Venditore); A.9.1, 
					(sospensione account Acquirente); A.9.3 (diritto di recesso di Stones Business S.r.l. con e senza preavviso); A.9.4 (assenza conseguenze recesso di Stones Business S.r.l.); A.11.4 (limitazione divulgazione informazioni 
					contrattuali a terzi); A.13.1 (informazioni veritiere fornite dall’Acquirente e manleva); A.14.1 (esclusione responsabilità Stones Business S.r.l. per interruzioni /guasti /malfunzionamenti /errori Piattaforma); A.14.2 
					(sospensione/interruzione/rimozione/modifiche Piattaforma assenza responsabilità); A.15.3 (modifiche alle condizioni e ai termini del Contratto); A.17.1 (foro competente); B.1.6 (violazione delle regole di registrazione alla 
					Piattaforma e obblighi risarcitori dell’Acquirente); B.3.2 (esclusiva responsabilità Acquirente per uso Piattaforma); B.3.3 (sospensione o ritardo nell’accesso alla Piattaforma o nella visualizzazione delle offerte create dal 
					Venditore); B.4. (facoltà di Stones Business S.r.l. di interruzione, modifica, sospensione della operatività della Piattaforma Transazionale). 
				</p>
				<b>
					L’Acquirente dichiara di aver letto attentamente il paragrafo precedente, di averne compreso il contenuto e di accettarlo integralmente. 
				</b>
			</div>
		)
	}
}

TermsAndConditions.propTypes = {
	classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(TermsAndConditions);
 

