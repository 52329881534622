export const storeToken = (jwt) => {
	localStorage.setItem('jwt', jwt);
};

export const getToken = () => {
	return localStorage.getItem('jwt');
};

export const removeToken = () => {
	localStorage.removeItem('jwt');
};
