import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
//import List from 'Routes/Stone/List';
import View from 'Routes/Material/View';
import ErrorPage from 'Routes/Errors/404';


class Router extends Component {

	render() {
		const { t, body } = this.props;
		return (
			<Switch>
				<Route exact path="/material/:permalink" render={(props)=>{ return <View t={t} body={body} {...props} />}}/>
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}

Router.propTypes ={
	t: PropTypes.func,
	body: PropTypes.object
};

export default Router;