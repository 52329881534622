import { connect } from 'react-redux';
import ThemeProvider from 'App/components/ThemeProvider';

const mapStateToProps = (state) => {
	return {
		color: state.getIn(['ui', 'color'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ThemeProvider)