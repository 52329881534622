export default theme => ({
	icon:{
		...theme.flex.container,
		...theme.flex.centerCenter,
		width: "24px",
		height: "auto",
		fill: "inherit",
		display: "inline-block",
		"&.long":{
			width: "32px",
		},
		"&.is-small":{
			width: "12px"
		},
		"&.is-big":{
			width: "48px"
		},
		"& svg": {
			display: "block"
		},
		"&.is-spin":{
			animation: "spin 2s infinite linear"
		}
	}
});