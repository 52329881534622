import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import DropDownMenu from './DropDownMenu';

const styles = theme => ({
	root: {
		...theme.flex.elemNone,
		...theme.flex.container
	},
	navLink: {
		position: "relative",
		...theme.flex.elemNone,
		...theme.flex.container,
		...theme.flex.centerCenter,
		textDecoration: 'none',
		padding: '0 16px',
		lineHeight: '60px',
		color: theme.colors.navbar.item.regular,
		fill: theme.colors.navbar.item.regular,
		fontSize: theme.text.default,
		"& .info":{
			display: 'block',
			position: "absolute",
			bottom: "10px",
			left: "0px",
			fontSize: "9px",
			textAlign: "center",
			lineHeight: "20px",
			backgroundColor: theme.colors.navbar.item.regular,
			color: theme.colors.text.default.regular,
			borderRadius: "20px",
			height: "20px",
			width: "20px"
		},
		"& span:last-child:not(:first-child)":{
			marginLeft: "0.5em"
		},
		'&:hover':{
			color: theme.colors.navbar.item.hover,
			fill: theme.colors.navbar.item.hover
		},
		'&.active':{
			color: theme.colors.navbar.item.active,
			fill: theme.colors.navbar.item.active
		}
	},
	navButton: {
		...theme.flex.elemNone,
		padding: '0 16px',
		lineHeight: '60px',
		fontSize: theme.text.default,
		...theme.flex.container,
		...theme.flex.centerCenter
	}
});

class Nav extends Component{
	render(){
		const { items, classes } = this.props;
		let navItems = [];
		if(items){
			for(let i = 0; i < items.length; i++){
				let item = items[i];
				if(item.type === 'link'){
					navItems.push(
						<NavLink exact to={item.url} className={classes.navLink} title={item.title} key={i}>
							<span>{item.name}</span>
							{ item.icon && <item.icon /> }
							{ item.info > 0 && <span className="info">{item.info}</span> }
						</NavLink>
					);
				}else if(item.type === "dropDown"){
					navItems.push(
						<DropDownMenu items={item.items} name={item.name} key={i}/>
					)
				}else if(item.type === "button"){
					navItems.push(
						<div className={classes.navButton} key={i}>
							<Button href={item.url} text={item.name} Icon={item.icon} />
						</div>
					);
				}
			}
		}
		return (
			<nav className={classes.root}>
				{ navItems }
			</nav>
		);	
	}
}
Nav.propTypes ={
	classes: PropTypes.object.isRequired,
	items: PropTypes.array
};
export default injectSheet(styles)(Nav);