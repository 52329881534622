import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class MinusIcon extends PureComponent{
	render(){
		const { classes, isSmall } = this.props;
		let iconClass = classes.icon;
		if(isSmall) iconClass += " is-small";
		return (
			<span className={iconClass}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/></svg>
			</span>
		)
	}
}
MinusIcon.propTypes ={
	classes: PropTypes.object,
	isSmall: PropTypes.bool
};

export default injectSheet(style)(MinusIcon);