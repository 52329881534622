export default {
	"category":{
		"type":{
			"Granite": "Granito",
			"Limestone": "Pietra Calcarea",
			"Marble": "Marmo",
			"Onyx": "Onice",
			"Quartzite": "Quarzite",
			"Stone": "Pietra",
			"Travertine": "Travertino",
			"Ceramic": "Ceramica"
		}
	},
	"errors":{
		"required": "necessario",
		"userNotFound": "L'utente non esiste",
		"wrongPassword": "Password errata",
		"invalidEmail": "Email non valida",
		"noMatchPassword": "Le password non coincidiono",
		"userAlreadyExists": "un utente con la stessa mail è già registrato"
	},
	"login":{
		"title": "Accedi",
		"login": "Accedi",
		"signup": "Iscriviti",
		"email": "Email",
		"password": "Password",
		"enter": "Entra",
		"forgotPassword": "Password dimenticata",
		"recoverPassword": "Recupera password",
		"recoverPasswordAnswer": "Abbiamo inviato una mail al tuo indirizzo, segui le istruzioni.",
		"changePassword": "Crea una nuova passoword",
		"passwordChanged": "Password modificata correttamente"
	},
	"material":{
	},
	"navbar":{
		"enter": "entra"
	},
	"signup":{
		"title": "Iscriviti",
		"login": "Accedi",
		"signup": "Iscriviti",
		"email": "Email",
		"password": "Password",
		"passwordB": "Conferma la tua password",
		"signin": "Registrati",
		"readAndAccepted": "ho letto e accettato",
		"privacyPolicy": "informativa sulla privacy",
		"termsAndConditions": "le disposizioni generali, termini e condizioni di utilizzo",
		"mailingList": "Accetto di ricevere newsletter con informazioni commerciali e promozionali relative al servizio (facoltativa)"
	},
	"stone":{
		"name": "Nome",
		"shape": "Forma",
		"category": "Tipo di materiale",
		"material": "Materiale",
		"height": "Altezza",
		"width": "Larghezza",
		"depth": "Profondità",
		"description": "Descrizione",
		"thickness": "Spessore",
		"weight": "Peso",
		"coverArea": "Copertura",
		"quantity": "Quantità",
		"where": "Dove",
		"shapeType":{
			"shapeless": "informe",
			"block": "blocco",
			"slab": "lastra",
			"tile": "marmette"
		},
		"search":{
			"title": "Trova i materiali",
			"minHeight": "Altezza minima",
			"maxHeight": "Altezza massima",
			"minWidth": "Larghezza minima",
			"maxWidth": "Larghezza massima",
			"minDepth": "Profondità minima",
			"maxDepth": "Profondità massima",
			"minThickness": "Spessore minimo",
			"maxThickness": "Spessore massimo",
			"minWeight": "Peso minimo",
			"maxWeight": "Peso massimo",
			"minCoverArea": "Area di copertura minima",
			"maxCoverArea": "Area di copertura massima",
			"search": "cerca",
			"nearMe": "intorno a me",
			"noResults": "nessun risultato"
		},
		"list":{
			"title": "Le mie inserzioni",
			"add": "Aggiungi",
			"images": "Immagini",
			"modify": "Modifica",
			"view": "Visualizza",
			"delete": "Elimina",
			"search": "Cerca",
			"close": "Chudi"
		},
		"add":{	
			"addImages": "Aggiungi una o più immagini",
			"where": "Dove si trova?",
			"save": "Salva",
			"cancel": "Annulla"
		},
		"delete":{	
			"title": "Rimuovi l'inserzione",
			"text": "Sei sicuro di voler elimiare l'inserzione?",
			"delete": "Elimina",
			"cancel": "Annulla"
		},
		"update":{
			"where": "Dove si trova?",
			"save": "Salva",
			"cancel": "Annulla"
		},
		"images":{
			"addImages": "Aggiungi una o più immagini",
			"add": "Aggiungi",
			"cancel": "Annulla"
		}
	},	
	"user":{
		"complete":'completa profilo',
		"kind": "tipo di utente",
		"company": "Società",
		"pro": "Libero Professionista",
		"private": "Privato",
		"firstName": "Nome",
		"lastName": "Cognome",
		"companyName": "Nome della Società",
		"telephone": "Telefono",
		"email": "Email",
		"taxCode": "Partita IVA",
		"update":{
			"save": "Salva",
			"cancel": "Annulla"
		},
		"images":{
			"addImages": "Aggiungi una o più immagini",
			"add": "Aggiungi",
			"cancel": "Annulla"
		}		
	},
	"address":{
		"country":"Paese",
		"state": "Provincia / Stato / Regione",
		"city": "Città",
		"address": "indirizzo",
		"postalCode": "C.A.P. / Codice Postale"
	},
	"message":{
		"call": "Accedi per inviare un messaggio al venditore",
		"label": "Invia il tuo messaggio",
		"send": "invia",
		"sent": "messaggio inviato",
		"empty": "Nessun messaggio"
	},
	"warehouse":{
		"warehouse": "deposito",
		"where": "Dove",
		"managePhotos": "gestisci foto",
		"watchPhotos": "guarda foto",
		"name": "nome",
		"description": "descrizione",
		"dimensions": "dimensioni area espositiva (m2)",
		"totalRows": "file totali",
		"freeRows": "file disponibili",
		"email": "indirizzo e-mail",
		"phone": "numero di telefono",
		"website": "sito internet",
		"loading": "carico e scarico",
		"transport": "trasporto",
		"weight": "pesa",
		"weightVGM": "pesa VGM",
		"water": "acqua disponibile",
		"turn": "gira blocchi",
		"overheadCrane": "carroponte",
		"crane": "semovente",
		"cutting": "taglio",
		"polishing": "lucidatura",
		"dressing": "scapezzatura",
		"uncovered": "deposito scoperto",
		"covered": "deposito coperto",
		"update": {
			"where": "dove si trova?"
		},
		"search": {
			"title": "Cerca deposito",
			"search": "cerca",
			"nearMe": "intorno a me",
			"noResults": "nessun risultato"
		},
		"addPhotos": "aggiungi",
		"close": "chiudi"
	}
}