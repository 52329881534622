import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, Submit } from 'UI/Form';

const styles = theme => ({
	forgot: {
		textAlign:'right',
		fontSize: theme.text.small
	}
});

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('email')) {
		errors.email = t('errors.required');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('email'))) {
		errors.email = t('errors.invalidEmail');
	}
	if (!values.get('password')) {
		errors.password = t('errors.required');
	}
	return errors
}

class LoginForm extends Component{
	render(){
		const { classes, handleSubmit, authError, t } = this.props;
		let userError, passwordError;
		if(authError && authError.message === 'user does not exist')
			userError = t('errors.userNotFound');
		if(authError && authError.message === 'wrong password')
			passwordError = t('errors.wrongPassword');
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="email"
					type="email"
					label={t('login.email')}
					component={Input}
					asyncerror={userError}
				/>
				<Field
					name="password"
					type="password"
					label={t('login.password')}
					component={Input}
					asyncerror={passwordError}
				/>
				<div className={classes.forgot} ><Link to="/auth/recoverPassword">{ t('login.forgotPassword')}</Link></div>
				<Submit text={t('login.enter')}/>
			</form>
		);
	}
}
LoginForm.propTypes = {
	...propTypes,
	classes: PropTypes.object.isRequired,
	authError: PropTypes.object,
	t: PropTypes.func
};

export default reduxForm({
	form:'loginForm',
	validate
})(injectSheet(styles)(LoginForm));