import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import style from '../jss';

class Switch extends PureComponent{
	constructor(props){
		super(props);
		this.state = { 
			active: false
		}
	}
	toggle = () => {
		const { active } = this.state;
		const { input: {onChange} } = this.props;
		if(onChange) onChange(!active);
		this.setState({
			active: !active
		});
	}
	componentDidUpdate(prevProps){
		const { input: {value} } = this.props;
		if(value !== prevProps.input.value){
			this.setState({
				active: value
			})
		}
	}
	render(){
		const { classes, icon, input, label } = this.props;
		const { active } = this.state;
		return (
			<div className={classNames(classes.field, 'inline')}>
				{ icon && 
					<span className={classes.switchIcon}>
						{ icon }
					</span>
				}
				{ label && <label className={classes.label} htmlFor={input.name}>{ label }</label> }
				<div className={classes.separator} />
				<div className={ classNames(classes.switch, {'active': active}) } onClick={this.toggle}>
					<div className={ classNames(classes.switchBtn, {'active': active}) } />
				</div>
			</div>
		)
	}
}

Switch.propTypes = {
	classes: PropTypes.object,
	input: PropTypes.object,
	icon: PropTypes.element,
	label: PropTypes.string,
};

export default injectSheet(style)(Switch);