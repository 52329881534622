import React, { Component } from 'react';
import { ThemeProvider } from 'react-jss';
import App from '../container';
import { defaultTheme, redTheme, orangeTheme, greenTheme, yellowTheme, pinkTheme, blueTheme } from 'themes';

class Theme extends Component {
	render(){
		const { color } = this.props;
		let theme = defaultTheme;
		if(color === 'red') theme = redTheme;
		if(color === 'orange') theme = orangeTheme;
		if(color === 'green') theme = greenTheme;
		if(color === 'yellow') theme = yellowTheme;
		if(color === 'pink') theme = pinkTheme;
		if(color === 'blue') theme = blueTheme;
		return (
			<ThemeProvider theme={theme}>
				<App/>
			</ThemeProvider>
		);
	}
};

export default Theme;