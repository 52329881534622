import { connect } from 'react-redux';
import { get, update } from 'state/actions/me';
import Update from './components';

const mapStateToProps = (state) => {
	return {
		me: state.get('me'),
		lastAction: state.getIn(['ui','lastAction']),
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: () => {
			dispatch(get());
		},
		update: (me) => {
			dispatch(update(me));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Update)