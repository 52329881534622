import { connect } from 'react-redux';
import { get, empty } from 'state/actions/stone';
import { get as getUser } from 'state/actions/user';
import { addClick } from 'state/actions/analytics/stone';
import { startChat } from 'state/actions/message';
import { showNotification, clearNotification } from 'state/actions/ui';
import View from './components';

const mapStateToProps = (state) => {
	return {
		stones: state.getIn(['stone', 'stones']),
		users: state.getIn(['user', 'users']),
		stats: state.getIn(['analytics', 'stone']),
		isLogged: state.getIn(['auth' ,'isLogged']),
		error: state.getIn(['ui', 'error']),
		lastAction: state.getIn(['ui','lastAction'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		empty: () => {
			dispatch(empty());
		},
		getUser:(userId) => {
			dispatch(getUser(userId));
		},
		addClick:(id) => {
			dispatch(addClick(id));
		},
		startChat: (to, message) => {
			dispatch(startChat(to, message));
		},
		showNotification: (message) =>{
			dispatch(clearNotification());
			dispatch(showNotification(message));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(View)