import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Pagination from 'UI/Pagination';
import ListActionBar from './ListActionBar';
import EmptyResults from './EmptyResults';
import DeleteModal from './Modals/Delete';
import InfoModal from './Modals/Info';
import List from './List';

const styles = theme => ({
	stoneList:{
		...theme.layout.container,
		...theme.layout.section,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
	},
	title:{
		...theme.text.title3,
		marginBottom: '16px'
	}
});

class StoneList extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedStoneId: null,
			isRemoveModalOpen: false,
			isInfoModalOpen: false,
			infoText: null
		}
	}
	changePage = (page) => {
		const { history } = this.props;
		const { location:{ search }} = this.props;
		const params = new URLSearchParams(search);
		params.set('page', page);
		history.push({
		 	search: '?'+params.toString()
		});
	}
	reorder = (orderBy, order) => {
		const { history } = this.props;
		const { location:{ search }} = this.props;
		const params = new URLSearchParams(search);
		params.set('orderBy', orderBy);
		params.set('order', order);
		params.set('page', 1);
		history.push({
		 	search: '?'+params.toString()
		});
	}
	onSearch = (values) => {
		const { history } = this.props;
		const { location:{ search }} = this.props;
		const searchText = values.get('search');
		const params = new URLSearchParams(search);
		params.set('orderBy', 'modifiedAt');
		params.set('order', 'asc');
		params.set('page', 1);
		if(searchText) params.set('search', searchText);
		if(!searchText) params.delete('search');
		history.push({
		 	search: '?'+params.toString()
		});
	}
	getAll = (orderBy, order, perPage, page) => {
		const { getAll } = this.props;
		getAll(orderBy, order, perPage, page);
	}
	remove = (id) => {
		const { remove } = this.props;
		if(id) remove(id);	
	}
	edit = (id) => {
		const { history } = this.props;
		if(id) history.push(`/stone/edit/${id}`);
	}
	managePhotos = (id) => {
		const { history } = this.props;
		if(id) history.push(`/stone/photos/${id}`);
	}
	select = (id) => {
		const { history } = this.props;
		if(id) history.push(`/stone/dealer-view/${id}`);
	}
	openRemoveModal = (id) => {
		this.setState({
			isRemoveModalOpen: true,
			selectedStoneId: id
		});
	}
	closeRemoveModal = () => {
		this.setState({
			isRemoveModalOpen: false,
			selectedStoneId: null
		});
	}
	openInfoModal = (text) => {
		this.setState({
			isInfoModalOpen: true,
			infoText: text
		});
	}
	closeInfoModal = () => {
		this.setState({
			isInfoModalOpen: false,
			infoText: null
		});
	}
	confirmRemove = () => {
		const { selectedStoneId } = this.state;
		this.remove(selectedStoneId);
		this.closeRemoveModal();
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	componentDidMount(){
		const { location:{ search }} = this.props;
		const params = new URLSearchParams(search);
		const orderBy = params.get('orderBy') || 'modifiedAt';
		const order = params.get('order') || 'desc';
		const perPage = params.get('perPage') || 10;
		const page = params.get('page') || 1;
		this.empty();
		this.getAll(orderBy, order, perPage, page);
	}
	componentDidUpdate(prevProps){
		const { location:{ search } } = this.props;
		if(prevProps.location.search !== search){
			const params = new URLSearchParams(search);
			const orderBy = params.get('orderBy') || 'modifiedAt';
			const order = params.get('order') || 'desc';
			const perPage = params.get('perPage') || 10;
			const page = params.get('page') || 1;
			this.empty();
			this.getAll(orderBy, order, perPage, page);	
		}
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, stones, page, perPage, total, t } = this.props;
		const { isRemoveModalOpen, isInfoModalOpen, infoText } = this.state;
		return (
			<div className={classes.stoneList}>
				<h1 className={classes.title}>{t('stone.list.title')}</h1>
				<ListActionBar t={t}/>
				{ total === 0 && <EmptyResults t={t}/> }
				<List stones={stones} t={t} onRemove={this.openRemoveModal} onEdit={this.edit} onManagePhotos={this.managePhotos} onSelect={this.select} onShowInfo={this.openInfoModal} />
				{ total > 0 && <Pagination page={page} perPage={perPage} total={total} onChange={this.changePage} /> }
				<DeleteModal t={t} isOpen={isRemoveModalOpen} onClose={this.closeRemoveModal} onRemove={this.confirmRemove}/>
				<InfoModal t={t} isOpen={isInfoModalOpen} onClose={this.closeInfoModal} text={infoText} />
			</div>
		);
	}
}
StoneList.propTypes ={
	classes: PropTypes.object.isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	getAll: PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};
export default injectSheet(styles)(StoneList);