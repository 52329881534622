import { connect } from 'react-redux';
import { get, empty } from 'state/actions/category';
import { getAllByCategory, empty as emptyStones } from 'state/actions/stone';
import { addImpression as addStoneImpression } from 'state/actions/analytics/stone';
import View from './components';

const mapStateToProps = (state) => {
	return {
		categories: state.getIn(['category','categories']),
		stones: state.getIn(['stone', 'stones']),
		page: state.getIn(['stone','page']),
		perPage: state.getIn(['stone','perPage']),
		total: state.getIn(['stone', 'total']),
		lastAction: state.getIn(['ui', 'lastAction']),
		error: state.getIn(['ui', 'error'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		getAllByCategory:(categoryId, orderBy, order, perPage, page) => {
			dispatch(getAllByCategory(categoryId, orderBy, order, perPage, page));
		},
		emptyStones: () => {
			dispatch(emptyStones());
		},
		addStoneImpression: (id) => {
			dispatch(addStoneImpression(id));
		},
		empty: () => {
			dispatch(emptyStones());
			dispatch(empty());
		}
	}
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(View)