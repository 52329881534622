import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Trash } from 'UI/Icon';
import Progress from 'UI/Progress';

const styles = theme => ({
	root:{
		position: 'relative',
		height: "150px",
		margin:".5em",
		boxShadow: theme.shadows.s1,
		"& img":{
			height: "100%"
		}
	},
	delete:{ 
		cursor: 'pointer',
		position: 'absolute',
		top: '5px',
		right: '5px',
		fill: theme.colors.text.default.regular
	},
	obfuscator:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		right: 0,
		top: 0,
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	progressContainer:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		padding: '.5rem',
		right: 0,
		top: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
});

class Preview extends Component{
	constructor(props){
		super(props);
		this.state = {
			src:""
		}
	}
	componentDidMount(){
		const { file } = this.props;
		var reader  = new FileReader();
		reader.addEventListener("load", () => {
			this.setState({
				src: reader.result
			})
		}, false);
		reader.readAsDataURL(file);
	}
	componentDidUpdate(prevProps){
		const { file } = this.props;
		if(file !== prevProps.file){
			var reader  = new FileReader();
			reader.addEventListener("load", () => {
				this.setState({
					src: reader.result
				})
			}, false);
			reader.readAsDataURL(file);
		}
	}
	canc = (file) => {
		const { onDelete } = this.props;
		if(onDelete) onDelete(file);
	} 
	render(){
		const { classes, file, isUploading, progress } = this.props;
		const { src } = this.state;
		return (
			<div className={classes.root}>
				<img alt={file.name} src={src} />
				<div className={classes.delete} onClick={() => {this.canc(file)}}><Trash isSmall={true} /> </div>
				{ isUploading && <div className={classes.obfuscator}></div> }
				{ isUploading &&
					<div className={classes.progressContainer}>
						<Progress progress={progress} />
					</div>
				}
			</div>
		);
	}
}
Preview.propTypes = {
	classes: PropTypes.object.isRequired,
	file: PropTypes.instanceOf(File).isRequired,
	progress: PropTypes.number,
	isUploading: PropTypes.bool
};

export default injectSheet(styles)(Preview);