import {
	MESSAGE_GET,
	MESSAGE_LIST,
	MESSAGE_TRANSLATE,
	MESSAGE_EMPTY,
	MESSAGE_SET_TIMEOUT
} from "state/actions/message";
import { OrderedMap, fromJS, Map } from 'immutable'

const initState = fromJS({
	page:0,
	perPage:0,
	total:0,
	messages: OrderedMap({}),
	translations: Map({}),
	timeout: null
});

const message = ( state = initState, action ) => {
	switch(action.type){
		case MESSAGE_GET: {
			let reversedMessages = state.get('messages').reverse();
			let messages = reversedMessages.set(action.message._id, fromJS(action.message));
			return state.set('messages', messages.reverse());
		}
		case MESSAGE_LIST: {
			let _messages = [];
			for(let i = 0; i < action.messages.length; i++){
				let _message = [ action.messages[i]._id, fromJS(action.messages[i])];
				_messages.push(_message);
			}
			return state.merge({
				page: action.page,
				perPage: action.perPage,
				results: action.results,
				messages: OrderedMap(_messages),
			});
		}
		case MESSAGE_TRANSLATE: {
			const { translation: { _id, text } } = action;
			return state.setIn(['translations', _id], text);
		}
		case MESSAGE_SET_TIMEOUT:{
			const { timeout } = action;
			return state.merge({
				timeout
			});
		}
		case MESSAGE_EMPTY:{
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default message;