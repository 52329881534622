import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { LogoDark } from 'Elements/Logo'

const styles = theme => ({
	root: {
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		height: '100%'
	},
	logo: {
		marginBottom: '16px'
	},
	title:{
		...theme.text.title4,
		marginBottom: '16px'
	},
	text: {
		padding: "8px"
	}
});

class StonesBusiness extends Component {
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.logo}><LogoDark isBig={true} /></div>
				<h2 className={classes.title}>Stones Business S.R.L.</h2>
				<p className={classes.text}>Via Fivizzano 15, 54033 Carrara (MS)</p>
				<p className={classes.text}>Codice Fiscale / Partita IVA 01398350452 </p>
				<p className={classes.text}>Cap. Sociale € 12.000,00 </p>
				<p className={classes.text}>REA MS – 137871</p>
				<p className={classes.text}>e-mail: <a href="mailto:info@stonesbusiness.com">info@stonesbusiness.com</a></p>
			</div>
		);
	}
}

StonesBusiness.propTypes = {
	classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(StonesBusiness);