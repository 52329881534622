const text = {
	fontFamily: "'Comfortaa', monospace",
	default: "1rem",
	small: "0.8rem",
	big: "1.2rem",
	title1: {
		fontSize: "3rem",
		margin:".2em 0"
	},
	title2: {
		fontSize: "2.5rem",
		margin:".2em 0"
	},
	title3: {
		fontSize: "2rem",
		margin:".2em 0"
	},
	title4: {
		fontSize: "1.5rem",
		margin:".2em 0"
	},
	title5: {
		fontSize: "1.25rem",
		margin:".2em 0"
	}
};

export default text;