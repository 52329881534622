import { connect } from 'react-redux';
import { signup } from 'state/actions/auth';
import Signup from './components';

const mapStateToProps = (state) => {
	return {
		error: state.getIn(['ui','error'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		signup:(email, passwordA, passwordB) => {
			dispatch(signup(email, passwordA, passwordB));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Signup)