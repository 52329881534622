import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { withTranslation } from 'react-i18next';
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import EmptyResults from "./EmptyResults";
import Pagination from "UI/Pagination";
import RankingBox from 'Elements/User/RankingBox';

const styles = theme => ({
	stoneSearch:{
		...theme.layout.container,
		...theme.layout.section,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
		paddingTop: 0
	}
});

class StoneSearch extends Component {
	constructor(props){
		super(props);
		this.state = {
			categoryId: undefined,
			searched: false
		};
		this.elem = React.createRef();
		this.isNewSearch = false;
	}
	select = (permalink) => {
		const { history } = this.props;
		if(history) history.push('/stone/' + permalink);
	}
	addImpression = (id) => {
		const { addImpression } = this.props;
		if(id) addImpression(id);
	}
	startScroll = () => {
		const { body } = this.props;
		let node = this.elem.current;
		this._animationStart = null;
		let dim = node.getBoundingClientRect();
		this._currentScroll = body.scrollTop;
		this._offset = this._currentScroll + dim.top - 60;
		if(this._offset > 0){
			window.requestAnimationFrame(this.scroll);
		}
	}
	scroll = (timestamp) => {
		const { body } = this.props;
		if(!this._animationStart) this._animationStart = timestamp;
		let t = timestamp - this._animationStart;
		let scroll = t*(this._offset - this._currentScroll)/500 + this._currentScroll;
		if(t <= 500){
			body.scrollTop = scroll;
			window.requestAnimationFrame(this.scroll);
		}else{
			body.scrollTop =this._offset;
		}
	}
	prepareSearch = (values) => {
		const { history, location:{ search } } = this.props;
		const shape = values.get('shape');
		const categoryId= values.get('categoryId');
		const materialId = values.get('materialId');
		const text = values.get('text');
		const minWeight = values.get('minWeight');
		const maxWeight = values.get('maxWeight');
		const minHeight = values.get('minHeight');
		const maxHeight = values.get('maxHeight');
		const minWidth = values.get('minWidth');
		const maxWidth = values.get('maxWidth');
		const minDepth = values.get('minDepth');
		const maxDepth = values.get('maxDepth');
		const minCoverArea = values.get('minCoverArea');
		const maxCoverArea = values.get('maxCoverArea');
		const geoloc = values.get('geoloc');
		const params = new URLSearchParams(search);
		params.set('orderBy', 'createdAt');
		params.set('order', 'desc');
		params.set('page', 1);
		params.set('perPage', 10);
		if(shape){
			params.set('shape', shape);
		}else{
			params.delete('shape');
		}
		if(categoryId){
			params.set('categoryId', categoryId);
		}else{
			params.delete('categoryId');
		}
		if(materialId){
			params.set('materialId', materialId);
		}else{
			params.delete('materialId');
		}
		if(text){
			params.set('text', text);
		}else{
			params.delete('text');
		}
		if(minWeight){
			params.set('minWeight', minWeight);
		}else{
			params.delete('minWeight');
		}
		if(maxWeight){
			params.set('maxWeight', maxWeight);
		}else{
			params.delete('maxWeight');
		}
		if(minHeight){
			params.set('minHeight', minHeight);
		}else{
			params.delete('minHeight');
		}
		if(maxHeight){
			params.set('maxHeight', maxHeight);
		}else{
			params.delete('maxHeight');
		}
		if(minWidth){
			params.set('minWidth', minWidth);
		}else{
			params.delete('minWidth');
		}
		if(maxWidth){
			params.set('maxWidth', maxWidth);
		}else{
			params.delete('maxWidth');
		}
		if(minDepth){
			params.set('minDepth', minDepth);
		}else{
			params.delete('minDepth');
		}
		if(maxDepth){
			params.set('maxDepth', maxDepth);
		}else{
			params.delete('maxDepth');
		}
		if(minCoverArea){
			params.set('minCoverArea', minCoverArea);
		}else{
			params.delete('minCoverArea');
		}
		if(maxCoverArea){
			params.set('maxCoverArea', maxCoverArea);
		}else{
			params.delete('maxCoverArea');
		}
		if(geoloc){
			params.set('lat', geoloc.coordinates[1]);
			params.set('long', geoloc.coordinates[0]);
		}else{
			params.delete('lat');
			params.delete('long');
		}
		history.push({
			search: '?'+params.toString()
		});
		this.isNewSearch = true;
	}
	changePage = (page) => {
		const { history, location:{ search } } = this.props;
		const params = new URLSearchParams(search);
		params.set('page', page);
		history.push({
			search: '?'+params.toString()
		});
		this.isNewSearch = true;
	}
	search = (shape, categoryId, materialId, text, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, orderBy, order, perPage, page) => {
		const { search } = this.props;
		if(search) search(shape, categoryId, materialId, text, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, orderBy, order, perPage, page)
	}
	setCategory = (categoryId) => {
		this.setState({
			categoryId: categoryId
		});
	}
	getCategories = () => {
		const { getCategories } = this.props;
		getCategories();
	}
	getMaterial = (materialId) => {
		const { getMaterial } = this.props;
		if(materialId) getMaterial(materialId);
	}
	searchMaterial = (text) => {
		const { searchMaterial } = this.props;
		const { categoryId } = this.state;
		searchMaterial(text, categoryId, 5);
	}
	empty  = () => {
		const { empty } = this.props;
		empty();
	}
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	componentDidMount(){
		const { location:{ search } } = this.props;
		const params = new URLSearchParams(search);
		const orderBy = params.get('orderBy') || 'createdAt';
		const order = params.get('order') || 'desc';
		const perPage = params.get('perPage') || 10;
		const page = params.get('page') || 1;
		const shape = params.get('shape');
		const categoryId = params.get('categoryId');
		const materialId = params.get('materialId');
		const text = params.get('text');
		const minWeight = params.get('minWeight');
		const maxWeight = params.get('maxWeight');
		const minHeight = params.get('minHeight');
		const maxHeight = params.get('maxHeight');
		const minWidth = params.get('minWidth');
		const maxWidth = params.get('maxWidth');
		const minDepth = params.get('minDepth');
		const maxDepth = params.get('maxDepth');
		const minCoverArea = params.get('minCoverArea');
		const maxCoverArea = params.get('maxCoverArea');
		const lat = params.get('lat');
		const lng = params.get('long');
		this.getCategories();
		this.getMaterial(materialId);
		this.empty();
		this.search(shape, categoryId, materialId, text, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, orderBy, order, perPage, page)
	}
	componentDidUpdate(prevProps){
		const { location:{ search }, stones, lastAction } = this.props;
		if(lastAction!== prevProps.lastAction && lastAction === "stone_search_done") this.setState({searched: true});
		if(prevProps.location.search !== search){
			const params = new URLSearchParams(search);
			const orderBy = params.get('orderBy') || 'createdAt';
			const order = params.get('order') || 'desc';
			const perPage = params.get('perPage') || 10;
			const page = params.get('page') || 1;
			const shape = params.get('shape');
			const categoryId = params.get('categoryId');
			const materialId = params.get('materialId');
			const text = params.get('text');
			const minWeight = params.get('minWeight');
			const maxWeight = params.get('maxWeight');
			const minHeight = params.get('minHeight');
			const maxHeight = params.get('maxHeight');
			const minWidth = params.get('minWidth');
			const maxWidth = params.get('maxWidth');
			const minDepth = params.get('minDepth');
			const maxDepth = params.get('maxDepth');
			const minCoverArea = params.get('minCoverArea');
			const maxCoverArea = params.get('maxCoverArea');
			const lat = params.get('lat');
			const lng = params.get('long');
			this.empty();
			this.search(shape, categoryId, materialId, text, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, orderBy, order, perPage, page)
		}
		if(stones && !stones.equals(prevProps.stones) && this.isNewSearch){
			this.startScroll();
		}
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { t, classes, categories, materials, stones, location:{ search }, screen, page, perPage, results } = this.props;
		const { searched } = this.state;
		const params = new URLSearchParams(search);
		const initialSearch = {
			shape: params.get('shape'),
			categoryId: params.get('categoryId'),
			materialId: params.get('materialId'),
			text: params.get('text'),
			dealerId: params.get('dealerId'),
			status: params.get('status'),
			minWeight: params.get('minWeight'),
			maxWeight: params.get('maxWeight'),
			minHeight: params.get('minHeight'),
			maxHeight: params.get('maxHeight'),
			minWidth: params.get('minWidth'),
			maxWidth: params.get('maxWidth'),
			minDepth: params.get('minDepth'),
			maxDepth: params.get('maxDepth'),
			minCoverArea: params.get('minCoverArea'),
			maxCoverArea: params.get('maxCoverArea'),
			geoloc: params.get('geoloc'),
			distance: params.get('distance')
		}
		let categoryItems = [];
		categories.forEach( category => {
			categoryItems.push(
				{
					value: category.get('_id'),
					name: t('category.type.' + category.get('name'))
				}
			);
		});
		let materialItems = [];
		materials.forEach( material => {
			materialItems.push(
				{
					value: material.get('_id'),
					name: material.get('name')
				}
			);
		})
		let initialMaterial;
		if(materials){
			initialMaterial = materials.getIn([initialSearch.materialId,'name']);
		}
		return (
			<div className={classes.stoneSearch}>
				<SearchForm t={t} screen={screen} onSubmit={ this.prepareSearch } initialValues={initialSearch} initialMaterial={initialMaterial} categoryItems={categoryItems} materialItems={materialItems} onCancel={ this.back } onSearch={ this.searchMaterial } onSetCategory={this.setCategory}/>
				<div ref={this.elem} />
				{ (searched && results === 0) && <EmptyResults t={t}/> }
				<SearchResults t={t} stones={stones} onSelect={this.select} onAddImpression={this.addImpression} />
				{ results > 0 && <Pagination page={page} perPage={perPage} total={results} onChange={this.changePage} /> }
				<RankingBox />
			</div>
		);
	}
}

StoneSearch.propTypes = {
	classes: PropTypes.object.isRequired,
	body: PropTypes.object,
	page: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	results: PropTypes.number,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	materials: PropTypes.instanceOf(OrderedMap).isRequired,
	categories: PropTypes.instanceOf(OrderedMap).isRequired,
	screen: PropTypes.string,
	lastAction: PropTypes.string,
	t: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,
	searchMaterial: PropTypes.func.isRequired,
	getMaterial: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
	addImpression: PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired	
};

export default injectSheet(styles)(withTranslation()(StoneSearch));