import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputSearch from './InputSearch';

class GSearchBox extends Component {
	constructor(props){
		super(props);	
		this.container = React.createRef();
		this.state={
			items:[]
		}
	}
	focus = () => {
		const { onFocus } = this.props;
		if(onFocus) onFocus();
	}
	search = (value) => {
		if(this.places && value)
			this.places.textSearch({query:value}, (results)=>{
				if(results && results.length>0){
					let items = [];
					for(let i = 0; i < results.length; i++){
						let item = {
							name: results[i].formatted_address,
							value: JSON.stringify(results[i].geometry)
						}
						items.push(item);
					}
					this.setState({items:items});
				}
			});
	}
	select = (value) => {
		const { setBounds, setLocation, onSetBounds, onSetLocation } = this.props;
		if(value){
			let valueObj = JSON.parse(value);
			if(valueObj.viewport) setBounds(JSON.stringify(valueObj.viewport));
			if(valueObj.location) setLocation(JSON.stringify(valueObj.location));
			if(onSetLocation && valueObj.location) onSetLocation(valueObj.location);
			if(onSetBounds && valueObj.viewport) onSetBounds(valueObj.viewport)
		}
	}
	componentDidMount(){
		const { ready } = this.props;
		if(ready) this.places = new window.google.maps.places.PlacesService(this.container.current);
	}
	componentDidUpdate(prevProps){
		const { ready } = this.props;
		if(ready && ready !== prevProps.ready){
			this.places = new window.google.maps.places.PlacesService(this.container.current);
		}
	}
	render(){
		const { label, placeholder, name, icon, geolocalize, nearMeLabel } = this.props;
		const {items} = this.state;
		return (
			<div>
				<InputSearch onSearch={this.search} onSelect={this.select} items={items} name={name} label={label} placeholder={placeholder} onFocus={this.focus} icon={icon} geolocalize={geolocalize} nearMeLabel={nearMeLabel} />
				<div ref={this.container} />
			</div>
		);	
	}
}
GSearchBox.propTypes = {
	ready: PropTypes.bool, 
	setBounds: PropTypes.func,
	setLocation: PropTypes.func,
	onSetBounds: PropTypes.func,
	onSetLocation: PropTypes.func,
	onFocus: PropTypes.func,
	label:PropTypes.string,
	placeholder:PropTypes.string,
	name:PropTypes.string,
	icon: PropTypes.element,
	geolocalize: PropTypes.element,
	nearMeLabel: PropTypes.string
};


export default GSearchBox;