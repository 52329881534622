import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import List from './List';
import Talk from './Talk';
import injectSheet from 'react-jss';

const styles = theme => ({
	messageBox:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.columns,
		height: "100%",
		['@media ' + theme.media.mobile]: {
			paddingLeft: "0.5rem",
			paddingRight: "0.5rem",
			paddingTop:"1rem",
			paddingBottom:"0.5rem"
		},
		['@media ' + theme.media.tablet]: {
			paddingLeft: "0.5rem",
			paddingRight: "0.5rem",
			paddingTop:"1rem",
			paddingBottom:"0.5rem"
		}
	},
});

class MessageBox extends Component {
	constructor(props){
		super(props);
		this.state = {
			selected: null,
		};
	}
	componentDidMount(){
		this.listConversations();
		this.unlistenMessagesToRead();
	}
	componentDidUpdate(prevProps){
		const { lastAction, messageToReadTimeout } = this.props;
		const { selected } = this.state;
		if(lastAction !== prevProps.lastAction && lastAction === "conversation_list_done"){
			this.listenListConversations();
		}
		if(lastAction !== prevProps.lastAction && lastAction === "message_get_list_done"){
			this.listenToMessages(selected);
		}
		if(messageToReadTimeout !== prevProps.messageToReadTimeout) this.unlistenMessagesToRead();
	}
	componentWillUnmount(){
		this.emptyConversations();
		this.unselect();
		this.unlistenListConversations();
		this.listenMessagesToRead();
	}
	translateMessage = (id, conversationId) => {
		const { translateMessage } = this.props;
		const language = localStorage.getItem('i18nextLng') || 'it';
		if(!!id && !!conversationId) translateMessage(id, conversationId, language);
	}
	emptyMessagesToRead = (id) => {
		const {emptyMessagesToRead} = this.props;
		if(id) emptyMessagesToRead(id);
	}
	setRead = (id) => {
		const {setRead} = this.props;
		if(setRead && id) setRead(id);
	}
	sendMessage = (conversationId, text) => {
		const { sendMessage } = this.props;
		if(!!conversationId && !!text) sendMessage(conversationId, text);
	}
	unselect = () => {
		this.emptyMessages();
		this.unlistenToMessages();
		this.setState({
			selected: null
		});
	}
	select = (id) => {
		this.emptyMessages();
		this.unlistenToMessages();
		this.setState({
			selected: id
		});
		this.emptyMessagesToRead(id);
		this.listMessages(id);
	}
	viewProfile = (id) => {
		const { history } = this.props;
		if(history) history.push('/user/' + id);
	}
	listConversations = () => {
		const { listConversations } = this.props;
		if(listConversations) listConversations(1);
	}
	listMessages = (id) => {
		const { listMessages } = this.props;
		if(listMessages && id) listMessages(id, 1);
	}
	
	listenMessagesToRead = () => {
		const { listenMessagesToRead } = this.props;
		listenMessagesToRead(5000);
	}
	unlistenMessagesToRead = () => {
		const { unlistenMessagesToRead, messageToReadTimeout } = this.props;
		if(!!messageToReadTimeout) unlistenMessagesToRead(messageToReadTimeout);
	}
	listenListConversations = () => {
		const { listenListConversations } = this.props;
		listenListConversations(5000);
	}
	unlistenListConversations = () => {
		const { unlistenListConversations, conversationsListTimeout } = this.props;
		if(!!conversationsListTimeout) unlistenListConversations(conversationsListTimeout);
	}
	listenToMessages = (id) => {
		const { listenToMessages } = this.props;
		if(!!id) listenToMessages(id, 5000);
	}
	unlistenToMessages = () => {
		const {unlistenToMessages, messageListTimeout} = this.props;
		if(!!messageListTimeout) unlistenToMessages(messageListTimeout);
	}
	emptyConversations = () => {
		const { emptyConversations } = this.props;
		if(emptyConversations) emptyConversations();
	}
	emptyMessages = () => {
		const { emptyMessages } = this.props;
		if(emptyMessages) emptyMessages();
	}
	render(){
		const { classes, conversations, t, messages, translations, screen } = this.props;
		const { selected } = this.state;
		return (
			<div className={classes.messageBox}>
				{ ( (screen !== 'tablet' && screen !== 'mobile') || !selected ) && <List t={t} conversations={conversations} onSelect={this.select} selected={selected} /> }
				{ ( (screen !== 'tablet' && screen !== 'mobile') || selected ) && <Talk t={t} onViewProfile={this.viewProfile} conversation={conversations.get(selected)} messages={messages} send={this.sendMessage} setRead={this.setRead} translate={this.translateMessage} translations={translations} screen={screen} onUnselect={this.unselect} /> }
			</div>
		);
	}
}
MessageBox.propTypes ={
	classes: PropTypes.object.isRequired,
	listConversations: PropTypes.func.isRequired,
	emptyMessagesToRead: PropTypes.func.isRequired,
	emptyConversations: PropTypes.func.isRequired,
	sendMessage: PropTypes.func.isRequired,
	listMessages: PropTypes.func.isRequired,
	translateMessage: PropTypes.func.isRequired,
	emptyMessages: PropTypes.func.isRequired,
	listenMessagesToRead: PropTypes.func.isRequired,
	unlistenMessagesToRead: PropTypes.func.isRequired,
	listenListConversations: PropTypes.func.isRequired,
	unlistenListConversations: PropTypes.func.isRequired,
	listenToMessages: PropTypes.func.isRequired,
	unlistenToMessages: PropTypes.func.isRequired,
	messageToReadTimeout: PropTypes.number,
	conversationsListTimeout: PropTypes.number,
	messageListTimeout:  PropTypes.number,
	conversations: PropTypes.instanceOf(OrderedMap),
	messages: PropTypes.instanceOf(OrderedMap),
	translations: PropTypes.instanceOf(Map),
	t: PropTypes.func.isRequired
};
export default injectSheet(styles)(MessageBox);