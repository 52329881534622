import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, Submit, TextArea } from 'UI/Form';
import CheckBox from 'UI/CheckBox';

const validate = (values, props) => {
	const { t } = props;
	const errors = {
	}
	if(!values.get('message')){
		errors.message = t('errors.required');;
	}
	if(!values.get('name')){
		errors.name = t('errors.required');;
	}
	if(!values.get('lastname')){
		errors.lastname = t('errors.required');;
	}
	if (!values.get('email')) {
		errors.email = t('errors.required');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('email'))) {
		errors.email = t('errors.invalidEmail');
	}
	if (!values.get('policy')) {
		errors.policy = t('errors.required');
	}
	return errors
}

class ContactForm extends Component{
	render(){
		const { handleSubmit, t } = this.props;
		const policy = (
			<React.Fragment>
				<span>{t('signup.readAndAccepted')}</span><Link to="/legal/policy" target="_blank"> {t('signup.privacyPolicy')}</Link>
			</React.Fragment>
		)
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="name"
					type="text"
					label={t('user.firstName')}
					component={Input}
				/>
				<Field
					name="lastname"
					type="text"
					label={t('user.lastName')}
					component={Input}
				/>
				<Field
					name="company"
					type="text"
					label={t('user.companyName')}
					component={Input}
				/>
				<Field
					name="email"
					type="email"
					label={t('user.email')}
					component={Input}
				/>
				<Field
					name="message"
					type="text"
					label={"messaggio"}
					component={TextArea}
				/>
				<Field
					name="policy"
					label={policy}
					component={CheckBox}
				/>
				<Submit isPrimary={true} text="Invia"/>
			</form>
		);
	}
}
ContactForm.propTypes = {
	...propTypes,
	onCancel: PropTypes.func,
	t: PropTypes.func
};

export default reduxForm({
	form:'contactForm',
	validate
})(ContactForm);