import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import image from '../png/icon.png';

const styles = theme => ({
	logo:{
		...theme.flex.container,
		...theme.flex.centerCenter,
		width: "auto",
		height: "60px",
		fill: "inherit",
		display: "inline-block",
		padding: '8px 16px',
		"& img":{
			width: 'auto',
			maxHeight: '100%'
		},
		"&.isBig": {
			height: "120px",
		}
	}
});

class Logo_Icon extends Component{
	render(){
		const { classes, isBig } = this.props;
		return (
			<div className={classNames(classes.logo,{isBig})}>
				<img src={image} alt="stones business" />
			</div>
		)
	}
}

Logo_Icon.propTypes ={
	classes: PropTypes.object.isRequired,
	isBig: PropTypes.bool
};

export default injectSheet(styles)(Logo_Icon);