import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { TextArea, Submit, FieldGroup } from 'UI/Form';

const validate = (values) => {
	const errors = {
	}
	return errors
}

class MessageForm extends Component{
	submit = (values) => {
		const { reset, onSubmit } = this.props;
		if(onSubmit) onSubmit(values);
		if(reset) reset();
	}
	render(){
		const { handleSubmit, t } = this.props;
		return (
			<form onSubmit={ handleSubmit(this.submit.bind(this)) }>
				<FieldGroup isMarginless={true} >
					<Field
						name="message"
						placeholder={t('message.label')}
						component={TextArea}
						rows="2"
					/>
					<Submit isPrimary={true} text={t('message.send')} style={{height:"54px"}} />
				</FieldGroup>
			</form>
		);
	}
}
MessageForm.propTypes = {
	...propTypes,
	onCancel: PropTypes.func,
	t: PropTypes.func
};

export default reduxForm({
	form:'meMessageForm',
	validate
})(MessageForm);