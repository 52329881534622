import React, { Component } from 'react';
import { OrderedMap, Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import AddForm from "./AddForm";
import Button from "UI/Button";
import { Close } from "UI/Icon";

const styles = theme => ({
	stoneAdd:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
	},
	title:{
		...theme.text.title3,
		marginBottom: '16px'
	},
	action:{
		position: 'absolute',
		top: '.5rem',
		left: '1.5rem',
		zIndex: 10
	}
});

class StoneAdd extends Component {
	constructor(props){
		super(props);
		this.state = {
			categoryId:undefined
		}
		this.numOfImages = 0;
		this.uploadedImages = 0;
	}
	startScrollUp = () => {
		const { body } = this.props;
		this._animationStart = null;
		this._currentScroll = body.scrollTop;
		window.requestAnimationFrame(this.scrollUp);
	}
	scrollUp = (timestamp) => {
		const { body } = this.props;
		if(!this._animationStart) this._animationStart = timestamp;
		let t = timestamp - this._animationStart;
		let scroll = -t*this._currentScroll/500 + this._currentScroll;
		if (t <= 500) {
			window.requestAnimationFrame(this.scrollUp);
			body.scrollTop = scroll;
		}else{
			body.scrollTop = 0;
		}
	}
	add = (values) => {
		const { add } = this.props;
		let stone = {
			images: values.get('images'),
			name: values.get('name'),
			shape: values.get('shape'),
			category: values.get('category'),
			material: values.get('material'),
			dimensions: {
				height: values.get('height'),
				width: values.get('width'),
				depth: values.get('depth'),
			},
			description: values.get('description'),
			quantity: values.get('quantity'),
			weight: values.get('weight'),
			geoloc: values.get('geoloc')
		}
		this.startScrollUp();
		this.numOfImages = (stone.images)? stone.images.length: 0
		this.uploadedImages = 0;
		add(stone);
	}
	setCategory = (categoryId) => {
		this.setState({
			categoryId: categoryId
		});
	}
	search = (text) => {
		const { materialSearch } = this.props;
		const { categoryId } = this.state;
		if(materialSearch) materialSearch(text, categoryId, 5);
	}
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	getCategories = () => {
		const { getCategories } = this.props;
		getCategories();
	}
	componentDidMount(){
		this.getCategories();
	}
	componentDidUpdate(prevProps){
		const { lastAction } = this.props;
		if(this.numOfImages === 0){
			if(lastAction !== prevProps.lastAction && lastAction === "stone_add_done")	this.back();
		}else{
			if(lastAction && lastAction !== prevProps.lastAction && lastAction.indexOf("stone_upload_image_done")>-1){
				this.uploadedImages ++;
				if(this.uploadedImages === this.numOfImages) this.back();
			}
		}
	}
	render(){
		const { classes, categories, materials, isUploading, uploadingProgress, t } = this.props;
		let categoryItems = [];
		let materialItems = [];
		
		categories.forEach(category => {
			let item = {
				value: category.get('_id'),
				name: t('category.type.' + category.get('name'))
			}
			categoryItems.push(item);
		});

		materials.forEach(material => {
			let item = {
				value: material.get('_id'),
				name: material.get('name')
			}
			materialItems.push(item);
		})

		return (
			<div className={classes.stoneAdd}>
				<h1 className={classes.title}>{t('stone.list.add')}</h1>
				<div className={classes.action}><Button Icon={Close} isSmallIcon={true} onClick={this.back} /></div>
				<AddForm t={t} onSubmit={ this.add } isUploading={isUploading} uploadingProgress={uploadingProgress} categoryItems={categoryItems} materialItems={materialItems} onCancel={ this.back } onSearch={ this.search } onSetCategory={this.setCategory}/>
			</div>
		);
	}
};

StoneAdd.propTypes ={
	classes: PropTypes.object,
	categories: PropTypes.instanceOf(OrderedMap).isRequired,
	materials: PropTypes.instanceOf(OrderedMap).isRequired,
	isUploading: PropTypes.instanceOf(Map).isRequired,
	uploadingProgress: PropTypes.instanceOf(Map).isRequired,
	body: PropTypes.object,
	t: PropTypes.func.isRequired,
	add: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,
	materialSearch: PropTypes.func.isRequired
};

export default injectSheet(styles)(StoneAdd);