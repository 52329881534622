import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, Submit, TextSelect, RadioGroup } from 'UI/Form';
import CheckBox from 'UI/CheckBox';

const validate = (values, props) => {
	const { t } = props;
	const errors = {
		name:{}
	}
	const kind = values.get('kind');
	if(!kind){
		errors.kind = t('errors.required');;
	}
	if(kind === 'company'){
		if (!values.getIn(['name', 'company'])) {
			errors.name.company = t('errors.required');
		}
		if (!values.getIn(['name', 'first'])) {
			errors.name.first = t('errors.required');
		}
		if (!values.getIn(['name', 'last'])) {
			errors.name.last = t('errors.required');
		}
	}else{
		if (!values.getIn(['name', 'first'])) {
			errors.name.first = t('errors.required');
		}
		if (!values.getIn(['name', 'last'])) {
			errors.name.last = t('errors.required');
		}
	}
	if(kind === 'company' || kind === 'pro'){
		if (!values.get('taxCode')) {
			errors.taxCode = t('errors.required');
		}
	}
	if (!values.get('telephone')) {
		errors.telephone = t('errors.required');
	}
	return errors
}

class CreateForm extends Component{
	constructor(props){
		super(props);
		this.state = {
			kind:undefined
		};
	}
	cancel = () => {
		const { onCancel } = this.props;
		if(onCancel) onCancel();
	}
	setKind = (event, newValue, prevValue, name) =>{
		const { change } = this.props;
		if(newValue !== prevValue){
			this.setState({kind:newValue});
			change('name.first', null);
			change('name.last', null);
			change('name.company', null);
		}
	}
	render(){
		const { handleSubmit, t } = this.props;
		const { kind } = this.state;
		const items = [
			{ component: TextSelect, value:"company", label:t('user.company')},
			{ component: TextSelect, value:"pro", label:t('user.pro')},
			{ component: TextSelect, value:"private", label:t('user.private')},
		];
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="kind"
					label={t('user.kind')}
					component={RadioGroup}
					items={items}
					onChange={this.setKind}
				/>
				<Field
					name="name.first"
					type="text"
					label={t('user.firstName')}
					component={Input}
				/>
				<Field
					name="name.last"
					type="text"
					label={t('user.lastName')}
					component={Input}
				/>
				{	(kind === 'company') &&
					<Field
						name="name.company"
						type="text"
						label={t('user.companyName')}
						component={Input}
					/>
				}
				{ (kind === 'company' || kind === 'pro') &&
					<Field
						name="taxCode"
						type="text"
						label={t('user.taxCode')}
						component={Input}
					/>
				}
				<Field
					name="telephone"
					type="tel"
					label={t('user.telephone')}
					component={Input}
				/>
				<Field
					name="address.country"
					type="text"
					label={t('address.country')}
					component={Input}
				/>
				<Field
					name="address.state"
					type="text"
					label={t('address.state')}
					component={Input}
				/>
				<Field
					name="address.city"
					type="text"
					label={t('address.city')}
					component={Input}
				/>
				<Field
					name="address.address"
					type="text"
					label={t('address.address')}
					component={Input}
				/>
				<Field
					name="address.postCode"
					type="number"
					label={t('address.postalCode')}
					component={Input}
				/>
				<Field
					name="mailing"
					label={t('signup.mailingList')}
					component={CheckBox}
				/>
				<Submit isPrimary={true} text={t('user.update.save')}/>
			</form>
		);
	}
}
CreateForm.propTypes = {
	...propTypes,
	onCancel: PropTypes.func,
	t: PropTypes.func
};

export default reduxForm({
	form:'meCreateForm',
	validate
})(CreateForm);