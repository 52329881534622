const helper = {
	colorLuminance: (hex, lum) => {

		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, '');
		if (hex.length < 6) {
			hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		var rgb = "#", c, i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i*2,2), 16);
			c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
			rgb += ("00"+c).substr(c.length);
		}

		return rgb;
	}
}

const main = {
	primary: "#0066ff",
	secondary: "#D3D3D3",
	success: "#2bca3a",
	warning: "#ffd900",
	error: "#fd1b1b",
	background: "#ffffff",
	border:"#0066ff66",
}
const text = {
	default:{
		regular:"#4a4a4a",
		bold:"#363636",
		hover:"#2b2b2b"
	},
	inverse:{
		regular :"#efefef",
		bold: "#cecece",
		hover:"#cecece"
	}
}
const link = {
	default:{
		regular:"#4a4a4a",
		bold:"#363636",
		hover:"#3273dc"
	},
	inverse:{
		regular :"#efefef",
		bold: "#cecece",
		hover:"#3273dc"
	}
}
const navbar = {
	background: "#0066ff",
	item: {
		regular: "#efefef",
		hover: "#cecece",
		active: "#ffffff"
	}
}
const button = {
	default:{
		backgroundColor: main.background,
		color: text.default.regular,
		fill: text.default.regular
	},
	primary:{
		backgroundColor: main.primary,
		color: text.inverse.regular,
		fill: text.inverse.regular
	},
	secondary:{
		backgroundColor: main.secondary,
		color: text.default.regular,
		fill: text.default.regular
	},
	disabled:{
		backgroundColor: main.background,
		color: text.inverse.regular,
		fill: text.inverse.regular
	},
	active:{
		default:{
			backgroundColor: helper.colorLuminance(main.background, -0.2),
			color: helper.colorLuminance(text.default.regular, 0.2),
			fill: helper.colorLuminance(text.default.regular, 0.2)
		},
		primary:{
			backgroundColor: helper.colorLuminance(main.primary, -0.2),
			color: helper.colorLuminance(text.inverse.regular, 0.2),
			fill: helper.colorLuminance(text.inverse.regular, 0.2)
		},
		secondary:{
			backgroundColor: helper.colorLuminance(main.background, -0.2),
			color: helper.colorLuminance(text.default.regular, 0.2),
			fill: helper.colorLuminance(text.default.regular, 0.2)
		}
	},
	hover:{
		default:{
			backgroundColor: helper.colorLuminance(main.background, -0.1),
			color: helper.colorLuminance(text.default.regular, 0.1),
			fill: helper.colorLuminance(text.default.regular, 0.1)
		},
		primary:{
			backgroundColor: helper.colorLuminance(main.primary, -0.1),
			color: helper.colorLuminance(text.inverse.regular, 0.1),
			fill: helper.colorLuminance(text.inverse.regular, 0.1)
		},
		secondary:{
			backgroundColor: helper.colorLuminance(main.background, -0.1),
			color: helper.colorLuminance(text.default.regular, 0.1),
			fill: helper.colorLuminance(text.default.regular, 0.1)
		}
	}
}


export default {
	...main,
	text: text,
	link: link,
	button: button,
	navbar: navbar,
	...helper
};