import { connect } from 'react-redux';
import { 
	list as listConversations,
	empty as emptyConversations,
	listenMessagesToRead,
	unlistenMessagesToRead,
	listenList as listenListConversations,
	unlistenList as unlistenListConversations,
	emptyMessagesToRead
} from 'state/actions/conversation';
import { 
	send as sendMessage,
	list as listMessages,
	empty as emptyMessages,
	translate as translateMessage,
	listenToMessages,
	unlistenToMessages
} from 'state/actions/message';
//import { translate } from 'state/actions/translation';
import MessageBox from './components';

const mapStateToProps = (state) => {
	return {
		conversations: state.getIn(['conversation','conversations']),
		users: state.getIn(['user', 'users']),
		messages: state.getIn(['message', 'messages']),
		lastAction: state.getIn(['ui','lastAction']),
		messageListTimeout: state.getIn(['message', 'timeout']),
		messageToReadTimeout: state.getIn(['conversation', 'messageToReadTimeout']),
		conversationsListTimeout: state.getIn(['conversation', 'listTimeout']),
		translations: state.getIn(['message','translations']),
		screen: state.getIn(['ui' ,'screen'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		sendMessage: (conversationId, text) => {
			dispatch(sendMessage(conversationId, text));
		},
		listConversations: (page) => {
			dispatch(listConversations(page));
		},
		emptyMessagesToRead: (id) => {
			dispatch(emptyMessagesToRead(id));
		},
		emptyConversations:() => {
			dispatch(emptyConversations());
		},
		listenListConversations: (millisec) => {
			dispatch(listenListConversations(millisec))
		},
		unlistenListConversations: (timeout) => {
			dispatch(unlistenListConversations(timeout))
		},
		listMessages: (id, page) => {
			dispatch(listMessages(id, page));
		},
		listenToMessages: (id, millisec) => {
			dispatch(listenToMessages(id, millisec))
		},
		unlistenToMessages: (timeout) => {
			dispatch(unlistenToMessages(timeout));
		},
		listenMessagesToRead:(millisec) => {
			dispatch(listenMessagesToRead(millisec));
		},
		unlistenMessagesToRead:(timeout) => {
			dispatch(unlistenMessagesToRead(timeout));
		},
		emptyMessages: () => {
			dispatch(emptyMessages());
		},
		translateMessage: (id, conversationId, language) => {
			dispatch(translateMessage(id, conversationId, language));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MessageBox)