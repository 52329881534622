import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const styles = theme => ({
	root: {
		height: '60px',
		backgroundColor: theme.colors.navbar.background,
	},
	container:{
		...theme.layout.container,
		...theme.flex.container
	},
	separator: {
		...theme.flex.elem,
	}
});

class Navbar extends Component{
	render(){
		const { Logo, items, classes } = this.props;
		return (
			<div className={classes.root} >
				<div className={classes.container} >
					<Link to="/">{Logo}</Link>
					<div className={ classes.separator }></div>
					<Nav items={items} />
				</div>
			</div>
		);	
	}
}
Navbar.propTypes ={
	classes: PropTypes.object.isRequired,
	Logo: PropTypes.object.isRequired,
	alt:  PropTypes.string.isRequired,
	items: PropTypes.array.isRequired
};

export default injectSheet(styles)(Navbar);