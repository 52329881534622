import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	root:{
		...theme.flex.container,
		...theme.flex.startCenter,
		backgroundColor: theme.colors.navbar.background,
		color: theme.colors.text.inverse.regular,
		fill: theme.colors.text.inverse.regular,
		"& .title":{
			padding: ".75em",
			...theme.flex.elem
		},
		"& .icon":{
			padding: "0 .75em",
		}
	}
});

class Header extends PureComponent{
	render(){
		const { classes, title, Icon } = this.props;
		return (
			<div className={classes.root} >
				{ title && <div className="title">{ title }</div> }
				{ Icon && <div className="icon"><Icon /></div> }
			</div>
		);
	}
}
Header.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	Icon: PropTypes.elementType
};

export default injectSheet(styles)(Header);