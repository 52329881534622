import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class BadgeCheckedIcon extends PureComponent{
	render(){
		const { classes, isSmall } = this.props;
		let iconClass = classes.icon;
		if(isSmall) iconClass += " is-small";
		return (
			<span className={iconClass}>
				<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="megaphone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M560 32h-16c-8.84 0-16 7.16-16 16v12.94L47.28 172.41C45.61 165.36 39.56 160 32 160H16c-8.84 0-16 7.16-16 16v160c0 8.84 7.16 16 16 16h16c7.56 0 13.61-5.36 15.28-12.41l115.01 26.67c-1.17 5.78-2.28 11.6-2.28 17.74 0 53.02 42.98 96 96 96 44.19 0 80.99-29.99 92.08-70.66L528 451.06V464c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V48c0-8.84-7.16-16-16-16zM256 432c-26.47 0-48-21.53-48-48 0-2.42.9-4.54 1.25-6.85l92.25 21.39C295.3 417.87 277.37 432 256 432zM48 290.5v-69l480-111.31V401.8L48 290.5z"></path></svg>
			</span>
		)
	}
}
BadgeCheckedIcon.propTypes ={
	classes: PropTypes.object,
	isSmall: PropTypes.bool
};

export default injectSheet(style)(BadgeCheckedIcon);