import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Close } from 'UI/Icon';
import Images from "./Images";
import Details from "./Details";

const styles = theme => ({
	stoneView:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.column,
		...theme.flex.wrap,
		...theme.flex.wrapStart,
		position: 'relative'
	},
	action:{
		position: 'absolute',
		top: '.5rem',
		left: '1.5rem',
		zIndex: 10
	}
});

class StoneDealerView extends Component {
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	goToNotFound = () =>  {
		const { history } = this.props;
		if(history) history.push('/not_found');
	}
	get = () => {
		const { match:{params}, get } = this.props;
		const id = params.id;
		if(id) get(id)
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	getStats = () => {
		const { match:{params}, getStats } = this.props;
		const id = params.id;
		if(id) getStats(id);
	}
	isMine = () => {
		const { match:{params}, stones } = this.props;
		const id = params.id;
		const stone = stones.get(id);
		if(stone && stone.get('mine')) return true;
		return false;
	}
	componentDidMount(){
		this.get();
	}
	componentDidUpdate(prevProps){
		const { stones, error } = this.props;
		if(stones && !stones.equals(prevProps.stones) && !stones.isEmpty()){
			if(this.isMine()) this.getStats();
			else this.goToNotFound();
		} 
		if(error && error.message === 'not found') this.goToNotFound();
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, match:{params}, stones, stats, t } = this.props;
		const id = params.id;
		const stone = stones.get(id);
		const stoneStats = stats.get(id);
		const images = stones.getIn([id, 'images']);
		return (
			<div className={classes.stoneView}>
				<div className={classes.action}><Button Icon={Close} isSmallIcon={true} onClick={this.back} /></div>
				<Images t={t} images={images} />
				{ stone && <Details t={t} stone={stone} stats={stoneStats} /> }
			</div>
		);
	}
}

StoneDealerView.propTypes ={
	classes: PropTypes.object.isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	stats: PropTypes.instanceOf(Map).isRequired,
	t: PropTypes.func.isRequired,
	get: PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired,
	getStats: PropTypes.func.isRequired,
	error: PropTypes.object
};

export default injectSheet(styles)(StoneDealerView);