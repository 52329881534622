import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
//import backend from "i18next-xhr-backend";
import { initReactI18next  } from 'react-i18next';
import ar from 'translations/ar';
import de from 'translations/de';
import en from 'translations/en';
import es from 'translations/es';
import fr from 'translations/fr';
import it from 'translations/it';
import ru from 'translations/ru';
import zh from 'translations/zh';

const resources = {
	ar:{
		translation: ar
	},
	de:{
		translation: de
	},
	en:{
		translation: en
	},
	es:{
		translation: es
	},
	fr:{
		translation: fr
	},
	it:{
		translation: it
	},
	ru:{
		translation: ru
	},
	zh:{
		translation: zh
	}	
};
i18n
	.use(detector)
	.use(initReactI18next )
	.init({
		resources,
		fallbackLng: "it",
		keySeparator: '.', // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
	});


export default i18n;