import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

const PATH = "/message/";

export const send = async (conversationId, text) => {
	const url = `${apiUrl}${PATH}send`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const date = (new Date()).toISOString();
	const data = { conversationId, text, date }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const remove = async (id, conversationId) => {
	const url = `${apiUrl}${PATH}remove`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id, conversationId }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const translate = async (id, conversationId, language) => {
	const url = `${apiUrl}${PATH}translate`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id, conversationId, language }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const setRead = async (id, conversationId) => {
	const url = `${apiUrl}${PATH}setRead`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = { id, conversationId }
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const list = async (query) => {
	const url = `${apiUrl}${PATH}list?`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	for(const [key, value] of Object.entries(query)) {
		params.append(key, value.toString());
	}
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}