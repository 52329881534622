import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Modal from 'UI/Modal';
import Card from 'UI/Card';
import Button from 'UI/Button';

const styles = theme => ({
	root: {
		...theme.text.default
	},
	actions:{
		...theme.flex.container,
		...theme.flex.endCenter
	}
});

class DeleteModal extends PureComponent {
	close = () => {
		const { onClose } = this.props;
		onClose();
	}
	remove = () => {
		const { onRemove } = this.props;
		onRemove();
	}
	render(){
		const { t, classes, isOpen } = this.props;
		return (
			<Modal isOpen={isOpen} onClose={this.close}>
				<Card title={t('stone.delete.title')} >
					<div className={classes.root}>
						{t('stone.delete.text')}
					</div>
					<div className={classes.actions}>
						<Button text={t('stone.delete.delete')} isPrimary={true} onClick={this.remove} />
						<Button text={t('stone.delete.cancel')} onClick={this.close} />
					</div>
				</Card>
			</Modal>
		);
	}
};


DeleteModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(DeleteModal);