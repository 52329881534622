import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import injectSheet from 'react-jss';
import WarehousePreview from 'Elements/Warehouse/Preview';

const styles = theme => ({
	results:{
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.columns,
		...theme.flex.wrap
	},
});

class SearchResults extends Component{
	select = (permalink) => {
		const { onSelect } = this.props;
		if(permalink) onSelect(permalink);
	}
	render(){
		const { classes, warehouses, t } = this.props;
		let warehouseItems = [];
		warehouses.forEach( warehouse => {
			warehouseItems.push(
				<WarehousePreview key={warehouse.get('_id')} warehouse={warehouse} onSelect={this.select} t={t} />
			);
		});
		return (
			<div className={classes.results}>
				{ warehouseItems }
			</div>
		);
	}
}
SearchResults.propTypes = {
	classes: PropTypes.object.isRequired,
	warehouses: PropTypes.instanceOf(OrderedMap).isRequired,
	onSelect: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(SearchResults);