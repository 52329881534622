import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class FileInput extends Component{
	onChange =  e => {
		const { input : { onChange } } = this.props;
		onChange(e.target.files[0]);
	}
	render(){
		const { classes, input, asyncerror, label, meta } = this.props;
		const { touched, error } = meta;
		let inputClass = classes.input;
		if(touched && (error || asyncerror) ) inputClass += " is-error";
		if(touched && (!error && !asyncerror) ) inputClass += " is-success";
		return (
			<div className={classes.field} >
				{ label && <label className={classes.label} htmlFor={input.name}>{ label }</label> }
				<div className={classes.control}>
					<input {...input} className={inputClass} type="file" value={undefined}/>
					{touched && error && <p className={classes.error}> {error} </p>}
					{asyncerror && <p className={classes.error}> {asyncerror} </p>}
				</div>
			</div>
		);
	}
}
FileInput.propTypes = {
	classes: PropTypes.object,
	input: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	type: PropTypes.string,
};

export default injectSheet(style)(FileInput);