import { Component } from 'react';
import PropTypes from 'prop-types';

class Loader extends Component {
	componentDidMount(){
		const { apiKey, setReady } = this.props;
		window.initMap = () => {
			setReady();
		}
		let script = document.createElement('script');
		script.src="https://maps.googleapis.com/maps/api/js?key=" + apiKey + "&libraries=places&callback=initMap&v=3.31";
		let firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
	}
	render(){
		return null;	
	}
}
Loader.propTypes ={
	children: PropTypes.node,
	apiKey: PropTypes.string
};

export default Loader;