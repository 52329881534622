import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import Message from './Message';
import injectSheet from 'react-jss';

const styles = theme => ({
	messages: {
		...theme.flex.elem,
		...theme.flex.container,
		...theme.flex.rows,
		flexDirection: "column-reverse",
		height: "100%",
		overflow: 'auto',
		"-webkit-overflow-scrolling": "touch"
	}
})

class Messages extends PureComponent {
	constructor(props){
		super(props);
		this.container = React.createRef();
	}
	componentDidUpdate(prevProps){
		const { list } = this.props;
		if(list && !list.equals(prevProps.list)){
			let node = this.container.current;
			node.scrollTop = node.scrollHeight;
		}
	}
	setRead = (id) => {
		const { setRead } = this.props;
		if(setRead && id) setRead(id);
	}
	translate = (id) => {
		const { translate } = this.props;
		if(id) translate(id);
	}
	render(){
		const { classes, messages, conversationId, translations } = this.props;
		let messagesList = [];
		messages.forEach( ( message, index) => {
			let id = message.get('_id');
			let translation = (translations)? translations.get(id): null;
			messagesList.push(
				<Message key={id} message={message} conversationId={conversationId} setRead={this.setRead} translate={this.translate} translation={translation} />
			)
		})
		return (
			<div className={classes.messages} ref={this.container}>
				{messagesList}
			</div>
		);
	}
}
Messages.propTypes ={
	classes: PropTypes.object.isRequired,
	messages: PropTypes.instanceOf(OrderedMap).isRequired,
	translations: PropTypes.instanceOf(Map).isRequired,
	conversation: PropTypes.instanceOf(Map),
	setRead: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};
export default injectSheet(styles)(Messages);