import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Card from 'UI/Card';
import { User } from 'UI/Icon';
import RecoverPasswordForm from "./RecoverPasswordForm";

const styles = theme => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	login:{
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.centerCenter,
		"& form":{
			width:"20rem"
		}
	}
});

class RecoverPassword extends Component {
	constructor(props){
	super(props);
		this.state = {
			done: false
		}
	}
	componentDidUpdate(prevProps){
		const { lastAction } = this.props;
		if(lastAction !== prevProps.lastAction && lastAction === "auth_recover_password_done") this.setState({done: true});
	}
	recoverPassword = (values) => {
		const { recoverPassword } = this.props;
		recoverPassword(values.get('email'));
	}
	render(){
		const { classes, error, t } = this.props;
		const { done } = this.state;
		let links = [
			{to:"/auth/login", isActive: false, name:t('login.login')},
			{to:"/auth/signup", isActive: false, name:t('login.signup')}
		]
		return (
			<section className={classes.root}>
				<div className={classes.login}>
					<Card title={t('login.recoverPassword')} Icon={User} links={links}>
						{ done === false && <RecoverPasswordForm t={t} onSubmit={ this.recoverPassword } authError={ error }/> }
						{ done === true &&
							 <div>
								{t('login.recoverPasswordAnswer')}
							</div>
						}
					</Card>
				</div>
			</section>
		);
	}
}
RecoverPassword.propTypes ={
	classes: PropTypes.object.isRequired,
	error: PropTypes.object,
	t: PropTypes.func.isRequired,
	recoverPassword: PropTypes.func.isRequired
};
export default injectSheet(styles)(RecoverPassword);