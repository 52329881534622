import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Card from 'UI/Card';
import { User } from 'UI/Icon';
import LoginForm from "./LoginForm";

const styles = theme => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	login:{
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.centerCenter,
		"& form":{
			width:"20rem"
		}
	}
});

class UserLogin extends Component {
	login = (values) => {
		const { login } = this.props;
		login(values.get('email'), values.get('password'));
	}
	render(){
		const { classes, error, t } = this.props;
		let links = [
			{to:"/auth/login", isActive: true, name:t('login.login')},
			{to:"/auth/signup", isActive: false, name:t('login.signup')}
		]
		return (
			<section className={classes.root}>
				<div className={classes.login}>
					<Card title={t('login.title')} Icon={User} links={links}>
						<LoginForm t={t} onSubmit={ this.login } authError={ error }/>
					</Card>
				</div>
			</section>
		);
	}
}
UserLogin.propTypes ={
	classes: PropTypes.object,
	error: PropTypes.object,
	t: PropTypes.func
};
export default injectSheet(styles)(UserLogin);