import { connect } from 'react-redux';
import StonesBusiness from './components';

const mapStateToProps = (state) => {
	return {
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StonesBusiness)