import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import UpdateForm from "./UpdateForm";

const style = theme => ({
	meUpdate: {
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.rows,
	}
});

class MeUpdate extends Component {
	update = (values) => {
		const { update } = this.props;
		let updatedMe = { ...values.toJS()	}
		update(updatedMe);
	}
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	get = () => {
		const { get } = this.props;
		get();
	}
	componentDidMount(){
		this.get();
	}
	componentDidUpdate(prevProps){
		const { lastAction } = this.props;
		if(lastAction !== prevProps.lastAction && lastAction === "me_update_done")
			this.back();
	}
	render(){
		const { classes, me, t } = this.props;
		return (
			<div className={classes.meUpdate}>
				{ me.size && <UpdateForm onSubmit={ this.update } initialValues={me} onCancel={ this.back } t={t} /> }
			</div>
		);
	}
}
MeUpdate.propTypes ={
	classes: PropTypes.object.isRequired,
	me: PropTypes.instanceOf(Map).isRequired,
	get: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};
export default injectSheet(style)(MeUpdate);