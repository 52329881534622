import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { LogoDark } from 'Elements/Logo'

const styles = theme => ({
	root: {
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		height: '100%'
	},
	logo: {
		marginBottom: '16px'
	},
	text:{
		...theme.text.title4,
		marginBottom: '16px'
	}
});

class ConfirmEmail extends Component {
	constructor(props){
		super(props);
		this.state = {
			isError: false,
			isDone: false
		}
	}
	logout = () => {
		const {  logout } = this.props;
		logout();
	}
	emailVerify = () => {
		const { emailVerify, match: { params } } = this.props;
		const token = params.token;
		if(token) emailVerify(token);
	}
	componentDidUpdate(prevProps){
		const { lastAction } = this.props;
		if(lastAction !== prevProps.lastAction && lastAction === "auth_email_verify_error") this.setState({isError: true});
		if(lastAction !== prevProps.lastAction && lastAction === "auth_email_verify_done") this.setState({isDone: true});
	}
	componentDidMount(){
		this.logout();
		this.emailVerify();
	}
	render(){
		const { classes } = this.props;
		const { isError, isDone } = this.state;
		return (
			<div className={classes.root}>
				<div className={classes.logo}><LogoDark isBig={true} /></div>
				{ (isError === false && isDone === false) &&
				 	<div className={classes.text}>Verifica Email</div>
				}
				{ (isError === true && isDone === false) &&
					<React.Fragment>
				 		<div className={classes.text}>Token errato o scaduto</div>
				 	</React.Fragment>
				}
				{ (isError === false && isDone === true) &&
					<React.Fragment>
				 		<div className={classes.text}>Grazie di aver verificato la tua mail</div>
				 	</React.Fragment>
				}
			</div>
		);
	}
}

ConfirmEmail.propTypes = {
	classes: PropTypes.object.isRequired,
	lastAction: PropTypes.string,
	emailVerify: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired
};

export default injectSheet(styles)(ConfirmEmail);