export default {
	"category":{
		"type":{
			"Granite": "花岗石",
			"Limestone": "石灰石",
			"Marble": "大理石",
			"Onyx": "缟玛瑙",
			"Quartzite": "石英岩",
			"Stone": "石头",
			"Travertine": "石灰华",
			"Ceramic": "陶瓷的"
		}
	},
	"errors":{
		"required": "需要",
		"userNotFound": "用户不存在",
		"wrongPassword": "密码错误",
		"invalidEmail": "不合规电邮",
		"noMatchPassword": "密码不匹配",
		"UserAlreadyExists": "这封电子邮件已被注册用户使用"
	},
	"login":{
		"title": "登录",
		"login": "登录",
		"signup": "注册",
		"email": "电邮地址",
		"password": "密码",
		"enter": "进入",
		"forgotPassword": "忘记密码了吗？"
	},
	"material":{
	},
	"navbar":{
		"enter": "进入"
	},
	"signup":{
		"title": "注册",
		"login": "登录",
		"signup": "注册",
		"email": "电邮地址",
		"password": "密码",
		"passwordB": "确认你的密码",
		"signin": "注册"
	},
	"stone":{
		"name": "名",
		"shape": "外形",
		"category": "石头类型",
		"material": "材料",
		"height": "高度",
		"width": "宽度",
		"depth": "深度",
		"thickness": "厚度",
		"weight": "重量",
		"coverArea": "覆盖区域",
		"quantity": "数量",
		"shapeType":{
			"shapeless": "无定形",
			"block": "石块",
			"slab": "石板",
			"tile": "砖瓦"
		},
		"search":{
			"title": "找到材料",
			"minHeight": "最小高度",
			"maxHeight": "最大高度",
			"minWidth": "最小宽度",
			"maxWidth": "最大宽度",
			"minDepth": "最小深度",
			"maxDepth": "最大深度",
			"minThickness": "最小厚度",
			"maxThickness": "最大厚度",
			"minWeight": "最小重量",
			"maxWeight": "最大重量",
			"minCoverArea": "最大覆盖区域",
			"maxCoverArea": "最小覆盖区域",
			"search": "搜索"
		},
		"list":{
			"title": "我的广告",
			"add": "添加",
			"images": "照片",
			"modify": "编辑",
			"view": "查看",
			"delete": "删除",
			"search": "搜索"
		},
		"add":{
			"addImages": "着照片",
			"where": "物品在哪儿？",
			"save": "保存",
			"cancel": "取消"
		},
		"update":{
			"where": "物品在哪儿？",
			"save": "保存",
			"cancel": "取消"
		},
		"images":{
			"addImages": "着照片",
			"add": "添加",
			"cancel": "取消"
		}
	},	
	"user":{
		"kind": "用户类型",
		"firstName": "名",
		"lastName": "姓",
		"pro": "零售商",
		"company": "公司",
		"private": "个人",
		"companyName": "公司名",
		"taxCode": "增值税号",
		"telephone": "电话",
		"email": "电邮地址",
		"update":{
			"save": "保存",
			"cancel": "取消"
		},
		"images":{
			"addImages": "着照片",
			"add": "添加",
			"cancel": "取消"
		}		
	},
	"address":{
		"country":"国家",
		"state": "省/区/州",
		"city": "市",
		"address": "地址",
		"postalCode": "邮政编码"
	},
	"message":{
		"call": "登录或注册以向卖家发送消息",
		"label": "写下你的信息",
		"send": "发送",
		"empty": "没有消息"
	}
}