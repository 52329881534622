import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Menu from 'UI/Menu';

const styles = theme => ({
	root: {
		...theme.flex.elemNone,
		padding: '0 16px',
		background: 'none',
		textTransform: 'uppercase',
		lineHeight: '60px',
		border: 'none',
		fontSize: theme.text.default,
		color: theme.colors.navbar.item.regular,
		cursor: 'pointer',
		position: 'relative',
		'&:hover': {
			color: theme.colors.navbar.item.hover
		},
		'&:focus': {
			outline: 'none'
		}
	}
});
class DropDown extends Component{
	constructor(props){
		super(props);
		this.state = {
			isOpen:false
		}
	}
	toogleMenu = () => {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen
		});
	}
	render(){
		const { name, items, classes } = this.props;
		const { isOpen } = this.state;
		return (
			<button href="#" className={classes.root} onClick={this.toogleMenu}>
				<span>{ name }</span>
				<Menu show={isOpen} items={items}/>
			</button>
		);	
	}
}
DropDown.propTypes ={
	classes: PropTypes.object,
	items: PropTypes.array,
	name: PropTypes.string
};
export default injectSheet(styles)(DropDown);