const media  = {
	mobile:'screen and (max-width: 768px)',
	tablet:'screen and (max-width: 1023px) and (min-width: 769px)',
	laptop: 'screen and (max-width: 1215px) and (min-width: 1024px)',
	desktop:'screen and (max-width: 1407px) and (min-width: 1216px)',
	fullHd:'screen and (min-width: 1408px)',
	touch: 'screen and (max-width: 1023px)',
	notTouch: 'screen and (min-width: 1024px)'
};

export default media;