import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

const PATH = "/image/";

export const upload = (file, ref, refId, progress, callback) => {
	let token = getToken();
	let xhr = new XMLHttpRequest();
	let data = new FormData();
	data.append('ref', ref);
	data.append('refId', refId);
	data.append('image', file);
	xhr.open('POST', apiUrl + '/image/upload');
	if(token) xhr.setRequestHeader('Authorization', 'Bearer ' + token);
	xhr.onload = function() {
		let answer = JSON.parse(xhr.responseText);
		callback(null, answer);
	}
	xhr.onerror = function () {
		let error = JSON.parse(xhr.responseText);
		callback(error, null);
	}
	xhr.upload.onprogress = function (event) {
		if (event.lengthComputable) {
			var percent = Math.round((event.loaded / event.total) * 100)
			if(progress) progress(percent);
		}
	}
	xhr.send(data);
}

const randomString = () =>  { // buffer is an ArrayBuffer
	return Math.random().toString(36).substring(12);
}

export const _upload = async (uri, ref, refId) => {
	const url = `${apiUrl}${PATH}upload`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Accept', 'application/json');
	headers.append('Content-Type', 'multipart/form-data');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const uriParts = uri.split('.');
  const fileType = uriParts[uriParts.length - 1];
  const filename = randomString();
	const formData = new FormData();
	formData.append('ref', ref);
	formData.append('refId', refId);
	formData.append('image', {
		// @ts-ignore
    uri,
    name: `${filename}.${fileType}`,
    type: `image/${fileType}`,
  });
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body: formData
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}