import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';

const styles = theme => ({
	root: {
		...theme.flex.elemNone,
		...theme.flex.container
	}
})
class BtnGroup extends PureComponent{
	render(){
		const { items, classes } = this.props;
		let btnItems = [];
		if(items){
			for(let i = 0; i < items.length; i++){
				let item = items[i];
				btnItems.push(
					<Button 
						key={i}
						href={item.href}
						text={item.name}
						Icon={item.Icon}
						isPrimary={item.isPrimary}
						isSecondary={item.isSecondary}
						isDisabled={item.isDisabled}
						onClick={item.clickHandler}
					/>
				);
			}
		}
		return (
			<div className={classes.root}>
				{ btnItems }
			</div>
		);	
	}
}
BtnGroup.propTypes ={
	classes: PropTypes.object.isRequired,
	items: PropTypes.array
};
export default injectSheet(styles)(BtnGroup);