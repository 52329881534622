import { connect } from 'react-redux';
import { get, empty } from 'state/actions/user';
import { getAllByDealer, empty as emptyStones } from 'state/actions/stone';
import { addImpression as addStoneImpression } from 'state/actions/analytics/stone';
import { startChat } from 'state/actions/message';
import { showNotification, clearNotification } from 'state/actions/ui';
import View from './components';

const mapStateToProps = (state) => {
	return {
		users: state.getIn(['user','users']),
		stones: state.getIn(['stone', 'stones']),
		page: state.getIn(['stone','page']),
		perPage: state.getIn(['stone','perPage']),
		total: state.getIn(['stone', 'total']),
		lastAction: state.getIn(['ui', 'lastAction']),
		isLogged: state.getIn(['auth' ,'isLogged']),
		error: state.getIn(['ui', 'error'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		getAllByDealer:(dealerId, orderBy, order, perPage, page) => {
			dispatch(getAllByDealer(dealerId, orderBy, order, perPage, page));
		},
		emptyStones: () => {
			dispatch(emptyStones());
		},
		addStoneImpression: (id) => {
			dispatch(addStoneImpression(id));
		},
		empty: () => {
			dispatch(emptyStones());
			dispatch(empty());
		},
		startChat: (to, message) => {
			dispatch(startChat(to, message));
		},
		showNotification: (message) =>{
			dispatch(clearNotification());
			dispatch(showNotification(message));
		}
	}
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(View)