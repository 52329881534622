import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class ForkliftIcon extends PureComponent{
	render(){
		const { classes, isSmall } = this.props;
		let iconClass = classes.icon;
		if(isSmall) iconClass += " is-small";
		return (
			<span className={iconClass}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M624 384h-80V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v416c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM416 237.1c0-8.7-1.8-17.2-5.2-25.2L332.5 29.1C324.9 11.4 307.6 0 288.3 0H144c-26.5 0-48 21.5-48 48v112H48c-26.5 0-48 21.5-48 48v208c0 53 43 96 96 96s96-43 96-96h64c0 53 43 96 96 96s96-43 96-96c0-28.3-12.5-53.5-32-71.1V237.1zM96 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm160-208l-96-96V64h117.8L352 237.1V256h-96zm96 208c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"></path></svg>
			</span>
		)
	}
}
ForkliftIcon.propTypes ={
	classes: PropTypes.object,
	isSmall: PropTypes.bool
};

export default injectSheet(style)(ForkliftIcon);