import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { LogoDark } from 'Elements/Logo'

const styles = theme => ({
	root: {
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		height: '100%'
	},
	logo: {
		marginBottom: '16px'
	},
	error:{
		...theme.text.title4,
		marginBottom: '16px'
	}
});

class Error404 extends Component {
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.logo}><LogoDark isBig={true} /></div>
				<div className={classes.error}>404 page not found</div>
				<Link to="/">HOME PAGE</Link> 
			</div>
		);
	}
}

Error404.propTypes = {
	classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(Error404);