const reset = {
	"html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6": {
		margin: 0,
		padding: 0
	},
	"h1, h2, h3, h4, h5, h6": {
		fontSize: "100%",
		fontWeight: "normal"
	},
	ul: {
		listStyle: 'none'
	},
	"button, input, select, textarea": {
		margin: 0
	},
	html:{
		boxSizing: "border-box"
	},
	"*":{
		boxSizing: "inherit",
		"&:before, &:after": {
			boxSizing: "inherit"
		} 
	},
	"img, embed, object, audio, video": {
		maxWidth: "100%"
	},
	iframe: {
		border: 0
	},
	table: {
		borderCollapse: "collapse",
		borderSpacing: 0
	},
	"td, th": {
		padding: 0,
		textAlign: "left"
	},
	"input, textarea, select, button": {
		textRendering: "inherit",
		color: "inherit",
		letterSpacing:"inherit",
		wordSpacing: "inherit",
		textTransform: "inherit",
		textIndent: "inherit",
		textShadow: "inherit",
		display: "block",
		textAlign: "inherit",
		margin: 0,
		font: "inherit"
	},
	"button:focus": {
		outline: "none"
	}
};
export default reset;