import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class IconSelect extends Component {
	constructor(props){
		super(props);
		this.state = { selected:false };
	}
	componentDidMount(){
		const { isSelected } = this.props;
		if(isSelected)
			this.setState({selected:isSelected})
	}
	componentDidUpdate(prevProps) {
		const { isSelected } = this.props;
		if(isSelected !== prevProps.isSelected)
			this.setState({selected:isSelected});
	}
	click = (event) => {
		const { selected } = this.state;
		const { onSelect, input } = this.props;
		this.setState({selected:!selected});
		if(onSelect) onSelect(!selected);
		if(input && input.onChange) input.onChange(!selected);
	}
	render(){
		const { classes, label, Icon } = this.props;
		const { selected } = this.state;
		const iconSelectClass = (selected) ? classes.iconSelect + " is-selected": classes.iconSelect;
		return (
			<div className={iconSelectClass} onClick={this.click}>
				{ Icon && <Icon />}
				{ label && <div className="label"> {label} </div>}
			</div>
		);	
	}
}
IconSelect.propTypes ={
	classes: PropTypes.object,
	isSelected: PropTypes.bool,
	label: PropTypes.string,
	onSelect: PropTypes.func
};
export default injectSheet(style)(IconSelect);