import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Progress from 'UI/Progress';

const styles = theme => ({
	root: {
		padding: '8px'
	},
	preview:{
		height: 200,
		width: 200,
		position: 'relative',
		backgroundColor: '#000000',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		borderRadius: '8px',
		cursor: 'pointer'
	},
	obfuscator:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		right: 0,
		top: 0,
		backgroundColor: "rgba(0,0,0,.5)",
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	progressContainer:{
		position: 'absolute',
		height: '100%',
		width: '100%',
		padding: '.5rem',
		right: 0,
		top: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: 'center',
		alignItems: 'center'
	},
	progress:{
		color: '#ffffff'
	}
});

class Preview extends Component{
	constructor(props){
		super(props);
		this.state = { 
			url:''
		}
	}
	upload = () => {
		const { file, index, onUpload } = this.props;
		onUpload(file, index);
	}
	componentDidMount(){
		const { file } = this.props;
		var reader  = new FileReader();
		reader.addEventListener("load", () => {
			this.setState({
				url: reader.result
			});
			this.upload()
		}, false);
		reader.readAsDataURL(file);
	}
	render(){
		const { classes, progress, isUploading } = this.props;
		const { url } = this.state;
		return (
			<div className={classes.root}>
				<div className={classes.preview} style={{backgroundImage: 'url(' + url + ')'}}>
					{ isUploading && 
						<React.Fragment>
							<div className={classes.obfuscator}></div>
							<div className={classes.progressContainer}>
								<Progress progress={progress} />
							</div>
						</React.Fragment>
					}
				</div>
			</div>
		);
	}
}
Preview.propTypes = {
	classes: PropTypes.object,
	file: PropTypes.instanceOf(File),
	index: PropTypes.number.isRequired,
	progress: PropTypes.number,
	isUploading: PropTypes.bool,
	onUpload: PropTypes.func.isRequired
};

export default injectSheet(styles)(Preview);