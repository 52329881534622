import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class ForkliftIcon extends PureComponent{
	render(){
		const { classes, isSmall } = this.props;
		let iconClass = classes.icon;
		if(isSmall) iconClass += " is-small";
		return (
			<span className={iconClass}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M496 64H112V16c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16v48H16C7.2 64 0 71.2 0 80v16c0 8.8 7.2 16 16 16h48v384c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V112h80v48c-17.7 0-32 14.3-32 32v160c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32v-48h48c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zm-64 272H208V208h224v128zm-16-176H224v-48h192v48z"></path></svg>
			</span>
		)
	}
}
ForkliftIcon.propTypes ={
	classes: PropTypes.object,
	isSmall: PropTypes.bool
};

export default injectSheet(style)(ForkliftIcon);