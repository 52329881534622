import { CHANGE_SCREEN, CHANGE_COLOR, SHOW_NOTIFICATION, CLEAR_NOTIFICATION, START_FETCHING, END_FETCHING, INIT_UPLOADING, START_UPLOADING, PROGRESS_UPLOADING, END_UPLOADING, STORE_ERROR, REMOVE_ERROR, STORE_MESSAGES } from 'state/actions/ui';
import { Map, fromJS } from 'immutable';

const initState = fromJS({
	screen: undefined,
	info: undefined,
	error: undefined,
	lastAction: undefined,
	isFetching: false,
	toFetch: [],
	isUploading: Map(),
	uploadingProgress: Map(),
	color: 'default',
	messages: 0
});

const ui = (state = initState, action) => {
	switch(action.type){
		case CHANGE_SCREEN: {
			return state.set('screen', action.screen)
		}
		case SHOW_NOTIFICATION: {
			return state.set('info', action.info)
		}
		case CLEAR_NOTIFICATION: {
			return state.set('info', undefined)
		}
		case START_FETCHING:{
			let toFetch = state.get('toFetch').push(action.toFetch);
			let isFetching = toFetch.size > 0;
			return state.merge({
				isFetching: isFetching,
				toFetch: toFetch,
				lastAction: undefined
			});
		}
		case END_FETCHING:{
			let key =  state.get('toFetch').keyOf(action.toFetch);
			let toFetch = state.get('toFetch').delete(key);
			let isFetching = toFetch.size > 0;
			return state.merge({
				isFetching: isFetching,
				toFetch: toFetch,
				lastAction: action.done
			})
		}
		case INIT_UPLOADING:{
			return state.merge({
				isUploading: Map(),
				uploadingProgress: Map()
			})
		}
		case START_UPLOADING:{
			let isUploading = state.get('isUploading');
			let uploadingProgress = state.get('uploadingProgress');
			return state.merge({
				isUploading: isUploading.set(action.index, true),
				uploadingProgress: uploadingProgress.set(action.index, 0),
			})
		}
		case PROGRESS_UPLOADING:{
			let uploadingProgress = state.get('uploadingProgress');
			return state.merge({
				'uploadingProgress': uploadingProgress.set(action.index, action.progress)
			});
		}
		case END_UPLOADING:{
			let isUploading = state.get('isUploading');
			let uploadingProgress = state.get('uploadingProgress');
			return state.merge({
				isUploading: isUploading.set(action.index, false),
				uploadingProgress: uploadingProgress.set(action.index, undefined),
			});
		}
		case STORE_ERROR:{
			return state.set('error', action.error);
		}
		case REMOVE_ERROR:{
			return state.set('error', undefined);
		}
		case CHANGE_COLOR:{
			return state.set('color', action.color);
		}
		case STORE_MESSAGES:{
			return state.set('messages', action.messages);
		}
		default:
			return state;
	}
};

export default ui;