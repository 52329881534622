import * as category from 'fetch/category';
import {
	startFetching,
	endFetching,
	storeError,
	removeError
} from 'state/actions/ui';

export const CATEGORY_GET = "CATEGORY_GET";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_EMPTY = "CATEGORY_EMPTY"

const storeGet = (answer) => {
	return {
		type: CATEGORY_GET,
		category : answer
	}
};

const storeList = (answer) => {
	return {
		type: CATEGORY_LIST,
		categories: answer.categories,
		page: answer.page,
		perPage: answer.perPage,
		total: answer.results 
	}
};

const storeEmpty = () => {
	return {
		type: CATEGORY_EMPTY
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('category_get'));
		try {
			const answer = await category.get(id);
			dispatch(storeGet(answer));
			dispatch(endFetching('category_get','category_get_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('category_get','category_get_error'));
		}
	}
};

export const getAll = (orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('category_get_all'));
		try {
			const answer = await category.list({orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('category_get_all','category_get_all_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('category_get_all','category_get_all_done'));
		}
	}
};

export const search = (name, lang, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('category_search'));
		try {
			const answer = await category.list({name, lang, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('category_search','category_search_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('category_search','category_search_done'));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};