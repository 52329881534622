import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { staticUrl } from 'config/urls';
import Immutable from 'immutable';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Close, ArrowLeft, ArrowRight } from 'UI/Icon';
import mockImage from 'images/mock.jpg';

const styles = theme => ({
	root: {
		position: 'fixed',
		display: 'flex',
		flexDirection: 'column',
		top: 0,
		left: 0,
		zIndex: 1202,
		height: '100%',
		width: '100%',
		backgroundColor: theme.colors.primary
	},
	header: {
		height: "2.25em",
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.endEnd
	},
	footer: {
		height: "2.25em",
	},
	grow: {
		flexGrow: 1
	},
	close:{
		color: "#ffffff"
	},
	container: {
		flexGrow: 1,
		display: 'flex'
	},
	action: {
		width: '2.25rem',
		display: 'flex',
		alignItems: 'center'
	},
	nav: {
		color: "#ffffff"
	},
	imageContainer: {
		flexGrow: 1,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'contain'
	},	
	image: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& img': {
			height: '100%',
			maxWidth: '100%'
		}
	}
});

class ImagesSlider extends PureComponent{
	constructor(props){
		super(props);
		this.state = {
			index:0
		}
	}
	next = () => {
		const { list } = this.props;
		const { index } = this.state;
		if(index < list.size - 1){
			this.setState({index: index + 1});
		}
	}
	prev = () => {
		const { index } = this.state;
		if(index > 0){
			this.setState({index: index - 1});
		}
	}
	exit = ()  =>{ 
		const { onClose } = this.props;
		onClose();
	}
	componentDidMount(){
		const { initIndex } = this.props;
		this.setState({index: initIndex});
	}
	render(){
		const { classes, images, list } = this.props;
		const { index } = this.state;
		let actualImage;
		if(list && images){
			actualImage = staticUrl + '/' + images.getIn([list.get(index), 'url']);
		}else{
			actualImage = mockImage;
		}
		return(
			<div className={classes.root}>
				<div className={classes.header}>
					<Button isPrimary isSmallIcon={true} noBorder={true} Icon={Close} onClick={this.exit}/>
				</div>
				<div className={classes.container}>
					<div className={classes.action}>
						<Button isPrimary isSmallIcon={true} noBorder={true} Icon={ArrowLeft} onClick={this.prev}/>
					</div>
					<div className={classes.imageContainer} style={{backgroundImage: 'url(' + actualImage + ')'}}></div>
					<div className={classes.action}>
						<Button isPrimary isSmallIcon={true} noBorder={true} Icon={ArrowRight} onClick={this.next}/>
					</div>
				</div>
				<div className={classes.footer} />
			</div>
		)
	}
}

ImagesSlider.propTypes = {
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	list: PropTypes.instanceOf(Immutable.List),
	images: PropTypes.instanceOf(Immutable.Map),
	initIndex: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired
};

export default injectSheet(styles)(ImagesSlider);
