import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Modal from 'UI/Modal';
import Card from 'UI/Card';
import Button from 'UI/Button';

const styles = theme => ({
	root: {
		...theme.text.default
	},
	actions:{
		...theme.flex.container,
		...theme.flex.endCenter
	}
});

class InfoModal extends PureComponent {
	close = () => {
		const { onClose } = this.props;
		onClose();
	}
	render(){
		const { t, classes, isOpen, text } = this.props;
		return (
			<Modal isOpen={isOpen} onClose={this.close}>
				<Card title={"Info"} >
					<div className={classes.root}>
						{ text }
					</div>
					<div className={classes.actions}>
						<Button text={t('stone.list.close')} onClick={this.close} />
					</div>
				</Card>
			</Modal>
		);
	}
};


InfoModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(InfoModal);