import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = theme => ({
	'@global': {
		'@keyframes slideRight ': {
	    from: {backgroundPosition: '200% 0'},
	    to: {backgroundPosition: '-200% 0'}
	  }
	},
	root: {
		border: 'none',
		borderRadius: '290486px',
		display: 'block',
		height: '.5rem',
		overflow: 'hidden',
		padding: 0,
		width: '100%',
		'&::-webkit-progress-bar':{
			backgroundColor: '#dbdbdb'
		},
		'&::-webkit-progress-value':{
			backgroundColor: '#00d1b2'
		},
		'&:indeterminate': {
			animationDuration: '1.5s',
			animationIterationCount: 'infinite',
			animationName: 'slideRight',
			animationTimingFunction: 'linear',
			backgroundColor: '#dbdbdb',
  		backgroundImage: 'linear-gradient(to right, #00d1b2 30%, #dbdbdb 30%)',
  		backgroundPosition: 'top left',
  		backgroundRepeat: 'no-repeat',
  		backgroundSize: '150% 150%',
  		'&::-webkit-progress-bar':{
				backgroundColor: 'transparent'
			},
		}
	}
});

class Progress extends Component {
	render(){
		const { classes, progress } = this.props;
		return (
			<progress className={classes.root} value={progress} max="100"/>
		)
	}
}
Progress.propTypes ={
	classes: PropTypes.object.isRequired,
	progress: PropTypes.number
};
export default injectSheet(styles)(Progress);