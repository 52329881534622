import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

const styles = theme => ({
	root: {
		boxShadow: theme.shadows.s1,
		minHeight: "20px",
		minWidth: "20px",
		backgroundColor: '#ffffff'
	},
});

class Card extends Component{
	render(){
		const { classes, title, Icon, children, links } = this.props;
		let footerElements = [];
		if(links)
			for(let i = 0; i < links.length; i++){
				let className = "link";
				if(links[i].isActive) className += " active";
				footerElements.push(<Link to={links[i].to} className={className} key={i}>{links[i].name}</Link>)
			}
		return (
			<div className={classes.root} >
				<Header title={title} Icon={Icon}/>
				<Body >
					{ children }
				</Body>
				<Footer links={footerElements}/>
			</div>
		);
	}
}
Card.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	Icon: PropTypes.elementType,
	children: PropTypes.node
};

export default injectSheet(styles)(Card);