export default {
	"category":{
		"type":{
			"Granite": "Granito",
			"Limestone": "Caliza",
			"Marble": "Mármol",
			"Onyx": "ónix",
			"Quartzite": "Cuarcita",
			"Stone": "Piedra",
			"Travertine": "Travertino",
			"Ceramic": "Cerámico"
		}
	},
	"errors":{
		"required": "Necesario",
		"userNotFound": "el usuario no existe",
		"wrongPassword": "contraseña incorrecta",
		"invalidEmail": "Correo inválido",
		"noMatchPassword": "Las contraseñas no coinciden",
		"UserAlreadyExists": "Este correo electrónico ya ha sido tomado por un usuario registrado"
	},
	"login":{
		"title": "Entra",
		"login": "Accede",
		"signup": "Inscrìbite",
		"email": "Correo",
		"password": "Password",
		"enter": "Entra",
		"forgotPassword": "Has olvidado tu contraseña?"
	},
	"material":{
	},
	"navbar":{
		"enter": "Entra"
	},
	"signup":{
		"title": "Inscrìbite",
		"login": "Accede",
		"signup": "Inscrìbite",
		"email": "Correo",
		"password": "Contraseña",
		"passwordB": "Confirma tu contraseña",
		"signin": "Inscrìbite"
	},
	"stone":{
		"name": "Nombre",
		"shape": "Forma",
		"category": "Tipo de piedra",
		"material": "Materiale",
		"height": "Altura",
		"width": "Anchura",
		"depth": "Profundidad",
		"thickness": "Espesor",
		"weight": "Peso",
		"coverArea": "Zona de cobertura",
		"quantity": "Cantidad",
		"shapeType":{
			"shapeless": "Sin forma",
			"block": "Bloque",
			"slab": "Placa",
			"tile": "Baldosas"
		},
		"search":{
			"title": "Encuentra los materiales",
			"minHeight": "Altura mínima ",
			"maxHeight": "Altura máxima",
			"minWidth": "Anchura mínima",
			"maxWidth": "Anchura máxima",
			"minDepth": "Profundidad mínima",
			"maxDepth": "Profundidad máxima",
			"minThickness": "Espesor mínimo",
			"maxThickness": "Espesor máximo",
			"minWeight": "Peso mínimo",
			"maxWeight": "Peso máximo",
			"minCoverArea": "Zona de cobertura mínima",
			"maxCoverArea": "Zona de cobertura máxima",
			"search": "Busca"
		},
		"list":{
			"title": "Mis anuncios",
			"add": "Inserta",
			"images": "Imágenes",
			"modify": "Modifica",
			"view": "Muestra",
			"delete": "Quita",
			"search": "Busca"
		},
		"add":{
			"addImages": "Añade algunas fotos",
			"where": "Donde se encuentra?",
			"save": "Guarda",
			"cancel": "Deshace"
		},
		"update":{
			"where": "Donde se encuentra?",
			"save": "Guarda",
			"cancel": "Deshace"
		},
		"images":{
			"addImages": "Añade algunas fotos",
			"add": "Inserta",
			"cancel": "Deshace"
		}
	},	
	"user":{
		"kind": "tipo de usuario",
		"firstName": "Nombre",
		"lastName": "Apellido",
		"company": "Empresa",
		"pro": "Comerciante",
		"private": "Personal",
		"companyName": "nombre de empresa",
		"taxCode": "Número de valor agregado",
		"telephone": "Número de teléfono",
		"email": "Correo",
		"update":{
			"save": "Guarda",
			"cancel": "Deshace"
		},
		"images":{
			"addImages": "Añade algunas fotos",
			"add": "Inserta",
			"cancel": "Deshace"
		}		
	},
	"address":{
		"country":"País",
		"state": "Provincia / Distrito / Estado",
		"city": "Ciudad",
		"address": "Dirección",
		"postalCode": "Código postal"
	},
	"message":{
		"call": "Inicie sesión o regístrese para enviar un mensaje al vendedor",
		"label": "escribe tu mensaje",
		"send": "enviar",
		"empty": "Sin mensaje"
	}	
}