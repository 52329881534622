import { connect } from 'react-redux';
import { create } from 'state/actions/me';
import { authenticate } from 'state/actions/auth';
import Update from './components';

const mapStateToProps = (state) => {
	return {
		lastAction: state.getIn(['ui','lastAction']),
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		authenticate: () => {
			dispatch(authenticate());
		},
		create: (me) => {
			dispatch(create(me));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Update)