import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Close } from 'UI/Icon';
import Images from "./Images";
import Details from "./Details";

const styles = theme => ({
	stoneView:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.column,
		...theme.flex.wrap,
		...theme.flex.wrapStart,
		position: 'relative'
	},
	action:{
		position: 'absolute',
		top: '.5rem',
		left: '1.5rem',
		zIndex: 10
	}
});

class StoneView extends Component {
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	goToNotFound = () =>  {
		const { history } = this.props;
		if(history) history.push('/not_found');
	}
	showNotification = (text) => {
		const { showNotification } = this.props;
		if(!!text) showNotification(text);
	}
	sendMessage = (to, message) => {
		const { startChat } = this.props;
		if(!!to && !!message)	startChat(to, message)
	}
	get = () => {
		const { match:{params}, get } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		if(id) get(id)
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	getDealer = () => {
		const { match:{params}, stones, getUser } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		const userId = stones.getIn([id, 'dealer']);
		if(userId)	getUser(userId);
	}
	addClick = () => {
		const { match:{params}, addClick } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		if(id) addClick(id);
	}

	componentDidMount(){
		this.get();
	}
	componentDidUpdate(prevProps){
		const { t, stones, error, lastAction } = this.props;
		if(stones && !stones.equals(prevProps.stones)){
			this.getDealer();
			this.addClick();
		} 
		if(error && error.message === 'not found') this.goToNotFound();
		if(prevProps.lastAction !== lastAction && lastAction === "message_start_chat_done") this.showNotification(t('message.sent'));
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, match:{params}, stones, stats, users, isLogged, t } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		const stone = stones.get(id);
		const stoneStats = stats.get(id);
		const userId = stones.getIn([id, 'dealer']);
		const user = users.get(userId);
		const images = stones.getIn([id, 'images']);
		return (
			<div className={classes.stoneView}>
				<div className={classes.action}><Button Icon={Close} isSmallIcon={true} onClick={this.back} /></div>
				<Images t={t} images={images} />
				{ stone && <Details t={t} stone={stone} stats={stoneStats} user={user} isLogged={isLogged} sendMessage={this.sendMessage} /> }
			</div>
		);
	}
}

StoneView.propTypes ={
	classes: PropTypes.object.isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	stats: PropTypes.instanceOf(Map).isRequired,
	users: PropTypes.instanceOf(Map).isRequired,
	lastAction: PropTypes.string,
	isLogged: PropTypes.bool,
	t: PropTypes.func.isRequired,
	get: PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired,
	getUser: PropTypes.func.isRequired,
	addClick: PropTypes.func.isRequired,
	startChat: PropTypes.func.isRequired,
	showNotification: PropTypes.func.isRequired,
	error: PropTypes.object
};

export default injectSheet(styles)(StoneView);