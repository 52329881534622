import * as warehouse from 'fetch/warehouse';
import * as imageFetch from 'fetch/image';
import { 
	startFetching,
	endFetching,
	storeError,
	removeError,
	showNotification,
	clearNotification,
	startUploading,
	progressUploading,
	endUploading
} from 'state/actions/ui';

export const WAREHOUSE_GET = "WAREHOUSE_GET";
export const WAREHOUSE_GET_IMAGE = "WAREHOUSE_GET_IMAGE";
export const WAREHOUSE_LIST = "WAREHOUSE_LIST";
export const WAREHOUSE_UPDATE = "WAREHOUSE_UPDATE";
export const WAREHOUSE_EMPTY = "WAREHOUSE_EMPTY";

const storeGet = (answer) => {
	return {
		type: WAREHOUSE_GET,
		warehouse : answer
	}
};

const storeList = (answer) => {
	return {
		type: WAREHOUSE_LIST,
		warehouses: answer.warehouses,
		page: answer.page,
		perPage: answer.perPage,
		results: answer.results 
	}
};

const storeUpdate = (id, warehouse) => {
	return {
		type: WAREHOUSE_UPDATE,
		id, 
		warehouse
	}
};

const storeEmpty = () => {
	return {
		type: WAREHOUSE_EMPTY
	}
};

export const update = (id, warehouseUpdate) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_update'));
		dispatch(clearNotification());
		try {
			await warehouse.update(id, warehouseUpdate);
			dispatch(storeUpdate(id, warehouseUpdate));
			dispatch(endFetching('warehouse_update','warehouse_update_done'));
			dispatch(showNotification('Updated!'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_update','warehouse_update_error'));
		}
	}
};

export const uploadImage = (id, image, index) => {
	return dispatch => {
		const onProgress = (progress) => {
			dispatch(progressUploading(progress, index));
		}
		const onSuccess = async (err, newImage) => {
			if(err){
				dispatch(endUploading(index));
				dispatch(storeError(err));
				dispatch(endFetching('warehouse_upload_image:' + image.name, 'warehouse_upload_image_error:' + image.name));
			} else {
				try {
					await warehouse.addImage(id, newImage._id);
					dispatch(endUploading(index))
					dispatch(endFetching('warehouse_upload_image:' + image.name, 'warehouse_upload_image_done:' + image.name));
				} catch(error){
					console.error(error);
					dispatch(storeError(error));
					dispatch(endFetching('warehouse_upload_image:' + image.name, 'warehouse_upload_image_error:' + image.name));
				}
			} 
		}
		dispatch(removeError());
		//dispatch(startFetching('warehouse_upload_image:' + image.name));
		dispatch(clearNotification());
		dispatch(startUploading(index));
		imageFetch.upload(image, 'deposit', id, onProgress, onSuccess);
	}
};

export const removeImage = (id, imageId) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_remove_image'));
		dispatch(clearNotification());
		try {
			await warehouse.removeImage(id, imageId);
			dispatch(endFetching('warehouse_remove_image','warehouse_remove_image_done'));
			dispatch(showNotification('Image removed!'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_remove_image','warehouse_remove_image_error'));
		}
	}
};

export const uploadLogo = (id, image) => {
	return dispatch => {
		const onProgress = (progress) => {
			dispatch(progressUploading(progress, 0));
		}
		const onSuccess = async (err, newImage) => {
			if(err){
				dispatch(endUploading(0));
				dispatch(storeError(err));
				dispatch(endFetching('warehouse_upload_logo:' + image.name, 'warehouse_upload_logo_error:' + image.name));
			} else {
				try {
					await warehouse.addLogo(id, newImage._id);
					dispatch(endUploading(0))
					dispatch(endFetching('warehouse_upload_logo:' + image.name, 'warehouse_upload_logo_done:' + image.name));
				} catch(error){
					console.error(error);
					dispatch(storeError(error));
					dispatch(endFetching('warehouse_upload_logo:' + image.name, 'warehouse_upload_logo_error:' + image.name));
				}
			} 
		}
		dispatch(removeError());
		//dispatch(startFetching('warehouse_upload_logo:' + image.name));
		dispatch(clearNotification());
		dispatch(startUploading(0));
		imageFetch.upload(image, 'deposit', id, onProgress, onSuccess);
	}
};

export const removeLogo = (id, logoId) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_remove_logo'));
		dispatch(clearNotification());
		try {
			await warehouse.removeLogo(id, logoId)
			dispatch(endFetching('warehouse_remove_logo','warehouse_remove_logo_done'));
			dispatch(showNotification('Logo removed!'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_remove_logo','warehouse_remove_logo_error'));
		}
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_get'));
		try {
			const answer = await warehouse.get(id);
			dispatch(storeGet(answer));
		 	dispatch(endFetching('warehouse_get','warehouse_get_done'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_get','warehouse_get_error'));
		}
	}
};

export const getMine = () => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_mine'));
		try {
			const answer = await warehouse.mine();
			dispatch(storeGet(answer));
			dispatch(endFetching('warehouse_mine','warehouse_mine_done'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_mine','warehouse_mine_error'));
		}
	}
};

export const getAll = (orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_get_all'));
		try {
			const answer = await warehouse.list({orderBy, order, perPage, page});
			console.log(answer);
			dispatch(storeList(answer));
			dispatch(endFetching('warehouse_get_all','warehouse_get_all_done'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_get_all','warehouse_get_all_error'));
		}
	}
};

export const search = (lat, lng, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('warehouse_search'));
		try {
			const answer = await warehouse.list({lat, lng, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('warehouse_search','warehouse_search_done'));
		} catch(error){
			console.error(error);
			dispatch(storeError(error));
			dispatch(endFetching('warehouse_search','warehouse_search_error'));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};