import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import Item from './Item';
import injectSheet from 'react-jss';

const styles = theme => ({
	list: {
		...theme.flex.container,
		...theme.flex.rows,
		height: "100%",
		overflow: 'auto',
		"-webkit-overflow-scrolling": "touch",
		...theme.flex.elemSetFlex(30,'columns'),
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'columns')
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'columns')
		}
	}
})

class List extends Component {
	select = (id) => {
		const { onSelect } = this.props;
		if(onSelect && id) onSelect(id);
	}
	getUser = (id) => {
		const { getUser } = this.props;
		getUser(id);
	}
	componentDidMount(){

	}
	componentDidUpdate(prevProps){


	}
	render(){
		const { classes, conversations, selected, t } = this.props;
		let list = [];
		conversations.forEach( (conversation, index) => {
			let id = conversation.get('_id');
			list.push(
				<Item id={id} conversation={conversation} key={id} onSelect={this.select} selected={selected===id} />
			)
		});
		return (
			<div className={classes.list}>
				{ list.length === 0 && <div className="info"> {t('message.empty')} </div> }
				{ list }
			</div>
		);
	}
}
List.propTypes ={
	classes: PropTypes.object,
	conversations: PropTypes.instanceOf(OrderedMap),
	onSelect: PropTypes.func.isRequired,
	selected: PropTypes.string
};
export default injectSheet(styles)(List);