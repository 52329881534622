export default {
	"category":{
		"type":{
			"Granite": "Granite",
			"Limestone": "Limestone",
			"Marble": "Marble",
			"Onyx": "Onyx",
			"Quartzite": "Quartzite",
			"Stone": "Stone",
			"Travertine": "Travertine",
			"Ceramic": "Ceramic"
		}
	},
	"errors":{
		"required": "required",
		"userNotFound": "User does not exist",
		"wrongPassword": "Wrong password",
		"invalidEmail": "Invalid email",
		"noMatchPassword": "Passwords do not match",
		"UserAlreadyExists": "This email is already taken by a registered user"
	},
	"login":{
		"title": "Login",
		"login": "Log in",
		"signup": "Sign up",
		"email": "Email",
		"password": "Password",
		"enter": "Enter",
		"forgotPassword": "Forgot your password?",
		"recoverPassword": "Recover password",
		"recoverPasswordAnswer": "We've send a e-mail to you, follow the instructions.",
		"changePassword": "Set a new password",
		"passwordChanged": "Password changed",
		"readAndAccepted": "I have read and accepted",
		"privacyPolicy": "privacy policy",
		"termsAndConditions": "the general provisions, terms and conditions of use",
		"mailingList": "I agree to receive newsletters with commercial and promotional information relating to the service (optional)"
	},
	"material":{
	},
	"navbar":{
		"enter": "Enter"
	},
	"signup":{
		"title": "Subscribe",
		"login": "login",
		"signup": "Sign up",
		"email": "Email",
		"password": "Password",
		"passwordB": "Confirm your password",
		"signin": "Sign in"
	},
	"stone":{
		"name": "Name",
		"shape": "Shape",
		"category": "Stone Type",
		"material": "Material",
		"height": "Height",
		"width": "Width",
		"depth": "Depth",
		"thickness": "Thickness",
		"weight": "Weight",
		"coverArea": "Coverage area",
		"quantity": "Quantity",
		"where": "Where",
		"shapeType":{
			"shapeless": "Formless",
			"block": "Block",
			"slab": "Slab",
			"tile": "Tiles"
		},
		"search":{
			"where": "Where",
			"title": "Find the materials",
			"minHeight": "Minimum height",
			"maxHeight": "Maximum height",
			"minWidth": "Minimum width",
			"maxWidth": "Maximum width",
			"minDepth": "Minimum depth",
			"maxDepth": "Maximum depth",
			"minThickness": "Minimum thickness",
			"maxThickness": "Maximum thickness",
			"minWeight": "Minimum weight",
			"maxWeight": "Maximum weight",
			"minCoverArea": "Minimum coverage area",
			"maxCoverArea": "Maximum coverage area",
			"search": "Search",
			"nearMe": "close to me",
			"noResults": "no results"
		},
		"list":{
			"title": "My adverts",
			"add": "Add",
			"images": "Images",
			"modify": "Modify",
			"view": "View",
			"delete": "Delete",
			"search": "Search"
		},
		"delete":{	
			"title": "Remove the ad",
			"text": "Are you sure you want to delete the ad?",
			"delete": "Delete",
			"cancel": "Annulla"
		},
		"add":{
			"addImages": "Add some photos",
			"where": "Where is it?",
			"save": "Save",
			"cancel": "Cancel"
		},
		"update":{
			"where": "Where is it?",
			"save": "Save",
			"cancel": "Cancel"
		},
		"images":{
			"addImages": "Add some photos",
			"add": "Add",
			"cancel": "Cancel"
		}
	},	
	"user":{
		"complete":'complete user profile',
		"kind": "User Type",
		"company": "Company",
		"pro": "Dealer",
		"private": "Private",
		"firstName": "Name",
		"lastName": "Last Name",
		"companyName": "Company Name",
		"telephone": "Telephone",
		"email": "Email",
		"taxCode": "VAT Number",
		"update":{
			"save": "Save",
			"cancel": "Cancel"
		},
		"images":{
			"addImages": "Add some photos",
			"add": "Add",
			"cancel": "Cancel"
		}		
	},
	"address":{
		"country":"Country",
		"state": "Province / District / State",
		"city": "City",
		"address": "Address",
		"postalCode": "Postal code"
	},
	"message":{
		"call": "Login or signup to send a message to the seller",
		"label": "Write your message",
		"send": "send",
		"empty": "no message",
		"sent": "message sent"
	},
	"warehouse":{
		"warehouse": "Warehouse",
		"where": "where",
		"managePhotos": "manage photos",
		"watchPhotos": "watch photos",
		"name": "name",
		"description": "description",
		"dimensions": "exhibition area size (m2)",
		"totalRows": "total rows",
		"freeRows": "free rows",
		"email": "e-mail address",
		"phone": "numero di telefono",
		"website": "website",
		"loading": "loading and unloading",
		"transport": "transport",
		"weight": "weight",
		"weightVGM": "VGM weight",
		"water": "water available",
		"turn": "blocks turn",
		"overheadCrane": "overhead crane",
		"crane": "crane",
		"cutting": "cutting",
		"polishing": "polishing",
		"dressing": "dressing",
		"uncovered": "uncovered warehouse",
		"covered": "covered warehouse",
		"update": {
			"where": "Where is it?"
		},
		"search": {
			"title": "Search warehouse",
			"search": "search",
			"nearMe": "close to me",
			"noResults": "no results"
		},
		"addPhotos": "add",
		"close": "close"
	}		
}