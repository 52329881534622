import { connect } from 'react-redux';
import { search, empty } from 'state/actions/stone';
import { addImpression } from 'state/actions/analytics/stone';
import { getAll } from 'state/actions/category';
import { searchName, get as getMaterial } from 'state/actions/material';
import Search from './components';

const mapStateToProps = (state) => {
	return {
		page: state.getIn(['stone','page']),
		perPage: state.getIn(['stone','perPage']),
		results: state.getIn(['stone', 'results']),
		stones: state.getIn(['stone','stones']),
		categoryList: state.getIn(['category','list']),
		categories: state.getIn(['category','categories']),
		materialList: state.getIn(['material','list']),
		materials: state.getIn(['material','materials']),
		lastAction: state.getIn(['ui', 'lastAction']),
		screen: state.getIn(['ui' ,'screen']),
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		search: (shape, categoryId, materialId, text, dealerId, status, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, distance, orderBy, order, perPage, page) => {
			dispatch(search(shape, categoryId, materialId, text, dealerId, status, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, distance, orderBy, order, perPage, page));
		},
		getCategories: () => {
			dispatch(getAll('createdAt', 'asc', 20, 1));
		},
		searchMaterial: (text, cateogryId, perPage) => {
			dispatch(searchName(text, cateogryId, perPage));
		},
		getMaterial:(id) => {
			dispatch(getMaterial(id));
		},
		addImpression: (id) => {
			dispatch(addImpression(id));
		},
		empty:() => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Search)