import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class MapMarkerIcon extends PureComponent{
	render(){
		const { classes } = this.props;
		return (
			<span className={classes.icon}>
				<svg viewBox="0 0 512 512">
					<path xmlns="http://www.w3.org/2000/svg" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"/>
				</svg>
			</span>
		)
	}
}
MapMarkerIcon.propTypes ={
	classes: PropTypes.object
};

export default injectSheet(style)(MapMarkerIcon);