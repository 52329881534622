import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { TextArea, Submit } from 'UI/Form';

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('message')) {
		errors.message = t('errors.required')
	}
	return errors
}

class MessageForm extends Component{
	submit = (values) => {
		const { reset, onSubmit } = this.props;
		if(onSubmit) onSubmit(values);
		if(reset) reset();
	}
	render(){
		const { handleSubmit, t } = this.props;
		return (
			<form onSubmit={ handleSubmit(this.submit.bind(this)) }>
				<Field
					name="message"
					placeholder={t('message.label')}
					component={TextArea}
					rows="4"
				/>
				<Submit isPrimary={true} text={t('message.send')}/>
			</form>
		);
	}
}
MessageForm.propTypes = {
	...propTypes,
	onCancel: PropTypes.func,
	t: PropTypes.func
};

export default reduxForm({
	form:'userMessageForm',
	validate
})(MessageForm);