import React, { Component } from 'react';
import { Transition } from 'react-transition-group';

class ShowAnimator extends Component {
	constructor(props){
		super(props);
		this.elem = React.createRef();
		this.state = {
			transitionStyles: {
			}
		}
	}
	onEnter = (node, done) => {
		this.height = this.elem.current.getBoundingClientRect().height;
	}
	onEntering = (node, done) => {
		if(node && node.animate) node.animate([
				{	height:0, transform: 'translate3d(-100px, 0, 0)', opacity:0},
				{	transform: 'translate3d(25px, 0, 0)', offset:0.7},
				{	height: this.height +'px' ,transform: 'translate3d(0, 0, 0)', opacity:1},
			],{duration:300, easing: "ease-in-out"} )
	}
	onEntered = (node, done) => {
	}
	onExit = (node, done) => {
		this.height = this.elem.current.getBoundingClientRect().height;
	}
	onExiting = (node, done) => {
		if(node && node.animate) node.animate([
			 	{ height: this.height +'px', transform: 'translate3d(0, 0, 0)', opacity:1},
			 	{	height: '1px', transform: 'translate3d(-100px, 0, 0)', opacity:0},
		 ],{duration:300, easing: "ease-in-out"} )
	}
	onExited = (node, done) => {
	}
	render(){
		const { animate, children } = this.props;
		return(
			<Transition 
				in={ animate }
				mountOnEnter={true}
				unmountOnExit={true}
				timeout={ 300 }
				onEnter={this.onEnter}
				onEntering={this.onEntering}
				onEntered={this.onEntered}
				onExit={this.onExit}
				onExiting={this.onExiting}
				onExited={this.onExited}
			>
				{ state  => (
					<div 
						style={{overflow: 'hidden'}}
						ref={this.elem}
					>
						{ children }
					</div>
				)}
			</Transition>
		)
	}
}

export default ShowAnimator;