import { connect } from 'react-redux';
import { recoverPassword } from 'state/actions/auth';
import RecoverPassword from './components';

const mapStateToProps = (state) => {
	return {
		error: state.getIn(['ui','error']),
		lastAction: state.getIn(['ui', 'lastAction'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		recoverPassword:(email) =>{
			dispatch(recoverPassword(email));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RecoverPassword)