import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, Map } from 'immutable';
import UserInfo from './UserInfo';
import Messages from './Messages';
import MessageForm from './MessageForm';
import injectSheet from 'react-jss';

const styles = theme => ({
	talk: {
		...theme.flex.container,
		...theme.flex.rows,
		backgroundColor: theme.colors.secondary,
		height: "100%",
		"-webkit-overflow-scrolling": "touch",
		overflow: 'auto',
		...theme.flex.elemSetFlex(70,'columns'),
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'columns')
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'columns')
		}
	}
})

class Talk extends Component {
	setRead = (id) => {
		const {setRead} = this.props;
		if(setRead && id) setRead(id);
	}
	cancel = () => {
		const { onUnselect } = this.props;
		if(onUnselect) onUnselect();
	}
	translate = (id) => {
		const { translate, conversation } = this.props;
		const conversationId = conversation.get('_id');
		if(id) translate(id, conversationId);
	}
	send = (values) => {
		const message = values.get('message');
		const { send, conversation } =  this.props;
		const conversationId = conversation?.get('_id');
		if(!!conversationId && !!message) send(conversationId, message);
	}
	viewProfile = (id) => {
		const { onViewProfile } = this.props;
		if(onViewProfile && id) onViewProfile(id);
	}
	render(){
		const { classes, t, messages, conversation, translations, screen } = this.props;
		const user = conversation?.get('recipient');
		const conversationId = conversation?.get('_id');
		return (
			<div className={classes.talk}>
				{ !!conversation && <UserInfo user={user} onSelect={this.viewProfile} screen={screen} onCancel={this.cancel}/> }
				{ !!conversation && <Messages messages={messages} conversationId={conversationId} setRead={this.setRead} translate={this.translate} translations={translations} />}
				{ !!conversation && <MessageForm t={t} onSubmit={this.send} />}
			</div>
		);
	}
}
Talk.propTypes ={
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	translations: PropTypes.instanceOf(Map).isRequired,
	setRead: PropTypes.func.isRequired,
	onViewProfile: PropTypes.func.isRequired,
	onUnselect: PropTypes.func.isRequired,
	messages: PropTypes.instanceOf(OrderedMap).isRequired,
	conversation: PropTypes.instanceOf(Map),
	send: PropTypes.func.isRequired
};
export default injectSheet(styles)(Talk);