import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import List from 'Routes/Stone/List';
import Add from 'Routes/Stone/Add';
import Update from 'Routes/Stone/Update';
import Images from 'Routes/Stone/Images';
import View from 'Routes/Stone/View';
import DealerView from 'Routes/Stone/DealerView';
//import Search from 'Routes/Stone/Search';
import ErrorPage from 'Routes/Errors/404';


class Router extends Component {
	isLogged = () => {
		const { isLogged } = this.props;
		if(isLogged === true) return true;
		else if(isLogged === false) return false;
		else return null;
	}
	isDealer = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && [3,5,6].includes(role) ) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	render() {
		const { t, body } = this.props;
		return (
			<Switch>
				

				<Route exact path="/stone/edit/:id" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isDealer() === false) return (<Redirect to='/'/>);
					return (<Update t={t} {...props} />)
				}} />

				<Route exact path="/stone/list/" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isDealer() === false) return (<Redirect to='/'/>);
					return (<List t={t} {...props} />)
				}} />

				<Route exact path="/stone/add/" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isDealer() === false) return (<Redirect to='/'/>);
					return (<Add t={t} body={body} {...props} />)
				}} />

				<Route exact path="/stone/dealer-view/:id" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isDealer() === false) return (<Redirect to='/'/>);
					return (<DealerView t={t} {...props} />)
				}} />

				<Route exact path="/stone/photos/:id" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isDealer() === false) return (<Redirect to='/'/>);
					return (<Images t={t} {...props} />)
				}} />

				<Route exact path="/stone/:permalink" render={(props)=>{ return <View t={t} {...props} />}}/>
				{ /* <Route exact path="/stone/list" render={(props)=>{ return <List t={t} {...props} />}}/>
				
				<Route exact path="/stone/search" render={(props)=>{ return <Search t={t} {...props} />}}/>
				<Route exact path="/stone/update/:id" render={(props)=>{ return <Update t={t} {...props} />}}/>
				<Route exact path="/stone/images/:id" render={(props)=>{ return <Images t={t} {...props} />}}/>
				<Route exact path="/stone/:permalink" render={(props)=>{ return <View t={t} {...props} />}}/>
				<Route exact path="/stone/dview/:id" render={(props)=>{ return <DealerView t={t} {...props} />}}/> */}
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}

Router.propTypes ={
	t: PropTypes.func,
	isLogged: PropTypes.bool,
	role: PropTypes.number,
	body: PropTypes.object,
};

export default Router;