import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, FieldGroup, InputMap, Submit, Switch, TextArea } from 'UI/Form';
import { CoveredWarehouse, Crane, Cutter, Envelope, Forklift, Link, Pallet, Phone, Rotate, Screwdriver, Sparkles, Truck, TruckLoading, UncoveredWarehouse, Water, Weight } from 'UI/Icon';

const styles = theme => ({
	root:{
		...theme.layout.container,
		['@media ' + theme.media.notTouch]: {
			...theme.flex.container
		}
	},
	content:{
		...theme.layout.section,
		paddingBottom: 0,
		paddingTop: 0,
		['@media ' + theme.media.notTouch]: {
			paddingBottom: '3rem',
			...theme.flex.elemSetFlex(60, 'row'),
		}
	},
	sectionTitle: {
		...theme.text.title5
	},
	rightColumn:{
		...theme.layout.section,
		paddingTop: 0,
		['@media ' + theme.media.notTouch]: {
			paddingTop: '3rem',
			...theme.flex.elemSetFlex(40, 'row'),
		}
	},
	actions:{
		...theme.layout.section,
		...theme.layout.container,
		paddingTop: 0
	}
});

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('name')) {
		errors.name = t('errors.required')
	}
	return errors
}

class WarehouseUpdate extends Component {
	render(){
		const { t, classes, handleSubmit } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<div className={classes.root}>
					<div className={classes.content}>
						<Field
							name="name"
							label={t('warehouse.name')}
							type="text"
							component={Input}
						/>
						<Field
							name="description"
							label={t('warehouse.description')}
							component={TextArea}
						/>
						<Field
							name="dim"
							label={t('warehouse.dimensions')}
							type="number"
							min="0"
							component={Input}
						/>
						<FieldGroup>
							<Field
								name="rows"
								label={t('warehouse.totalRows')}
								type="number"
								min="0"
								component={Input}
								icon={<Pallet />}
							/>
							<Field
								name="freeRows"
								label={t('warehouse.freeRows')}
								type="number"
								min="0"
								component={Input}
								icon={<Pallet />}
							/>
						</FieldGroup>
						<h4 className={classes.sectionTitle}>Servizi</h4>
						<Field
							name="services['loading']"
							label={t('warehouse.loading')}
							component={Switch}
							icon={<TruckLoading />}
						/>
						<Field
							name="services['transport']"
							label={t('warehouse.transport')}
							component={Switch}
							icon={<Truck />}
						/>
						<Field
							name="services['weight']"
							label={t('warehouse.weight')}
							component={Switch}
							icon={<Weight />}
						/>
						<Field
							name="services['weightVGM']"
							label={t('warehouse.weightVGM')}
							component={Switch}
							icon={<Weight />}
						/>
						<Field
							name="services['water']"
							label={t('warehouse.water')}
							component={Switch}
							icon={<Water />}
						/>
						<Field
							name="services['turn']"
							label={t('warehouse.turn')}
							component={Switch}
							icon={<Rotate />}
						/>
						<Field
							name="services['overheadCrane']"
							label={t('warehouse.overheadCrane')}
							component={Switch}
							icon={<Crane />}
						/>
						<Field
							name="services['crane']"
							label={t('warehouse.crane')}
							component={Switch}
							icon={<Forklift />}
						/>
						<Field
							name="services['cutting']"
							label={t('warehouse.cutting')}
							component={Switch}
							icon={<Cutter />}
						/>
						<Field
							name="services['polishing']"
							label={t('warehouse.polishing')}
							component={Switch}
							icon={<Sparkles />}
						/>
						<Field
							name="services['dressing']"
							label={t('warehouse.dressing')}
							component={Switch}
							icon={<Screwdriver />}
						/>
						<Field
							name="services['uncovered']"
							label={t('warehouse.uncovered')}
							component={Switch}
							icon={<UncoveredWarehouse />}
						/>
						<Field
							name="services['covered']"
							label={t('warehouse.covered')}
							component={Switch}
							icon={<CoveredWarehouse />}
						/>
						<h4 className={classes.sectionTitle}>Contatti</h4>
						<Field
							name="email"
							label={t('warehouse.email')}
							type="text"
							component={Input}
							icon={<Envelope />}
						/>
						<Field
							name="phone"
							label={t('warehouse.phone')}
							type="text"
							component={Input}
							icon={<Phone />}
						/>
						<Field
							name="website"
							label={t('warehouse.website')}
							type="text"
							component={Input}
							icon={<Link />}
						/>
					</div>
					<div className={classes.rightColumn}>
						<Field
							name="geoloc"
							label={t('warehouse.update.where')}
							component={InputMap}
						/>
						<h4 className={classes.sectionTitle}>Indirizzo</h4>
						<Field
							name="address.country"
							type="text"
							label={t('address.country')}
							component={Input}
						/>
						<Field
							name="address.state"
							type="text"
							label={t('address.state')}
							component={Input}
						/>
						<Field
							name="address.city"
							type="text"
							label={t('address.city')}
							component={Input}
						/>
						<Field
							name="address.address"
							type="text"
							label={t('address.address')}
							component={Input}
						/>
						<Field
							name="address.postCode"
							type="number"
							label={t('address.postalCode')}
							component={Input}
						/>
					</div>
				</div>
				<div className={classes.actions}>
					<Submit isPrimary={true} text={t('user.update.save')}/>
				</div>
			</form>
		);
	}
}

WarehouseUpdate.propTypes ={
	...propTypes,
	classes: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired
};

export default reduxForm({
	form:'depositUpdateForm',
	validate
})(injectSheet(styles)(WarehouseUpdate));