import {
	USER_GET,
	USER_LIST,
	USER_SELECT,
	USER_SELECT_ALL,
	USER_UNSELECT,
	USER_UNSELECT_ALL,
	USER_UPDATE,
	USER_REMOVE,
	USER_EMPTY
} from "state/actions/user";
import { fromJS, Map, OrderedMap } from 'immutable'

const initState = Map({
	selected:[],
	page:0,
	perPage:0,
	total:0,
	users:  OrderedMap({})
});

const user = ( state = initState, action ) => {
	switch(action.type){
		case USER_GET: {
			return state.setIn(['users', action.user._id], fromJS(action.user));
		}
		case USER_LIST: {
			let _users = [];
			for(let i = 0; i < action.users.length; i++){
				let _user = [ action.users[i]._id, fromJS(action.users[i])];
				_users.push(_user);
			}
			return state.mergeDeep({
				page: action.page,
				perPage: action.perPage,
				total: action.total,
				users: OrderedMap(_users),
			});
		}
		case USER_SELECT:{
			const list = state.get('selected');
			if(list.keyOf(action.id)>-1) return state;
			return state.merge({
				selected: list.push(action.id)
			})
		}
		case USER_SELECT_ALL:{
			return state.merge({
				selected: state.get('list')
			})
		}
		case USER_UNSELECT:{
			const list = state.get('selected');
			return state.merge({
				selected: list.delete(list.keyOf(action.id))
			})
		}
		case USER_UNSELECT_ALL:{
			return state.merge({
				selected: fromJS([])
			})
		}
		case USER_UPDATE: {
			const user = state.getIn(['users', action.id]);
			return state.setIn(['users', action.id], user.mergeDeep(fromJS(action.user)));
		}
		case USER_REMOVE: {
			const users = state.get('users');
			return state.merge({
				users:users.delete(action.id)
			});
		}
		case USER_EMPTY: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default user;