import { 
	STONE_GET,
	STONE_LIST,
	STONE_SELECT,
	STONE_SELECT_ALL,
	STONE_UNSELECT,
	STONE_UNSELECT_ALL,
	STONE_UPDATE,
	STONE_REMOVE,
	STONE_EMPTY
} from "state/actions/stone";
import { fromJS, Map, Set, OrderedMap } from 'immutable';

const initState = Map({
	selected: Set(),
	page: 0,
	perPage: 0,
	results: 0,
	stones: OrderedMap({})
});

const stone = ( state = initState, action ) => {
	switch(action.type){
		case STONE_GET: {
			return state.setIn(['stones', action.stone._id], fromJS(action.stone));
		}
		case STONE_LIST: {
			let _stones = [];
			const { stones, page, perPage, results } = action;
			for(let i = 0; i < stones.length; i++){
				let _stone = [ stones[i]._id, fromJS(stones[i])];
				_stones.push(_stone);
			}
			return state.mergeDeep({
				page,
				perPage,
				results,
				stones: OrderedMap(_stones),
			});
		}
		case STONE_SELECT:{
			const selected = state.get('selected');
			return state.set('selected', selected.add(action.id))
		}
		case STONE_SELECT_ALL:{
			const stones = state.get('stones');
			const list = [];
			stones.forEach(stone => {
				list.push(stone.get('_id'))
			});
			return state.set('selected', Set(list));
		}
		case STONE_UNSELECT:{
			const selected = state.get('selected');
			return state.set('selected', selected.delete(action.id))
		}
		case STONE_UNSELECT_ALL:{
			const selected = state.get('selected');
			return state.set('selected', selected.clear())
		}
		case STONE_UPDATE: {
			const stone = state.getIn(['stones', action.id]);
			return state.setIn(['stones', action.id], stone.mergeDeep(fromJS(action.stone)));
		}
		case STONE_REMOVE: {
			const stones = state.get('stones');
			return state.merge({
				stones:stones.delete(action.id)
			});
		}
		case STONE_EMPTY:{
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default stone;