import * as http from 'fetch/message';
import { start } from 'fetch/conversation';
import {
	startFetching,
	endFetching,
	storeError,
	removeError
} from 'state/actions/ui';

export const MESSAGE_GET = "MESSAGE_GET";
export const MESSAGE_LIST = "MESSAGE_LIST";
export const MESSAGE_SET_TIMEOUT = "MESSAGE_SET_TIMEOUT";
export const MESSAGE_TRANSLATE = "MESSAGE_TRANSLATE";
export const MESSAGE_EMPTY = "MESSAGE_EMPTY";
export const MESSAGE_NO_TIMEOUT = "MESSAGE_NO_TIMEOUT";
export const MESSAGE_MSG_TIMEOUT = "MESSAGE_MSG_TIMEOUT";

const storeGet = (answer) => {
	return {
		type: MESSAGE_GET,
		message : answer
	}
};

const storeList = (answer) => {
	return {
		type: MESSAGE_LIST,
		messages: answer.messages,
		page: answer.page,
		perPage: answer.perPage,
		results: answer.results 
	}
};

const storeTranslation = (translation) => {
	return {
		type: MESSAGE_TRANSLATE,
		translation
	}
}

const storeTimeout = (timeout) => {
	return {
		type: MESSAGE_SET_TIMEOUT,
		timeout
	}
};

const storeEmpty = () => {
	return {
		type: MESSAGE_EMPTY
	}
};

export const send = (conversationId, text) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('message_send'));
		try {
			const message = await http.send(conversationId, text);
			dispatch(storeGet(message));
			dispatch(endFetching('message_send','message_send_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('message_send','message_send_error'));
		}
	}
};

export const setRead = (id, conversationId) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('message_set_read'));
		try {
			await http.setRead(id, conversationId);
			dispatch(endFetching('message_set_read','message_set_read_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('message_set_read','message_set_read_error'));
		}
	}
};

export const list = (id, page) => {
	return async dispatch => {
		const query = { 
			conversation: id,
			orderBy: "sentAt",
			order: "desc",
			perPage: 100,
			page: page
		}
		dispatch(removeError());
		dispatch(startFetching('message_get_list'));
		try {
			const answer = await http.list(query);
			dispatch(storeList(answer));
			dispatch(endFetching('message_get_list','message_get_list_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('message_get_list','message_get_list_error'));
		}
	}
};

export const translate = (id, conversationId, language) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('message_translate'));
		try {
			const translation = await http.translate(id, conversationId, language);
			dispatch(storeTranslation(translation));
			dispatch(endFetching('message_translate','message_translate_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('message_translate','message_translate_error'));
		}
	}
}

export const listenToMessages = (id, millisec) => {
	return dispatch => {
		const query = { 
			conversation: id,
			orderBy: "sentAt",
			order: "desc",
			perPage: 100,
			page: 1
		}
		const timeout = setInterval( async ()=>{
			try {
				const answer = await http.list(query);
				dispatch(storeList(answer));
			} catch(error){
				console.log(error);
			}
		}, millisec);
		dispatch(storeTimeout(timeout));
	}
};

export const unlistenToMessages = (timeout) => {
	return dispatch => {
		clearInterval(timeout);
		dispatch(storeTimeout(null));
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};

export const startChat = (userId, text) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('message_start_chat'));
		try {
			const conversation = await start(userId);
			await http.send(conversation._id, text);
			dispatch(endFetching('message_start_chat','message_start_chat_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('message_start_chat','message_start_chat_error'));
		}
	}
} 