import React, { Component } from 'react';
import { List } from 'immutable'
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { staticUrl } from 'config/urls';
import ImageSlider from 'UI/ImageSlider';

const styles = theme => ({
	images:{
		...theme.flex.elemSetFlex(50,'row'),
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row')
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row')
		}
	}
});
class Images extends Component{
	render(){
		const { classes, images } = this.props;
		let slides = [];
		if(images && images.size){
			images.forEach( image => {
				let imageUrl = image.getIn(['urls', 'md']) || image.getIn(['urls', 'ld']);
				let url = staticUrl + "/" + imageUrl;
				slides.push(url)
			})
		} else if(images && images.size === 0){
			slides.push("/assets/images/no-image.jpg");
		}
		return (
			<div className={classes.images}>
				<ImageSlider images={slides} />
			</div>
		);
	}
}
Images.propTypes = {
	classes: PropTypes.object.isRequired,
	images: PropTypes.instanceOf(List),
};

export default injectSheet(styles)(Images);