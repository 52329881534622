import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
//import List from 'Routes/User/List';
import View from 'Routes/User/View';
import ErrorPage from 'Routes/Errors/404';

class Router extends Component {

	render() {
		const { t, body} = this.props;
		return (
			<Switch>
				<Route exact path="/user/:permalink" render={(props)=>{ return <View t={t} body={body} {...props} />}}/>
				{ /* <Route exact path="/stone/list" render={(props)=>{ return <List t={t} {...props} />}}/>
				<Route exact path="/stone/add" render={(props)=>{ return <Add t={t} {...props} />}}/>
				<Route exact path="/stone/search" render={(props)=>{ return <Search t={t} {...props} />}}/>
				<Route exact path="/stone/update/:id" render={(props)=>{ return <Update t={t} {...props} />}}/>
				<Route exact path="/stone/images/:id" render={(props)=>{ return <Images t={t} {...props} />}}/>
				<Route exact path="/stone/:permalink" render={(props)=>{ return <View t={t} {...props} />}}/>
				<Route exact path="/stone/dview/:id" render={(props)=>{ return <DealerView t={t} {...props} />}}/> */}
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>
			</Switch>
		);
	}
}

Router.propTypes ={
	t: PropTypes.func,
	isLogged: PropTypes.bool,
	role: PropTypes.number.isRequired,
	body: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		isLogged: state.getIn(['auth' ,'isLogged']),
		role: state.getIn(['auth', 'role'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Router)