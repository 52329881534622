export default {
	"category":{
		"type":{
			"Granite": "Granit",
			"Limestone": "Calcaire",
			"Marble": "Marbre",
			"Onyx": "Onyx",
			"Quartzite": "Quartzite",
			"Stone": "Pierre",
			"Travertine": "Travertin",
			"Ceramic": "Céramique"
		}
	},
	"errors":{
		"required": "champs obligatoires",
		"userNotFound": "L'utilisateur n'existe pas",
		"wrongPassword": "Mauvais mot de passe",
		"invalidEmail": "Email invalide",
		"noMatchPassword": "Les mots de passe ne correspondent pas",
		"UserAlreadyExists": "Cet email est déjà pris par un utilisateur enregistré"
	},
	"login":{
		"title": "Entre",
		"login": "Entre",
		"signup": "Inscrit-toi",
		"email": "Email",
		"password": "Password",
		"enter": "Entre",
		"forgotPassword": "Mot de passe oublié?"
	},
	"material":{
	},
	"navbar":{
		"enter": "Entre"
	},
	"signup":{
		"title": "Inscrit-toi",
		"login": "Entre",
		"signup": "Inscrit-toi",
		"email": "E-mail",
		"password": "Password",
		"passwordB": "Confirme ton mot de passe",
		"signin": "Inscrit-toi"
	},
	"stone":{
		"name": "Nom",
		"shape": "forme",
		"category": "Genre de Pierre",
		"material": "Matériaux",
		"height": "Hauteur",
		"width": "Largeur",
		"depth": "Profondeur",
		"thickness": "Épaisseur",
		"weight": "Poids",
		"coverArea": "Zone de couverture",
		"quantity": "Quantité",
		"shapeType":{
			"shapeless": "Sans forme",
			"block": "Bloc",
			"slab": "Dalle",
			"tile": "Carrelages"
		},
		"search":{
			"title": "Trouve les matériaux",
			"minHeight": "Hauteur minimum",
			"maxHeight": "Hauteur maximum",
			"minWidth": "Largeur minimum",
			"maxWidth": "Largeur maximum",
			"minDepth": "Profondeur minimum",
			"maxDepth": "Profondeur maximum",
			"minThickness": "Épaisseur minimum",
			"maxThickness": "Épaisseur maximum",
			"minWeight": "Poids minimum",
			"maxWeight": "Poids maximum",
			"minCoverArea": "Zone de couverture minimum",
			"maxCoverArea": "Zone de couverture maximum",
			"search": "Cherche"
		},
		"list":{
			"title": "Mes annonces",
			"add": "Ajoute",
			"images": "Images",
			"modify": "Modifie",
			"view": "Affiche",
			"delete": "Efface",
			"search": "Cherche"
		},
		"add":{
			"addImages": "Ajoute des photos",
			"where": "Où se trouve-t-il?",
			"save": "Enregistre",
			"cancel": "Deshace"
		},
		"update":{
			"where": "Où se trouve-t-il?",
			"save": "Enregistre",
			"cancel": "Deshace"
		},
		"images":{
			"addImages": "Ajoute des photos",
			"add": "Ajoute",
			"cancel": "Deshace"
		}
	},	
	"user":{
		"kind": "type d'utilisateur",
		"company": "Compagnie",
		"pro": "Marchand",
		"private": "Personnel",
		"firstName": "Nom",
		"lastName": "Prénom",
		"companyName": "Nom de la compagnie",
		"taxCode": "Numéro de TVA",
		"telephone": "Numéro de téléphone",
		"email": "E-mail",
		"update":{
			"save": "Enregistre",
			"cancel": "Deshace"
		},
		"images":{
			"addImages": "Ajoute des photos",
			"add": "Ajoute",
			"cancel": "Deshace"
		}		
	},
	"address":{
		"country":"Pays",
		"state": "Province / District / Etat",
		"city": "Ville",
		"address": "Adresse",
		"postalCode": "Code postal"
	},
	"message":{
		"call":"Connectez-vous ou inscrivez-vous pour envoyer un message au vendeur",
		"label": "Écrivez votre message",
		"send": "envoyer",
		"empty": "Pas de messages"
	}		
}