import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import Update from 'Routes/Me/Update';
import View from 'Routes/Me/View';
import Complete from 'Routes/Me/Complete';
import ToVerify from 'Routes/Me/ToVerify';
import ErrorPage from 'Routes/Errors/404';

class Router extends Component {
	isLogged = () => {
		const { isLogged } = this.props;
		if(isLogged === true) return true;
		else if(isLogged === false) return false;
		else return null;
	}
	isCompleted = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && role !== -2) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	isVerified = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && role !== -1) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	render() {
		const { t } = this.props;
		return (
			<Switch>
				<Route exact path="/me" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isVerified() === false) return (<Redirect to='/me/toVerify'/>);
					if(this.isCompleted() === false) return (<Redirect to='/me/complete'/>);
					if(this.isLogged() === true)	return (<View t={t} {...props} />)
				}} />
				<Route exact path="/me/update" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isVerified() === false) return (<Redirect to='/me/toVerify'/>);
					if(this.isCompleted() === false) return (<Redirect to='/me/complete'/>);
					if(this.isLogged() === true) return (<Update t={t} {...props} />)
				}} />
				<Route exact path="/me/toVerify" render={(props)=>{
					if(this.isLogged() === false) return (<Redirect to='/'/>);
					if(this.isVerified() === false) return (<ToVerify t={t} {...props} />)
					if(this.isVerified() === true) return (<Redirect to='/'/>);
					if(this.isCompleted() === false) return (<Redirect to='/me/complete'/>);
				}} />
				<Route exact path="/me/complete" render={(props)=>{ 
					if(this.isCompleted() === false) return (<Complete t={t} {...props}/>)
					if(this.isCompleted() === true) return (<Redirect to='/me'/>); return (<Redirect to='/me'/>)
				}}/>
				{/* <Route exact path="/me/complete" render={(props)=>{ return <Complete t={t} {...props}/>}}/>
				// <Route exact path="/me/update" render={(props)=>{ return <Update t={t} {...props}/>}}/> /> */}
				<Route render={(props)=>{ return <ErrorPage t={t} {...props} />}}/>/> */}
			</Switch>
		);
	}
}

Router.propTypes ={
	t: PropTypes.func
};

export default Router;