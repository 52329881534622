import { connect } from 'react-redux';
import { send } from 'state/actions/mailer';
import Contact from './components';

const mapStateToProps = (state) => {
	return {
		lastAction: state.getIn(['ui','lastAction'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		send: (email, subject, message) => {
			dispatch(send(email, subject, message));
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contact)