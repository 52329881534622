import { connect } from 'react-redux';
import { setBounds, setLocation } from 'lib/google-maps/actions';
import GMap from '../components';

const mapStateToProps = (state) => {
	return {
		ready: state.getIn(['gmaps','ready'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		setBounds: (latLngBounds) => {
			dispatch(setBounds(latLngBounds));
		},
		setLocation: (location) => {
			dispatch(setLocation(location));
		}
	}
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GMap)