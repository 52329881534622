import React, { Component } from 'react';
import { Map } from 'immutable'
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Main from "./Main";
import Details from "./Details";

const styles = theme => ({
	userView:{
		...theme.layout.section,
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.wrap,
		...theme.flex.wrapStart
	},
})

class MeView extends Component {
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	logout = () => {
		const { logout } = this.props;
		logout();
	}
	uploadImage = (image) => {
		const { me, uploadImage } = this.props;
		const id = me.get('_id');
		const images = me.get('images').toJS();
		if(id && image) uploadImage(id, image, images, 1);
	}
	componentDidMount(){
		const {get } = this.props;
		if(get) get();
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, me, t, uploadingProgress, isUploading } = this.props;
		let images = me.get('images');
		let name = me.get('name');
		let kind = me.get('kind');
		let group = me.get('group');
		return (
			<div className={classes.userView}>
				<Main 
					t={t}
					images={images}
					name={name}
					kind={kind}
					group={group}
					logout={this.logout}
					uploadingProgress={uploadingProgress}
					isUploading={isUploading}
					onUpload={this.uploadImage}
				/>
				<Details me={me} t={t} />
			</div>
		);
	}
}
MeView.propTypes ={
	classes: PropTypes.object.isRequired,
	me: PropTypes.instanceOf(Map).isRequired,
	isUploading: PropTypes.instanceOf(Map),
	uploadingProgress: PropTypes.instanceOf(Map),
	t: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired
};
export default injectSheet(styles)(MeView);