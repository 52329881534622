import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, InputMap, InputSearch, Submit, ImageSelect, RadioGroup, TextSelect, TextArea } from 'UI/Form';
import Button from 'UI/Button';
import ImagesUpload from 'UI/ImagesUpload';

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('name')) {
		errors.name = t('errors.required')
	}
	if (values.get('categoryId') !== "5b338212ecb90907caba2e48" && !values.get('materialId')) {
		errors.materialId = t('errors.required')
	}
	if (!values.get('categoryId')) {
		errors.categoryId = t('errors.required')
	}
	if (!values.get('shape')) {
		errors.shape = t('errors.required')
	}
	return errors
}

class AddForm extends Component{
	constructor(props){
		super(props);
		this.state = {
			shape:undefined
		}
	}
	search = (text) => {
		const { onSearch } = this.props;
		if(onSearch) onSearch(text);
	}
	setCategory = (event, newValue, prevValue, name) =>{
		const { onSetCategory, change, untouch } = this.props;
		if(newValue !== prevValue && onSetCategory){
			if(change) change('materialId','');
			if(untouch) untouch('materialId');
			onSetCategory(newValue);
		}
	}
	setShape = (event, newValue, prevValue, name) =>{
		const { change, untouch } = this.props;
		if(newValue && newValue !== prevValue){
			this.setState({shape:newValue});

			change('height', null);
			untouch('height', null);

			change('width', null);
			untouch('width', null);

			change('depth', null);
			untouch('depth', null);

			change('thickness', null);
			untouch('thickness', null);

			change('weight', null);
			untouch('weight', null);

			change('quantity', null);
			untouch('quantity', null);
		}
	}
	cancel = (event) => {
		const { onCancel } = this.props;
		event.preventDefault();
		onCancel();
	}
	render(){
		const { handleSubmit, categoryItems, materialItems, invalid, isUploading, uploadingProgress, t } = this.props;
		const { shape } = this.state;
		const items = [
			{ component: ImageSelect, image:"/assets/shapes/shapeless.png", value:"shapeless", label:t('stone.shapeType.shapeless')},
			{ component: ImageSelect, image:"/assets/shapes/block.png", value:"block", label:t('stone.shapeType.block')},
			{ component: ImageSelect, image:"/assets/shapes/slabs.png", value:"slab", label:t('stone.shapeType.slab')},
			{ component: ImageSelect, image:"/assets/shapes/tiles.png", value:"tile", label:t('stone.shapeType.tile')},
		]
		let _categoryItems = [];
		if(categoryItems)
			for(let i = 0; i < categoryItems.length; i ++){
				_categoryItems.push(
					{ component: TextSelect, value:categoryItems[i].value, label:categoryItems[i].name, isDisabled:categoryItems[i].value ==='5b338212ecb90907caba2e48' && shape!=='tile'}
				);
			}
		let mapIcon = "/assets/shapes/block_map.png";
		if(shape === "shapeless" ) mapIcon =  "/assets/shapes/shapeless_map.png";
		if(shape === "slab" ) mapIcon =  "/assets/shapes/slabs_map.png";
		if(shape === "tile" ) mapIcon =  "/assets/shapes/tiles_map.png";
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="images"
					label={t('stone.add.addImages')}
					component={ImagesUpload}
					isUploading={isUploading}
					progress={uploadingProgress}
				/>
				<Field
					name="name"
					type="text"
					label={t('stone.name')}
					component={Input}
				/>
				<Field
					name="shape"
					label={t('stone.shape')}
					component={RadioGroup}
					items={items}
					onChange={this.setShape}
				/>
				<Field
					name="category"
					label={t('stone.category')}
					component={RadioGroup}
					items={_categoryItems}
					onChange={this.setCategory}
				/>
				<Field
					name="material"
					label={t('stone.material')}
					component={InputSearch}
					onSearch={this.search}
					items={materialItems}
				/>
				{ shape &&
					<React.Fragment>
						{ (shape === 'block' || shape === 'shapeless') &&
							<Field
								name="weight"
								type="number"
								label={t('stone.weight') + " (t)"}
								component={Input}
							/>
						}
						{ (shape === 'slab' || shape === 'tile') &&
							<Field
								name="quantity"
								type="number"
								label={t('stone.quantity')}
								component={Input}
							/>	
						}
						<Field
							name="height"
							type="number"
							label={t('stone.height') + " (m)"}
							component={Input}
						/>
						<Field
							name="width"
							type="number"
							label={t('stone.width') + " (m)"}
							component={Input}
						/>
						{ (shape === 'block' || shape === 'shapeless') &&
							<Field
								name="depth"
								type="number"
								label={t('stone.depth') + " (m)"}
								component={Input}
							/>
						}
						{ (shape === 'slab' || shape === 'tile') &&
							<Field
								name="depth"
								type="number"
								label={t('stone.thickness') + " (mm)"}
								component={Input}
							/>
						}
					</React.Fragment> 
				}
				<Field
					name="description"
					label={t('stone.description')}
					component={TextArea}
				/>
				<Field
					name="geoloc"
					label={t('stone.add.where')}
					component={InputMap}
					icon={mapIcon}
				/>
				<Submit isPrimary={true} text={t('stone.add.save')} isDisabled={invalid}/>
				<Button text={t('stone.add.cancel')} onClick={this.cancel}/>
			</form>
		);
	}
}
AddForm.propTypes = {
	...propTypes,
	isUploading: PropTypes.instanceOf(Map).isRequired,
	uploadingProgress: PropTypes.instanceOf(Map).isRequired,
	onCancel: PropTypes.func.isRequired,
	onSearch: PropTypes.func.isRequired,
	onSetCategory: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default reduxForm({
	form:'stoneAddForm',
	validate,
	touchOnChange: true,
  touchOnBlur: true
})(AddForm);