import * as http from 'fetch/conversation';
import {
	startFetching,
	endFetching,
	storeError,
	removeError,
	clearNotification
} from 'state/actions/ui';

export const CONVERSATION_GET = "CONVERSATION_GET";
export const CONVERSATION_GET_MESSAGES_TO_READ = "CONVERSATION_GET_MESSAGES_TO_READ";
export const CONVERSATION_SET_TIMEOUT_MESSAGES_TO_READ = "CONVERSATION_SET_TIMEOUT_MESSAGES_TO_READ";
export const CONVERSATION_SET_TIMEOUT_LIST = "CONVERSATION_SET_TIMEOUT_LIST";
export const CONVERSATION_REMOVE = "CONVERSATION_REMOVE";
export const CONVERSATION_LIST = "CONVERSATION_LIST";
export const CONVERSATION_EMPTY = "CONVERSATION_EMPTY";

const storeGet = (conversation) => {
	return {
		type: CONVERSATION_GET,
		conversation
	}
};

const storeList = (answer) => {
	return {
		type: CONVERSATION_LIST,
		conversations: answer.conversations,
		page: answer.page,
		perPage: answer.perPage,
		results: answer.results 
	}
};

const storeRemove = (id) => {
	return {
		type: CONVERSATION_REMOVE,
		id
	}
};

const storeGetMessagesToRead = (messages) => {
	return {
		type: CONVERSATION_GET_MESSAGES_TO_READ,
		messages
	}
};

const storeTimeout = (messageToReadTimeout) => {
	return {
		type: CONVERSATION_SET_TIMEOUT_MESSAGES_TO_READ,
		messageToReadTimeout
	}
}

const storeListTimeout = (listTimeout) => {
	return {
		type: CONVERSATION_SET_TIMEOUT_LIST,
		listTimeout
	}
}

const storeEmpty = () => {
	return {
		type: CONVERSATION_EMPTY
	}
};

export const start = (recipient) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('conversation_start'));
		dispatch(clearNotification());
		try {
			const conversation = await http.start(recipient);
			dispatch(storeGet(conversation));
			dispatch(endFetching('conversation_start','conversation_start_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('conversation_start','conversation_start_error'));
		}
	}
};

export const remove = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('conversation_remove'));
		dispatch(clearNotification());
		try {
			await http.remove(id);
			dispatch(storeRemove(id));
			dispatch(endFetching('conversation_remove','conversation_remove_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('conversation_remove','conversation_remove_error'));
		}
	}
};

export const emptyMessagesToRead = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('conversation_empty_messages_to_read'));
		dispatch(clearNotification());
		try {
			await http.emptyMessagesToRead(id);
			dispatch(endFetching('conversation_empty_messages_to_read','conversation_empty_messages_to_read_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('conversation_empty_messages_to_read','conversation_empty_messages_to_read_error'));
		}
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('conversation_get'));
		dispatch(clearNotification());
		try {
			const conversation = await http.get(id);
			dispatch(storeGet(conversation));
			dispatch(endFetching('conversation_get','conversation_get_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('conversation_get','conversation_get_error'));
		}
	}
};

export const getMessagesToRead = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('conversation_get_messages_to_read'));
		dispatch(clearNotification());
		try {
			const messages = await http.getMessagesToRead(id);
			dispatch(storeGetMessagesToRead(messages));
			dispatch(endFetching('conversation_get_messages_to_read','conversation_get_messages_to_read_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('conversation_get_messages_to_read','conversation_get_messages_to_read_error'));
		}
	}
};

export const listenMessagesToRead = (millisec) => {
	return dispatch => {
		const timeout = setInterval( async () =>{
			try {
				const answer = await http.getMessagesToRead();
				dispatch(storeGetMessagesToRead(answer));
			} catch(error) {
				console.log(error);
			}
		}, millisec);
		dispatch(storeTimeout(timeout));
	}
};

export const unlistenMessagesToRead = (timeout) => {
	return dispatch => {
		clearInterval(timeout);
		dispatch(storeTimeout(null));
	}
};

export const list = (page) => {
	return async dispatch => {
		const query = {
			page,
			perPage: 100,
			orderBy: "lastMessageAt",
			order: "desc",
			status: "available"
		}
		dispatch(removeError());
		dispatch(startFetching('conversation_list'));
		dispatch(clearNotification());
		try {
			const answer = await http.list(query);
			dispatch(storeList(answer));
			dispatch(endFetching('conversation_list','conversation_list_done'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('conversation_list','conversation_list_error'));
		}
	}
};

export const listenList = (millisec) => {
	return dispatch => {
		const query = {
			page: 1,
			perPage: 100,
			orderBy: "lastMessageAt",
			order: "desc",
			status: "available"
		}
		const timeout = setInterval( async () =>{
			try {
				const answer = await http.list(query);
				dispatch(storeList(answer));
			} catch(error) {
				console.log(error);
			}
		}, millisec);
		dispatch(storeListTimeout(timeout));
	}
};

export const unlistenList = (timeout) => {
	return dispatch => {
		clearInterval(timeout);
		dispatch(storeListTimeout(null));
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};