import { 
	CONVERSATION_GET,
	CONVERSATION_GET_MESSAGES_TO_READ,
	CONVERSATION_SET_TIMEOUT_MESSAGES_TO_READ,
	CONVERSATION_SET_TIMEOUT_LIST,
	CONVERSATION_REMOVE,
	CONVERSATION_LIST,
	CONVERSATION_EMPTY	
	} from "state/actions/conversation";
import { Map, OrderedMap, fromJS } from 'immutable';

const initState = fromJS({
	page: 0,
	perPage: 0,
	results: 0,
	conversations: OrderedMap({}),
	messagesToRead: 0,
	messageToReadTimeout: null,
	listTimeout: null
});

const conversation = ( state = initState, action ) => {
	switch(action.type){
		case CONVERSATION_GET: {
			const { conversation } = action;
			return state.setIn(['conversations', conversation._id], fromJS(conversation));
		}
		case CONVERSATION_GET_MESSAGES_TO_READ: {
			const { messages } = action;
			return state.set('messagesToRead', messages);
		}
		case CONVERSATION_SET_TIMEOUT_MESSAGES_TO_READ: {
			const { messageToReadTimeout } = action;
			return state.set('messageToReadTimeout', messageToReadTimeout);
		}
		case CONVERSATION_SET_TIMEOUT_LIST: {
			const { listTimeout } = action;
			return state.set('listTimeout', listTimeout);
		}
		case CONVERSATION_LIST: {
			const _conversations = [];
			const { conversations, page, perPage, results } = action;
			for(let i = 0; i < conversations.length; i++){
				let conversation = [ conversations[i]._id, fromJS(conversations[i])];
				_conversations.push(conversation);
			}
			return Map({
				page: page,
				perPage: perPage,
				results: results,
				conversations: OrderedMap(_conversations),
			});
		}
		case CONVERSATION_REMOVE: {
			const { id } = action;
			const conversations = state.get('conversations');
			return state.merge({
				conversations: conversations.delete(id)
			});
		}
		case CONVERSATION_EMPTY: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default conversation;