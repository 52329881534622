import { connect } from 'react-redux';
import { logout } from 'state/actions/auth';
import { get, uploadImage, empty } from 'state/actions/me';
import View from './components';

const mapStateToProps = (state) => {
	return {
		me: state.get('me'),
		isUploading: state.getIn(['ui','isUploading']),
		uploadingProgress: state.getIn(['ui','uploadingProgress'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		logout:() => {
			dispatch(logout());
		},
		uploadImage:(id, image, images, index) => {
			dispatch(uploadImage(id, image, images, index))
		},
		empty: () => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(View)