import { apiUrl } from 'config/urls';
import { getToken } from 'lib/jwt';

const PATH = "/warehouse/";

export const update = async (id, warehouse) => {
	const url = `${apiUrl}${PATH}update`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		update: warehouse
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const setPublished = async (id, isPublished) => {
	const url = `${apiUrl}${PATH}setPublished`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		isPublished
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const addImage = async (id, imageId) => {
	const url = `${apiUrl}${PATH}addImage`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		imageId
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const removeImage = async (id, imageId) => {
	const url = `${apiUrl}${PATH}removeImage`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		imageId
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const addLogo = async (id, imageId) => {
	const url = `${apiUrl}${PATH}addLogo`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		imageId
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const removeLogo = async (id, imageId) => {
	const url = `${apiUrl}${PATH}removeLogo`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const data = {
		id,
		imageId
	};
	const response = await fetch(url, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data)
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const get = async (id)=> {
	const url = `${apiUrl}${PATH}get?`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	let params = new URLSearchParams();
	params.append('id', id);
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const list = async (query) => {
	const url = `${apiUrl}${PATH}list?`;
	const token = getToken();
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const params = new URLSearchParams();
	for(const [key, value] of Object.entries(query)) {
		if(value) params.append(key, value.toString());
	}
	const response = await fetch(url + params.toString(), {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}

export const mine = async () => {
	const url = `${apiUrl}${PATH}mine?`;
	let token = getToken();
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if(token) headers.append('Authorization', 'Bearer ' + token);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	if(response.status !== 200 ) throw new Error('Server Error');
	return response.json();
}