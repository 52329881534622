import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Camera } from 'UI/Icon';

const styles = theme => ({
	root:{
	},
	fileInput:{
		display: "none"
	}
});
class ImagesUpload extends Component{
	constructor(props){
		super(props);
		this.images = React.createRef();
	}
	add = () => {
		const { onAdd } = this.props;
		let fileList = this.images.current.files;
		if(onAdd) onAdd(fileList);
		this.images.current.value = '';
	}
	browseFile = () => {
		this.images.current.click();
	}
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root} >
				<Button Icon={Camera} onClick={this.browseFile} />
				<input name="images" type="file" accept="image/png, image/jpeg" className={classes.fileInput} ref={this.images} multiple={true} onChange={this.add}/>
			</div>
		);
	}
}
ImagesUpload.propTypes = {
	onAdd: PropTypes.func,
	classes: PropTypes.object
};

export default injectSheet(styles)(ImagesUpload);