import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import style from '../jss';

class Input extends PureComponent{
	render(){
		const { classes, icon, input, asyncerror, label, meta, placeholder, type, width } = this.props;
		const { touched, error } = meta;
		let inputClass = classes.input;
		let fieldClass = classes.field;
		if(touched && (error || asyncerror) ) inputClass += " is-error";
		if(touched && (!error && !asyncerror) ) inputClass += " is-success";
		if(icon) inputClass += " has-icon";
		if(width) fieldClass += " width";
		return (
			<div className={fieldClass} >
				{ label && <label className={classes.label} htmlFor={input.name}>{ label }</label> }
				<div className={classes.control}>
					<input {...input} className={inputClass} type={type} placeholder={placeholder}/>
					{ icon && 
						<span className={classes.icon}>
							 { icon }
						</span>
					}
					{touched && error && <p className={classes.error}> {error} </p>}
					{asyncerror && <p className={classes.error}> {asyncerror} </p>}
				</div>
			</div>
		);
	}
}
Input.propTypes = {
	classes: PropTypes.object,
	icon: PropTypes.element,
	width: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	type: PropTypes.string,
};

export default injectSheet(style)(Input);