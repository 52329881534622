import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedMap, List } from 'immutable';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Close } from 'UI/Icon';
import Image from "./Image";
import Details from "./Details";
import Stones from './Stones';
import Pagination from "UI/Pagination";

const styles = theme => ({
	root: {
		...theme.layout.section,
		...theme.layout.container,
	},
	userView:{		
		...theme.flex.container,
		...theme.flex.elem,
		...theme.flex.wrap,
		...theme.flex.wrapStart
	},
	action:{
		position: 'absolute',
		top: '.5rem',
		left: '1.5rem',
		zIndex: 10
	},
});

class UserView extends Component {
	constructor(props){
		super(props);
		this.elem = React.createRef();
		this.isNew = true;
	}
	back = () => {
		const { history } = this.props;
		if(history) history.goBack();
	}
	showNotification = (text) => {
		const { showNotification } = this.props;
		if(!!text) showNotification(text);
	}
	sendMessage = (to, message) => {
		const { startChat } = this.props;
		if(!!to && !!message)	startChat(to, message)
	}
	goToNotFound = () =>  {
		const { history } = this.props;
		if(history) history.push('/not_found');
	}
	viewStone = (permalink) => {
		const { history } = this.props;
		if(history) history.push('/stone/' + permalink);
	}
	get = () => {
		const { match: { params }, get } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		if(id) get(id);
	}
	addStoneImpression = (id) => {
		const { addStoneImpression } = this.props;
		if(id) addStoneImpression(id);
	}
	startScroll = () => {
		const { body } = this.props;
		let node = this.elem.current;
		this._animationStart = null;
		let dim = node.getBoundingClientRect();
		this._currentScroll = body.scrollTop;
		this._offset = this._currentScroll + dim.top - 60;
		if(this._offset > 0){
			window.requestAnimationFrame(this.scroll);
		}
	}
	scroll = (timestamp) => {
		const { body } = this.props;
		if(!this._animationStart) this._animationStart = timestamp;
		let t = timestamp - this._animationStart;
		let scroll = t*(this._offset - this._currentScroll)/500 + this._currentScroll;
		if(t <= 500){
			body.scrollTop = scroll;
			window.requestAnimationFrame(this.scroll);
		}else{
			body.scrollTop =this._offset;
		}
	}
	getStones = (page) => {
		const { match: { params }, getAllByDealer } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		if(id){
			this.emptyStones();
			getAllByDealer(id, null, null, 10, page);
		} 
	}
	emptyStones  = () => {
		const { emptyStones } = this.props;
		emptyStones();
	}
	empty = () => {
		const { empty } = this.props;
		empty();
	}
	componentDidMount(){
		this.get();
		this.getStones(1);
	}
	componentDidUpdate(prevProps){
		const { t, stones, lastAction, error } = this.props;
		if(lastAction !== prevProps.lastAction && lastAction === "stone_get_all_done") this.isNew = false;
		if(stones && !stones.equals(prevProps.stones)){
			if(this.isNew === false) this.startScroll();
		}
		if(error && error.message === 'not found') this.goToNotFound();
		if(prevProps.lastAction !== lastAction && lastAction === "message_start_chat_done") this.showNotification(t('message.sent'));
	}
	componentWillUnmount(){
		this.empty();
	}
	render(){
		const { classes, match:{params}, users, stones, page, perPage, total, isLogged, t } = this.props;
		const permalink = params.permalink;
		const id = permalink.substring(permalink.length - 24);
		let user = users.get(id);
		let images = users.getIn([id, 'images']) || List();
		return (
			<div className={classes.root}>
				<div className={classes.action}><Button Icon={Close} isSmallIcon={true} onClick={this.back} /></div>
				<div className={classes.userView}>
					{ images && <Image images={images}/> }
					{ user && <Details t={t} user={user} isLogged={isLogged} sendMessage={this.sendMessage}/> }
				</div>
				<div ref={this.elem} />
				{ stones && <Stones t={t} stones={stones} onSelect={this.viewStone} onAddImpression={this.addStoneImpression}/> }
				{ total > 0 && <Pagination page={page} perPage={perPage} total={total} onChange={this.getStones} /> }
			</div>
		);
	}
}

UserView.propTypes = {
	classes: PropTypes.object.isRequired,
	body: PropTypes.object,
	users: PropTypes.instanceOf(OrderedMap).isRequired,
	stones: PropTypes.instanceOf(OrderedMap).isRequired,
	page: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	total: PropTypes.number,
	isLogged: PropTypes.bool,
	lastAction: PropTypes.string,
	get: PropTypes.func.isRequired,
	getAllByDealer: PropTypes.func.isRequired,
	addStoneImpression:  PropTypes.func.isRequired,
	empty: PropTypes.func.isRequired,
	emptyStones: PropTypes.func.isRequired,
	startChat: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
};

export default injectSheet(styles)(UserView);