import { GMAP_READY, GMAP_SET_BOUNDS, GMAP_SET_LOCATION } from 'lib/google-maps/actions';
import Immutable from 'immutable';

const initState = Immutable.fromJS({
	ready: false,
	latLngBounds: '',
	location: ''
});

const gmaps = (state = initState, action) => {
	switch(action.type){
		case GMAP_READY: {
			return state.set('ready', true)
		}
		case GMAP_SET_BOUNDS: {
			return state.set('latLngBounds', action.latLngBounds)
		}
		case GMAP_SET_LOCATION: {
			return state.set('location', action.location)
		}
		default:
			return state;
	}
};

export default gmaps;