import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Immutable from 'immutable';
import injectSheet from 'react-jss';
import Modal from 'UI/Modal';
import Card from 'UI/Card';
import Button from 'UI/Button';
import { Images } from 'UI/Icon';
import Preview from './Thumbs/Preview';
import { staticUrl } from 'config/urls';
import Image from './Thumbs/Image';

const styles = theme => ({
	content:{
		...theme.flex.container,
		...theme.flex.wrap
	},
	item:{
		padding: '.5rem'
	},
	tmpItem:{
		padding: '.5rem',
		display: 'none',
		'&.is-active':{
			display: 'block'
		}
	},
	actions:{
		...theme.flex.container,
		...theme.flex.columns,
		...theme.flex.endEnd
	},
	button: {
		marginLeft: '0.5rem'
	},
	input:{
		display: 'none'
	},
});

class PhotosModal extends Component{
	constructor(props){
		super(props);
		this.input = React.createRef();
		this.state = {
			tmp: undefined,
			imageList: undefined
		}
	}
	close = () => {
		const { onClose } = this.props;
		onClose();
	}
	browse = () => {
		this.input.current.click();
	}
	delete = (id) => {
		const { onDelete } = this.props;
		if(id) onDelete(id)
	}
	startUpload = () => {
		let fileList = this.input.current.files;
		this.setState({
			tmp: fileList
		});
	}
	upload = (file, index) => {
		const { onUpload } = this.props;
		if(file && index >= 0) onUpload(file, index)
	}
	componentDidMount(){
		const { list } = this.props;
		if(list) this.setState({
			imageList: list
		})	
	}
	componentDidUpdate(prevProps){
		const { list } = this.props;
		if(list && !list.equals(prevProps.list)){
			this.setState({
				imageList: list
			});
		}
	}
	render(){
		const { classes, isOpen, t, progress, isUploading, images } = this.props;
		const { imageList, tmp } = this.state;
		let thumbs = [];
		if(imageList && images){
			for(let i = 0; i < imageList.size; i++){
				let image = images.get(imageList.get(i));
				if(image){
					let url = staticUrl + '/' + image.get('url');
					let imageId = image.get('_id');
					thumbs.push(
						<div className={classes.item}	key={i}	>
							<Image
								url={url}
								id={imageId}
								onDelete={this.delete}
							/>
						</div>
					)
				}		
			}
		}
		if(tmp){
			for(let i = 0; i < tmp.length; i++){
				let key = 'tmp-'+i;
				thumbs.push(
					<div className={ classNames(classes.tmpItem, {'is-active':isUploading.get(i)})} key={key}>
						<Preview image={tmp[i]} progress={progress.get(i)} index={i} onUpload={this.upload}/>
					</div>
				)
			}
		}
		return (
			<Modal isOpen={isOpen} onClose={this.close}>
				<Card title={t('warehouse.managePhotos')} Icon={Images}>
					<div className={classes.content}>
						{ thumbs }
					</div>
					<div className={classes.actions}>
						<div className={classes.button}>
							<Button className={classes.button} isPrimary={true} text={t('warehouse.addPhotos')} onClick={this.browse} />
							<input name="images" type="file" accept="image/png, image/jpeg" className={classes.input} ref={this.input} multiple={true} onChange={this.startUpload}/>
						</div>
						<div className={classes.button}>
							<Button className={classes.button} text={t('warehouse.close')} onClick={this.close} />
						</div>
					</div>
				</Card>
			</Modal>
		)
	}
}

PhotosModal.propTypes = {
	classes: PropTypes.object.isRequired,
	images: PropTypes.instanceOf(Immutable.Map),
	list: PropTypes.instanceOf(Immutable.List),
	isUploading: PropTypes.instanceOf(Immutable.Map),
	uploadingProgress: PropTypes.instanceOf(Immutable.Map),
	t: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(PhotosModal);