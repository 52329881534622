import * as auth from 'fetch/auth';
import { 
	startFetching,
	endFetching,
	storeError,
	removeError,
	showNotification,
	clearNotification
} from 'state/actions/ui';

export const AUTH_AUTH = "AUTH_AUTH";

const storeAuth = (isLogged, role) => {
	return {
		type: AUTH_AUTH,
		isLogged,
		role
	}
};

export const login = (email, password) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_login'));
		dispatch(clearNotification());
		try {
			await auth.login(email, password);
			dispatch(authenticate());
			dispatch(endFetching('auth_login','auth_login_done'));
			dispatch(showNotification('Wellcome'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(storeAuth(false, 1));
			dispatch(endFetching('auth_login','auth_login_error'));
		}
	}
};

export const signup = (email, passwordA, passwordB) => {
	return async dispatch => {
		dispatch(removeError());
		try {
			await auth.signup(email, passwordA, passwordB);
			dispatch(login(email, passwordA));
		} catch (error){
			dispatch(storeError(error));
		}
	}
};

export const recoverPassword = (email) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_recover_password'));
		try {
			await auth.recoverPassword(email);
			dispatch(authenticate());
			dispatch(endFetching('auth_recover_password','auth_recover_password_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('auth_recover_password','auth_recover_password_error'));
		}
	}
};

export const changePasswordFromRecovery = (token, newPasswordA, newPasswordB) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_change_password_from_recovery'));
		try {
			await auth.changePasswordFromRecovery(token, newPasswordA, newPasswordB);
			dispatch(authenticate());
			dispatch(endFetching('auth_change_password_from_recovery','auth_change_password_from_recovery_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('auth_change_password_from_recovery','auth_change_password_from_recovery_error'));
		}
	}
};

export const appChangePasswordFromRecovery = (token, newPasswordA, newPasswordB) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_change_password_from_recovery'));
		try {
			await auth.appChangePasswordFromRecovery(token, newPasswordA, newPasswordB);
			dispatch(authenticate());
			dispatch(endFetching('auth_change_password_from_recovery','auth_change_password_from_recovery_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('auth_change_password_from_recovery','auth_change_password_from_recovery_error'));
		}
	}
};

export const emailVerify = (token) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_email_verify'));
		try {
			const answer = await auth.emailVerify(token);
			if(answer) dispatch(endFetching('auth_email_verify','auth_email_verify_done'));
			else dispatch(endFetching('auth_email_verify','auth_email_verify_error'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('auth_email_verify','auth_email_verify_error'));
		}
	}
};

export const appEmailVerify = (token) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_email_verify'));
		try {
			const answer = await auth.appEmailVerify(token);
			if(answer) dispatch(endFetching('auth_email_verify','auth_email_verify_done'));
			else dispatch(endFetching('auth_email_verify','auth_email_verify_error'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('auth_email_verify','auth_email_verify_error'));
		}
	}
};

export const logout = () => {
	return dispatch => {
		dispatch(removeError());
		auth.logout();
		dispatch(storeAuth(false, 0));
	}
};

export const authenticate = () => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('auth_authenticate'));
		try {
			const answer = await auth.authenticate();
			if(answer === 1){
				dispatch(storeAuth(false, 1));
				dispatch(endFetching('auth_authenticate','auth_authenticate_done'));
			}else {
				dispatch(storeAuth(true, answer));
				dispatch(endFetching('auth_authenticate','auth_authenticate_done'));
			}
		} catch(error){
			dispatch(storeError(error));
			dispatch(logout());
			dispatch(endFetching('auth_authenticate','auth_authenticate_error'));
		}
	}
};