import { connect } from 'react-redux';
import { getAll } from 'state/actions/category';
import { searchName } from 'state/actions/material';
import { get, update, empty } from 'state/actions/stone';
import Update from './components';

const mapStateToProps = (state) => {
	return {
		error: state.getIn(['ui','error']),
		lastAction: state.getIn(['ui','lastAction']),
		userId: state.getIn(['me', '_id']),
		stones: state.getIn(['stone','stones']),
		categories: state.getIn(['category','categories']),
		materials: state.getIn(['material','materials'])
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		get: (id) => {
			dispatch(get(id));
		},
		update: (id, stone) => {
			dispatch(update(id, stone));
		},
		getCategories: () => {
			dispatch(getAll('createdAt', 'asc', 20, 1));
		},
		materialSearch: (text, cateogryId, perPage) => {
			dispatch(searchName(text, cateogryId, perPage));
		},
		empty: () => {
			dispatch(empty());
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Update)