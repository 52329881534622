import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'config/i18n';
import { Translation } from 'react-i18next';
import GAnalytics from 'lib/google-analytics';
import { GMapLoader } from 'lib/google-maps';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import AppBar from './AppBar';
import Router from './Router';
import FloatButton from 'UI/FloatButton';
import { AngleUp } from 'UI/Icon';
//import ColorSelector from './ColorSelector';
import LangSelector from './LangSelector';
import Waiting from 'UI/Waiting';
import Notification from 'UI/Notification';
import Cookies from './Cookies'
import Footer from './Footer';
import ActionBar from './ActionBar';
import media from 'themes/default/media';

const styles = theme => ({
	'@global': {
		...theme.reset,
		"html, body, #root": {
			height: "100%",
			overflowX: "hidden",
			color: theme.colors.text.default.regular
		},
		"a":{
			color: theme.colors.link.default.regular,
			"&:hover":{
				color: theme.colors.link.default.hover,
			}
		},
		"::-webkit-scrollbar": {
			width: "0.5rem",
			height: "0.5rem"
		},
		"::-webkit-scrollbar-track": {
			background: "#fbfbfb",
		},
		"::-webkit-scrollbar-thumb": {
				background: theme.colors.border, 
		},
		"::-webkit-scrollbar-thumb:hover": {
			background: theme.colors.primary
		},
		'@keyframes spin': {
			from: {transform: 'rotate(0deg)'},
	    to: {transform: 'rotate(360deg)'}   
	 	}
	},  
	app:{
		position: 'relative',
		...theme.flex.container,
		...theme.flex.fill,
		...theme.flex.rows,
		fontFamily: "'Comfortaa', cursive"
	},
	body:{
		overflow: 'auto',
		"-webkit-overflow-scrolling": "touch",
		height: '100%'
	}
});

class App extends Component {
	constructor(props){
		super(props);
		this.i18n = i18n;
		this.state = {
			scrollUp: false,
			cookies: false,
			actionBar: false
		};
		this.body = React.createRef();
	}
	openCookies = () => {
		this.setState({
			cookies: true
		});
	}
	closeCookies = () => {
		this.setState({
			cookies: false
		});
		window.localStorage.setItem('cookiePolicy', true);
	}
	authenticate(){
		const { authenticate } = this.props;
		authenticate();
	}
	isDealer = () => {
		const { isLogged, role } = this.props;
		if (isLogged === true && [3,5,6].includes(role) ) return true;
		else if(isLogged === true ) return false;
		else return null;
	}
	getWindowSize(){
		if (window.matchMedia(media.fullHd).matches) {
			return 'fullHd';
		}
		if(window.matchMedia(media.desktop).matches) {
			return 'desktop';
		}
		if(window.matchMedia(media.laptop).matches) {
			return 'laptop';
		}
		if(window.matchMedia(media.tablet).matches) {
			return 'tablet';
		}
		if(window.matchMedia(media.mobile).matches) {
			return 'mobile';
		}
	}
	storeWindowSize = () => {
		const { changeScreen } = this.props;
		if(changeScreen) changeScreen(this.getWindowSize());
	}
	checkScroll = (event) => {
		if(event.target !== this.body.current) return;
		const { scrollUp } = this.state;
		if(event.target.scrollTop >= 500 && scrollUp === false){
			this.setState({
				scrollUp: true
			});
		} else if(event.target.scrollTop < 500 && scrollUp === true){
			this.setState({
				scrollUp: false
			});
		}
	}
	startScrollUp = () => {
		let body = this.body.current;
		this._animationStart = null;
		this._currentScroll = body.scrollTop;
		window.requestAnimationFrame(this.scrollUp);
	}
	scrollUp = (timestamp) => {
		let body = this.body.current;
		if(!this._animationStart) this._animationStart = timestamp;
		let t = timestamp - this._animationStart;
		let scroll = -t*this._currentScroll/500 + this._currentScroll;
		if (t <= 500) {
			window.requestAnimationFrame(this.scrollUp);
			body.scrollTop = scroll;
		}else{
			body.scrollTop = 0;
		}
	}
	changeColor = (color) => {
		const { changeColor } = this.props;
		if(changeColor) changeColor(color);
	}
	changeLang = (lang) => {
		this.i18n.changeLanguage(lang);
	}
	getMessagesToRead = () => {
		const { getMessagesToRead } = this.props;
		getMessagesToRead();
	}
	listenMessagesToRead = () => {
		const { listenMessagesToRead } = this.props;
		listenMessagesToRead(15000);
	}
	unlistenMessagesToRead = () => {
		const { unlistenMessagesToRead, messageToReadTimeout } = this.props;
		if(!!messageToReadTimeout) unlistenMessagesToRead(messageToReadTimeout);
	}
	componentDidMount(){
		if(!window.localStorage.getItem('cookiePolicy')) this.openCookies();
		window.addEventListener("resize", this.storeWindowSize);
		this.storeWindowSize();
		this.authenticate();
	}
	componentDidUpdate(prevProps){
		const { role } = this.props;
		if(role !== prevProps.role && !this.isDealer()) this.actionBarOpen();
	}
	actionBarOpen = () => {
		this.setState({
			actionBar: true
		});
	}
	actionBarClose = () => {
		this.setState({
			actionBar: false
		});
	}
	render(){
		const { classes, isLogged, role, isWaiting, error, info, messages, screen, lastAction } = this.props;
		const { scrollUp, cookies, actionBar } = this.state;
		return (
			<BrowserRouter>
				<Translation>
					{ 
						(t, { i18n }) => (
							<div className={classes.app}>
								<GAnalytics id={"UA-31119207-6"} />
								<GMapLoader apiKey={"AIzaSyBL0MuXlnkCYL9Sq8zMpjFMIqmV0BNfZOw"}/>
								<AppBar isLogged={isLogged} role={role} t={t} messages={messages} screen={screen} />
								{ actionBar && <ActionBar onClose={this.actionBarClose} /> }
								{ /* <ColorSelector onChangeColor={this.changeColor} color={color} /> */}
								<LangSelector onChangeLang={this.changeLang} lang={i18n.language} />
								<div className={classes.body} onScroll={this.checkScroll} ref={this.body} >
									<Router 
										isLogged={isLogged}
										role={role}
										t={t}
										i18n={i18n}
										getNotifications={this.getMessagesToRead}
										lastAction={lastAction}
										listenToNotifications={this.listenMessagesToRead}
										unlistenToNotifications={this.unlistenMessagesToRead}
										body={this.body.current}
									/>
								</div>
								<Waiting isWaiting={isWaiting} />
								<Notification isSuccess={true}>{info}</Notification>
								<Notification isError={true}>{error && error.message}</Notification>
								{ cookies && <Cookies close={this.closeCookies} /> }
								<Footer />
								{ scrollUp && <FloatButton Icon={AngleUp} onClick={this.startScrollUp} /> }
							</div>
						)
					}
				</Translation>
			</BrowserRouter>
		);
	}
}
App.propTypes ={
	authenticate: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	messages: PropTypes.number,
	noTimeout: PropTypes.number,
	lastAction: PropTypes.string,
	getMessagesToRead: PropTypes.func.isRequired
};


export default injectSheet(styles)(App);