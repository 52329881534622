import * as stoneAnalytics from 'fetch/analytics/stone';
import { storeError, removeError } from 'state/actions/ui';

export const ANALYTICS_STONE_STORE_STATS = "ANALYTICS_STONE_STORE_STATS";
export const ANALYTICS_STONE_STORE_IMPRESSIONS = "ANALYTICS_STONE_STORE_IMPRESSIONS";
export const ANALYTICS_STONE_STORE_CLICKS = "ANALYTICS_STONE_STORE_CLICKS";
export const ANALYTICS_STONE_ADD_IMPRESSION = "ANALYTICS_STONE_ADD_IMPRESSION";
export const ANALYTICS_STONE_ADD_CLICK = "ANALYTICS_STONE_ADD_CLICK";
export const ANALYTICS_STONE_EMPTY = "ANALYTICS_STONE_EMPTY";

const storeStats = (id, stats) => {
	return {
		type: ANALYTICS_STONE_STORE_STATS,
		id,
		stats
	}
};

const storeAddImpression = (id) => {
	return {
		type: ANALYTICS_STONE_ADD_IMPRESSION,
		id
	}
};

const storeAddClick = (id) => {
	return {
		type: ANALYTICS_STONE_ADD_CLICK,
		id
	}
};

const storeEmpty = () => {
	return {
		type: ANALYTICS_STONE_EMPTY
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		try {
			const stats = await stoneAnalytics.get(id);
			dispatch(storeStats(id, stats));
		} catch(error){
			dispatch(storeError(error));
		}
	}
};

export const addImpression = (id) => {
	return async dispatch => {
		dispatch(removeError());
		try {
			await stoneAnalytics.addImpression(id);
			dispatch(storeAddImpression(id)); 
		} catch(error){
			dispatch(storeError(error));
		}
	}
};

export const addClick = (id) => {
	return async dispatch => {
		dispatch(removeError());
		try {
			await stoneAnalytics.addClick(id);
			dispatch(storeAddClick(id)); 
		} catch(error){
			dispatch(storeError(error));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};