import * as stone from 'fetch/stone';
import * as imageFetch from 'fetch/image';

import {
	startFetching,
	endFetching,
	storeError,
	removeError,
	showNotification,
	clearNotification,
	startUploading,
	progressUploading,
	endUploading
} from 'state/actions/ui';

export const STONE_GET = "STONE_GET";
export const STONE_LIST = "STONE_LIST";
export const STONE_SELECT = "STONE_SELECT";
export const STONE_SELECT_ALL = "STONE_SELECT_ALL";
export const STONE_UNSELECT = "STONE_UNSELECT";
export const STONE_UNSELECT_ALL = "STONE_UNSELECT_ALL";
export const STONE_UPDATE = "STONE_UPDATE";
export const STONE_REMOVE = "STONE_REMOVE";
export const STONE_EMPTY = "STONE_EMPTY";

const storeGet = (answer) => {
	return {
		type: STONE_GET,
		stone : answer
	}
};

const storeList = (answer) => {
	return {
		type: STONE_LIST,
		stones: answer.stones,
		page: answer.page,
		perPage: answer.perPage,
		total: answer.results 
	}
};

const storeSelect = (id) => {
	return {
		type: STONE_SELECT,
		id
	}
};

const storeSelectAll = () =>{
	return {
		type: STONE_SELECT_ALL
	}
};

const storeUnselect = (id) => {
	return {
		type: STONE_UNSELECT,
		id
	}
};

const storeUnselectAll = () =>{
	return {
		type: STONE_UNSELECT_ALL
	}
};

const storeUpdate = (id, stone) => {
	return {
		type: STONE_UPDATE,
		id, 
		stone
	}
};

const storeRemove = (id) => {
	return {
		type: STONE_REMOVE,
		id
	}
}

const storeEmpty = () => {
	return {
		type: STONE_EMPTY
	}
};

export const add = (newStone) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_add'));
		dispatch(clearNotification());
		try {
			let images = newStone.images;
			delete newStone.images;
			const answer = await stone.add(newStone);
			if(images) images.forEach( (image, index) => {
				dispatch(uploadImage(answer._id, image, index));
			})
			dispatch(storeGet(answer));
			dispatch(endFetching('stone_add','stone_add_done'));
			dispatch(showNotification('Well Done!'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_add','stone_add_error'));
		}
	}
};

export const remove = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_remove'));
		dispatch(clearNotification());
		try {
			await stone.remove(id);
			dispatch(storeRemove(id));
			dispatch(endFetching('stone_remove','stone_remove_done'));
			dispatch(showNotification('Removed!'));
		} catch(error) {
			dispatch(storeError(error));
			dispatch(endFetching('stone_remove','stone_remove_error'));
		}
	}
};

export const update = (id, stoneUpdate) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_update'));
		dispatch(clearNotification());
		try {
			await stone.update(id, stoneUpdate);
			dispatch(storeUpdate(id, stoneUpdate));
			dispatch(endFetching('stone_update','stone_update_done'));
			dispatch(showNotification('Updated!'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_update','stone_update_error'));
		}
	}
};

export const uploadImage = (id, image, index) => {
	return dispatch => {
		const onProgress = (progress) => {
			dispatch(progressUploading(progress, index));
		}
		const onSuccess = async (err, newImage) => {
			if(err){
				dispatch(endUploading(index));
				dispatch(storeError(err));
				dispatch(endFetching('stone_upload_image:' + index, 'stone_upload_image_error:' + index));
			} else {
				try {
					await stone.addImage(id, newImage._id);
					dispatch(endUploading(index))
					dispatch(endFetching('stone_upload_image:' + index, 'stone_upload_image_done:' + index));
				} catch(error){
					dispatch(storeError(error));
					dispatch(endFetching('stone_upload_image:' + index, 'stone_upload_image_error:' + index));
				}
			} 
		}
		dispatch(removeError());
		dispatch(startFetching('stone_upload_image:' + index));
		dispatch(startUploading(index));
		imageFetch.upload(image, 'stone', id, onProgress, onSuccess);
	}
};

export const removeImage = (id, imageId) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_remove_image'));
		dispatch(clearNotification());
		try {
			await stone.removeImage(id, imageId);
			dispatch(endFetching('stone_remove_image','stone_remove_image_done'));
			dispatch(showNotification('Image removed!'));
		} catch (error) {
			dispatch(storeError(error));
			dispatch(endFetching('stone_remove_image','stone_remove_image_error'));
		}
	}
};

export const select = (id) => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeSelect(id));
	}
};

export const unselect = (id) => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeUnselect(id));
	}
}

export const selectAll = () => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeSelectAll());
	}
};

export const unselectAll = () => {
	return dispatch => {
		dispatch(removeError());
		dispatch(storeUnselectAll());
	}
};

export const get = (id) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_get'));
		try {
			const answer = await stone.get(id);
			if(!answer) return dispatch(storeError(new Error('not found')));
			dispatch(storeGet(answer));
			dispatch(endFetching('stone_get','stone_get_done'));
		} catch(error){
			dispatch(storeError(error.message));
			dispatch(endFetching('stone_get','stone_get_error'));
		}
	}
}

export const getAllMine = (orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_get_all'));
		try {
			const answer = await stone.listMine({orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('stone_get_all','stone_get_all_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_get_all','stone_get_all_error'));
		}
	}
};

export const getAllByDealer = (dealer, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_get_all'));
		try {
			const answer = await stone.list({dealer, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('stone_get_all','stone_get_all_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_get_all','stone_get_all_error'));
		}
	}
};

export const getAllByMaterial = (material, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_get_all'));
		try {
			const answer = await stone.list({material, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('stone_get_all','stone_get_all_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_get_all','stone_get_all_error'));
		}
	}
};

export const getAllByCategory = (category, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_get_all'));
		try {
			const answer = await stone.list({category, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('stone_get_all','stone_get_all_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_get_all','stone_get_all_error'));
		}
	}
};

export const searchMineByName = (name, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_search'));
		try {
			const answer = await stone.listMine({name, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('stone_search','stone_search_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_search','stone_search_error'));
		}
	}
};

export const search = (shape, category, material, text, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, orderBy, order, perPage, page) => {
	return async dispatch => {
		dispatch(removeError());
		dispatch(startFetching('stone_search'));
		try {
			const answer = await stone.list({shape, category, material, text, minWeight, maxWeight, minHeight, maxHeight, minWidth, maxWidth, minDepth, maxDepth, minCoverArea, maxCoverArea, lat, lng, orderBy, order, perPage, page});
			dispatch(storeList(answer));
			dispatch(endFetching('stone_search','stone_search_done'));
		} catch(error){
			dispatch(storeError(error));
			dispatch(endFetching('stone_search','stone_search_error'));
		}
	}
};

export const empty = () => {
	return dispatch => {
		dispatch(storeEmpty());
	}
};