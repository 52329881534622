import { 
	CATEGORY_GET, 
	CATEGORY_LIST,
	CATEGORY_EMPTY
} from "state/actions/category";
import { Map, OrderedMap, fromJS } from 'immutable';

const initState = Map({
	page: 0,
	perPage: 0,
	total: 0,
	categories: OrderedMap({})
});

const category = ( state = initState, action ) => {
	switch(action.type){
		case CATEGORY_GET: {
			return state.setIn(['categories', action.category._id], fromJS(action.category));
		}
		case CATEGORY_LIST: {
			let _categories = [];
			for(let i = 0; i < action.categories.length; i++){
				let _category = [ action.categories[i]._id, fromJS(action.categories[i])];
				_categories.push(_category);
			}
			return Map({
				page: action.page,
				perPage: action.perPage,
				total: action.total,
				categories: OrderedMap(_categories),
			});
		}
		case CATEGORY_EMPTY: {
			return initState;
		}
		default: {
			return state;
		}
	}
};

export default category;