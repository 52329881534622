const flex = {
	container: {
		display: 'flex'
	},
	rows:{
		flexDirection: 'column'
	},
	columns:{
		flexDirection: 'row'
	},
	wrap:{
		flexWrap: 'wrap'
	},
	noWrap:{
		flexWrap: 'nowrap'
	},
	fill:{
		margin: 0,
		height:'100%',
		width: '100%'
	},
	wrapStart:{
		alignContent: 'flex-start'
	},
	wrapCenter:{
		alignContent: 'center'
	},
	wrapEnd:{
		alignContent: 'flex-end'
	},
	wrapSpaceBetween:{
		alignContent: 'space-between'
	},
	wrapSpaceAround:{
		alignContent: 'space-around'
	},
	spaceAroundStart:{
		justifyContent: 'space-around',
		alignItems: 'flex-start'
	},
	spaceAroundCenter:{
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	spaceAroundEnd:{
		justifyContent: 'space-around',
		alignItems: 'flex-end'
	},
	spaceAroundStreched:{
		justifyContent: 'space-around',
		alignItems: 'stretch'
	},
	spaceBetweenStart:{
		justifyContent: 'space-between',
		alignItems: 'flex-start'
	},
	spaceBetweenCenter:{
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	spaceBetweenEnd:{
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	spaceBetweenStreched:{
		justifyContent: 'space-between',
		alignItems: 'stretch'
	},
	startStart:{
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	startCenter:{
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	startEnd:{
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
	},
	startStreched:{
		justifyContent: 'flex-start',
		alignItems: 'stretch',
	},
	centerStart:{
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	centerCenter:{
		justifyContent: 'center',
		alignItems: 'center',
	},
	centerEnd:{
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	centerStreched:{
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	endStart:{
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
	},
	endCenter:{
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	endEnd:{
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	endStreched:{
		justifyContent: 'flex-end',
		alignItems: 'stretch',
	},
	elem:{
		flex:1,
	},
	elemGrow:{
		flex: '1 1 100%',
	},
	elemInitial:{
		flex: '0 1 auto',
	},
	elemAuto:{
		flex: '1 1 auto',
	},
	elemNone:{
		flex: '0 0 auto',
	},
	elemSetFlex: (n, direction) => {
		return {
			flex:`1 1 ${n}%`,
			maxWidth: (direction === 'column')? '100%' : `${n}%`,
			maxHeight: (direction === 'column')? `${n}%` : '100%',
		}
	}
};
export default flex;