import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { Link } from 'react-router-dom';

const styles = theme => ({
	root: {
		position: 'absolute',
		bottom : 0,
		backgroundColor: 'rgba(102,102,102,0.8)',
		padding: "32px",
		width: '100%',
		zIndex: 10000
	},
	text:{
		color: "#fff",
		textAlign: 'center',
		fontSize: theme.text.small
	},
	link:{
		color: "#fff",
	},
	action:{
		marginTop: '16px',
		...theme.flex.container,
		...theme.flex.centerCenter
	}
})

class Cookies extends Component {
	close = () => {
		const { close } = this.props;
		close();
	}
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<p className={classes.text}>
					Questo sito o gli strumenti di terze parti in esso integrati fanno uso di cookie necessari per il funzionamento e per il raggiungimento delle finalità descritte nella <Link className={classes.link} to="/legal/policy">cookie policy</Link>.<br/>
					Per saperne di più o per revocare il consenso relativamente a uno o tutti i cookie, fai riferimento alla <Link className={classes.link} to="/legal/policy">cookie policy</Link>.<br/>
					Dichiari di accettare l’utilizzo di cookie chiudendo o nascondendo questo banner, cliccando un link o un pulsante o continuando a navigare in altro modo.
				</p>
				<div className={classes.action}><Button text="accetta" onClick={this.close} /></div>
			</div>
		);
	}
} 

Cookies.propTypes = {
	classes: PropTypes.object.isRequired,
	close: PropTypes.func.isRequired
}

export default injectSheet(styles)(Cookies);