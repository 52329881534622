import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { LogoDark } from 'Elements/Logo'

const styles = theme => ({
	root: {
		...theme.layout.container,
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		height: '100%'
	},
	logo: {
		marginBottom: '16px'
	},
	error:{
		...theme.text.title4,
		marginBottom: '16px'
	}
});

class ToVerify extends Component {
	render(){
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.logo}><LogoDark isBig={true} /></div>
				<div className={classes.error}>Ti abbiamo inviato una email, segui le istruzioni</div>
			</div>
		);
	}
}

ToVerify.propTypes = {
	classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(ToVerify);