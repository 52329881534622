import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { SignOut } from 'UI/Icon';
import UserImage from './UserImage';

const styles = theme => ({
	main:{
		position: "relative",
		...theme.flex.elemSetFlex(50,'row'),
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.startCenter,
		['@media ' + theme.media.mobile]: {
			...theme.flex.elemSetFlex(100,'row')
		},
		['@media ' + theme.media.tablet]: {
			...theme.flex.elemSetFlex(100,'row')
		}
	},
	image:{
		position: 'relative',
		width: "50%",
		marginBottom: "1rem",
		cursor: 'pointer',
		"& .hoverEl": {
			display: 'none'
		},
		"&:hover":{
			"& .hoverEl": {
				display: 'flex'
			},
		}
	},
	title:{
		...theme.text.title3
	},
	text:{
		...theme.text.title5
	},
	action:{
		position: 'absolute',
		...theme.flex.container,
		...theme.flex.centerCenter,
		top: '0',
		left: '0',
		height: '100%',
		width: '100%',
		background: 'rgba(0,0,0,0.2)'
	},
	hexagon:{
		clipPath: "polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)"
	},
})

class Main extends Component{
	upload = (image) => {
		const { onUpload } = this.props;
		onUpload(image);
	}
	logout = () => {
		const { logout } = this.props;
		logout();
	}
	openImageModal = () => {
		
	}
	render(){
		const { classes, name, kind, group, images, uploadingProgress, isUploading } = this.props;
		let displayName;
		if(name && kind === "company"){
			displayName = name.get('company')
		}else if(name){
			displayName = name.get('first');
			displayName += ' ' + name.get('last');
		}
		const image = (images) ? images.get(0): null;
		return (
			<div className={classes.main}>
				<UserImage 
					onUpload={this.upload}
					progress={uploadingProgress.get(1)}
					isUploading={isUploading.get(1)}
					image={image}
				/>
				<h1 className={classes.title}> { displayName }  </h1>
				<p className={classes.text}> { group }  </p>
				<Button Icon={SignOut} text={"logout"} onClick={this.logout} />
			</div>
		);
	}
}
Main.propTypes = {
	classes: PropTypes.object,
	images: PropTypes.object,
	list: PropTypes.object,
	name: PropTypes.object,
	signOut: PropTypes.func,
	onUpload: PropTypes.func.isRequired
};

export default injectSheet(styles)(Main);