import React, { Component } from 'react';
import classNames from "classnames"; 
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Menu from 'UI/Menu';
import style from '../jss';

class Select extends Component{
	constructor(props){
		super(props);
		this.state = {
			isOpen: false,
			geolocalized: false
		}
		this.input = React.createRef();
		this.field = React.createRef();
	}
	select = (event) => {
		const { onSelect } = this.props;
		const target = event.target;
		const value = target.getAttribute('value');
		const name = target.getAttribute('title');
		this.input.current.value = name;
		onSelect(value);
		this.close();
	}
	clickOutside = (event) => {
		if (this.field && this.field.current && !this.field.current.contains(event.target))
			this.blur();
	}
	blur = () => {
		//this.input.current.value = null;
		this.close();
	}
	focus = () => {
		const { onFocus } = this.props;
		if(onFocus) onFocus();
		this.input.current.value = null;
		this.setState({
			geoLocalized: false
		});
	}
	search = () => {
		const { onSearch } = this.props;
		if(onSearch) onSearch(this.input.current.value);
		this.open();
	}
	open = () => {
		this.setState({isOpen:true});
	}
	close = () => {
		this.setState({isOpen:false});
	}
	geoLocalize = () => {
		const { geoLocalized } = this.state;
		const { nearMeLabel, onSelect } = this.props;
		if(navigator && navigator.geolocation){
			navigator.geolocation.getCurrentPosition((data) => {
				this.setState({
					geoLocalized: !geoLocalized
				});
				let value = JSON.stringify({
					"location":{
						"lat": data.coords.latitude,
						"lng":data.coords.longitude
					}
				});
				this.input.current.value = (geoLocalized)? null: nearMeLabel;
				onSelect(value);
			});
		}
		// eliminare in produzione!!!!!
		// let position = {
		// 	coords: {
		// 		accuracy: 42,
		// 		altitude: null,
		// 		altitudeAccuracy: null,
		// 		heading: null,
		// 		latitude: 44.1131366,
		// 		longitude: 9.916707299999999,
		// 		speed: null
		// 	}
		// }
		// this.setState({
		// 	geoLocalized: !geoLocalized
		// });
		// let value = JSON.stringify({
		// 	"location":{
		// 		"lat": position.coords.latitude,
		// 		"lng":position.coords.longitude
		// 	}
		// });
		// this.input.current.value = (geoLocalized)? null: nearMeLabel;
		// if(geoLocalized){
		// 	onSelect(null);
		// 	this.input.current.value = null;
		// }else{
		// 	onSelect(value);
		// 	this.input.current.value = nearMeLabel;
		// }

		///------------------
	}
	componentDidMount() { 
		document.addEventListener('mousedown', this.clickOutside)
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.clickOutside);
	}
	componentDidUpdate(prevProps){
				
	}
	render(){
		const { classes, items, label, placeholder, name, icon, width, geolocalize } = this.props;
		const { isOpen, geoLocalized } = this.state;
		let inputClass = classes.input;
		let fieldClass = classes.field;
		if(items)
			for(let i = 0; i < items.length; i++){
				let item = items[i];
				let onClick = this.select;
				item.onClick = onClick;
			}
		if(icon) inputClass += " has-icon";
		if(geolocalize) inputClass += " has-geolocalize";
		if(width) fieldClass += " width";
		return (
			<div className={fieldClass} ref={this.field} >
				{ label && <label className={classes.label} htmlFor={name}>{ label }</label> }
				<div className={classes.control}>
					<input className={inputClass} placeholder={placeholder} ref={this.input} onChange={this.search} onFocus={this.focus}/>
					{ icon && 
						<span className={classes.icon}>
							 { icon }
						</span>
					}
					{ geolocalize && 
						<span className={classNames(classes.geolocIcon, {active: geoLocalized })} onClick={this.geoLocalize} >
							 { geolocalize }
						</span>
					}
					<div className={classes.menuContainer}><Menu show={isOpen} items={items}/></div>
				</div>
			</div>
		);
	}
}
Select.propTypes = {
	onSelect: PropTypes.func.isRequired,
	classes: PropTypes.object,
	icon: PropTypes.element,
	geolocalize: PropTypes.element,
	nearMeLabel: PropTypes.string,
	width: PropTypes.string,
	items: PropTypes.array,
	name: PropTypes.object,
	label: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	onFocus: PropTypes.func
};

export default injectSheet(style)(Select);