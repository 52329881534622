import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from 'UI/Button';
import { ArrowLeft, ArrowRight } from 'UI/Icon'

const styles = theme => ({
	root:{
		width: '100%',
		position: 'relative',
		overflow: 'hidden',
		boxShadow: theme.shadows.s1
	},
	slides:{
		width: '100%',
		...theme.flex.container,
		//transition: "transform 0.5s ease-in-out"
	},
	slide:{
		width: '100%',
		zIndex: 1,
		"& .outer":{
			position: 'relative',
			width: '100%',
			paddingTop: '100%',
			"& .content":{
				position: 'absolute',
				top: '0px',
				left: '0px',
				bottom: '0px',
				right: '0px',
				"& .body":{
					height: '100%',
					width: '100%',
					backgroundSize: 'cover',
					backgroundPosition: 'center center'
				}
			}
		}
	},
	left:{
		zIndex: 10,
		position: "absolute",
		opacity: .3,
		left: 0,
		top: 0,
		height: "100%",
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		"&:hover": {
			opacity: .6,
		}
	},
	right:{
		zIndex: 10,
		position: "absolute",
		opacity: .3,
		right: 0,
		top: 0,
		height: "100%",
		...theme.flex.container,
		...theme.flex.rows,
		...theme.flex.centerCenter,
		"&:hover": {
			opacity: .6,
		}
	}
});

class ImageSlider extends Component{
	constructor(props){
		super(props);
		this.state={
			width: undefined,
			slideWidth: undefined,
			translate: 0,
			index:0,
		}
		this._swipeThreshold = 100; //required min distance traveled to be considered swipe
  	this._swipeRestraint = 100; // maximum distance allowed at the same time in perpendicular direction
  	this._swipeAllowedTime = 1000; // maximum time allowed to travel that distance
		this.slider = React.createRef();
		this.slides = React.createRef();
	}
	setDimensions = () => {
		const { images } = this.props;
		if(images){
			let node = this.slider.current;
			const { width } = node.getBoundingClientRect();
			this.setState({
				width: width * images.length,
				slideWidth: width,
				index: 0
			});
			this.slides.current.style.width = (width * images.length) +'px';
		}
	}
	swipeStartHandler = (e) => {
		//	e.preventDefault();
		const { index, slideWidth } = this.state;
		var touchobj = e.changedTouches[0];
		this._swipeStartX = touchobj.pageX + index*slideWidth;
		this._swipeStartY = touchobj.pageY;
		this._swipeStartTime = new Date().getTime();
		this.slides.current.style.transition = "";
		//e.preventDefault();
	}
	swipeMoveHandler = (e) => {
		//e.preventDefault();
		var touchobj = e.changedTouches[0];
		let distX = touchobj.pageX - this._swipeStartX;
		this.slides.current.style.transform = `translate3d(${distX}px, 0px, 0px)`
	}
	swipeEndHandler = (e) => {
		//e.preventDefault();
		const { index, slideWidth } = this.state;
		var touchobj = e.changedTouches[0];
		let distX = touchobj.pageX - this._swipeStartX + index*slideWidth;;
		let distY = touchobj.pageY - this._swipeStartY;
		let elapsedTime = new Date().getTime() - this._swipeStartTime;
		if(elapsedTime <= this._swipeAllowedTime && Math.abs(distX) >= this._swipeThreshold && Math.abs(distY) <= this._swipeRestraint){
			if(distX < 0) return this.next();
			if(distX > 0) return this.prev();
		} else {
			this.backPosition();
		}
	}
	backPosition = () => {
		const { index, slideWidth } = this.state;
		this.slides.current.style.transition = "transform 0.5s ease-in-out";
		this.slides.current.style.transform = `translate3d(${-(index) * slideWidth}px, 0px, 0px)`
	}
	next = () => {
		this.slides.current.style.transition = "transform 0.5s ease-in-out";
		const { images } = this.props;
		const { index, slideWidth } = this.state;
		if(index === images.length - 1){
			this.slides.current.style.transform = `translate3d(${-(index) * slideWidth}px, 0px, 0px)`
		}else{
			this.slides.current.style.transform = `translate3d(${-(index + 1) * slideWidth}px, 0px, 0px)`
			this.setState({index: index + 1});
		}
	}
	prev = () => {
		this.slides.current.style.transition = "transform 0.5s ease-in-out";
		const { index, slideWidth } = this.state;
		if(index === 0){
			this.slides.current.style.transform = `translate3d(${0}px, 0px, 0px)`
		}else{
			this.slides.current.style.transform = `translate3d(${-(index - 1) * slideWidth}px, 0px, 0px)`
			this.setState({index: index - 1});
		}
	}
	componentDidMount(){
		setTimeout(this.setDimensions,100);
	}
	componentDidUpdate(prevProps){
		const { images } = this.props;
		if(JSON.stringify(images)!==JSON.stringify(prevProps.images))
			this.setDimensions();
	}
	render(){
		const { classes, images } = this.props;
		const { width, index } = this.state;
		let slides = [];
		if(images)
			for(let i = 0; i < images.length; i++){
				let url = images[i];
				slides.push(
					<div key={i} className={classes.slide} >
						<div className="outer">
							<div className="content">
								<div className="body" style={{backgroundImage:'url('+ url +')'}}/>
							</div>
						</div>
					</div>
				)
			}
		return (
			<div className={classes.root} ref={this.slider} onTouchEnd={this.swipeEndHandler} onTouchMove={this.swipeMoveHandler} onTouchStart={this.swipeStartHandler} >
				<div className={classes.slides} style={{width: width }} ref={this.slides}> 
					{ slides }
				</div>
				{ index >0 && 
					<div className={classes.left}>
						<Button Icon={ArrowLeft} onClick={this.prev}/>
					</div>
				}
				{ index < slides.length - 1 && 
					<div className={classes.right}>
						<Button Icon={ArrowRight} onClick={this.next} />
					</div>
				}
			</div>
		);
	}
}
ImageSlider.propTypes = {
	classes: PropTypes.object,
	images: PropTypes.array
};

export default injectSheet(styles)(ImageSlider);