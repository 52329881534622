import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, propTypes  } from 'redux-form/immutable';
import { Input, Submit } from 'UI/Form';
import CheckBox from 'UI/CheckBox';

const validate = (values, props) => {
	const { t } = props;
	const errors = {}
	if (!values.get('email')) {
		errors.email = 'Required'
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('email'))) {
		errors.email = t('errors.invalidEmail');
	}
	if (!values.get('passwordA')) {
		errors.passwordA = t('errors.required');
	}
	if (!values.get('passwordB')) {
		errors.passwordB = t('errors.required');
	}else if(values.get('passwordA') !== values.get('passwordB')){
		errors.passwordB = t('errors.noMatchPassword');
	}
	if (!values.get('terms')) {
		errors.terms = t('errors.required');
	}
	if (!values.get('policy')) {
		errors.policy = t('errors.required');
	}
	return errors
}

class SignupForm extends Component{
	render(){
		const { handleSubmit, authError, t } = this.props;
		const policy = (
			<React.Fragment>
				<span>{t('signup.readAndAccepted')}</span><Link to="/legal/policy" target="_blank"> {t('signup.privacyPolicy')}</Link>
			</React.Fragment>
		)
		const terms = (
			<React.Fragment>
				<span>{t('signup.readAndAccepted')}</span><Link to="/legal/terms" target="_blank"> {t('signup.termsAndConditions')}</Link>
			</React.Fragment>
		)
		let userError, passwordError;
		if(authError?.message === 'user already exists')
			userError = t('errors.userAlreadyExists');
		if(authError && authError.message === 'passwords do not match')
			passwordError = t('errors.noMatchPassword');
		return (
			<form onSubmit={ handleSubmit }>
				<Field
					name="email"
					type="email"
					label={t('signup.email')}
					component={Input}
					asyncerror={userError}
				/>
				<Field
					name="passwordA"
					type="password"
					label={t('signup.password')}
					component={Input}
				/>
				<Field
					name="passwordB"
					type="password"
					label={t('signup.passwordB')}
					component={Input}
					asyncerror={passwordError}
				/>
				<Field
					name="terms"
					label={terms}
					component={CheckBox}
				/>
				<Field
					name="policy"
					label={policy}
					component={CheckBox}
				/>
				<Submit text={t('signup.signin')}/>
			</form>
		);
	}
}
SignupForm.propTypes = {
	...propTypes,
	authError: PropTypes.object,
	t: PropTypes.func
};

export default reduxForm({
	form:'signupForm',
	validate
})(SignupForm);

////Ho letto e accetto la nostra privacy policy, le disposizioni generali, termini e condizioni di utilizzo e l'informativa sulla privacy
				 //Accetto di ricevere newsletter con informazioni commerciali e promozionali relative al servizio (facoltativa)