export const GMAP_READY = "GMAP_READY";
export const  GMAP_SET_BOUNDS = "GMAP_SET_BOUNDS";
export const  GMAP_SET_LOCATION = "GMAP_SET_LOCATION";

export const setReady = () => {
	return {
		type:GMAP_READY,
	};
};

export const setBounds = (latLngBounds) => {
	return {
		type: GMAP_SET_BOUNDS,
		latLngBounds
	}
};

export const setLocation = (location) => {
	return {
		type: GMAP_SET_LOCATION,
		location
	}
};